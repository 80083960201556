import * as React from "react";
import { connect } from "react-redux";
import FeatureCard from "src/components/offers/shared/FeatureCard";
import localeService from "src/dataServices/LocaleService";
import localeEnabledForV2, { LocaleConfigStrings } from "src/common/localeEnabledForV2";
import { IApplicationState } from "src/state";
import { IFeatureFlagsState } from "src/state/feature-flags";
import { isBingAdsOfferAvailable } from "src/components/offers/bingAds/BingAdsUtils";
import { INonprofitMiniProfile, OfferId } from 'src/state/nonprofit';
import useCommunityTrainingOffer from '../offers/communitytraining/useCommunityTrainingOffer';

interface IOurProductsFeatureCardsStateProps {
  featureFlags: IFeatureFlagsState;
  nonprofit: INonprofitMiniProfile;
}

interface IOurProduct {
  id: string;
  title: string;
  description: string;
  subText: string;
  subTextNew: string;
  ctaText: string;
  ctaLink: string;
  imgSrc: string;
  imgAlt: string;
}

export type IOurProductsFeatureCardsProps = IOurProductsFeatureCardsStateProps;

const OurProductsFeatureCards = (props: IOurProductsFeatureCardsProps) => {
  const { isCommunityTrainingOfferAvailable } = useCommunityTrainingOffer();

  const ourProducts = localeService.getCategoryContent('HomeV2').OurProducts as IOurProduct[];
  const filteredProducts = ourProducts.filter((product) => {
    if (product.id === 'bingAds') {
      return (
        isBingAdsOfferAvailable(props.nonprofit, props.featureFlags) && localeEnabledForV2(LocaleConfigStrings.BingAds)
      );
    }

    if (product.id === OfferId.community_training) {
      return isCommunityTrainingOfferAvailable;
    }

    return true;
  });

  const reverseTextAlign = (index: number) => {
    return index % 2 === 0;
  };

  const renderCard = (card: IOurProduct, index: number) => {
    return (
      <FeatureCard
        key={index}
        title={card.title}
        titleClassName={'h3'}
        description={card.description}
        boldSubtext={card.subTextNew}
        ctaText={card.ctaText}
        ctaLink={card.ctaLink}
        reverseTextAlign={reverseTextAlign(index)}
        offset={true}
        imageProps={{
          id: 'HomePageBannerImage',
          root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/HomePage`,
          imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/HomePage/${card.imgSrc}`,
          imageClassName: 'card-img mx-0 card-offset-img pl-0',
          imageAlt: card.imgAlt
        }}
      />
    );
  };

  return (
    <section className="mx-5 my-5">
      <div className="text-center">
        <h2 className="h2">{localeService.getText('HomeV2', 'OurProductsHeading')}</h2>
      </div>
      {filteredProducts.map((card, index) => renderCard(card, index))}
    </section>
  );
};

const mapStateToProps = ({ flags, nonprofit }: IApplicationState) => ({
  featureFlags: flags,
  nonprofit: nonprofit.miniProfile
});

export default connect(mapStateToProps)(OurProductsFeatureCards);
