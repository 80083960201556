import { action } from "typesafe-actions";
import {
  AzureFormSubmission,
  AzureOfferActionTypes,
  AzureRegistrationInfo,
  AzureRegistrationResultCode,
  AzureTokenValidationResult
} from "./types";

export const fetchAzureRegistrationInfo = () => action(AzureOfferActionTypes.FETCH_REGISTRATION_INFO);
export const setAzureRegistrationInfo = (info: AzureRegistrationInfo | undefined | null) =>
  action(AzureOfferActionTypes.SET_REGISTRATION_INFO, info);
export const setAzureRegistrationInfoError = () => action(AzureOfferActionTypes.SET_REGISTRATION_INFO_ERROR);

export const initiateAzureRegistration = (values: AzureFormSubmission) =>
  action(AzureOfferActionTypes.INITIATE_REGISTRATION, values);
export const initiateAzureRegistrationForOfferId = (offerId: string) =>
  action(AzureOfferActionTypes.INITIATE_REGISTRATION_FOR_OFFER_ID, offerId);
export const resetAzureRegistrationForOfferId = (offerId: string) =>
  action(AzureOfferActionTypes.RESET_REGISTRATION_FOR_OFFER_ID, offerId);

export const setAzureRegistrationResult = (result: AzureRegistrationResultCode) =>
  action(AzureOfferActionTypes.SET_REGISTRATION_RESULT, result);
export const initiateAzureRenewal = (token: string) => action(AzureOfferActionTypes.INITIATE_RENEWAL, token);
export const setAzureTokenValidationResult = (result: AzureTokenValidationResult) =>
  action(AzureOfferActionTypes.SET_TOKEN_RESULT, result);
