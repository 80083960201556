import * as React from 'react';
import localeEnabledForV2, { LocaleConfigStrings } from 'src/common/localeEnabledForV2';
import FollowUsFooter from 'src/components/followUsFooter/FollowUsFooter';
import localeService from 'src/dataServices/LocaleService';
import { ContactUsSection } from '../shared/ContactUsSection';
import InfoSection from '../shared/InfoSection';
import NavigationBar from '../shared/NavigationBar';
import PartnersBanner from '../shared/PartnersBanner';
import PricingDisclaimer from '../shared/PricingDisclaimer';
import StandardTrainingSection from '../shared/StandardTrainingSection';
import CloudRegisterNowBanner from './CloudRegisterNowBanner';
import CloudSteps from './CloudSteps';
import CloudTopBanner from './CloudTopBanner';
import './CloudV2.scss';
import IndustrySolutionPlans from './IndustrySolutionPlans';
import IndustrySolutionPlansV2 from './IndustrySolutionPlansV2';
import MicrosoftCloudPlansV2 from './MicrosoftCloudPlansV2';
import SolutionCenterHeading from './SolutionCenterHeading';

const CloudV2 = () => {
  const [stepsSection, setStepsSection] = React.useState<HTMLElement | null>(null);
  const [offerSection, setOfferSection] = React.useState<HTMLElement | null>(null);
  const [trainingSection, setTrainingSection] = React.useState<HTMLElement | null>(null);
  const [cloudSection, setCloudSection] = React.useState<HTMLElement | null>(null);
  const [nonprofitPlanSection, setNonprofitPlanSection] = React.useState<HTMLElement | null>(null);
  const spring2023VersionEnabledForLocale = localeEnabledForV2(LocaleConfigStrings.CloudSpring2023);

  return (
    <section>
      <NavigationBar
        offersTitle={localeService.getText('CloudV2', 'NavigationBarOffersTitle')}
        offersElement={offerSection}
        stepsElement={stepsSection}
        trainingElement={trainingSection}
      />
      <CloudTopBanner plansElement={cloudSection} />
      {spring2023VersionEnabledForLocale ? (
        <InfoSection
          category="CloudV2"
          imagePath1="hub-redesign/MC4NP-Feature1-1920.jpg"
          title1="UnifyingTrustedTitleV2"
          description1="UnifyingTrustedDetailV2"
          imagePath2="hub-redesign/MC4NP-Feature2-1920.jpg"
          title2="CreatingScalableTitleV2"
          description2="CreatingScalableDetailV2"
          imagePath3="hub-redesign/MC4NP-Feature3-1920.jpg"
          title3="SecureDonorTitleV2"
          description3="SecureDonorDetailV2"
          setRef={setOfferSection}
        />
      ) : (
        <InfoSection
          category="CloudV2"
          imagePath1="hub-redesign/MC4NP-Feature1-1920.jpg"
          title1="UnifyingTrustedTitle"
          description1="UnifyingTrustedDetail"
          imagePath2="hub-redesign/MC4NP-Feature2-1920.jpg"
          title2="CreatingScalableTitle"
          description2="CreatingScalableDetail"
          imagePath3="hub-redesign/MC4NP-Feature3-1920.jpg"
          title3="SecureDonorTitle"
          description3="SecureDonorDetail"
          setRef={setOfferSection}
        />
      )}
      <CloudRegisterNowBanner />
      <CloudSteps
        setRef={setCloudSection}
        plansElement={nonprofitPlanSection}
        springVersionEnabled={spring2023VersionEnabledForLocale}
      />
      {spring2023VersionEnabledForLocale ? (
        <IndustrySolutionPlansV2 setRef={setStepsSection} />
      ) : (
        <IndustrySolutionPlans setRef={setStepsSection} />
      )}
      <MicrosoftCloudPlansV2 setRef={setNonprofitPlanSection} />
      <SolutionCenterHeading />
      <PartnersBanner />
      <StandardTrainingSection setRef={setTrainingSection} />
      <ContactUsSection />
      <PricingDisclaimer pricingDisclaimer1="PricingDisclaimerCommonParagraph" />
      <FollowUsFooter />
    </section>
  );
};

export default CloudV2;
