import localeService from "src/dataServices/LocaleService";
import { extractLocale, isNullOrUndefined } from "src/common/utilities/Utilities";
import { ILanguage, IRouteDetail, RouteSettings } from "src/state/localization/types";

const languageCountryCodeMap = process.env.REACT_APP_LANGUAGE_COUNTRY_CODE_MAP?.split(",");

export function isLocalizationEnabled(): boolean {
  return process.env.REACT_APP_LOCALIZATION_ENABLED === "1";
}

export function getLocalizedPath(route: string | undefined): string {
  if (isLocalizationEnabled()) {
    let sessionLocale = sessionStorage.getItem('locale');
    sessionLocale = extractLocale(sessionLocale);
    const routeSetting = getRouteSetting(route);

    // disallowed routes should be routed to english.
    if (sessionLocale !== null && sessionLocale !== undefined && !routeSetting.isDisallowed) {
      return '/' + sessionLocale + route;
    } else {
      return '/en-us' + route;
    }
  } else {
    return route ?? '';
  }
}

export function getRouteSetting(route: string | undefined): IRouteDetail {
  if (isNullOrUndefined(route) === undefined) {
    return { isDisallowed: false, routeName: route } as IRouteDetail;
  }

  const currentLocale = localeService.getlocale() ? localeService.getlocale() : "en-us";
  let evaluatedLocale: string | undefined = currentLocale?.toLowerCase();

  // evaluate with session locale. If Session locale isn't the same as the rendered locale, it means that we are currently in an disallowed route for the locale.
  const sessionLocale = sessionStorage.getItem("locale");
  if (!isNullOrUndefined(sessionLocale) && sessionLocale && sessionLocale?.toLowerCase() !== currentLocale) {
    evaluatedLocale = sessionLocale;
  }

  const routeSetting = fetchLanguageDetail(route, evaluatedLocale);

  return { isDisallowed: !routeSetting.isValidForRoute, routeName: routeSetting.currentRoute } as IRouteDetail;
}

/**
 * Evaluates localization for a given path and locale.
 * @param pathName current Path to be evalulated
 * @param localeOption locale Option to be evaluated with.
 */
export function fetchLanguageDetail(pathName: string | undefined, locale: string | null | undefined): RouteSettings {
  let returnVal = { isValidForRoute: true, isValidForApp: true, currentRoute: pathName };

  if (locale === undefined) {
    return returnVal;
  }

  const localeOption = getLocaleSettings(locale);

  const disallowedRoutes =
    localeOption && localeOption.disallowedRoutes !== undefined ? localeOption.disallowedRoutes.split(",") : [];
  if (disallowedRoutes.length === 0) {
    return returnVal;
  }

  disallowedRoutes.forEach(currentDisallowedRoute => {
    if (!pathName || pathName?.includes(currentDisallowedRoute)) {
      returnVal.isValidForRoute = false;
      returnVal.isValidForApp = true;
      returnVal.currentRoute = currentDisallowedRoute;
    }
  });

  return returnVal;
}

export function getSupportedLanguages(): string[] {
  let localeInfo = [];
  let jsonStr;
  const pathName = window.location.pathname.toLowerCase();

  jsonStr = process.env.REACT_APP_SUPPORTED_LANGUAGES;
  localeInfo = JSON.parse(jsonStr ?? '') as ILanguage[];
  const languages: string[] = [];

  localeInfo.forEach(currentOption => {
    const validForRoute = fetchLanguageDetail(pathName, currentOption.locale).isValidForRoute;
    if (validForRoute) {
      languages.push(currentOption.locale.toLowerCase());
    }
  });

  return languages;
}

export function getLocaleSettings(locale: string | null) {
  if (isNullOrUndefined(locale)) {
    return undefined;
  }
  const jsonStr = process.env.REACT_APP_SUPPORTED_LANGUAGES;
  const localeInfo = JSON.parse(jsonStr ?? '') as ILanguage[];

  const matchedSetting = localeInfo.filter(value => value.locale.toLowerCase() === locale?.toLowerCase());

  return matchedSetting && matchedSetting.length > 0 ? matchedSetting[0] : undefined;
}

export function getBrowserSupportedLanguages(): string[] {
  const browserLanguages = window.navigator.languages;

  const validBrowserLanguages: string[] = [];

  browserLanguages.forEach(currentLanguage => {
    const normalizedISOCodes = normalizedISOCodeLowerCase(currentLanguage.toLowerCase());
    const isSupportedLanguage = getSupportedLanguages().includes(normalizedISOCodes.toLowerCase());

    if (isSupportedLanguage) {
      validBrowserLanguages.push(normalizedISOCodes.toLowerCase());
    }
  });

  return validBrowserLanguages;
}

export function isRedirectionRoute(currentPath: string, includeSignup: boolean = true): boolean {
  if (currentPath !== undefined && currentPath !== null) {
    const oldPaths = new Set(["/ngoportal", "/applicationstatus", "/reseller"]);
    const isSignupRedirection = includeSignup && currentPath.includes("/signup-complete");

    return oldPaths.has(currentPath) || isSignupRedirection;
  }

  return false;
}

/**
 * A handler that replaces the url if the path contains the locale that wasn't found in CDN with the fallback locale.
 */
export function handleFallbackRoute(currentPath: string, localeFailedToFetch: string | undefined, fallBackLocale: string) {
  // split into an array for exact string match for locale.
  const localePathArray = currentPath.split("/");

  // replace url with fallback locale only when newLocaleForEvaluation is valid and url has it. Other cases are handled by Router.
  if (
    !isNullOrUndefined(localeFailedToFetch) &&
    localeFailedToFetch &&
    localePathArray.includes(localeFailedToFetch.toLowerCase()) &&
    localeFailedToFetch.toLowerCase() !== fallBackLocale
  ) {
    currentPath = currentPath.replace("/" + localeFailedToFetch.toLowerCase(), "/" + fallBackLocale.toLowerCase());
    window.location.replace(window.location.origin + currentPath);
  }
}

export function isCurrentLocaleDefault() {
  if (isLocalizationEnabled()) {
    const sessionLocale = sessionStorage.getItem("locale");
    return !isNullOrUndefined(sessionLocale) && sessionLocale?.toLowerCase() === "en-us";
  }

  return true;
}

function normalizedISOCodeLowerCase(currentLocale: string): string {
  if (currentLocale.length === 2) {
    const languageCountryCode = getCountryCode(currentLocale);

    if (languageCountryCode !== undefined) {
      return languageCountryCode.toLowerCase();
    }
  }

  return currentLocale.toLowerCase();
}

function getCountryCode(languageCode: string) {
  const languageCountryMap = languageCountryCodeMap ?? [];

  for (const value of languageCountryMap) {
    if (languageCode === value.split("-")[0]) {
      return value.toLowerCase();
    }
  }

  return undefined;
}
