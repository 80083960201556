import { Icon } from "@fluentui/react/lib/Icon";
import * as React from "react";
import localeService from "src/dataServices/LocaleService";

export class EmailVerificationHeader extends React.Component {
  public render() {
    return (
      <span style={{ fontSize: 22, alignContent: "center" }}>
        <Icon iconName="MailCheck" style={{ fontSize: 60, height: 80, width: 80, top: 2, alignContent: "center" }} />
        <br />
        {localeService.getText("AgentVerification", "Email_Title")}
      </span>
    );
  }
}

export default EmailVerificationHeader;
