import "@ms-mwf/moray/dist/css/main.css";
import htmlParser from "html-react-parser";
import * as React from "react";

interface IOfferCardBadge {
  id: string;
  badgeText?: string;
};

export const OfferCardBadge = ({ id, badgeText }: IOfferCardBadge) => (
  <div id={id} className="card-body pt-3 offer-card-badge">
    <span className="badge bg-yellow ">{badgeText}</span>
  </div>
);

interface IOfferCardBadgeWithInfo {
  infoText?: string;
}

export const OfferCardBadgeWithInfo = ({ infoText }: IOfferCardBadgeWithInfo) => (
  <div className="card-body pt-3 mx-n4">
    <span
      className={`badge ${
        infoText ? 'bg-blue' : ''
      } px-4 d-flex w-100 align-items-center justify-content-center offer-card-badge-with-info`}
    >
      {infoText}
    </span>
  </div>
);

interface IOfferCardDescription {
  id: string;
  text?: string;
}

export const OfferCardDescription = ({ id, text }: IOfferCardDescription) => (
  <p id="PlanSectionHeaderDescription" className="mb-3">
    {text}
  </p>
);

interface IOfferCardFeatureHeading {
  children: React.ReactNode;
}

export const OfferCardFeatureHeading = ({ children }: IOfferCardFeatureHeading) => (
  <section>
    <div className="card d-block mx-md-0">
      <div className="row no-gutters material-surface justify-content-center">
        <div className="d-flex ">
          <div className="card-body align-self-center p-4 py-5 p-md-5 text-center">{children}</div>
        </div>
      </div>
    </div>
  </section>
);

interface IOfferCardList {
  id: string;
  glyph: string;
  list: string[];
}

export const OfferCardList = ({ id, glyph, list }: IOfferCardList) => (
  <div id={id}>
    {list.map((element) => (
      <div key={`${id}-${element}`} className="row sameline">
        <p className={`glyph-prepend glyph-prepend-large glyph-prepend-${glyph} text-blue mb-list-element`} />
        <p className="ml-3">{element}</p>
      </div>
    ))}
  </div>
);

export const OfferCardText = ({ id, text }: IOfferCardDescription) => (
  <p id={id} className="base">
    {htmlParser(text ?? '')}
  </p>
);

export const OfferCardLargeText = ({ id, text }: IOfferCardDescription) => (
  <p id={id} className="large">
    {htmlParser(text ?? '')}
  </p>
);

interface IOfferCardSubHeading {
  id: string;
  subHeading?: string;
}

export const OfferCardSubHeading = ({ id, subHeading }: IOfferCardSubHeading) => (
  <p id={id} className="font-weight-semibold large">
    {subHeading}
  </p>
);

interface IOfferCardTitle {
  id: string;
  title?: string;
}

export const OfferCardLargeTitle = ({ id, title }: IOfferCardTitle) => (
  <h2 id={id} className="font-weight-semibold h2 h2-max">
    {title}
  </h2>
);

export const OfferCardSmallTitle = ({ id, title }: IOfferCardTitle) => (
  <h5 id={id} className="font-weight-semibold h5">
    {title}
  </h5>
);

export const OfferCardTitle = ({ id, title }: IOfferCardTitle) => (
  <h3 id={id} className="font-weight-semibold h3">
    {title}
  </h3>
);

interface IOfferCardPrice {
  id: string;
  price?: string;
}

export const OfferCardPrice = ({ id, price }: IOfferCardPrice) => (
  <h3 id={id} className="text-blue font-weight-semibold my-2 h3">
    {price}
  </h3>
);

export const OfferCardSmallPrice = ({ id, price }: IOfferCardPrice) => (
  <h2 id={id} className="text-blue font-weight-semibold my-2 h2">
    {price}
  </h2>
);

interface IOfferCardDisclaimer {
  id: string;
  disclaimer?: string;
}

export const OfferCardDisclaimer = ({ id, disclaimer }: IOfferCardDisclaimer) => (
  <p id={id} className="small offer-card-disclaimer">
    {disclaimer}
  </p>
);

export const PlanSectionDisclaimer = ({ id, disclaimer }: IOfferCardDisclaimer) => (
  <p id={id} className="small plan-section-disclaimer">
    {disclaimer}
  </p>
);

interface IOfferCardSection {
  id: string;
  children: React.ReactNode;
}

export const OfferCardSection = ({ id, children }: IOfferCardSection) => (
  <div id={id} className="card-body pt-3">
    {children}
  </div>
);

export const OfferCardFauxColumn = () => <div className="card-body faux-column" />;

export const OfferCardMiniFauxColumn = () => <div className="card-body faux-column-mini" />;

interface IOfferCardButton {
  id: string;
  buttonText?: string;
  buttonLink?: string;
  buttonAriaLabel?: string;
}

export const OfferCardButton = ({ id, buttonText, buttonLink, buttonAriaLabel }: IOfferCardButton) => (
  <button
    role="link"
    id={id}
    className="btn btn-primary btn-sm mr-4"
    aria-label={buttonAriaLabel == undefined ? buttonText : buttonAriaLabel}
    onClick={() => window.open(buttonLink, '_blank', 'noopener,noreferrer')}
  >
    {buttonText}
  </button>
);

interface IOfferCardCTA {
  ctaText?: string;
  ctaLink?: string;
};

export const OfferCardCTA = ({ ctaText, ctaLink }: IOfferCardCTA) => (
  <a href={ctaLink} target="_blank" rel="noopener noreferrer" className="cta" aria-label={ctaText}>
    {ctaText}
    <span className="glyph" aria-hidden="true"></span>
  </a>
);

interface IOfferCardLinkGroup {
  children: React.ReactNode;
};

export const OfferCardLinkGroup = ({ children }: IOfferCardLinkGroup) => <div className="link-group">{children}</div>;

interface IOfferCard {
  children: React.ReactNode;
};

export const OfferCard = ({ children }: IOfferCard) => (
  <div className="col mb-5 mb-md-0 bg-white offer-card-container">
    <div className="card px-4 pt-2 pb-4" data-mount="click-group">
      {children}
    </div>
  </div>
);

interface IOfferCardContainer {
  children: React.ReactNode;
  paddingBottom?: boolean
};

export const OfferCardContainer = ({ children, paddingBottom = true }: IOfferCardContainer) => (
  <div
    className={`row row-cols-1 row-cols-md-3 bg-gray-100 plan-section-container bg-gray-100 ${
      paddingBottom ? "pb-5" : ""
    }`}
  >
    {children}
  </div>
);
