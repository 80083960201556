import {
  OfferCard,
  OfferCardBadge,
  OfferCardBadgeWithInfo,
  OfferCardButton,
  OfferCardContainer,
  OfferCardDescription,
  OfferCardDisclaimer,
  OfferCardFeatureHeading,
  OfferCardLargeTitle,
  OfferCardList,
  OfferCardPrice,
  OfferCardSection,
  OfferCardSubHeading,
  OfferCardTitle
} from 'src/components/moray/MorayOfferCard';
import localeService from 'src/dataServices/LocaleService';
import useM365CopilotOfferUpdate from './useM365CopilotOfferUpdate';

interface IPlanSectionProps {
  setRef?: (el: HTMLDivElement | null) => void;
}

const PlanSection = (props: IPlanSectionProps) => {
  const { isM365CopilotOfferAvailable } = useM365CopilotOfferUpdate();
  return (
    <div
      className=" "
      ref={(el) => {
        if (props.setRef !== undefined) {
          props.setRef(el);
        }
      }}
    >
      <OfferCardFeatureHeading>
        <OfferCardLargeTitle
          id={'PlanSectionHeaderTitle'}
          title={localeService.getText('Microsoft365V2', 'PlanSectionHeaderTitle')}
        />
        <OfferCardDescription
          id={'p#PlanSectionHeaderDescription'}
          text={localeService.getText('Microsoft365V2', 'PlanSectionHeaderDescription')}
        />
      </OfferCardFeatureHeading>
      <OfferCardContainer>
        <OfferCard>
          <OfferCardBadge id="BasicBadge" badgeText="" />
          {isM365CopilotOfferAvailable && <OfferCardBadgeWithInfo infoText="" />}
          <OfferCardSection id="offer-card-title">
            <OfferCardTitle
              id={'BasicTitle'}
              title={localeService.getText('Microsoft365V2', 'PlanSectionTitleBasic')}
            />
            <OfferCardPrice
              id={'BasicPrice'}
              price={localeService.getText('Microsoft365V2', 'PlanSectionPriceGrant')}
            />
            <OfferCardDisclaimer
              id={'BasicDisclaimer'}
              disclaimer={localeService.getText('Microsoft365V2', 'PlanSectionDisclaimerBasic')}
            />
          </OfferCardSection>
          <OfferCardSection id="offer-card-button">
            <OfferCardButton
              id="BasicButton"
              buttonText={localeService.getText('Microsoft365V2', 'PlanSectionBuyNow')}
              buttonLink={localeService.getText('Microsoft365V2', 'PlanSectionBasicBuyNowLink')}
              buttonAriaLabel={localeService.getText('Microsoft365V2', 'BasicBuyNowLinkAria')}
            />
          </OfferCardSection>
          <OfferCardSection id="offer-card-heading">
            <OfferCardSubHeading
              id={'BasicSubHeading1'}
              subHeading={localeService.getText('Microsoft365V2', 'PlanSectionSubHeadingVersions')}
            />
            <OfferCardSubHeading
              id={'BasicSubHeading2'}
              subHeading={localeService.getText('Microsoft365V2', 'PlanSectionSubHeadingOffice')}
            />
            <OfferCardList
              id={'BasicApplications'}
              glyph={'check-mark'}
              list={
                localeService.getText('Microsoft365V2', 'PlanSectionBasicOfficeApplications') as unknown as string[]
              }
            />
          </OfferCardSection>
          <OfferCardSection id="offer-card-heading">
            <OfferCardSubHeading
              id={'BasicSubHeading3'}
              subHeading={localeService.getText('Microsoft365V2', 'PlanSectionSubHeadingServices')}
            />
            <OfferCardList
              id={'BasicServices'}
              glyph={'check-mark'}
              list={localeService.getText('Microsoft365V2', 'PlanSectionBasicServices') as unknown as string[]}
            />
          </OfferCardSection>
        </OfferCard>
        <OfferCard>
          <OfferCardBadge id="StandardBadge" badgeText="" />
          {isM365CopilotOfferAvailable && (
            <OfferCardBadgeWithInfo infoText={localeService.getText('Microsoft365V2', 'PlanSectionCopilotBadgeInfo')} />
          )}
          <OfferCardSection id="offer-card-title">
            <OfferCardTitle
              id={'StandardTitle'}
              title={localeService.getText('Microsoft365V2', 'PlanSectionTitleStandard')}
            />
            <OfferCardPrice
              id={'StandardPrice'}
              price={localeService.getText('Microsoft365V2', 'PlanSectionPriceDollars')}
            />
            <OfferCardDisclaimer
              id={'StandardDisclaimer'}
              disclaimer={localeService.getText('Microsoft365V2', 'PlanSectionDisclaimerStandard')}
            />
          </OfferCardSection>
          <OfferCardSection id="offer-card-button">
            <OfferCardButton
              id="StandardButton"
              buttonText={localeService.getText('Microsoft365V2', 'PlanSectionBuyNow')}
              buttonLink={localeService.getText('Microsoft365V2', 'PlanSectionStandardBuyNowLink')}
              buttonAriaLabel={localeService.getText('Microsoft365V2', 'StandardBuyNowLinkAria')}
            />
          </OfferCardSection>
          <OfferCardSection id="offer-card-heading">
            <OfferCardSubHeading
              id={'StandardSubHeading1'}
              subHeading={localeService.getText('Microsoft365V2', 'PlanSectionSubHeadingOffice')}
            />
            <OfferCardList
              id={'StandardApplications'}
              glyph={'check-mark'}
              list={
                localeService.getText('Microsoft365V2', 'PlanSectionStandardOfficeApplications') as unknown as string[]
              }
            />
          </OfferCardSection>
          <OfferCardSection id="offer-card-heading">
            <OfferCardSubHeading
              id={'StandardSubHeading2'}
              subHeading={localeService.getText('Microsoft365V2', 'PlanSectionSubHeadingServices')}
            />
            <OfferCardList
              id={'StandardServices'}
              glyph={'check-mark'}
              list={localeService.getText('Microsoft365V2', 'PlanSectionStandardServices') as unknown as string[]}
            />
          </OfferCardSection>
        </OfferCard>
        <OfferCard>
          <OfferCardBadge id="PremiumBadge" badgeText={localeService.getText('Microsoft365V2', 'PlanSectionBadge')} />
          {isM365CopilotOfferAvailable && (
            <OfferCardBadgeWithInfo infoText={localeService.getText('Microsoft365V2', 'PlanSectionCopilotBadgeInfo')} />
          )}
          <OfferCardSection id="offer-card-title">
            <OfferCardTitle
              id={'PremiumTitle'}
              title={localeService.getText('Microsoft365V2', 'PlanSectionTitlePremium')}
            />
            <OfferCardPrice
              id={'PremiumPrice'}
              price={localeService.getText('Microsoft365V2', 'PlanSectionPriceGrant')}
            />
            <OfferCardDisclaimer
              id={'PremiumDisclaimer'}
              disclaimer={localeService.getText('Microsoft365V2', 'PlanSectionDisclaimerPremium')}
            />
          </OfferCardSection>
          <OfferCardSection id="offer-card-button">
            <OfferCardButton
              id="PremiumButton"
              buttonText={localeService.getText('Microsoft365V2', 'PlanSectionBuyNow')}
              buttonLink={localeService.getText('Microsoft365V2', 'PlanSectionPremiumBuyNowLink')}
              buttonAriaLabel={localeService.getText('Microsoft365V2', 'PremiumBuyNowLinkAria')}
            />
          </OfferCardSection>
          <OfferCardSection id="offer-card-heading">
            <OfferCardSubHeading
              id={'PremiumSubHeading1'}
              subHeading={localeService.getText('Microsoft365V2', 'PlanSectionSubHeadingOffice')}
            />
            <OfferCardList
              id={'PremiumApplications'}
              glyph={'check-mark'}
              list={
                localeService.getText('Microsoft365V2', 'PlanSectionPremiumOfficeApplications') as unknown as string[]
              }
            />
          </OfferCardSection>
          <OfferCardSection id="offer-card-heading">
            <OfferCardSubHeading
              id={'PremiumSubHeading2'}
              subHeading={localeService.getText('Microsoft365V2', 'PlanSectionSubHeadingServices')}
            />
            <OfferCardList
              id={'PremiumServices'}
              glyph={'check-mark'}
              list={localeService.getText('Microsoft365V2', 'PlanSectionPremiumServices') as unknown as string[]}
            />
          </OfferCardSection>
        </OfferCard>
      </OfferCardContainer>
    </div>
  );
};

export default PlanSection;
