import { withAITracking } from "@microsoft/applicationinsights-react-js";
import * as React from "react";
import LoadingButton from "src/components/utilities/LoadingButton";
import localeService from "src/dataServices/LocaleService";
import { telemetryService } from "npo-common";
import ActionCard from "../../../components/getting-started/new/ActionCard";
import Banner from "../../../components/getting-started/new/Banner";
import BulletBox from "../../../components/getting-started/new/BulletBox";
import CenteredTextSection from "../../../components/getting-started/new/CenteredTextSection";
import Disclaimer from "../../../components/getting-started/new/Disclaimer";
import RegisterButton from "../../../components/getting-started/new/RegisterButton";
import SignInButton from "../../../components/getting-started/new/SignInButton";
import StepCard from "../../../components/step-card/StepCard";
import "./GettingStartedPage.scss";

const imagesUrl = process.env.REACT_APP_IMAGES_URL;

export class GettingStartedPage extends React.Component {
  public render() {
    const StepCard1Content = (
      <div className="step-card-content">
        <p>
          {localeService.getText("StepCards", "Step1CardText1Part1")}
          <a
            href={localeService.getText("Urls", "Eligibility")}
            title={localeService.getText("GettingStarted", "EligibilityTitle")}
            target="_blank"
            rel="noopener noreferrer"
          >
            {localeService.getText("StepCards", "Step1CardText1Link1")}
          </a>
          {localeService.getText("StepCards", "Step1CardText1Part2")}
          <a
            href={localeService.getText("Urls", "FAQ")}
            title={localeService.getText("GettingStarted", "FaqTitle")}
            target="_blank"
            rel="noopener noreferrer"
          >
            {localeService.getText("StepCards", "Step1CardText1Link2")}
          </a>
          {localeService.getText("StepCards", "Step1CardText1Part3")}
        </p>
        <p>
          {localeService.getText("StepCards", "Step1CardText2Part1")}
          <a
            href={localeService.getText("Urls", "Eligibility")}
            title={localeService.getText("GettingStarted", "EligibilityTitle")}
            target="_blank"
            rel="noopener noreferrer"
          >
            {localeService.getText("StepCards", "Step1CardText2Link")}
          </a>
          {localeService.getText("StepCards", "Step1CardText2Part2")}
        </p>
        <p>{localeService.getText("StepCards", "Step1CardText3")}</p>
        <div className="inverted-button">
          <RegisterButton placement="StepCard" />
        </div>
      </div>
    );

    const StepCard2Content = (
      <div className="step-card-content">
        <p>{localeService.getText("StepCards", "Step2CardText1")}</p>
        <p>
          {localeService.getText("StepCards", "Step2CardText3Part1")}
          <a
            href={localeService.getText("Urls", "TrainingCourses")}
            title={localeService.getText("StepCards", "ResourcesTitle")}
            target="_blank"
            rel="noopener noreferrer"
          >
            {localeService.getText("StepCards", "Step2CardText3Link")}
          </a>
          {localeService.getText("StepCards", "Step2CardText3Part2")}
        </p>
      </div>
    );

    const StepCard3Content = (
      <div className="step-card-content">
        <p>{localeService.getText("StepCards", "Step3CardText")}</p>
      </div>
    );

    return (
      <section id="getting-started-page-new">
        <Banner />
        <div className="isolated-text">{localeService.getText("GettingStarted", "IsolatedText")}</div>
        <section className="ms-Grid-row step-card-section">
          <StepCard
            image={`${imagesUrl}/step-tile-image1.jpg`}
            altText={localeService.getText("StepCards", "Step1CardAltText")}
            title={localeService.getText("StepCards", "Step1Header")}
            selected={true}
            content={StepCard1Content}
          />
          <StepCard
            image={`${imagesUrl}/step-tile-image2.jpg`}
            altText={localeService.getText("StepCards", "Step2CardAltText")}
            title={localeService.getText("StepCards", "Step2Header")}
            selected={false}
            content={StepCard2Content}
          />
          <StepCard
            image={`${imagesUrl}/step-tile-image3.jpg`}
            altText={localeService.getText("StepCards", "Step3CardAltText")}
            title={localeService.getText("StepCards", "Step3Header")}
            selected={false}
            content={StepCard3Content}
          />
        </section>
        <div className="ms-Grid-col ms-Grid-row action-card-row">
          <ActionCard
            icon="AddFriend"
            header={localeService.getText("ActionBox", "RegisterSectionTitleNew")}
            content={localeService.getText("ActionBox", "RegisterSummary")}
            button={<RegisterButton placement="ActionCard" />}
          />
          <ActionCard
            icon="UserFollowed"
            header={localeService.getText("GettingStarted", "SignIn")}
            content={localeService.getText("ActionBox", "SignInSummary")}
            button={<SignInButton />}
          />
        </div>
        <div className="ms-Grid-col ms-Grid-Row ms-sm12 ms-lg12">
          <CenteredTextSection
            title={localeService.getText("CenteredText", "ExploreHeader")}
            content={localeService.getText("CenteredText", "ExploreContent")}
          />
        </div>
        <BulletBox />
        <div className="elevated-section">
          <CenteredTextSection
            title={localeService.getText("CenteredText", "ContactHeader")}
            content={localeService.getText("CenteredText", "ContactContent")}
          />
          <div className="button-wrapper">
            <LoadingButton
              href={localeService.getText("Urls", "GettingStarted-ContactUs")}
              target="_blank"
              isExternal={true}
              title={localeService.getText("GettingStarted", "ContactUsTitle")}
              text={localeService.getText("GettingStarted", "ContactUsButton")}
              loading={false}
              role="link"
              properties={{ ButtonName: "GettingStarted-ContactUsButton" }}
              className="inverted-large-button"
            />
          </div>
        </div>
        <Disclaimer />
      </section>
    );
  }
}

export default withAITracking(telemetryService.aiReactPlugin, GettingStartedPage);
