import { Spinner, SpinnerSize } from '@fluentui/react';
import { useEffect } from 'react';
import localeService from 'src/dataServices/LocaleService';

interface IHomeRedirectProps {
  locale: string;
  pathname: string;
}

export const HomeRedirect = (props: IHomeRedirectProps) => {
  useEffect(() => {
    window.location.replace(`/${props.locale.toLowerCase()}${props.pathname}`);
  }, []);

  if (document) {
    const header = document.getElementById('header-panel');
    const footer = document.getElementById('nonprofit-footer');

    if (header) {
      header.hidden = true;
    }

    if (footer) {
      footer.hidden = true;
    }
  }

  return (
    <Spinner
      size={SpinnerSize.large}
      label={localeService.getText('Shared', 'SpinnerPleaseWait')}
      ariaLive="assertive"
    />
  );
};

export default HomeRedirect;
