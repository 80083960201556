import { all, apply, fork, put, select, take, takeEvery } from "redux-saga/effects";
import { isLocalizationEnabled } from "src/components/utilities/Localization/Utils";
import { IApiResponse } from "src/common/ApiResponse";
import agentSettingsService from "src/dataServices/AgentSettingsService";
import authenticationService from "src/dataServices/AuthenticationService";
import localeService from "src/dataServices/LocaleService";
import { extractLocale, isNullOrUndefined } from "src/common/utilities/Utilities";
import { ActionType } from "typesafe-actions";
import { IApplicationState } from "../index";
import { loadedAgentVerification } from "../agent-verification/actions";
import { AgentVerificationActionTypes } from "../agent-verification/types";
import { setAppContext } from "../layout";
import { nonprofitError, NonprofitError } from "../nonprofit";
import { initiateRegister, setAuthStatus } from "./actions";
import { AuthActionTypes } from "./types";

export function* handleInitiateLogin() {
  const hasWcpCookieConsent: boolean = yield select(({ wcpCookieConsent }: IApplicationState) => wcpCookieConsent.hasConsent);
  if (typeof hasWcpCookieConsent !== "undefined") {
    if (hasWcpCookieConsent === false) {
      window.mscc.setConsent();
    }
  }

  yield put(setAuthStatus({ isAuthenticated: false, user: null, isAgentOkay: true }));
  yield apply(authenticationService, authenticationService.login, []);
}

export function* handleInitiateLogout() {
  authenticationService.logout();
}

export function* handleInitiateRegister(action: ActionType<typeof initiateRegister>) {
  const hasWcpCookieConsent: boolean = yield select(({ wcpCookieConsent }: IApplicationState) => wcpCookieConsent.hasConsent);
  if (typeof hasWcpCookieConsent !== "undefined") {
    if (hasWcpCookieConsent === false) {
      window.mscc.setConsent();
    }

    let verificationLoadedSuccessfully: boolean = yield select(
      ({ agentVerification }: IApplicationState) => agentVerification.loadedSuccessfully
    );
    if (typeof verificationLoadedSuccessfully === "undefined") {
      const loadedAction: ActionType<typeof loadedAgentVerification> = yield take(
        AgentVerificationActionTypes.LOADED_AGENT_VERIFICATION
      );
      verificationLoadedSuccessfully = loadedAction.payload;
    }

    if (verificationLoadedSuccessfully === true) {
      const sessionId: string = yield select(({ agentVerification }: IApplicationState) => agentVerification.sessionId);
      const response: IApiResponse<boolean> = yield apply(
        agentSettingsService,
        agentSettingsService.checkAgentRegistration,
        [sessionId]
      );

      if (!response.value) {
        yield agentErrorAndRedirect();
        return;
      }
    }
  }

  yield put(setAuthStatus({ isAuthenticated: false, user: null, isAgentOkay: true }));
  const locale = localeService.getlocale();
  const extractedLocale = extractLocale(locale);

  if (action !== undefined && action.payload) {
    // new signup experience
    isLocalizationEnabled() && !isNullOrUndefined(extractedLocale)
      ? window.location.assign(`${process.env.REACT_APP_SIGNUP_URL}${extractedLocale}`)
      : window.location.assign(process.env.REACT_APP_SIGNUP_URL ?? '');
  } else {
    window.location.assign(process.env.REACT_APP_REGISTER_URL ?? '');
  }
}

function* handleProcessLoginPromise() {
  const handlePromiseResponse: boolean = yield apply(authenticationService, authenticationService.handleLoginPromise, []);
  if (handlePromiseResponse) {
    const account = authenticationService.accountInfo;
    const context = {
      Puid: account && account.idTokenClaims ? account.idTokenClaims["puid"] : undefined,
      TenantId: account && account.idTokenClaims ? account.idTokenClaims["tid"] : undefined,
      ObjectId: account && account.idTokenClaims ? account.idTokenClaims["oid"] : undefined
    };

    // yield fork(nonprofitSaga);

    yield all([
      put(setAuthStatus({ isAuthenticated: true, user: account, isAgentOkay: true })),
      put(setAppContext(context))
    ]);
  } else {
    yield put(setAuthStatus({ isAuthenticated: false, user: null, isAgentOkay: true }));
  }
}

function* agentErrorAndRedirect() {
  yield all([
    put(setAuthStatus({ isAuthenticated: false, user: null, isAgentOkay: false })),
    put(nonprofitError(NonprofitError.AgentError))
  ]);
}

function* watchInitiateLogin() {
  yield takeEvery(AuthActionTypes.INITIATE_LOGIN, handleInitiateLogin);
}

function* watchInitiateLogout() {
  yield takeEvery(AuthActionTypes.INITIATE_LOGOUT, handleInitiateLogout);
}

function* watchInitiateRegister() {
  yield takeEvery(AuthActionTypes.INITIATE_REGISTER, handleInitiateRegister);
}

function* watchProcessLoginPromise() {
  yield takeEvery(AuthActionTypes.PROCESS_LOGIN_PROMISE, handleProcessLoginPromise);
}

function* authSaga() {
  yield all([
    fork(watchInitiateLogin),
    fork(watchInitiateLogout),
    fork(watchInitiateRegister),
    fork(watchProcessLoginPromise)
  ]);
}

export default authSaga;
