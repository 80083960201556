import { ICountryCode } from "../shared";

export const enum ContactUsActionTypes {
  CONTACTUS_CREATESUPPORTTICKET= "@@contactus/CONTACTUS_CREATESUPPORTTICKET",
  CONTACTUS_CREATESUPPORTTICKET_SUCCESS = "@@contactus/CONTACTUS_CREATESUPPORTTICKET_SUCCESS",
  CONTACTUS_CREATESUPPORTTICKET_ERROR = "@@contactus/CONTACTUS_CREATESUPPORTTICKET_ERROR",

  CONTACT_RESOURCES_REQUEST = "@@contactus/CONTACT_RESOURCES_REQUEST",
  CONTACT_RESOURCES_SUCCESS = "@@contactus/CONTACT_RESOURCES_SUCCESS",
  CONTACT_RESOURCES_ERROR = "@@contactus/CONTACT_RESOURCES_ERROR"
}

export interface IContactUsState {
  isSending?: boolean;
  isLoadingCountries?: boolean;
  isLoadingCategories?: boolean;
  isLoadingResources?: boolean;
  sentSuccessfully?: boolean;
  failedToSend?: boolean;
  countryCodes?: ICountryCode[];
  supportTicketResponse?: ISupportTicketResponse;
}

export interface IHelpIssueCategory {
  Name: string;
  Code: string;
  IssueCategories: IIssueCategory[];
  Key: string;
}

export interface IContactUsSubmissionFields {
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  issue: string;
  nonprofitName: string;
  userPhone: string;
  category: string;
  helpCategory: string;
  recaptcha: string;
  altEmail: string;
  countryCode: any;
  userLocale: string;
  role: string;
}

export interface IContactUsSubmissionForm extends IContactUsSubmissionFields {
  helpCategoryComboValue: any;
}

export interface IIssueCategory {
  Name: string;
  Code: string;
  HelpCategoryCode: string;
  Key: string;
}

export interface ISupportTicketResponse {
  Id: number;
}
export interface IRecaptchaProps {
  isRecaptchaValid: boolean;
  isRecaptchaExpired: boolean;
}

export interface IContactUsParameterizedRoute {
  category: string;
  subCategory: string;
  isValid: boolean;
}
