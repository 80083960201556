import { withAITracking } from "@microsoft/applicationinsights-react-js";
import * as React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Dispatch } from "redux";
import NonprofitSummary from "src/components/nonprofit/NonprofitSummary";
import AccountGuardV2 from "src/components/offers/accountguardv2/AccountGuardV2";
import AzureV2 from "src/components/offers/azurev2/AzureV2";
import BingAds from "src/components/offers/bingAds/BingAds";
import CloudV2 from "src/components/offers/cloudv2/CloudV2";
import Covid19 from "src/components/offers/covid19/Covid19";
import GitHub from "src/components/offers/gitHub/GitHub";
import Microsoft365V2 from "src/components/offers/m365v2/M365V2";
import PartnersV2 from "src/components/offers/partnersv2/PartnersV2";
import SoftwareV2 from "src/components/offers/softwarev2/SoftwareV2";
import TrainingV2 from "src/components/offers/trainingv2/TrainingV2";
import Text from "src/components/text/Text";
import Flag from "src/components/utilities/Flag/Flag";
import { getLocalizedPath } from "src/components/utilities/Localization/Utils";
import { telemetryService } from "npo-common";
import localeEnabledForV2, { LocaleConfigStrings } from 'src/common/localeEnabledForV2';
import { IApplicationState } from "src/state";
import { IOffer, OfferId, OfferPageId } from "src/state/nonprofit/types";
import Hardware from "../../components/eligibilitychanges/Hardware";
import Dynamic365V2 from "../../components/offers/dynamic365V2/Dynamic365V2";
import LinkedInV2 from "../../components/offers/linkedinv2/LinkedInV2";
import "./OfferPage.scss";
import CommunityTraining from '../../components/offers/communitytraining/CommunityTraining';

interface IPropsFromState {
  offers: IOffer[];
}

interface IRouteMatchProps {
  token: string;
  match: any;
}

export type IOfferPageProps = IPropsFromState & IRouteMatchProps;

class OfferPage extends React.Component<IOfferPageProps> {
  public render() {
    const { offers, match } = this.props;

    if (!offers) {
      return (
        <div>
          <Text category="OffersList" id="NoOffers" />
        </div>
      );
    }

    // eslint:disable-next-line:no-string-literal
    const offerId = match.params['id'] as OfferPageId;

    if (offerId === OfferId.o365.toString()) {
      return <Redirect to={getLocalizedPath('/offers/m365')} />;
    }

    if (!offers.some((o) => o.id === offerId)) {
      return <Redirect to={getLocalizedPath('/')} />;
    }

    return (
      <section id="offer-page">
        {
          {
            [OfferId.azure]: <AzureV2 token={match.params.token} />,
            [OfferId.accountGuard]: <AccountGuardV2 />,
            [OfferId.m365]: <Microsoft365V2>{offers}</Microsoft365V2>,
            [OfferId.dynamic365]: <Dynamic365V2 />,
            [OfferId.hardware]: <Microsoft365V2>{offers}</Microsoft365V2>,
            [OfferId.partners]: <PartnersV2 />,
            [OfferId.bingAds]: (
              <Flag
                name="BingAdsOfferEnabled"
                fallbackRender={() => {
                  return <Redirect to={getLocalizedPath('/')} />;
                }}
              >
                {localeBasedRender('BingAds', <BingAds />, <Redirect to={getLocalizedPath('/')} />)}
              </Flag>
            ),
            [OfferId.software]: <SoftwareV2 />,
            [OfferId.linkedIn]: <LinkedInV2 />,
            [OfferId.gitHub]: (
              <div>
                <NonprofitSummary />
                <GitHub />
              </div>
            ),
            [OfferId.cloud]: <CloudV2 />,
            [OfferId.covid19]: (
              <div>
                <NonprofitSummary />
                <Covid19 />
              </div>
            ),
            [OfferId.training]: <TrainingV2 />,
            [OfferId.community_training]: localeBasedRender(
              LocaleConfigStrings.CommunityTraining,
              <CommunityTraining />,
              <Redirect to={getLocalizedPath('/')} />
            ),
            undefined: <div>Unknown offer code.</div>
          }[offerId]
        }
      </section>
    );
  }
}

const localeBasedRender = (offer: string, v2Component: JSX.Element, fallbackComponent: JSX.Element) => {
  const v1Component = (
    <div>
      <NonprofitSummary />
      {fallbackComponent}
    </div>
  );

  return localeEnabledForV2(offer) ? v2Component : v1Component;
};

const mapStateToProps = ({ nonprofit }: IApplicationState) => ({
  offers: nonprofit && nonprofit.miniProfile ? nonprofit.miniProfile.availableOffers : []
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAITracking(telemetryService.aiReactPlugin, OfferPage));
