import { all, apply, fork, put, takeEvery } from "redux-saga/effects";
import { IApiResponse } from "src/common/ApiResponse";
import azureRegistrationService from "src/dataServices/AzureRegistrationService";
import { ActionType } from "typesafe-actions";
import {
  initiateAzureRegistration,
  initiateAzureRegistrationForOfferId,
  initiateAzureRenewal,
  resetAzureRegistrationForOfferId,
  setAzureRegistrationInfo,
  setAzureRegistrationInfoError,
  setAzureRegistrationResult,
  setAzureTokenValidationResult
} from "./actions";
import {
  AzureOfferActionTypes,
  AzureRegistrationInfo,
  AzureRegistrationResult,
  AzureRegistrationResultCode,
  AzureTokenValidationCode,
  AzureTokenValidationResult
} from "./types";

/**
 * Submit the form values provided by the user to apply for a new Azure registration.
 * @param action object whose payload contains the form values
 */
export function* handleInitiateAzureRegistration(action: ActionType<typeof initiateAzureRegistration>) {
  const formValues = action.payload;
  const response: IApiResponse<AzureRegistrationResult> = yield apply(
    azureRegistrationService,
    azureRegistrationService.createRegistration,
    [formValues]
  );
  if (!response.value) {
    yield put(setAzureRegistrationResult(AzureRegistrationResultCode.Error));
  } else if (response.value.isSuccessful) {
    yield put(setAzureRegistrationResult(AzureRegistrationResultCode.Ok));
  } else if (response.value.code) {
    yield put(setAzureRegistrationResult(response.value.code));
  } else {
    yield put(setAzureRegistrationResult(AzureRegistrationResultCode.Error));
  }
}

/**
 * Creates a new Azure Registration with the provided offer id.
 * @param action object whose payload contains the offerId.
 */
export function* handleInitiateAzureRegistrationForOfferId(
  action: ActionType<typeof initiateAzureRegistrationForOfferId>
) {
  const formValues = action.payload;
  const response: IApiResponse<AzureRegistrationResult> = yield apply(
    azureRegistrationService,
    azureRegistrationService.createRegistrationForOfferId,
    [formValues]
  );
  if (response.value !== undefined && response.value.isSuccessful) {
    yield put(setAzureRegistrationInfo(response.value.registration));
    yield put(setAzureRegistrationResult(AzureRegistrationResultCode.Ok));
  } else if (response.value !== undefined && response.value.code) {
    yield put(setAzureRegistrationResult(response.value.code));
  } else {
    yield put(setAzureRegistrationResult(AzureRegistrationResultCode.Error));
  }
}

/**
 * Resets existing Azure Registration with the provided offer id.
 * @param action object whose payload contains the offerId.
 */
export function* handleResetAzureRegistrationForOfferId(action: ActionType<typeof resetAzureRegistrationForOfferId>) {
  const formValues = action.payload;
  const response: IApiResponse<AzureRegistrationResult> = yield apply(
    azureRegistrationService,
    azureRegistrationService.resetRegistrationForOfferId,
    [formValues]
  );
  if (response.value !== undefined && response.value.isSuccessful) {
    yield put(setAzureRegistrationInfo(response.value.registration));
    yield put(setAzureRegistrationResult(AzureRegistrationResultCode.Ok));
  } else {
    yield put(setAzureRegistrationResult(AzureRegistrationResultCode.Error));
  }
}

export function* handleInitiateAzureRenewal(action: ActionType<typeof initiateAzureRenewal>) {
  const token = action.payload;
  const response: IApiResponse<AzureTokenValidationResult> = yield apply(
    azureRegistrationService,
    azureRegistrationService.renewRegistration,
    [token]
  );
  const result = {} as AzureTokenValidationResult;
  if (response.status === 200) {
    yield put(setAzureRegistrationInfo(response.value?.registration));
    result.code = AzureTokenValidationCode.Active;
    result.registration = response.value?.registration;
  } else if (response.status === 400 && response.value?.code) {
    result.code = response.value.code;
  } else {
    result.code = AzureTokenValidationCode.Error;
  }

  yield put(setAzureTokenValidationResult(result));
}

/**
 * Get any existing Azure registrations for the current user and dispatch an
 * action to reflect this existence.
 */
export function* handleFetchAzureRegistrationInfo() {
  const response: IApiResponse<AzureRegistrationInfo> = yield apply(
    azureRegistrationService,
    azureRegistrationService.getActiveRegistrations,
    []
  );

  if (response.status === 200) {
    yield put(setAzureRegistrationInfo(response.value));
  } else if (response.status === 404) {
    yield put(setAzureRegistrationInfo(null));
  } else {
    yield put(setAzureRegistrationInfoError());
  }
}

function* watchFetchAzureRegistrationInfo() {
  yield takeEvery(AzureOfferActionTypes.FETCH_REGISTRATION_INFO, handleFetchAzureRegistrationInfo);
}

function* watchInitiateAzureRegistration() {
  yield takeEvery(AzureOfferActionTypes.INITIATE_REGISTRATION, handleInitiateAzureRegistration);
}

function* watchInitiateAzureRegistrationForOfferId() {
  yield takeEvery(AzureOfferActionTypes.INITIATE_REGISTRATION_FOR_OFFER_ID, handleInitiateAzureRegistrationForOfferId);
}

function* watchResetAzureRegistrationForOfferId() {
  yield takeEvery(AzureOfferActionTypes.RESET_REGISTRATION_FOR_OFFER_ID, handleResetAzureRegistrationForOfferId);
}

function* watchInitiateAzureRenewal() {
  yield takeEvery(AzureOfferActionTypes.INITIATE_RENEWAL, handleInitiateAzureRenewal);
}

function* azureOfferSaga() {
  yield all([
    fork(watchInitiateAzureRegistration),
    fork(watchInitiateAzureRegistrationForOfferId),
    fork(watchResetAzureRegistrationForOfferId),
    fork(watchFetchAzureRegistrationInfo),
    fork(watchInitiateAzureRenewal)
  ]);
}

export default azureOfferSaga;
