import localeService from "../../dataServices/LocaleService";

export interface IMoraySelectOption {
    value: string;
    displayName: string;
}

export interface IMoraySelectProps {
    defaultOption: IMoraySelectOption;
    options: IMoraySelectOption[];
    onSelect: (value: string) => void;
}

const MoraySelect = ({ defaultOption, options, onSelect }: IMoraySelectProps) => {
    return (
      <div className="custom-select ">
        <select className="custom-select-input " onChange={(event) => onSelect(event.target.value)} aria-label={localeService.getText('GettingStartedV2', 'EligibilityPreCheckAriaLabelOrganizationLocationSelect')} aria-required>
          <option value={defaultOption.value}>{defaultOption.displayName}</option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.displayName}
            </option>
          ))}
        </select>
        <span className="custom-select-arrow" aria-hidden="true"></span>
      </div>
    );
};

export default MoraySelect;
