import "@ms-mwf/moray/dist/css/main.css";
import * as React from "react";
import Text from "src/components/text/Text";
import "./NavigationCard.scss";
const { useState } = React;

export interface INavigationCardProps {
  category: string;
  navigationCardTitle: string;
  navigationCardDescription: string;
  navigationCardNeedMoreHelp?: string;
  navigationCardTab1: string;
  navigationCardTab2: string;
  navigationCardTab3?: string;
  navigationCardTab4?: string;
  navigationCardTab5?: string;
  navigationCardContent1: JSX.Element;
  navigationCardContent2: JSX.Element;
  navigationCardContent3?: JSX.Element;
  navigationCardContent4?: JSX.Element;
  navigationCardContent5?: JSX.Element;
}
const NavigationCard: React.FunctionComponent<INavigationCardProps> = props => {
  const [[tab], setTab] = useState("00");
  const tabSelected = (selectedTab: string | number) => (selectedTab == tab ? "tab-selected" : "");
  const ariaSelected = (selectedTab: string | number) => (selectedTab == tab ? "true" : "false");
  return (
    <section className="navigation-card">
      <div id="navigation-card-title" className="material-surface" data-mount="click-group">
        <h2 className="navigation-card-title mt-4 mb-3 ml-4 h3">
          <Text category={props.category} id={props.navigationCardTitle} />
        </h2>
        <p id="navigation-card-description" className="ml-4 base">
          <Text category={props.category} id={props.navigationCardDescription} />
        </p>
        {props.navigationCardNeedMoreHelp !== undefined && (
          <p id="navigation-card-need-more-help-section" className="ml-4 base">
            <Text category={props.category} id={props.navigationCardNeedMoreHelp} />
          </p>
        )}
      </div>
      <div className="solutions-tabs pt-0">
        <div className="solutions-tab-group ml-4" role="tablist">
          <a
            role="tab"
            aria-selected={ariaSelected(0)}
            tabIndex={0}
            href={""}
            className={"tablinks " + tabSelected(0)}
            onClick={e => {
              e.preventDefault();
              setTab("00");
            }}
          >
            <Text category={props.category} id={props.navigationCardTab1} />
          </a>
          <a
            role="tab"
            aria-selected={ariaSelected(1)}
            tabIndex={0}
            href={""}
            className={"tablinks " + tabSelected(1)}
            onClick={e => {
              e.preventDefault();
              setTab("10");
            }}
          >
            <Text category={props.category} id={props.navigationCardTab2} />
          </a>
          {props.navigationCardTab3 !== undefined && (
            <a
              role="tab"
              aria-selected={ariaSelected(2)}
              tabIndex={0}
              href={""}
              className={"tablinks " + tabSelected(2)}
              onClick={e => {
                e.preventDefault();
                setTab("20");
              }}
            >
              <Text category={props.category} id={props.navigationCardTab3} />
            </a>
          )}
          {props.navigationCardTab4 !== undefined && (
            <a
              role="tab"
              aria-selected={ariaSelected(3)}
              tabIndex={0}
              href={""}
              className={"tablinks " + tabSelected(3)}
              onClick={e => {
                e.preventDefault();
                setTab("30");
              }}
            >
              <Text category={props.category} id={props.navigationCardTab4} />
            </a>
          )}
          {props.navigationCardTab5 !== undefined && (
            <a
              role="tab"
              aria-selected={ariaSelected(4)}
              tabIndex={0}
              href={""}
              className={"tablinks " + tabSelected(4)}
              onClick={e => {
                e.preventDefault();
                setTab("40");
              }}
            >
              <Text category={props.category} id={props.navigationCardTab5} />
            </a>
          )}
        </div>
      </div>
      <div className="power-platform-background" hidden={tab != "0"}>
        {props.navigationCardContent1}
      </div>
      <div className="power-platform-background" hidden={tab != "1"}>
        {props.navigationCardContent2}
      </div>
      {props.navigationCardContent3 !== undefined && (
        <div className="power-platform-background" hidden={tab != "2"}>
          {props.navigationCardContent3}
        </div>
      )}
      {props.navigationCardContent4 !== undefined && (
        <div className="power-platform-background" hidden={tab != "3"}>
          {props.navigationCardContent4}
        </div>
      )}
      {props.navigationCardContent5 !== undefined && (
        <div className="power-platform-background" hidden={tab != "4"}>
          {props.navigationCardContent5}
        </div>
      )}
    </section>
  );
};
export default NavigationCard;
