import "@ms-mwf/moray/dist/css/main.css";
import * as React from "react";
import "./Steps.scss";

interface ISteps {
  step1Card: React.ReactNode;
  step2Card: React.ReactNode;
  step3Card: React.ReactNode;
};

function Steps(props: ISteps) {
  return (
    <div id="steps" className="steps-container-style">
      <div className="step-card" id="step1">
        {props.step1Card}
      </div>
      <div className="step-card" id="step2">
        {props.step2Card}
      </div>
      <div className="step-card" id="step3">
        {props.step3Card}
      </div>
    </div>
  );
}

export default Steps;
