import { Label } from "@fluentui/react/lib/Label";
import { TooltipHost } from "@fluentui/react/lib/Tooltip";
import { Field } from "react-final-form";
import Text from "src/components/text/Text";
import { IconCallout } from "./IconCallout";
import { composeValidators, inputTextFieldComponent } from "./ProfileV2SharedComponents";

export interface IProfileTextInputProps {
  id: string;
  localeCategoryId: string;
  localeLabelId: string;
  localeErrorCategoryId?: string;
  required: boolean;
  hasHelpText?: boolean;
  autoFocus?: boolean;
  validators?: Function[];
  hasServerValidationError?: boolean;
}

const TextInputV2 = (props: IProfileTextInputProps): JSX.Element => {
  const {
    id,
    localeCategoryId,
    localeLabelId,
    localeErrorCategoryId,
    required,
    hasHelpText,
    autoFocus,
    hasServerValidationError,
    validators
  } = props;

  return (
    <div>
      <div className="field">
        {localeCategoryId && localeLabelId && (
          <Label tabIndex={0} htmlFor={id}>
            {required ? "* " : ""}
            <Text category={localeCategoryId} id={localeLabelId} />
          </Label>
        )}
        {hasHelpText && (
          <TooltipHost>
            <IconCallout content={new Text({ category: localeCategoryId, id: `${localeLabelId}Help` }).render()} />
          </TooltipHost>
        )}
      </div>
      <div>
        <Field
          id={id}
          name={id}
          required={required}
          component={inputTextFieldComponent}
          validate={composeValidators(validators)}
          maxLength={200}
          autoFocus={autoFocus}
          hasServerValidationError={hasServerValidationError}
          props={{ localeErrorCategoryId: localeErrorCategoryId }}
        />
      </div>
    </div>
  );
};

export default TextInputV2;
