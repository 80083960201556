import * as React from "react";
import MorayContentCardsSneakPeek, {
  IContentCard,
  MorayCardImageClassName
} from "src/components/moray/MorayContentCardsSneakPeek";
import { IResizableImage } from "src/components/moray/ResizeableImage";
import { getLocalizedPath } from "src/components/utilities/Localization/Utils";
import localeService from "src/dataServices/LocaleService";
import CarouselOffer from "../offers/shared/CarouselOffer";
import "./NewForYouCarousel.scss";

export const NewForYouCarousel = () => {
  const offer1Contents = <CarouselOffer category="HomeV2" descriptions={["Offer1Description"]} />;

  const offer2Contents = <CarouselOffer category="HomeV2" descriptions={["Offer2Description"]} />;

  const offer3Contents = <CarouselOffer category="HomeV2" descriptions={["Offer3Description"]} />;

  const offer4Contents = <CarouselOffer category="HomeV2" descriptions={["Offer4Description"]} />;

  const offer5Contents = <CarouselOffer category="HomeV2" descriptions={["Offer5Description"]} />;

  const offer1ImageSizes: IResizableImage = {
    root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}`,
    imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/HomePage/NewForYou_1.jpg`,
    imageClassName: MorayCardImageClassName,
    imageAlt: localeService.getText("HomeV2", "Offer1Title")
  };

  const offer2ImageSizes: IResizableImage = {
    root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}`,
    imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/HomePage/NewForYou_2.jpg`,
    imageClassName: MorayCardImageClassName,
    imageAlt: localeService.getText("HomeV2", "Offer2Title")
  };

  const offer3ImageSizes: IResizableImage = {
    root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}`,
    imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/HomePage/NewForYou_3.jpg`,
    imageClassName: MorayCardImageClassName,
    imageAlt: localeService.getText("HomeV2", "Offer3Title")
  };

  const offer4ImageSizes: IResizableImage = {
    root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}`,
    imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/HomePage/NewForYou_4.jpg`,
    imageClassName: MorayCardImageClassName,
    imageAlt: localeService.getText("HomeV2", "Offer4Title")
  };

  const offer5ImageSizes: IResizableImage = {
    root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}`,
    imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/HomePage/NewForYou_5.jpg`,
    imageClassName: MorayCardImageClassName,
    imageAlt: localeService.getText("HomeV2", "Offer5Title")
  };

  const offers: IContentCard[] = [
    {
      title: localeService.getText("HomeV2", "Offer1Title"),
      contents: offer1Contents,
      sectionAria: localeService.getText("HomeV2", "Offer1SectionAria"),
      imgSizes: offer1ImageSizes,
      actionText: localeService.getText("HomeV2", "OfferActionText"),
      onClickAria: localeService.getText("HomeV2", "OfferActionText"),
      onClickAction: () => {
        window.open(getLocalizedPath("/offers/accountguard"), "_self");
      }
    },
    {
      title: localeService.getText("HomeV2", "Offer2Title"),
      contents: offer2Contents,
      sectionAria: localeService.getText("HomeV2", "Offer2SectionAria"),
      imgSizes: offer2ImageSizes,
      actionText: localeService.getText("HomeV2", "OfferActionText"),
      onClickAria: localeService.getText("HomeV2", "OfferActionText"),
      onClickAction: () => {
        window.open(getLocalizedPath("/offers/m365/#m365banner"), "_self");
      }
    },
    {
      title: localeService.getText("HomeV2", "Offer3Title"),
      contents: offer3Contents,
      sectionAria: localeService.getText("HomeV2", "Offer3SectionAria"),
      imgSizes: offer3ImageSizes,
      actionText: localeService.getText("HomeV2", "OfferActionText"),
      onClickAria: localeService.getText("HomeV2", "OfferActionText"),
      onClickAction: () => {
        window.open(getLocalizedPath("/offers/hardware"), "_self");
      }
    },
    {
      title: localeService.getText("HomeV2", "Offer4Title"),
      contents: offer4Contents,
      sectionAria: localeService.getText("HomeV2", "Offer4SectionAria"),
      imgSizes: offer4ImageSizes,
      actionText: localeService.getText("HomeV2", "OfferActionText"),
      onClickAria: localeService.getText("HomeV2", "OfferActionText"),
      onClickAction: () => {
        window.open("https://socialimpact.github.com", "_blank");
      }
    },
    {
      title: localeService.getText("HomeV2", "Offer5Title"),
      contents: offer5Contents,
      sectionAria: localeService.getText("HomeV2", "Offer5SectionAria"),
      imgSizes: offer5ImageSizes,
      actionText: localeService.getText("HomeV2", "OfferActionText"),
      onClickAria: localeService.getText("HomeV2", "OfferActionText"),
      onClickAction: () => {
        window.open(getLocalizedPath("/offers/linkedin"), "_self");
      }
    }
  ];

  return (
    <div>
      <h2 className="new-for-you-title h2">{localeService.getText("HomeV2", "NewForYouTitle")}</h2>
      <MorayContentCardsSneakPeek contentCards={offers} ariaLabel={localeService.getText("HomeV2", "NewForYouTitle")} />
    </div>
  );
};
