import * as React from 'react';
import localeService from 'src/dataServices/LocaleService';
import { IProfile } from 'src/state/profile/types';
import {
  composeRequiredFieldValidator,
  emailAddressValidator,
  emailRequiredFieldValidator
} from './ProfileV2SharedComponents';
import RenderEmails from './RenderEmails';
import TextInputV2 from './TextInputV2';
import { FieldArray } from 'react-final-form-arrays';
import './ProfileForm.scss';
import './PrimaryContactEdit.scss';
import { IFeatureFlagsState } from 'src/state/feature-flags';
import { connect } from 'react-redux';
import { IApplicationState } from 'src/state';
import { Field } from 'react-final-form';
import { Label } from '@fluentui/react';
import Text from 'src/components/text/Text';
import FieldValidationError from './FieldValidationError';
import { IMeoLocale, MeoLocaleState } from 'src/state/meo-localization/types';

interface IPropsFromState {
  featureFlags: IFeatureFlagsState;
  meoLocale: MeoLocaleState;
}

interface IPropsFromParent {
  profile: IProfile;
  WrappedFieldArrayProps: any;
  isCommercial: boolean;
  initialEmailLocale: IMeoLocale;
}

type PrimaryContactEditProps = IPropsFromState & IPropsFromParent;

export const PrimaryContactEdit: React.FunctionComponent<PrimaryContactEditProps> = (props) => {

  return (
    <div id="org-info-part3">
      <div id="profile-primary-contact-description">
        <p>{localeService.getText('ProfileV2', 'PrimaryContactDescription')}</p>
      </div>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          <TextInputV2
            id="primaryContact.firstName"
            localeCategoryId="ProfileV2"
            localeLabelId="FirstName"
            required={true}
            validators={[composeRequiredFieldValidator('primaryContact.firstName')]}
          />
        </div>
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          <TextInputV2
            id="primaryContact.middleName"
            localeCategoryId="ProfileV2"
            localeLabelId="MiddleName"
            required={false}
          />
        </div>
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          <TextInputV2
            id="primaryContact.lastName"
            localeCategoryId="ProfileV2"
            localeLabelId="LastName"
            required={true}
            validators={[composeRequiredFieldValidator('primaryContact.lastName')]}
          />
        </div>
      </div>
      <div className="ms-Grid-row mt-2">
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          <TextInputV2
            id="primaryContact.email"
            localeCategoryId="ProfileV2"
            localeLabelId="PrimaryEmailAddress"
            required={true}
            validators={[emailRequiredFieldValidator, emailAddressValidator]}
          />
        </div>
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          <Label tabIndex={0} htmlFor="emailLanguagePreference">
            <Text category="ProfileV2" id="EmailLanguagePreferenceLabel" />
          </Label>
          <Field
            name="primaryContact.emailLanguagePreference"
            id="primaryContact.emailLanguagePreference"
            component={RenderMeoLocalesDropdown}
            props={{
              meoLocales: props.meoLocale.locales
            }}
            initialValue={props.initialEmailLocale.cultureCode}
          />
        </div>
      </div>
      <FieldArray name="notificationEmails">{({ fields }) => <RenderEmails fields={fields} />}</FieldArray>
    </div>
  );
};

interface IRenderMeoLocalesDropdown {
  input: any;
  meta: { touched: boolean; error: string };
  props: {
    meoLocales: IMeoLocale[]
  };
}

const RenderMeoLocalesDropdown = ({
  input,
  meta: { touched, error },
  props: { meoLocales }
}: IRenderMeoLocalesDropdown) => {
  return (
    <div>
      <div className="custom-select">
        <select
          className="custom-select-input"
          onBlur={input.onBlur}
          onChange={(e) => {
            input.onChange(e);
          }}
          value={input.value}
          aria-label={localeService.getText('ProfileV2', `EmailLanguagePreferenceLabel`)}
        >
          {(meoLocales || [])
          .sort((a, b) => a.displayName.localeCompare(b.displayName))
          .map((meoLocale, _index) => (
            <option key={meoLocale.cultureCode} value={meoLocale.cultureCode} lang={meoLocale.cultureCode}>
              {meoLocale.displayName}
            </option>
          ))}
        </select>
        <span className="custom-select-arrow" aria-hidden="true" />
      </div>
      {touched && error && <FieldValidationError localeErrorTextId={error} />}
    </div>
  );
};

const mapStateToProps = ({ flags, meoLocale }: IApplicationState) => ({
  featureFlags: flags,
  meoLocale: meoLocale
});

export default connect(mapStateToProps)(PrimaryContactEdit);
