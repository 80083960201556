import { Spinner, SpinnerSize } from '@fluentui/react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { initiateLogout } from 'src/state/auth';

interface IPropsFromState {}

interface IPropsFromDispatch {
  initiateLogout: typeof initiateLogout;
}

type SigninProps = IPropsFromDispatch & IPropsFromState;

const SignOutComponent = (props: SigninProps) => {
  const header = document.getElementById('uhfHeader');
  const footer = document.getElementById('uhfFooter');

  if (header) {
    header.hidden = true;
  }

  if (footer) {
    footer.hidden = true;
  }

  props.initiateLogout();

  return <Spinner size={SpinnerSize.large} />;
};

const mapStateToProps = (): IPropsFromState => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initiateLogout: () => dispatch(initiateLogout())
});

export default connect(mapStateToProps, mapDispatchToProps)(SignOutComponent);
