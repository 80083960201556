import { all, apply, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IApiResponse } from "src/common/ApiResponse";
import agentSettingsService from "src/dataServices/AgentSettingsService";
import { ActionType } from "typesafe-actions";
import { fetchError, setAppContext } from "../layout/actions";
import {
  fetchAgentSettingsSuccess,
  initiateTokenVerification,
  resendVerificationError,
  resendVerificationSuccess,
  tokenVerificationError,
  tokenVerificationSuccess
} from "./actions";
import { AgentSettings, AgentVerificationActionTypes } from "./types";

function* handleFetchAgentSettings() {
  try {
    const key = "AgentSettings";
    const agentSettingsJson = sessionStorage.getItem(key);
    let agentSettings: AgentSettings | null | undefined = null;
    if (agentSettingsJson) {
      agentSettings = JSON.parse(agentSettingsJson) as AgentSettings;
    } else {
      const response: IApiResponse<AgentSettings> = yield apply(
        agentSettingsService,
        agentSettingsService.getAgentSettings,
        []
      );
      agentSettings = response.value;
      sessionStorage.setItem(key, JSON.stringify(agentSettings));
    }

    yield all([
      put(fetchAgentSettingsSuccess(agentSettings)),
      put(setAppContext({ SessionId: agentSettings?.sessionId }))
    ]);
  } catch (err) {
    yield put(fetchError(err as string));
  }
}

export function* handleResendEmailRequest() {
  try {
    const response: IApiResponse<{}> = yield apply(agentSettingsService, agentSettingsService.initiateResendEmail, []);
    if (response.status === 200) {
      yield put(resendVerificationSuccess());
    } else {
      yield put(resendVerificationError());
    }
  } catch (e) {
    console.error("Error resending confirmation email.", e);
    yield put(resendVerificationError());
  }
}

export function* handleTokenVerificationRequest(action: ActionType<typeof initiateTokenVerification>) {
  try {
    const response: IApiResponse<{}> = yield apply(agentSettingsService, agentSettingsService.verifyAgentToken, [
      action.payload
    ]);
    if (response.status === 200) {
      yield put(tokenVerificationSuccess());
    } else {
      yield put(tokenVerificationError());
    }
  } catch (e) {
    console.error("Error verifying the token.", e);
    yield put(tokenVerificationError());
  }
}

function* watchFetchAgentSettings() {
  yield takeEvery(AgentVerificationActionTypes.FETCH_AGENT_SETTINGS_REQUEST, handleFetchAgentSettings);
}

function* watchResendConfirmationEmailRequest() {
  yield takeLatest(AgentVerificationActionTypes.RESEND_CONFIRMATION_EMAIL, handleResendEmailRequest);
}

function* watchTokenVerificationRequest() {
  yield takeLatest(AgentVerificationActionTypes.INITIATE_TOKEN_VERIFICATION, handleTokenVerificationRequest);
}

function* agentVerificationSaga() {
  yield all([
    fork(watchFetchAgentSettings),
    fork(watchResendConfirmationEmailRequest),
    fork(watchTokenVerificationRequest)
  ]);
}

export default agentVerificationSaga;
