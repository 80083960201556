import "./ResizableImage.scss";

export interface IResizableImage {
  root: string | undefined;
  imageSrc: string;
  imageAlt: string | undefined;
  imageClassName?: string;
  pictureClassName?: string;
  id?: string;
  fileSize1920?: string;
  fileSize1400?: string;
  fileSize1084?: string;
  fileSize860?: string;
  fileSize540?: string;
  fileSize360?: string;
}

export default function ResizableImage(props: IResizableImage) {
  const {
    root,
    imageSrc,
    imageAlt,
    imageClassName,
    pictureClassName,
    id,
    fileSize1920,
    fileSize1400,
    fileSize1084,
    fileSize860,
    fileSize540,
    fileSize360
  } = props;

  return (
    <picture id={id} className={pictureClassName}>
      {fileSize1920 !== undefined && <source srcSet={`${root}/${fileSize1920}`} media="(min-width: 1400px)" />}
      {fileSize1400 !== undefined && <source srcSet={`${root}/${fileSize1400}`} media="(min-width: 1084px)" />}
      {fileSize1084 !== undefined && <source srcSet={`${root}/${fileSize1084}`} media="(min-width: 860px)" />}
      {fileSize860 !== undefined && <source srcSet={`${root}/${fileSize860}`} media="(min-width: 540px)" />}
      {fileSize540 !== undefined && <source srcSet={`${root}/${fileSize540}`} media="(min-width: 360px)" />}
      {fileSize360 !== undefined && <source srcSet={`${root}/${fileSize360}`} media="(min-width: 0px)" />}
      <img src={imageSrc} className={imageClassName} alt={imageAlt} />
    </picture>
  );
}
