import { Label } from '@fluentui/react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import Text from 'src/components/text/Text';
import localeService from 'src/dataServices/LocaleService';
import {
  composeValidators,
  emailAddressValidator,
  emailRequiredFieldValidator,
  inputTextFieldComponent
} from './ProfileV2SharedComponents';

export interface IRenderEmailsProps {
  fields: any;
}

export const RenderEmails = (props: IRenderEmailsProps) => {
  const { fields } = props;

  return (
    <div>
      {fields && fields.length > 0 && (
        <div className="ms-Grid-row mt-2">
          <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
            <Label tabIndex={0} htmlFor="Alternate Email Address">
              <Text category="ProfileV2" id="AlternateEmailAddress" />
            </Label>
          </div>
        </div>
      )}
      <div className="notification-emails" id="notificationEmails">
        {fields &&
          fields.map((email: string, index: number) => (
            <div key={index}>
              <div className="ms-Grid-row mt-2">
                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
                  <Field
                    id={email}
                    name={email}
                    component={inputTextFieldComponent}
                    type="email"
                    autoFocus={true}
                    placeholder={localeService.getText('ProfileV2', 'AlternateEmailPlaceholder') + `#${index + 1}`}
                    validate={composeValidators([emailRequiredFieldValidator, emailAddressValidator])}
                    maxLength={200}
                    label={`Email #${index + 1}`}
                  />
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
                  <button
                    id={'DeleteButton' + `${index}`}
                    type="button"
                    className="action-trigger glyph-prepend glyph-prepend-delete action-button"
                    onKeyDown={(evt: { key: string }) => {
                      if (evt.key === 'Enter') {
                        fields.remove(index);
                      }
                    }}
                    onClick={() => {
                      fields.remove(index);
                    }}
                  >
                    {localeService.getText('ProfileV2', 'Delete')}
                  </button>
                </div>
              </div>
            </div>
          ))}
      </div>
      {fields && (
        <div className="ms-Grid-row">
          {fields.length < 5 && (
            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
              <button
                id="addMoreButton"
                type="button"
                className="action-trigger glyph-prepend glyph-prepend-circle-plus mt-2 action-button"
                onKeyDown={(evt: { key: string }) => {
                  if (evt.key === 'Enter') {
                    fields.push(undefined);
                  }
                }}
                onClick={() => {
                  fields.push(undefined);
                }}
              >
                {localeService.getText('ProfileV2', 'AddAlternateEmail')}
              </button>
            </div>
          )}
        </div>
      )}
      {fields && fields.length > 0 && (
        <div className="alternate-email-policy">
          <p className="action-trigger glyph-prepend glyph-prepend-info action-button">
            <Text category="ProfileV2" id="AlternateEmailPolicy" />
          </p>
        </div>
      )}
    </div>
  );
};

export default connect()(RenderEmails);
