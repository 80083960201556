
export interface INavigationCardContent {
  imgSrc: string;
  imgAlt?: string;
  title?: string;
  children?: JSX.Element | JSX.Element[];
  ctaText?: string;
  ctaLink?: string;
  linkAriaLabel?: string;
}

const NavigationCardContent = (props: INavigationCardContent) => {
  const { imgSrc, imgAlt, title, children, ctaText, ctaLink, linkAriaLabel } = props;

  return (
    <section>
      <div className="card d-block mx-ng mx-md-0 mt-5 mb-5">
        <div className="row no-gutters ml-5 mr-5">
          <div className="col-md-6">
            <picture>
              <img alt={imgAlt} src={imgSrc} className="card-img img-object-cover" />
            </picture>
          </div>
          <div className="d-flex col-md">
            <div className="card-body align-self-center p-4 p-md-5">
              <h2 className="text-style h3">{title}</h2>
              <div className="Detail-text mb-3 mt-2">{children}</div>
              <a href={ctaLink} className="cta" target="_blank" rel="noopener noreferrer" aria-label = { linkAriaLabel == undefined ? ctaText : linkAriaLabel }>
                {ctaText}
                <span className="glyph" aria-hidden="true"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NavigationCardContent;
