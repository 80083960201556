import { Reducer } from "redux";
import { telemetryService } from "npo-common";
import { LayoutActionTypes, LayoutState } from "./types";

// Type-safe initialState!
const initialState: LayoutState = {
  appContext: {}
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<LayoutState> = (state: LayoutState = initialState, action): LayoutState => {
  switch (action.type) {
    case LayoutActionTypes.SET_APP_CONTEXT: {
      const context = action.payload as { [key: string]: any };
      if (context) {
        telemetryService.setContext(context);
        if (context.ObjectId && context.Puid && telemetryService.ai) {
          telemetryService.ai.setAuthenticatedUserContext(context.ObjectId, context.Puid);
        }
        return { ...state, appContext: telemetryService.getContext() };
      } else {
        return state;
      }
    }
    // TODO: error message/loading message
    default: {
      return state;
    }
  }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as layoutReducer };
