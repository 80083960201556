import "@ms-mwf/moray/dist/css/main.css";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";
import localeService from "src/dataServices/LocaleService";
import { IApplicationState } from "src/state";
import * as authActions from "src/state/auth/actions";
import { initiateLogout } from "src/state/auth/actions";
import { NonprofitError } from "src/state/nonprofit";
import Text from "../text/Text";
import TextLink from "../text/TextLink";
import { getLocalizedPath } from "../utilities/Localization/Utils";
import "./UserPanel.scss";

interface IPropsFromState {
  userName: string;
  emailAddress: string;
  error?: NonprofitError;
}

interface IPropsFromDispatch {
  initiateLogout: typeof authActions.initiateLogout;
}

export type IUserPanelProps = IPropsFromState & IPropsFromDispatch;

interface IUserPanelState {
  showing: boolean;
  fullMenu: boolean;
}

export class UserPanel extends React.PureComponent<IUserPanelProps, IUserPanelState> {
  private buttonRef = React.createRef<HTMLButtonElement>();

  constructor(props: IUserPanelProps) {
    super(props);
    this.state = { showing: false, fullMenu: false };
  }

  public componentDidMount() {
    document.addEventListener("mouseup", this.hidePanelOnMouseUp);
  }

  public componentWillUnmount() {
    document.removeEventListener("mouseup", this.hidePanelOnMouseUp);
  }

  public render() {
    const yourAccount = localeService.getText("UserPanel", "YourAccount");

    const { showing, fullMenu } = this.state;
    const displayClass = fullMenu ? "dismiss " : "";
    const styleOverride = {
      backgroundImage: `url(${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/HomePage/NavBarUserPanelBackground.png)`
    };

    const panelContent = (
      <div>
        <div>
          <div>
            <br />
          </div>
          <div>
            <div>{this.props.userName}</div>
            <div>{this.props.emailAddress}</div>
          </div>
        </div>
        {this.userMenu()}
      </div>
    );

    return (
      <div id="user-panel">
        <button
          id="user-panel-button"
          className="glyph-prepend glyph-prepend-add-friend user-panel-button"
          style={styleOverride}
          onClick={showing ? this.hidePanel : this.showPanel}
          onKeyDown={e => {
            if (e.key === "Escape") {
              this.hidePanel();
            }
          }}
          onFocus={() => this.hidePanel()}
          ref={this.buttonRef}
          aria-expanded={showing}
          title={yourAccount}
        />
        <div id="user-panel-content">
          <aside className={displayClass + (showing ? (!this.props.error ? "show" : "show-without-profile") : "hide")}>
            {panelContent}
          </aside>
        </div>
      </div>
    );
  }

  private userMenu() {
    return (
      <ul
        onKeyDown={e => {
          if (e.key === "Escape") {
            this.hidePanel();
            this.buttonRef.current?.focus();
          }
        }}
      >
        {!this.props.error && (
          <li>
            <Link to={getLocalizedPath("/profile")}>{localeService.getText("UserPanel", "MyProfile")}</Link>
          </li>
        )}
        <li>
          <TextLink category="UserPanel" id="MyAccount" href={process.env.REACT_APP_MYACCOUNT_URL} target="_blank" />
        </li>
        <li>
          <button onKeyDown={this.handleKeyDown} onClick={this.props.initiateLogout}>
            <Text category="UserPanel" id="SignOut" />
          </button>
        </li>
      </ul>
    );
  }

  private handleKeyDownTab = (e: React.KeyboardEvent) => {
    if (e.key === "Tab") {
      this.buttonRef.current?.focus();
      this.hidePanel();
    }
  };

  private showPanel = () => {
    this.setState({ showing: true, fullMenu: false });
  };

  private hidePanel = () => {
    this.setState({ showing: false, fullMenu: false });
  };

  private handleKeyDown = (evt: { key: string }) => {
    if (evt.key === "Tab") {
      this.setState({ fullMenu: true });
    }
  };

  private hidePanelOnMouseUp = (event: any) => {
    const targetElement = event.srcElement;
    const targetElementId = targetElement.attributes.getNamedItem("id");
    if (!targetElementId || targetElementId.value !== "user-panel-button") {
      this.hidePanel();
    }
  };
}

const mapStateToProps = ({ auth, nonprofit }: IApplicationState) => ({
  emailAddress: !auth.user ? "" : auth.user.username,
  userName: !auth.user ? "" : auth.user.name ? auth.user.name : auth.user.username,
  error: nonprofit && nonprofit.error
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initiateLogout: () => dispatch(initiateLogout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserPanel);
