import { action } from "typesafe-actions";
import { AuthActionTypes, AuthStatus } from "./types";

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions
export const setAuthStatus = (status: AuthStatus) => action(AuthActionTypes.SET_AUTH_STATUS, status);
export const initiateLogin = () => action(AuthActionTypes.INITIATE_LOGIN);
export const initiateRegister = (newSignupExperience: boolean = false) =>
  action(AuthActionTypes.INITIATE_REGISTER, newSignupExperience);
export const processLoginPromise = () => action(AuthActionTypes.PROCESS_LOGIN_PROMISE);
export const initiateLogout = () => action(AuthActionTypes.INITIATE_LOGOUT);
