import * as React from "react";
import { OfferCardCTA, OfferCardFeatureHeading, OfferCardLargeText } from "src/components/moray/MorayOfferCard";
import localeService from "src/dataServices/LocaleService";

const SolutionCenterHeading = () => (
  <OfferCardFeatureHeading>
    <h4 id={"ActivateSolutionCenterTitle"} className="font-weight-semibold h4">
      {localeService.getText("CloudV2", "ActivateSolutionCenterTitle")}
    </h4>
    <OfferCardLargeText
      id={"SolutionCenterHeadingText"}
      text={localeService.getText("CloudV2", "ActivateSolutionCenterDescription")}
    />
    <OfferCardCTA
      ctaText={localeService.getText("CloudV2", "ActivateSolutionCenterButton")}
      ctaLink={localeService.getText("CloudV2", "ActivateSolutionCenterLink")}
    />
  </OfferCardFeatureHeading>
);

export default SolutionCenterHeading;
