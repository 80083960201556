import * as React from "react";
import MorayShowMore from "src/components/moray/MorayShowMore";
import Text from "src/components/text/Text";
import TextLink from "src/components/text/TextLink";
import localeService from "src/dataServices/LocaleService";

const SoftwareGrantsAndDiscounts = () => {
  const imgRoot = process.env.REACT_APP_HUB_IMAGES_CDN_URL;
  const findPartnerButtonText = localeService.getText("SoftwareV2", "FindPartnerButton");
  const techSoupButtonText = localeService.getText("SoftwareV2", "TechSoupButton");
  const readMoreText = localeService.getText("SoftwareV2", "ReadMore");
  const readLessText = localeService.getText("SoftwareV2", "ReadLess");

  const findAPartnerLink = localeService.getText("Urls", "FindAPartnerLink");
  const techSoupMicrosoft = localeService.getText("Urls", "TechSoupMicrosoft");

  return (
    <section className="software-and-grants">
      <div className="software-and-grants-card" data-mount="click-group">
        <img src={`${imgRoot}/Partners/partners-software-grants.jpg`} className="card-img" alt="" />
        <div className="card-content">
          <h3 className="title">
            <Text category={"SoftwareV2"} id={"SoftwareGrantsTitle"} />
          </h3>
          <div className="description">
            <MorayShowMore
              ariaControls="toggle-content-01"
              visibleContent={
                <p>
                  <Text category={"SoftwareV2"} id={"SoftwareGrantsDescription1"} />
                </p>
              }
              hiddenContent={
                <>
                  <p>
                    <Text category={"SoftwareV2"} id={"SoftwareGrantsDescription2"} />
                  </p>
                  <p>
                    <Text category={"SoftwareV2"} id={"SoftwareGrantsDescription3"} />
                  </p>
                  <p>
                    <Text category={"SoftwareV2"} id={"SoftwareGrantsDescription4"} />
                  </p>
                </>
              }
              showMoreText={readMoreText}
              showLessText={readLessText}
              buttonAriaLabel={readMoreText}
            />
            <img className={"guide-icon"} src={`${imgRoot}/Partners/Guide.svg`} alt="" />
            <p className="footer-text">
              <TextLink
                category={"SoftwareV2"}
                id={"SoftwareGrantsGuide"}
                href={"TechSoupOnPremiseProductsGuide"}
                target={"_blank"}
              />
            </p>
            <button
              type="button"
              className="btn btn-primary"
              aria-label={techSoupButtonText}
              onClick={() => {
                window.open(techSoupMicrosoft, "_blank");
              }}
            >
              {techSoupButtonText}
            </button>
          </div>
        </div>
      </div>
      <div className="software-and-grants-card" data-mount="click-group">
        <img src={`${imgRoot}/Partners/partners-software-discounts.jpg`} className="card-img" alt="" />
        <div className="card-content">
          <h3 className="title">
            <Text category={"SoftwareV2"} id={"SoftwareDiscountsTitle"} />
          </h3>
          <div className="description">
            <MorayShowMore
              ariaControls="toggle-content-02"
              visibleContent={
                <p>
                  <Text category={"SoftwareV2"} id={"SoftwareDiscountsDescription1"} />
                </p>
              }
              hiddenContent={
                <>
                  <p>
                    <Text category={"SoftwareV2"} id={"SoftwareDiscountsDescription2"} />
                  </p>
                  <p>
                    <Text category={"SoftwareV2"} id={"SoftwareDiscountsDescription3"} />
                  </p>
                  <p>
                    <Text category={"SoftwareV2"} id={"SoftwareDiscountsDescription4"} />
                  </p>
                </>
              }
              showMoreText={readMoreText}
              showLessText={readLessText}
              buttonAriaLabel={readMoreText}
            />
            <img className={"guide-icon"} src={`${imgRoot}/Partners/Guide.svg`} alt="" />
            <p className="footer-text">
              <TextLink
                category={"SoftwareV2"}
                id={"SoftwareDiscountsGuide"}
                href={"OnPremiseSoftwareLicensesGuide"}
                target={"_blank"}
              />
            </p>
            <button
              type="button"
              className="btn btn-primary"
              aria-label={findPartnerButtonText}
              onClick={() => {
                window.open(findAPartnerLink, "_blank");
              }}
            >
              {findPartnerButtonText}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SoftwareGrantsAndDiscounts;
