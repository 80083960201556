import { Reducer } from "redux";
import { IQueryState, QueryActionTypes } from "src/state/query/types";

const startingNumberOfSolutions = 3;

export const initialState: IQueryState = {
  query: "",
  queryResponse: "",
  error: "",
  isSending: false,
  numberOfSolutions: startingNumberOfSolutions
};

const reducer: Reducer<IQueryState> = (state = initialState, action) => {
  switch (action.type) {
    case QueryActionTypes.QUERY_SUBMITREQUEST: {
      return { ...state, query: action.payload.Text, isSending: true };
    }
    case QueryActionTypes.QUERY_SUCCESS: {
      return { ...state, queryResponse: action.payload, isSending: false };
    }
    case QueryActionTypes.QUERY_ERROR: {
      return {
        ...state,
        error: action.payload,
        isSending: false,
        queryResponse: {}
      };
    }
    case QueryActionTypes.QUERY_SHOW_MORE_RESULTS: {
      return { ...state, numberOfSolutions: state.numberOfSolutions + 3 };
    }
    case QueryActionTypes.QUERY_SHOW_FEWER_RESULTS: {
      return { ...state, numberOfSolutions: state.numberOfSolutions - 3 };
    }
    default: {
      return state;
    }
  }
};

export { reducer as queryReducer };
