import "@ms-mwf/moray/dist/css/main.css";
import * as React from "react";
import localeservice from "src/dataServices/LocaleService";
import ContactUsV2FeaturesCard from "./ContactUsV2FeaturesCard";

class ContactUsV2Features extends React.Component {
  public render() {
    return (
      <section>
        <div
          className="row row-cols-1 row-cols-md-2 "
          style={{
            margin: "0"
          }}
        >
          <ContactUsV2FeaturesCard
            iconName={"trophy2"}
            titleId={"NonprofitOffersTitle"}
            detailId={"NonprofitOffersDetail"}
            buttonId={"NonprofitOffersButton"}
            href={localeservice.getText("Links", "urlOffers") ?? undefined}
          />
          <ContactUsV2FeaturesCard
            iconName={"issuetracking"}
            titleId={"EligibilityTitle"}
            detailId={"EligibilityDetail"}
            buttonId={"EligibilityButton"}
            href={localeservice.getText("Links", "urlEligibility") ?? undefined}
          />
        </div>
        <div
          className="row row-cols-1 row-cols-md-2 "
          style={{
            margin: "0"
          }}
        >
          <ContactUsV2FeaturesCard
            iconName={"people"}
            titleId={"FindAPartnerTitle"}
            detailId={"FindAPartnerDetail"}
            buttonId={"FindAPartnerButton"}
            href={localeservice.getText("Links", "urlPartners") ?? undefined}
          />
          <ContactUsV2FeaturesCard
            iconName={"unknown"}
            titleId={"FAQTitle"}
            detailId={"FAQDetail"}
            buttonId={"FAQButton"}
            href={localeservice.getText("Links", "urlFaq") ?? undefined}
          />
        </div>
      </section>
    );
  }
}

export default ContactUsV2Features;
