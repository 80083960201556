import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import authenticationService from "src/dataServices/AuthenticationService";
import { logService } from "npo-common";
import { IApplicationState } from "src/state";
import { AuthState, initiateLogout } from "src/state/auth";
import "./AppContext.scss";

export interface IAuthAutoRenewOwnProps {
  authState?: AuthState;
}

export interface IPropsFromDispatch {
  initiateLogout: typeof initiateLogout;
}

export type IAuthAutoRenewProps = IPropsFromDispatch & IAuthAutoRenewOwnProps;

export class AuthAutoRenew extends React.Component<IAuthAutoRenewProps> {
  private intervalId?: NodeJS.Timeout;

  public componentDidMount() {
    const { authState } = this.props;
    if (authState && authState.isAuthenticated) {
      this.intervalId = setInterval(this.tryRefreshToken, 10 * 1000);
    }
  }

  public componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  public render() {
    return null;
  }

  private tryRefreshToken = () => {
    const currentToken = authenticationService.cachedToken;
    authenticationService.getToken().then(token => {
      if (token && currentToken !== token) {
        logService.logInfo("AuthAutoRenew: obtained new token.", {
          ExpiresInMinutes: authenticationService.getTokenExpirationInMinutes(token)
        });
      }

      if (!token || authenticationService.getTokenExpirationInMinutes(token) < 0) {
        logService.logInfo("AuthService:refreshToken:failed to refresh token, initiating logout.");
        this.props.initiateLogout();
      }
    });
  };
}

const mapStateToProps = ({ auth }: IApplicationState) => ({
  authState: auth
});

const mapDispatchToProps = (dispatch: Dispatch): IPropsFromDispatch => ({
  initiateLogout: () => dispatch(initiateLogout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthAutoRenew);
