import { IAddress } from "../shared";

export const enum NonprofitActionTypes {
  FETCH_NONPROFIT_REQUEST = "@@nonprofit/FETCH_NONPROFIT_REQUEST",
  FETCH_NONPROFIT_SUCCESS = "@@nonprofit/FETCH_NONPROFIT_SUCCESS",
  NONPROFIT_ERROR = "@@nonprofit/NONPROFIT_ERROR",
  FETCH_NONPROFIT_FROM_GRAPH_SUCCESS = "@@nonprofit/FETCH_NONPROFIT_FROM_GRAPH_SUCCESS",
  RESEND_CONFIRMATION_EMAIL = "@@nonprofit/RESEND_CONFIRMATION_EMAIL",
  RESEND_CONFIRMATION_EMAIL_SUCCESS = "@@nonprofit/RESEND_CONFIRMATION_EMAIL_SUCCESS",
  RESEND_CONFIRMATION_EMAIL_ERROR = "@@nonprofit/RESEND_CONFIRMATION_EMAIL_ERROR",
  INITIATE_CONTACT_PERMISSION_SUBMISSION = "@@nonprofit/INITIATE_CONTACT_PERMISSION_SUBMISSION",
  CONTACT_PERMISSION_SUCCESS = "@@nonprofit/CONTACT_PERMISSION_SUCCESS",
  CONTACT_PERMISSION_ERROR = "@@nonprofit/CONTACT_PERMISSION_ERROR"
}

export enum NonprofitRequestStatus {
  NotAvailable = "NotAvailable",
  InProgress = "InProgress",
  InProgressActionRequired = "InProgressActionRequired",
  Approved = "Approved",
  Declined = "Declined",
  ExpiredActionRequired = "ExpiredActionRequired"
}

export enum NonprofitStatus {
  InProgress = "InProgress",
  Approved = "Approved",
  Declined = "Declined",
  Closed = "Closed",
  Fraud = "Fraud",
  Unknown = "Unknown"
}

export enum TSStatus {
  NeedScreening = "need screening",
  Allowed = "allowed",
  NotAllowed = "not allowed",
  UnderReview = "under review",
  IngestionError = "ingestion error",
  Error = "error"
}

export enum TSReasonCode {
  SSI_D = "ssi-d",
  SSI_E = "ssi-e",
  SSI_F = "ssi-f",
  LIC_R = "lic-r",
  DataIssue = "data issue"
}

export enum TSDataIssue {
  Name = "data issue - name",
  Org = "data issue - org",
  Address = "data issue - address",
  LegalId = "data issue - legal id"
}

export interface TSComplianceStatus
{
    status: string;
    reasonCode: string;
    reasonDescription: string;
    lastScreened: Date;
    dataIssueCode: string;
}

export interface INonprofitBaseProfile {
  id: string;
  name: string;
  address: IAddress;
  status: NonprofitStatus;
  lastAttestationDate?: Date;
  effectiveDateTime?: Date;
  isIncomplete: boolean;
  pendingDeclineDate?: Date;
  agentEmail: string;
  emailVerified: IAgentEmailVerification;
  permissionToContact?: boolean;
  registrationIdentifiers: IRegistrationIdentifier[];
  tsComplianceStatus?: TSComplianceStatus;  
  disableCountryEdit: boolean;
}

export interface IRegistrationIdentifier {
  identifierKey: string;
  identifierAcronym: string;
  regulatoryBodyAcronym: string;
  legalIdentifier: string;
  regulatoryBody: string;
}

export enum IAgentEmailVerification {
  Yes = "Yes",
  No = "No",
  SkipContinue = "SkipContinue",
  SkipAbort = "SkipAbort"
}

export interface INonprofitMiniProfile extends INonprofitBaseProfile {
  isCharityApproved: boolean;
  validationRequest: IValidationRequest;
  availableOffers: IOffer[];
  primaryContact: IContact;
}

export interface IContact {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  emailLanguagePreference: string;
}

export interface IValidationRequest {
  providerTransactionId: string;
  nonprofitId: string;
  requestStatus: NonprofitRequestStatus;
  declinedReasonCodes: string[];
}

export interface IOffer {
  id: string;
  name: string;
  supportEmail: string;
  requiresConsent?: boolean;
}

export enum NonprofitError {
  AgentError = "AgentError",
  NotAdmin = "NotAdmin",
  NotFound = "NotFound",
  TenantNotReady = "TenantNotReady",
  Unknown = "Unknown",
  TenantError = "TenantError"
}

export enum OfferId {
  accountGuard = 'accountguard',
  azure = 'azure',
  azure_onboarding_concierge = 'azure_onboarding_concierge',
  bingAds = 'bingads',
  businessvoice = 'businessvoice',
  cloud = 'cloud',
  covid19 = 'covid19',
  covid19_windows_upgrade = 'covid19_windows_upgrade',
  covid19_win10_E3_licenses = 'covid19_win10_E3_licenses',
  covid19_azure_credits = 'covid19_azure_credits',
  covid19_azure_onboarding_concierge = 'covid19_azure_onboarding_concierge',
  covid19_wvd_helpdesk = 'covid19_wvd_helpdesk',
  covid19_ms_community_training = 'covid19_ms_community_training',
  dynamic365 = 'dynamic365',
  gitHub = 'github',
  hardware = 'hardware',
  linkedIn = 'linkedin',
  m365 = 'm365',
  o365 = 'o365',
  partners = 'partners',
  software = 'software',
  training = 'training',
  community_training = 'communitytraining'
}

export enum OfferPageId {
  accountGuard = "accountguard",
  azure = "azure",
  bingAds = "bingads",
  cloud = "cloud",
  covid19 = "covid19",
  dynamic365 = "dynamic365",
  gitHub = "github",
  hardware = "hardware",
  linkedIn = "linkedin",
  m365 = "m365",
  partners = "partners",
  software = "software",
  training = "training"
}

export enum NonprofitErrorOutcome {
  RetryTenantNotReady = "RetryTenantNotReady",
  NotAdmin = "NotAdmin",
  AgentError = "AgentError",
  Commercial = "Commercial",
  TenantNotFound = "TenantNotFound",
  TenantError = "TenantError"
}

export interface INonprofitState {
  readonly miniProfile: INonprofitMiniProfile;
  readonly error?: NonprofitError;
  readonly isLoading?: boolean;
  readonly retryAttempts: number;
  readonly isCommercial: boolean;
  readonly nonprofitId?: string;
  readonly contactPermission?: IContactPermissions;
}

export interface IContactPermissions {
  contactPermissionInProcess: boolean;
  contactPermissionSuccess: boolean;
  contactPermissionError: boolean;
}

export interface IContactPermissionFormSubmission {
  permissionToContact: boolean;
}
