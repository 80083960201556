import { IResizableImage } from 'src/components/moray/ResizeableImage';
import localeService from 'src/dataServices/LocaleService';
import { scrollOptions } from 'src/common/utilities/Utilities';
import HighlightBanner from '../shared/HighlightBanner';
import HighlightBannerCarousel from '../shared/HighlightBannerCarousel';
import localeEnabledForV2, { LocaleConfigStrings } from 'src/common/localeEnabledForV2';

interface ICloudTopBannerProps {
  plansElement: HTMLElement | null;
}

const CloudTopBanner = ({ plansElement }: ICloudTopBannerProps) => {
  const spring2023VersionEnabledForLocale = localeEnabledForV2(LocaleConfigStrings.CloudSpring2023);

  const resizableImageProps: IResizableImage[] = [
    {
      root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/MC4NP`,
      imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/MC4NP/MC4NP-HighlightFundraising.jpg`,
      imageClassName: 'img-fill-container card-img',
      imageAlt: localeService.getText('CloudV2', 'AccessMoreCarouselTitle'),
      fileSize1920: 'MC4NP-HighlightFundraising.jpg',
      fileSize1400: 'MC4NP-HighlightFundraising.jpg',
      fileSize1084: 'MC4NP-HighlightFundraising.jpg',
      fileSize860: 'MC4NP-HighlightFundraising.jpg',
      fileSize540: 'MC4NP-HighlightFundraising.jpg',
      fileSize360: 'MC4NP-HighlightFundraising.jpg'
    },
    {
      root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/MC4NP`,
      imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/MC4NP/MC4NP-Highlight1-540.jpg`,
      imageClassName: 'img-fill-container card-img',
      imageAlt: localeService.getText('CloudV2', 'MicrosoftCloudForNonprofitCarouselTitle'),
      fileSize1920: 'MC4NP-Highlight1-1920.jpg',
      fileSize1400: 'MC4NP-Highlight1-1400.jpg',
      fileSize1084: 'MC4NP-Highlight1-1084.jpg',
      fileSize860: 'MC4NP-Highlight1-860.jpg',
      fileSize540: 'MC4NP-Highlight1-540.jpg',
      fileSize360: 'MC4NP-Highlight1-360.jpg'
    },
    {
      root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/MC4NP`,
      imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/MC4NP/MC4NP-Highlight2-540.jpg`,
      imageClassName: 'img-fill-container card-img',
      imageAlt: localeService.getText('CloudV2', 'AccessMoreCarouselTitle'),
      fileSize1920: 'MC4NP-Highlight2-1920.jpg',
      fileSize1400: 'MC4NP-Highlight2-1400.jpg',
      fileSize1084: 'MC4NP-Highlight2-1084.jpg',
      fileSize860: 'MC4NP-Highlight2-860.jpg',
      fileSize540: 'MC4NP-Highlight2-540.jpg',
      fileSize360: 'MC4NP-Highlight2-360.jpg'
    }
  ];

  var highlightBanners = [
    <HighlightBanner
      key={'banner-cloud-for-nonprofit'}
      titleText={localeService.getText('CloudV2', 'MicrosoftCloudForNonprofitCarouselTitle')}
      descriptionText={localeService.getText('CloudV2', 'MicrosoftCloudForNonprofitCarouselDescription')}
      buttonText={localeService.getText('CloudV2', 'MicrosoftCloudForNonprofitCarouselButton')}
      buttonOnClick={() => {
        if (plansElement !== null) {
          plansElement.scrollIntoView(scrollOptions);
        }
      }}
      buttonAriaLabel={localeService.getText('CloudV2', 'MicrosoftCloudForNonprofitCarouselAriaLabel')}
      resizableImageProps={resizableImageProps[1]}
    />,
    <HighlightBanner
      key={'banner-cloud-access-more'}
      reverseTextAlign={true}
      titleText={localeService.getText('CloudV2', 'AccessMoreCarouselTitle')}
      descriptionText={localeService.getText('CloudV2', 'AccessMoreCarouselDescription')}
      buttonText={localeService.getText('CloudV2', 'AccessMoreCarouselButton')}
      buttonOnClick={() => window.open(localeService.getText('Urls', 'AccessMoreResources'))}
      buttonAriaLabel={localeService.getText('CloudV2', 'AccessMoreCarouselAriaLabel')}
      resizableImageProps={resizableImageProps[2]}
    />
  ];

  if (spring2023VersionEnabledForLocale) {
    highlightBanners.unshift(
      <HighlightBanner
        key={'banner-cloud-fundraising'}
        titleText={localeService.getText('CloudV2', 'CloudFundraisingCarouselTitle')}
        descriptionText={localeService.getText('CloudV2', 'CloudFundraisingCarouselDescription')}
        buttonText={localeService.getText('CloudV2', 'CloudFundraisingCarouselButton')}
        buttonOnClick={() => window.open(localeService.getText('Urls', 'CloudFundraisingGuidedTour'))}
        buttonAriaLabel={localeService.getText('CloudV2', 'CloudFundraisingCarouselAriaLabel')}
        resizableImageProps={resizableImageProps[0]}
      />
    );
  }

  return <HighlightBannerCarousel>{highlightBanners}</HighlightBannerCarousel>;
};

export default CloudTopBanner;
