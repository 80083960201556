import "@ms-mwf/moray/dist/css/main.css";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import ContactUsV2BillingSection from "../../components/contactusv2/ContactUsV2BillingSection";
import ContactUsV2Features from "../../components/contactusv2/ContactUsV2Features";
import ContactUsV2GetHelp from "../../components/contactusv2/ContactUsV2GetHelp";
import ContactUsV2GetMoreHelp from "../../components/contactusv2/ContactUsV2GetMoreHelp";
import ContactUsV2SelfHelp from "../../components/contactusv2/ContactUsV2SelfHelp";
import ContactUsV2Success from "../../components/contactusv2/ContactUsV2Success";
import ContactUsV2TopBanner from "../../components/contactusv2/ContactUsV2TopBanner";
import FollowUsFooter from "../../components/followUsFooter/FollowUsFooter";

class ContactUsPageV2 extends React.Component<RouteComponentProps> {
  public render() {
    return (
      <div>
        <ContactUsV2TopBanner />
        <ContactUsV2Success />
        <ContactUsV2Features />
        <ContactUsV2BillingSection />
        <ContactUsV2GetHelp />
        <ContactUsV2SelfHelp />
        <ContactUsV2GetMoreHelp />
        <FollowUsFooter useExternalLinks={true} />
      </div>
    );
  }
}

export default ContactUsPageV2;
