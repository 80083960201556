// Default Values
export const FeatureFlags = {
  isLoading: false,
  LocalizationV2: false,
  BingAdsOfferEnabled: false,
  UHFEnabled: true,
  TSEnabled: false,
  M365CopilotOfferUpdateEnabled: false,
  D365CopilotOfferUpdateEnabled: false,
  MC4NPOfferUpdateEnabled: false
};
