import * as React from "react";
import Text from "src/components/text/Text";
import localeService from "src/dataServices/LocaleService";

const CloudRegisterNowBanner = () => {
  return (
    <div className="cloud-register-now-banner card">
      <div id="cloud-register-now-banner-section" className="row no-gutters material-surface">
        <div className="d-flex col-md">
          <div className="card-body align-self-center p-4 p-md-5">
            <h2 id="cloud-register-now-banner-title" className="text-style h3">
              <Text category="CloudV2" id="WebinarBannerTitle" />
            </h2>
            <div id="cloud-register-now-banner-description" className="Detail-text mb-3 mt-2">
              <p>
                <Text category="CloudV2" id="WebinarBannerDescription" />
              </p>
            </div>
            <div id="cloud-register-now-banner-button" className="link-group">
              <a
                href={localeService.getText("Urls", "CloudRegisterNowLink")}
                className="btn btn-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Text category="CloudV2" id="WebinarBannerButton" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <picture>
            <source
              srcSet={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/MC4NP/MC4NP-RegisterNow-1920.jpg`}
              media="(min-width: 1400px)"
            />
            <source
              srcSet={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/MC4NP/MC4NP-RegisterNow-1400.jpg`}
              media="(min-width: 1084px)"
            />
            <source
              srcSet={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/MC4NP/MC4NP-RegisterNow-1084.jpg`}
              media="(min-width: 860px)"
            />
            <source
              srcSet={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/MC4NP/MC4NP-RegisterNow-860.jpg`}
              media="(min-width: 540px)"
            />
            <img
              src={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/MC4NP/MC4NP-RegisterNow-540.jpg`}
              className="card-img img-object-cover"
              alt={localeService.getText("CloudV2", "WebinarPlaceholder")}
            />
          </picture>
        </div>
      </div>
    </div>
  );
};

export default CloudRegisterNowBanner;
