import { Reducer } from "redux";
import { ProfileActionTypes } from "./types";

const initialState: any = {
  isLoading: undefined,
  profile: undefined,
  validationRequired: undefined,
  error: undefined,
  isNewProfile: false,
  contactPermissionSettings: undefined,
  isProfileSubmissionValidationInProgress: undefined,
  editEmailState: undefined,
  editMode: false,
  showConfirmation: false,
  isCommercial: false
};

const reducer: Reducer<any> = (state = initialState, action) => {
  switch (action.type) {
    case ProfileActionTypes.FETCH_PROFILE_REQUEST: {
      return { ...state, profile: undefined, error: undefined, isLoading: true };
    }
    case ProfileActionTypes.FETCH_PROFILE_SUCCESS: {
      return {
        ...state,
        profile: action.payload.profile,
        validationRequired: undefined,
        isLoading: false
      };
    }
    case ProfileActionTypes.FETCH_NEW_PROFILE_SUCCESS: {
      return {
        ...state,
        profile: action.payload.profile,
        validationRequired: undefined,
        isLoading: false,
        isNewProfile: true
      };
    }
    case ProfileActionTypes.FETCH_CONTACT_PERMISSION_SETTINGS: {
      return { ...state, contactPermissionSettings: action.payload };
    }
    case ProfileActionTypes.PROFILE_FETCH_ERROR: {
      return { ...state, error: action.payload, isLoading: false };
    }
    case ProfileActionTypes.PROFILE_FORM_UPDATE:
    case ProfileActionTypes.NEW_PROFILE_FORM_UPDATE: {
      return { ...state, profile: action.payload, isLoading: false };
    }
    case ProfileActionTypes.PROFILE_ACK_ATTESTATION:
    case ProfileActionTypes.NEW_PROFILE_ACK_ATTESTATION: {
      const profile = { ...state.profile };
      profile.lastAttestationDate = new Date();
      return { ...state, profile, isLoading: true };
    }
    case ProfileActionTypes.CANCEL_PROFILE_ACK_ATTESTATION:
    case ProfileActionTypes.CANCEL_NEW_PROFILE_ACK_ATTESTATION: {
      return { ...state, error: undefined };
    }
    case ProfileActionTypes.PROFILE_UPDATE_COMPLETE: {
      return { ...state, profile: undefined, validationRequired: action.payload, isLoading: false };
    }
    case ProfileActionTypes.NEW_PROFILE_CREATE_COMPLETE: {
      return { ...state, profile: undefined, isNewProfile: false, validationRequired: true, isLoading: false };
    }
    case ProfileActionTypes.CANCEL_PROFILE_EDIT: {
      return { ...state, profile: undefined, isLoading: false };
    }
    case ProfileActionTypes.PROFILE_UPDATE_ERROR: {
      return { ...state, error: action.payload, isLoading: false };
    }
    case ProfileActionTypes.PROFILE_FORM_SUBMISSION_VALIDATION: {
      return { ...state, profile: action.payload, isProfileSubmissionValidationInProgress: true };
    }
    case ProfileActionTypes.PROFILE_FORM_SUBMISSION_VALIDATION_SUCCESS: {
      return { ...state, isProfileSubmissionValidationInProgress: false };
    }
    case ProfileActionTypes.INITIATE_AGENT_EMAIL_EDIT: {
      return { ...state, editEmailState: { inProgress: true, result: undefined } };
    }
    case ProfileActionTypes.AGENT_EMAIL_SUBMISSION_RESULT: {
      return { ...state, editEmailState: { inProgress: false, result: action.payload } };
    }
    case ProfileActionTypes.SET_EDIT_MODE: {
      return { ...state, editMode: action.payload };
    }
    case ProfileActionTypes.SET_COMMERCIAL: {
      return { ...state, isCommercial: action.payload };
    }
    case ProfileActionTypes.SET_SHOW_CONFIRMATION: {
      return { ...state, showConfirmation: action.payload };
    }
    case ProfileActionTypes.SAVE_PROFILE: {
      const updatedProfile = action.payload;
      updatedProfile.lastAttestationDate = new Date();
      return { ...state, profile: updatedProfile, isLoading: true };
    }
    case ProfileActionTypes.CREATE_PROFILE: {
      const newProfile = action.payload;
      newProfile.lastAttestationDate = new Date();
      newProfile.activityCode = "nil";
      return { ...state, profile: newProfile, validationRequired: true, isLoading: true };
    }
    default: {
      return state;
    }
  }
};

export { reducer as profileReducer };

