import { IconButton } from '@fluentui/react';
import { Link } from 'react-router-dom';
import Text from 'src/components/text/Text';
import localeService from 'src/dataServices/LocaleService';
import { getLocalizedPath } from '../utilities/Localization/Utils';
import './ConfirmationModal.scss';

export interface IConfirmationModal {
  revalidationRequired: boolean;
  onConfirmation?: Function;
  onCancelConfirmation?: Function;
  showConfirmation: boolean;
  convertAccount?: boolean;
  isCommercial?: boolean;
}

const InfoSection = ({ children }: { children: React.ReactNode }) => (
  <div className="profile-info-section">
    <img alt="" className="banner-icon" src={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/icons/info-icon.svg`} />
    <p className="banner-text">{children}</p>
  </div>
);

export default (props: IConfirmationModal) => {
  const ConfirmationText = () => {
    if (props.convertAccount) {
      return (
        <>
          <h6 className="h6">
            <Text id={'RegisterNonprofitAccount'} category={'ProfileV2'} />
          </h6>
          <p className="thanks-for-interest">
            <Text id={'ThankYouForInterest'} category={'ProfileV2'} />
          </p>
          <p className="register-questions">
            <Text id={'RegisterQuestions'} category={'ProfileV2'} />
          </p>
        </>
      );
    }

    if (props.revalidationRequired) {
      return (
        <>
          <h6 className="h6">
            <Text id={'SubmitForApproval'} category={'ProfileV2'} />
          </h6>
          <p className="thanks-for-updating">
            {props.isCommercial ? (
              <Text id={'ThankYouForRegistering'} category={'ProfileV2'} />
            ) : (
              <Text id={'ThankYouForUpdating'} category={'ProfileV2'} />
            )}
          </p>
          <p>
            <Text id={'YouMadeChanges'} category={'ProfileV2'} />
          </p>
          <p className="while-revalidating">
            <Text id={'WhileRevalidating'} category={'ProfileV2'} />
          </p>
          <InfoSection>
            <Text id={'DiscountBody'} category={'ProfileV2'} />
          </InfoSection>
          <InfoSection>
            <Text id={'IfAddingEmail'} category={'ProfileV2'} />
          </InfoSection>
        </>
      );
    }

    return (
      <>
        <h6 className="h6">
          <Text id={'SubmitForApproval'} category={'ProfileV2'} />
        </h6>
        <p className="thanks-for-updating">
          <Text id={'ThankYouForUpdating'} category={'ProfileV2'} />
        </p>
        <InfoSection>
          <Text id={'Affirmation'} category={'ProfileV2'} />
        </InfoSection>
        <InfoSection>
          <Text id={'IfAddingEmail'} category={'ProfileV2'} />
        </InfoSection>
      </>
    );
  };

  return (
    <div className={props.showConfirmation ? 'profile-confirm-modal-backdrop' : 'display-none'}>
      <div className="profile-confirm-modal">
        {!props.convertAccount && (
          <IconButton
            title={localeService.getText('ProfileV2', 'Cancel')}
            className="x-icon-button"
            onClick={() => props.onCancelConfirmation?.()}
          >
            <img alt="" src={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/icons/x-icon.svg`} />
          </IconButton>
        )}
        <ConfirmationText />
        <div className="profile-submit-edit-buttons flex-end">
          {!props.convertAccount && (
            <button
              type="button"
              className="profile-cancel-confirm-button btn"
              onClick={() => props.onCancelConfirmation?.()}
            >
              {localeService.getText('ProfileV2', 'Cancel')}
            </button>
          )}
          {!props.convertAccount && (
            <button
              type="submit"
              className="profile-submit-button btn btn-primary"
              onClick={() => props.onConfirmation?.()}
            >
              {localeService.getText('ProfileV2', 'ConfirmAndSubmit')}
            </button>
          )}
          {props.convertAccount && (
            <div id="profile-submit-button">
              <Link className="primary-button" to={getLocalizedPath('/new/profile')}>
                {localeService.getText('ProfileV2', 'ContinueButton')}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
