import { Callout, DirectionalHint, getId, IIconProps, mergeStyleSets } from "@fluentui/react";
import * as React from "react";
import localeService from "src/dataServices/LocaleService";
import "./IconCallout.scss";

export interface ICallOutProps {
  isCalloutVisible?: boolean;
}

const styles = mergeStyleSets({
  buttonArea: {
    verticalAlign: "top",
    display: "inline-block",
    textAlign: "center",
    marginLeft: "-4px",
    marginTop: "-8px"
  },
  callout: {
    maxWidth: 300
  },
  link: {
    fontSize: "12px"
  }
});

export class IconCallout extends React.Component<{ content: any }, ICallOutProps> {
  public state: ICallOutProps = {
    isCalloutVisible: false
  };

  private infoSolidIcon: IIconProps = { iconName: "InfoSolid" };
  private menuButtonElement = React.createRef<HTMLDivElement>();
  private labelId: string = getId("callout-label");
  private descriptionId: string = getId("callout-description");

  public render(): JSX.Element {
    return (
      <>
        <div className={styles.buttonArea} ref={this.menuButtonElement}>
          <button
            className="icon-button glyph-prepend glyph-prepend-unknown ml-2 mt-2"
            type="button"
            aria-label={localeService.getText("ProfileV2", "UnknownIconPlaceholder")}
            onClick={this.onShowMenuClicked}
            onFocus={this.onFocusShowMenu}
            onKeyDown={(evt: { key: string }) => {
              if (evt.key === "Enter") {
                this.setState({
                  isCalloutVisible: !this.state.isCalloutVisible
                });
              }
              if (evt.key === "Escape" && this.state.isCalloutVisible) {
                this.setState({
                  isCalloutVisible: false
                });
              }
            }}
          />
        </div>
        {this.state.isCalloutVisible && (
          <Callout
            className={styles.callout}
            ariaLabelledBy={this.labelId}
            ariaDescribedBy={this.descriptionId}
            role="alertdialog"
            gapSpace={0}
            target={this.menuButtonElement.current}
            onDismiss={this.onCallOutDismiss}
            setInitialFocus={true}
            onKeyDown={(evt: { key: string }) => {
              if (evt.key === "Tab") {
                this.setState({
                  isCalloutVisible: !this.state.isCalloutVisible
                });
              }
            }}
            directionalHint={DirectionalHint.topCenter}
          >
            <p className="callout-content">{this.props.content}</p>
          </Callout>
        )}
      </>
    );
  }

  private onShowMenuClicked = (): void => {
    this.setState({
      isCalloutVisible: !this.state.isCalloutVisible
    });
  };
  private onFocusShowMenu = (): void => {
    if (!this.state.isCalloutVisible) {
      this.setState({
        isCalloutVisible: true
      });
    }
  };
  private onCallOutDismiss = (): void => {
    this.setState({
      isCalloutVisible: false
    });
  };
}
