import * as React from "react";
import { IResizableImage } from "src/components/moray/ResizeableImage";
import localeService from "src/dataServices/LocaleService";
import HighlightBanner from "../offers/shared/HighlightBanner";

const HomePageTopBanner = () => {
  const resizableImageProps: IResizableImage = {
    root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/HomePage`,
    imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/HomePage/homepagetopbanner.png`,
    imageClassName: "img-fill-container card-img",
    imageAlt: localeService.getText("HomeV2", "TopBannerImageTitle")
  };

  return (
    <HighlightBanner
      titleText={localeService.getText("HomeV2", "TopBannerTitle")}
      descriptionText={localeService.getText("HomeV2", "TopBannerDescription")}
      resizableImageProps={resizableImageProps}
    />
  );
};

export default HomePageTopBanner;
