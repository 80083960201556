import localeService from '../../../dataServices/LocaleService';
import ActionStepCard from '../shared/ActionStepCard';
import ActionSteps from '../shared/ActionSteps';

const CommunityTrainingActionSteps = () => {
  const step1IconPath = 'hub-redesign/ActionStep_1_3x.png';
  const step2ImagePath = 'hub-redesign/ActionStep_2_3x.png';
  const step3ImagePath = 'hub-redesign/ActionStep_3_3x.png';

  return (
    <ActionSteps
      stepsHeader={localeService.getText('CommunityTrainingV2', 'ActionStepsBannerText')}
      step1Card={
        <ActionStepCard
          stepIconImagePath={step1IconPath}
          cardTextCategory="CommunityTrainingV2"
          cardTitle="ActionStep1Title"
          cardDescription="ActionStep1Description"
          ctaText="ActionStep1CtaText"
          onClickLink={localeService.getText('CommunityTrainingV2', 'ActionStep1CtaLink')}
        />
      }
      step2Card={
        <ActionStepCard
          stepIconImagePath={step2ImagePath}
          cardTextCategory="CommunityTrainingV2"
          cardTitle="ActionStep2Title"
          cardDescription="ActionStep2Description"
          ctaText="ActionStep2CtaText"
          onClickLink={localeService.getText('CommunityTrainingV2', 'ActionStep2CtaLink')}
        />
      }
      step3Card={
        <ActionStepCard
          stepIconImagePath={step3ImagePath}
          cardTextCategory="CommunityTrainingV2"
          cardTitle="ActionStep3Title"
          cardDescription="ActionStep3Description"
          ctaText="ActionStep3CtaText"
          onClickLink={localeService.getText('CommunityTrainingV2', 'ActionStep3CtaLink')}
        />
      }
    />
  );
};

export default CommunityTrainingActionSteps;
