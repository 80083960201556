import "@ms-mwf/moray/dist/css/main.css";
import * as React from "react";
import "./HomePageStatusBanner.scss";

export interface IHomePageStatusBannerProps {
  iconGlyph: string;
  iconGlyphColor: string;
  statusTitle: string | JSX.Element;
  statusDescription: JSX.Element[];
}

export const HomePageStatusBanner = (props: IHomePageStatusBannerProps) => {
  const icon = `glyph-prepend ${props.iconGlyph} m-0`;
  const statusIconColor = {
    color: props.iconGlyphColor
  };
  return (
    <div id="banner" className="banner card mx-ng mx-md-0">
      <div className="col no-gutters p-0">
        <div className="home-banner-header-section row">
          <div className="home-banner-icon-container">
            <p id="icon" className={"status-icon " + icon} style={statusIconColor} />
          </div>
          <div className="banner-title">
            <h5 id="title" className="m-0 h5">
              {props.statusTitle}
            </h5>
          </div>
        </div>
        <div className="home-banner-description-section col">
          <div id="description" className="home-banner-description-content">
            {props.statusDescription.map((para, index) => {
              if (index == 0) {
                return <p className="base mb-0">{para}</p>;
              } else {
                return (
                  <div>
                    <br />
                    <p className="base mb-0">{para}</p>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
