import { connect } from "react-redux";
import { Dispatch } from "redux";
import TextInput, { ITextInputValidation } from "src/components/shared/TextInput";
import Text from "src/components/text/Text";
import TextLink from "src/components/text/TextLink";
import localeService from "src/dataServices/LocaleService";
import { multiEmailIsValid, singleEmailIsValid } from "src/common/utilities/Utilities";
import { IApplicationState } from "src/state";
import { initiateSendToPartnerRequest, sendToPartnerReset } from "src/state/offers/software/send-to-partner/actions";
import { ISendToPartnerRequest, ISendToPartnerState } from "src/state/offers/software/send-to-partner/types";

interface IPropsFromState {
  userEmail: string | undefined;
  sendToPartner: ISendToPartnerState;
}

interface IPropsFromDispatch {
  initiateSendToPartner: typeof initiateSendToPartnerRequest;
}

interface IOwnProps {}

export type ISoftwareSendEligibilityFormProps = IPropsFromState & IPropsFromDispatch & IOwnProps;

export const SoftwareSendEligibilityForm = (props: ISoftwareSendEligibilityFormProps) => {
  const PartnerCompanyValidations: ITextInputValidation[] = [
    {
      validationRule: (text: string) => {
        return text.length > 0;
      },
      validationText: localeService.getText("SoftwareV2", "SendEligibilityValidation1")
    }
  ];
  const PartnerContactValidations: ITextInputValidation[] = [
    {
      validationRule: (text: string) => {
        return text.length > 0;
      },
      validationText: localeService.getText("SoftwareV2", "SendEligibilityValidation2")
    }
  ];
  const PartnerEmailValidations: ITextInputValidation[] = [
    {
      validationRule: (text: string) => {
        return text.length > 0;
      },
      validationText: localeService.getText("SoftwareV2", "SendEligibilityValidation3")
    },
    {
      validationRule: (text: string) => {
        // only show this validation if text length is greater than 0
        return text.length === 0 || singleEmailIsValid(text);
      },
      validationText: localeService.getText("SoftwareV2", "SendEligibilityValidation5")
    }
  ];
  const UserEmailValidations: ITextInputValidation[] = [
    {
      validationRule: (text: string) => {
        return text.length > 0;
      },
      validationText: localeService.getText("SoftwareV2", "SendEligibilityValidation4")
    },
    {
      validationRule: (text: string) => {
        // only show this validation if text length is greater than 0
        return text.length === 0 || multiEmailIsValid(text);
      },
      validationText: localeService.getText("SoftwareV2", "SendEligibilityValidation6")
    }
  ];

  const sendCertificate = (event: any) => {
    var isValid = true;

    event.preventDefault();
    const partnerCompany = event.target.partnerCompany.value;
    const partnerContact = event.target.partnerContact.value;
    const partnerEmail = event.target.partnerEmail.value;
    const userEmail = event.target.userEmail.value;

    var sendToPartnerequest: ISendToPartnerRequest = {
      partnerCompanyName: partnerCompany,
      partnerContactName: partnerContact,
      partnerContactEmail: partnerEmail,
      userEmails: userEmail
    };

    PartnerCompanyValidations.forEach(validation => {
      if (!validation.validationRule(partnerCompany)) {
        isValid = false;
        event.target.partnerCompany.focus();
        event.target.partnerCompany.blur();
      }
    });

    PartnerContactValidations.forEach(validation => {
      if (!validation.validationRule(partnerContact)) {
        isValid = false;
        event.target.partnerContact.focus();
        event.target.partnerContact.blur();
      }
    });

    PartnerEmailValidations.forEach(validation => {
      if (!validation.validationRule(partnerEmail)) {
        isValid = false;
        event.target.partnerEmail.focus();
        event.target.partnerEmail.blur();
      }
    });

    UserEmailValidations.forEach(validation => {
      if (!validation.validationRule(userEmail)) {
        isValid = false;
        event.target.userEmail.focus();
        event.target.userEmail.blur();
      }
    });

    if (isValid) {
      // send request
      props.initiateSendToPartner(sendToPartnerequest);
    } else {
      // validation errors
      console.error("Send to partner form validation errors");
    }
  };

  return (
    <>
      <div className="software-send-eligibility-form">
        <div className="col">
          <h3 className="form-title h3">
            <Text category="SoftwareV2" id="SendEligibilityTitle" />
          </h3>
          <p>
            <Text category="SoftwareV2" id="SendEligibilityDescription1" />
          </p>
          <p>
            <Text category="SoftwareV2" id="SendEligibilityDescription2" />
          </p>
        </div>
        <form className="form-group" onSubmit={sendCertificate}>
          <div className="row">
            <div className="col-sm-6">
              <TextInput
                required={true}
                id="partnerCompany"
                label={localeService.getText("SoftwareV2", "SendEligibilityLabel1")}
                placeholder={localeService.getText("SoftwareV2", "SendEligibilityPlaceholder1")}
                validationRules={PartnerCompanyValidations}
              />
            </div>
            <div className="col-sm-6">
              <TextInput
                required={true}
                id="partnerContact"
                label={localeService.getText("SoftwareV2", "SendEligibilityLabel2")}
                placeholder={localeService.getText("SoftwareV2", "SendEligibilityPlaceholder2")}
                validationRules={PartnerContactValidations}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <TextInput
                required={true}
                id="partnerEmail"
                label={localeService.getText("SoftwareV2", "SendEligibilityLabel3")}
                placeholder={localeService.getText("SoftwareV2", "SendEligibilityPlaceholder3")}
                validationRules={PartnerEmailValidations}
              />
            </div>
            <div className="col-sm-6">
              <TextInput
                required={true}
                id="userEmail"
                label={localeService.getText("SoftwareV2", "SendEligibilityLabel4")}
                defaultValue={props.userEmail}
                placeholder={localeService.getText("SoftwareV2", "SendEligibilityPlaceholder4")}
                description={localeService.getText("SoftwareV2", "SendEligibilityDescription4")}
                validationRules={UserEmailValidations}
              />
            </div>
          </div>
          <div className="row pt-3 pt-md-0">
            <div className="col">
              <button className="btn btn-primary" disabled={props.sendToPartner.isSending}>
                <Text category="SoftwareV2" id="SendCertificateButton" />
              </button>
            </div>
          </div>
        </form>
        {props.sendToPartner.failedToSend && (
          <div className="row col pt-3">
            <div>
              <p className="glyph-prepend glyph-prepend-large glyph-prepend-button-x text-red software-eligibility-error" />
            </div>
            <div className="col-sm">
              <div>
                <p className="text-red software-eligibility-error">
                  <Text category={"SoftwareV2"} id={"SendEligibilityError"} />
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="small pt-3 pb-3">
        <TextLink category="SoftwareV2" id="EligibilityDisclaimer" href={"Eligibility"} />
      </div>
    </>
  );
};

const mapStateToProps = ({ auth, offers }: IApplicationState) => ({
  sendToPartner: offers.software.sendToPartner,
  userEmail: auth.user?.username
});

const mapDispatchToProps: (dispatch: Dispatch) => IPropsFromDispatch = dispatch => ({
  initiateSendToPartner: (formValues: ISendToPartnerRequest) => dispatch(initiateSendToPartnerRequest(formValues)),
  sendToPartnerReset: () => dispatch(sendToPartnerReset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SoftwareSendEligibilityForm);
