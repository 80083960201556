import { useSelector } from 'react-redux';
import localeEnabledForV2, { LocaleConfigStrings } from '../../../common/localeEnabledForV2';
import { IApplicationState } from '../../../state';

interface IUseMC4NPOfferUpdate {
  isMC4NPOfferUpdateAvailable: boolean;
}

const useMC4NPOfferUpdate = (): IUseMC4NPOfferUpdate => {
  const { MC4NPOfferUpdateEnabled } = useSelector((state: IApplicationState) => state.flags);
  return {
    isMC4NPOfferUpdateAvailable: MC4NPOfferUpdateEnabled && localeEnabledForV2(LocaleConfigStrings.MC4NPOfferUpdate)
  };
};

export default useMC4NPOfferUpdate;
