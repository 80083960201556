import * as React from "react";
import InfoCard from "./InfoCard";
import "./InfoSection.scss";

export interface IInfoSectionProps {
  category: string;
  sectionHeaderString?: string;
  imagePath1: string;
  imagePath2: string;
  imagePath3: string;
  imagePath4?: string;
  title1: string;
  title2: string;
  title3: string;
  title4?: string;
  description1: string;
  description2: string;
  description3: string;
  description4?: string;
  cardLink1?: string;
  cardLink2?: string;
  cardLink3?: string;
  cardLink4?: string;
  cardButton1?: string;
  cardButton2?: string;
  cardButton3?: string;
  cardButton4?: string;
  cardButtonAriaLabel1?: string;
  cardButtonAriaLabel2?: string;
  cardButtonAriaLabel3?: string;
  cardButtonAriaLabel4?: string;
  setRef?: (el: HTMLDivElement | null) => void;
}

const InfoSection: React.FunctionComponent<IInfoSectionProps> = props => {
  return (
    <>
      {props.sectionHeaderString !== undefined && (
        <div className="info-section-header-container">
          <h2 id="inforSectionHeader" className="h2 text-center mb-0">
            {props.sectionHeaderString}
          </h2>
        </div>
      )}
      <div
        className="info-section"
        ref={el => {
          if (props.setRef !== undefined) {
            props.setRef(el);
          }
        }}
      >
        <section>
          <div
            className={`row row-cols-1 ${
              props.imagePath4 !== undefined ? "row-cols-md-4" : "row-cols-md-3"
            }  mt-5 mb-5`}
          >
            <div className="col mb-5 mb-md-0">
              <InfoCard
                stepIconImagePath={props.imagePath1}
                cardTitle={props.title1}
                cardDescription={props.description1}
                category={props.category}
                cardButton={props.cardButton1}
                cardLink={props.cardLink1}
                buttonAriaLabel={props.cardButtonAriaLabel1}
              />
            </div>
            <div className="col mb-5 mb-md-0">
              <InfoCard
                stepIconImagePath={props.imagePath2}
                cardTitle={props.title2}
                cardDescription={props.description2}
                category={props.category}
                cardButton={props.cardButton2}
                cardLink={props.cardLink2}
                buttonAriaLabel={props.cardButtonAriaLabel2}
              />
            </div>
            <div className="col">
              <InfoCard
                stepIconImagePath={props.imagePath3}
                cardTitle={props.title3}
                cardDescription={props.description3}
                category={props.category}
                cardButton={props.cardButton3}
                cardLink={props.cardLink3}
                buttonAriaLabel={props.cardButtonAriaLabel3}
              />
            </div>
            {props.imagePath4 !== undefined && (
              <div className="col mb-5 mb-md-0">
                <InfoCard
                  stepIconImagePath={props.imagePath4}
                  cardTitle={props.title4}
                  cardDescription={props.description4}
                  category={props.category}
                  cardButton={props.cardButton4}
                  cardLink={props.cardLink4}
                  buttonAriaLabel={props.cardButtonAriaLabel4}
                />
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default InfoSection;
