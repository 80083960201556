import * as React from "react";
import localeService from "src/dataServices/LocaleService";
import htmlParser from 'html-react-parser';

export interface ICarouselOffer {
  category: string;
  descriptions: string[];
}

export default function CarouselOffer(props: ICarouselOffer) {
  const { category, descriptions } = props;

  return (
    <>
      {descriptions.map((description, index) => {
        if (index > 0) {
          return (
            <div key={index}>
              <br />
              <p className="base">{htmlParser(localeService.getText(category, description) ?? '')}</p>
            </div>
          );
        } else {
          return (
            <p key={index} className="base">
              {htmlParser(localeService.getText(category, description) ?? '')}
            </p>
          );
        }
      })}
    </>
  );
}
