import { IFeatureFlagsState } from "src/state/feature-flags";
import { INonprofitMiniProfile } from "src/state/nonprofit";

export function isBingAdsOfferAvailable(nonprofit: INonprofitMiniProfile, flags: IFeatureFlagsState): boolean {
  if (!nonprofit || !nonprofit.availableOffers || !flags) {
    return false;
  }
  const bingAdsOffer = nonprofit.availableOffers.find(o => o.id == "bingads");
  return flags.BingAdsOfferEnabled && bingAdsOffer != null && bingAdsOffer != undefined;
}

export const BingAdsOfferId = "bing-ads";

export const BingAdsStatus = {
  Active: "Active",
  Inactive: "Inactive"
};

export const NoContentStatusCode = "204";
