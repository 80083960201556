import * as React from "react";
import Text from "src/components/text/Text";
import TextLink from "src/components/text/TextLink";

const Unknown: React.FunctionComponent = () => {
  return (
    <div className="unknown-error">
      <h2>
        <Text category="ErrorUnknown" id="Header" />
      </h2>
      <p>
        <TextLink category="ErrorUnknown" id="SubText" href="ContactUs" />
      </p>
    </div>
  );
};

export default Unknown;
