import * as React from "react";
import Text from "src/components/text/Text";
import TextLink from "src/components/text/TextLink";

export class ProductivityApplications extends React.Component {
  public render() {
    return (
      <section id="productivity-applications">
        <p>
          <Text category="EligibilityChanges" id="ProductivityApplications" />
        </p>
        <p>
          <Text category="EligibilityChanges" id="ProductivityApplications_Line1" />
        </p>
        <p>
          <TextLink
            category="EligibilityChanges"
            id="ProductivityApplications_Line2"
            href={process.env.REACT_APP_OFFICE_PORTAL_URL}
            target="_blank"
          />
        </p>
        <p>
          <Text category="EligibilityChanges" id="TipsForMigrating" />
        </p>
        <ul>
          <li>
            <Text category="EligibilityChanges" id="TipsForMigrating_1" />
          </li>
          <li>
            <Text category="EligibilityChanges" id="TipsForMigrating_2" />
          </li>
          <li>
            <TextLink
              category="EligibilityChanges"
              id="TipsForMigrating_3"
              href="SwitchToDifferentPlan AdminHelp SolutionProvider"
            />
          </li>
        </ul>
      </section>
    );
  }
}

export default ProductivityApplications;
