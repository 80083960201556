import CharacterCount from "@ms-mwf/moray/src/components/forms/character-count/character-count";
import * as React from "react";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import localeService from "src/dataServices/LocaleService";
import { IApplicationState } from "src/state";
import { submitAlchemyQuery } from "src/state/query/actions";
import { IQueryState, IQuerySubmissionFields, IQuerySubmissionForm } from "src/state/query/types";
import LoadingButton from "../utilities/LoadingButton";
import "./ContactUsV2GetHelp.scss";

export interface IPropsFromState {
  queryProps: IQueryState;
  sentSuccessfully: boolean;
}

export interface IPropsFromDispatch {
  submitQuery: typeof submitAlchemyQuery;
}

export type QueryFormProps = IPropsFromState &
  IPropsFromDispatch;

export class QueryForm extends React.Component<QueryFormProps> {
  private characterCount!: { remove: () => void; };
  private el!: HTMLElement | null;

  constructor(props: QueryFormProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.characterCount = new CharacterCount({
      el: this.el
    });
  }

  componentWillUnmount() {
    this.characterCount.remove();
  }

  public render() {
    const { queryProps, sentSuccessfully } = this.props;
    const hideForm = sentSuccessfully;

    return hideForm ? (
      <React.Fragment />
    ) : (
      <div
        style={{
          margin: "21px 5%"
        }}
      >
        <h3
          style={{
            textAlign: "center",
            marginBottom: "15px",
            fontWeight: 600,
            fontSize: "24px"
          }}
        >
          {localeService.getText("ContactUsGetHelp", "Title")}
        </h3>

        <Form
          onSubmit={this.submitQuery}
          render={({ handleSubmit, form }) => {
            const enableButton = form && form.getState().values && form.getState().values.query;

            return (
              <form
                id="query"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit()
                }}
              >
                <div className="form-group ">
                  <Field
                    name="query"
                    component="input"
                    className="form-control"
                    type="search"
                    id="input-element"
                    required={true}
                    maxLength={100}
                    aria-invalid="false"
                    placeholder={localeService.getText("ContactUsGetHelp", "Placeholder")}
                  />
                  <div
                    data-mount="character-count"
                    data-target="#input-element"
                    data-status-msg-template={localeService.getText("ContactUsGetHelp", "LetterCount") || ""}
                    data-max-msg-template={localeService.getText("ContactUsGetHelp", "MaxCountReached") || ""}
                    ref={el => (this.el = el)}
                  >
                    <span className="text-muted" aria-hidden="true" />
                    <span
                      className="sr-only"
                      data-sr-low-char-warning-lvl="5"
                      role="status"
                      aria-live="polite"
                      aria-atomic="true"
                    />
                    <div className="link-group" style={{ float: "right" }}>
                      <a
                        href={localeService.getText("Links", "urlPrivacyStatement") ?? undefined}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ margin: "0.5rem" }}
                      >
                        {localeService.getText("ContactUsGetHelp", "PrivacyPolicy")}
                      </a>
                    </div>
                  </div>
                  <div className="invalid-feedback " id="input-title-feedback" />
                </div>

                <LoadingButton
                  loading={queryProps.isSending}
                  disabled={!enableButton}
                  type="submit"
                  id="submitGetHelpRequest"
                  title={`${"Get Help"}`}
                  text={`${localeService.getText("ContactUsGetHelp", "Button")}`}
                  width={160}
                  properties={{ ButtonName: "ContactUsForm-Submit" }}
                />
              </form>
            )
          }}
        />
      </div>
    );
  }

  private submitQuery = (querySubmissionForm: IQuerySubmissionForm) => {
    const contactUsQuerySubmissionFields = {
      Text: querySubmissionForm.query,
      Locale: localeService.getlocale(),
      ClientContext: ""
    } as IQuerySubmissionFields;
    this.props.submitQuery(contactUsQuerySubmissionFields);
  };
}

const mapStateToProps = ({ query, contactUs }: IApplicationState): IPropsFromState => ({
  queryProps: query,
  sentSuccessfully: contactUs.sentSuccessfully ?? false
});

const mapDispatchToProps: (dispatch: Dispatch) => IPropsFromDispatch = dispatch => ({
  submitQuery: (formValues: IQuerySubmissionFields) => dispatch(submitAlchemyQuery(formValues))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QueryForm);
