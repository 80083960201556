import "@ms-mwf/moray/dist/css/main.css";
import localeService from "src/dataServices/LocaleService";
import Steps from "./Steps";
import "./TrainingSection.scss";

export interface ITrainingSectionProps {
  sectionHeader?: string;
  sectionContent?: string;
  ctaLink: string | undefined;
  sectionBackgroundColor: string;
  stepCard1: any;
  stepCard2: any;
  stepCard3: any;
}

export default function TrainingSection(props: ITrainingSectionProps) {
  const trainingSectionStyleOverride = {
    backgroundColor: props.sectionBackgroundColor
  };

  return (
    <div
      className="training-section material-surface text-center"
      data-mount="click-group"
      style={trainingSectionStyleOverride}
    >
      <h2 className="training-header mb-3 h2">{props.sectionHeader}</h2>
      <p className="training-content base">{props.sectionContent}</p>
      <a href={props.ctaLink} className="training-button cta" target="_blank" rel="noopener noreferrer">
        {localeService.getText("TrainingSection", "SectionButton")}
        <span className="glyph" aria-hidden="true"></span>
      </a>
      <Steps step1Card={props.stepCard1} step2Card={props.stepCard2} step3Card={props.stepCard3} />
    </div>
  );
}
