import { getBrowserSupportedLanguages, getSupportedLanguages } from "src/components/utilities/Localization/Utils";
import { IApiResponse } from "src/common/ApiResponse";
import { httpRequest } from "src/common/HttpRequest";
import { initialState } from "src/state/localization/reducer";
import { ILocale, ILocalizationResolver, ILocalizationResolverReason } from "src/state/localization/types";
import { store } from "src/state/reduxStore";
import { logService } from "npo-common";

const LOC_V2_QUERY_PARAM = "locv2";

export class LocaleService {
  private fallbackLocale = "en-us";
  private currentLocale!: string | undefined;
  private localeContentDictionary: { [key: string]: ILocale } = {};
  private localizationFeatureOn = this.isLocalizationEnabled();

  constructor() {
    if (!this.localizationFeatureOn) {
      this.currentLocale = "en-us";
    }
  }

  public isLocalizationEnabled(): boolean {
    return process.env.REACT_APP_LOCALIZATION_ENABLED === "1";
  }

  private fetchLocalLocalizationData(locale: string): any {
    // We can't use require since it's a dynamic import so we use a synchronous request instead
    const request = new XMLHttpRequest();
    request.open("GET", `${process.env.PUBLIC_URL}/cdn/localization/${locale}.json`, false);
    request.send(null);

    return JSON.parse(request.responseText);
  }

  public getLocaleContent(locale?: string): any {
    if (this.localizationFeatureOn) {
      const contentRetrieved = this.localeContentDictionary[this.currentLocale ?? locale ?? this.fallbackLocale];

      return contentRetrieved !== undefined && contentRetrieved.content;
    } else {
      locale = locale || this.currentLocale || this.fallbackLocale;
      return this.fetchLocalLocalizationData(locale);
    }
  }

  public getCategoryContent(category: string, locale?: string): { [key: string]: any } {
    const content = this.getLocaleContent(locale);

    const categoryContent = content[category];
    if (categoryContent === undefined) {
      logService.logError(`Category ${category} not found in locale ${locale}`);
    }

    return categoryContent;
  }

  public getContent(category: string, id: string, locale?: string): { [key: string]: any } {
    const categoryContent = this.getCategoryContent(category, locale);
    if (categoryContent === undefined) {
      return {};
    }

    const text = categoryContent[id];
    return text;
  }

  public getText(category: string, id: string, locale?: string): string | undefined {
    const categoryContent = this.getCategoryContent(category, locale);
    if (categoryContent === undefined) {
      return undefined;
    }

    const text = categoryContent[id];
    return text;
  }

  public getlocale(): string | undefined {
    return this.currentLocale;
  }

  public getDateString(date: string): string {
    return new Date(date).toLocaleString(this.currentLocale, { year: "numeric", month: "numeric", day: "numeric" });
  }

  public fetchDataFromLocalStorage(locale: string): { [key: string]: any } {
    const localDictionaryResponse = this.localeContentDictionary[locale];

    this.currentLocale = locale;
    return localDictionaryResponse.content;
  }

  public async fetchDataFromCDN(locale?: string): Promise<IApiResponse<{ [key: string]: any }>> {
    const locBaseUrl = this.getLocalizationBaseUrl();
    const localDictionaryResponse = await httpRequest(`${locBaseUrl}/${locale}.json`);

    if (localDictionaryResponse.status !== 200) {
      logService.logError(`There was an error while fetching the localization content.`, { locale }, { console: true });
    }

    this.currentLocale = locale;
    return {
      status: localDictionaryResponse.status,
      value: localDictionaryResponse.status === 200 ? await localDictionaryResponse.json() : undefined
    };
  }

  public async fetchDataFromSignupCDN(locale?: string): Promise<IApiResponse<{ [key: string]: any }>> {
    const signupLocBaseUrl = this.getSignupLocalizationBaseUrl();
    const regulatoryBodiesResponse = await httpRequest(`${signupLocBaseUrl}/${locale}.json`);

    this.currentLocale = locale;
    if (regulatoryBodiesResponse.status === 200) {
      const regulatoryBodiesResponseJson = await regulatoryBodiesResponse.json();
      return {
        status: regulatoryBodiesResponse.status,
        value: {
          RegulatoryBodies: {
            errorMessages: regulatoryBodiesResponseJson.OrgInfoForm.errorMessages,
            identifiers: regulatoryBodiesResponseJson.OrgInfoForm.registration.identifiers
          },
          JobTitles: regulatoryBodiesResponseJson.WelcomePolicies.jobTitles,
          Registration: regulatoryBodiesResponseJson.OrgInfoForm.registration
        }
      };
    }

    return {
      status: regulatoryBodiesResponse.status,
      value: undefined
    };
  }

  public getFallbackData() {
    const fallbackContent = this.fetchLocalLocalizationData(this.fallbackLocale);
    return { key: this.fallbackLocale, content: fallbackContent } as ILocale;
  }

  public addContentToDictionary(value: ILocale): boolean {
    this.localeContentDictionary[value.key] = { key: value.key, content: value.content };

    this.currentLocale = value.key;
    return this.localeContentDictionary[value.key] !== null;
  }

  public evaluateLocaleToFetch(localeToEvaluate?: string): ILocalizationResolver {
    if (localeToEvaluate !== undefined) {
      const isSupportedLanguage = getSupportedLanguages().includes(localeToEvaluate.toLowerCase());

      if (isSupportedLanguage) {
        return this.resolveWithLocaleToFetch(localeToEvaluate.toLowerCase());
      }
    }

    // always a subset of languages the app supports in user's preference order. Pick the first one to resolve to.
    const browserSupportedLanguages = getBrowserSupportedLanguages();
    if (browserSupportedLanguages.length > 0) {
      return this.resolveWithLocaleToFetch(browserSupportedLanguages[0].toLowerCase());
    }

    return {
      resolvedReason: ILocalizationResolverReason.None,
      languageToFetch: initialState.defaultAppLanguage.toLowerCase()
    };
  }

  private resolveWithLocaleToFetch(localeToEvaluate: string) {
    if (this.isInLocalStorage(localeToEvaluate)) {
      return {
        resolvedReason: ILocalizationResolverReason.FetchFromLocalStorage,
        languageToFetch: localeToEvaluate.toLowerCase()
      };
    } else {
      return {
        resolvedReason: ILocalizationResolverReason.FetchFromCDN,
        languageToFetch: localeToEvaluate.toLowerCase()
      };
    }
  }

  public purgeLocalStorage(): void {
    this.localeContentDictionary = {};
  }

  private isInLocalStorage(locale: string): boolean {
    const value = this.localeContentDictionary[locale];

    return value !== undefined;
  }

  private getLocalizationBaseUrl() {
    const state = store.getState();
    const v2QueryParam = window.location.search.includes(`${LOC_V2_QUERY_PARAM}=true`);
    const useLocV2 = state.flags.LocalizationV2 || v2QueryParam;
    const baseUrl = useLocV2
      ? process.env.REACT_APP_LOCALIZATION_CONTENT_V2_URL
      : process.env.REACT_APP_LOCALIZATION_CONTENT_URL;

    console.debug(`loc${useLocV2 ? "v2" : "v1"}`, {
      v2QueryParam,
      v2Flag: state.flags.LocalizationV2,
      baseUrl
    });

    return baseUrl;
  }

  private getSignupLocalizationBaseUrl() {
    const state = store.getState();
    const v2QueryParam = window.location.search.includes(`${LOC_V2_QUERY_PARAM}=true`);
    const useLocV2 = state.flags.LocalizationV2 || v2QueryParam;
    const baseUrl = useLocV2
      ? process.env.REACT_APP_SIGNUP_LOCALIZATION_V2_URL
      : process.env.REACT_APP_SIGNUP_LOCALIZATION_URL;

    console.debug(`signupLoc${useLocV2 ? "v2" : "v1"}`, {
      v2QueryParam,
      v2Flag: state.flags.LocalizationV2,
      baseUrl
    });

    return baseUrl;
  }
}

const localeService = new LocaleService();
export default localeService;
