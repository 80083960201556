import { Spinner, SpinnerSize } from '@fluentui/react';
import { useEffect, useState } from 'react';
import localeService from 'src/dataServices/LocaleService';
import localizationService from 'src/dataServices/LocalizationService';
import './LocalePicker.scss';

interface ILocalePicker {
  supportedRegions: string[];
  locale: string;
}

export const LocalePicker = (props: ILocalePicker) => {
  const languages = localizationService.getSupportedLanguages();
  const [UFHLoading, setUHFLoading] = useState(true);

  if (!props.supportedRegions.includes(props.locale)) {
    window.location.replace('/');
  }

  // runs UHF scripts on mount for getting started page
  useEffect(() => {
    window.dispatchEvent(new Event('loadUHF'));
  }, []);

  window.addEventListener(
    'UHFDoneLoading',
    () => {
      setUHFLoading(false);
    },
    false
  );

  if (UFHLoading) {
    return (
      <Spinner
        size={SpinnerSize.large}
        label={localeService.getText('Shared', 'SpinnerPleaseWait')}
        ariaLive="assertive"
      />
    );
  }

  return (
    <div className="locale-picker-container">
      <h1 className="locale-header">{localeService.getText('LocalizationPicker', 'Header')}</h1>
      {languages.map((language) => (
        <p>
          <a className="locale-link" href={'/' + language.locale}>
            {`${language.country} - ${language.language}`}
          </a>
        </p>
      ))}
    </div>
  );
};

export default LocalePicker;
