import * as React from "react";
import localeService from "src/dataServices/LocaleService";
import "./Disclaimer.scss";

const Disclaimer = () => (
  <section id="registration-complete-page-disclaimer" className="ms-Grid-col ms-sm-12 ms-lg12">
    <p>{localeService.getText("Disclaimer", "Disclaimer1")}</p>
  </section>
);

export default Disclaimer;
