import { PrimaryButton } from "@fluentui/react/lib/Button";
import { Field, Form } from "react-final-form";
import Text from "src/components/text/Text";
import localeService from "src/dataServices/LocaleService";
import { inputTextFieldValidators } from "../../shared/TextInputComponent";

interface IEditEmailFormProps {
  onSubmit(event: any): void;
}

const EditEmailForm = ((props: IEditEmailFormProps) => {
  const { onSubmit } = props;
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form
          id="edit-email-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          <div className="ms-Grid-row" aria-live="assertive" role="alert">
            <p className="subText">
              <Text category="AgentVerification" id="Email_Change_Content_1" />
            </p>
            <div className="ms-Grid-col ms-sm2 ms-md3" />
            <div className="ms-Grid-col ms-sm8 ms-md6">
              <Field
                placeholder={localeService.getText("AgentVerification", "EnterEmail")}
                id="agentEmail"
                name="agentEmail"
                component="input"
                type="email"
                className="inputfield"
                autoFocus
                validate={inputTextFieldValidators(true, "email")}
              />
            </div>
            <div className="ms-Grid-col ms-sm2 ms-md3" />
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12" id="code-submit-button">
              <PrimaryButton type="submit">
                <Text category="AgentVerification" id="Continue" />
              </PrimaryButton>
            </div>
          </div>
        </form>
      )}
    />
  );
});

export default EditEmailForm;
