import "@ms-mwf/moray/dist/css/main.css";
import CollapseControls from "@ms-mwf/moray/src/components/collapse/collapse-controls";
import HelpersUtil from "@ms-mwf/moray/src/js/util/helpers";
import htmlParser from "html-react-parser";
import * as React from "react";
import localeService from "src/dataServices/LocaleService";
import MorayCollapse, { MorayCollapseProps } from "./MorayCollapse";

export interface MorayAccordionProps {
  title: string | undefined;
  items: MorayCollapseProps[];
  showMoreResults: any;
  showFewerResults: any;
  totalItems: any;
}

class MorayAccordion extends React.Component<MorayAccordionProps> {
  private controls: any;
  private el!: HTMLDivElement | null;

  componentDidMount() {
    this.controls = new CollapseControls({
      el: this.el
    });
  }

  componentDidUpdate() {
    this.controls.remove();
    this.controls = new CollapseControls({
      el: this.el
    });
  }

  componentWillUnmount() {
    this.controls.remove();
  }

  public render() {
    const ctrlId = `mwf${HelpersUtil.getUid()}`;
    const maxListSize = 7;
    const minListSize = 3;
    return (
      <div>
        <h3>{this.props.title}</h3>
        <div
          className="btn-group align-items-center mb-g"
          role="group"
          data-mount="collapse-controls"
          data-target={`#${ctrlId}`}
          ref={el => (this.el = el)}
        >
          <button
            className="btn btn-link"
            aria-disabled="false"
            aria-pressed="false"
            data-action="expand"
            type="button"
          >
            {localeService.getText("ContactUsSelfHelp", "Expand")}
          </button>
          <span aria-hidden="true"> | </span>
          <button
            className="btn btn-link"
            aria-disabled="false"
            aria-pressed="false"
            data-action="collapse"
            type="button"
          >
            {localeService.getText("ContactUsSelfHelp", "Collapse")}
          </button>
        </div>
        <ul className="accordion" id={ctrlId}>
          {this.props.items.map((item, index) => (
            <li>
              <MorayCollapse title={item.title} description={htmlParser(item.description as unknown as string) as JSX.Element} />
            </li>
          ))}
        </ul>
        {this.props.items.length < this.props.totalItems && this.props.items.length < maxListSize ? (
          <div style={{ textAlign: "center", width: "100%" }}>
            <a onClick={this.props.showMoreResults}>{localeService.getText("ContactUsSelfHelp", "ShowMoreResults")}</a>
          </div>
        ) : (
          <React.Fragment />
        )}

        {(this.props.items.length == this.props.totalItems || this.props.items.length == maxListSize) &&
        this.props.items.length > minListSize ? (
          <div style={{ textAlign: "center", width: "100%" }}>
            <a onClick={this.props.showFewerResults}>
              {localeService.getText("ContactUsSelfHelp", "ShowFewerResults")}
            </a>
          </div>
        ) : (
          <React.Fragment />
        )}
      </div>
    );
  }
}

export default MorayAccordion;
