import "./StepCard.scss";

export interface IStepCardProps {
  image: string;
  altText?: string;
  title?: string;
  content: any;
  selected: boolean;
}

export const StepCard = (props: IStepCardProps) => (
  <div className="step-card-container ms-Grid-col ms-sm-12 ms-lg4 ms-md12">
    <div className="step-card-title-wrapper">
      <div className={props.selected ? "step-card-title selected" : "step-card-title"}>{props.title}</div>
    </div>
    <div className="step-card-image-div">
      <img src={props.image} alt={props.altText} className="step-card-image" />
    </div>
    {props.content}
  </div>
);

export default StepCard;
