import { all, apply, fork, put, takeLatest } from "redux-saga/effects";
import { MeoLocaleActionTypes, IMeoLocale, DefaultMeoLocale } from "./types";
import meoLocaleService from "src/dataServices/MeoLocaleService";
import { IApiResponse } from "src/common/ApiResponse";
import { setMeoLocales } from "./actions";
import { logService } from "npo-common";

export function* handleFetchMeoLocales() {
  try {
    const response: IApiResponse<IMeoLocale[]> = yield apply(
      meoLocaleService,
      meoLocaleService.getMeoLocales,
      []
    );
    
    if(response.status === 200) {
      yield put(setMeoLocales(response.value));
    }
  } catch (error) {
    logService.logError("Failed to fetch meo-locales.json", { error });
  }
}

function* watchFetchMeoLocalizations() {
  yield takeLatest(MeoLocaleActionTypes.FETCH_MEOLOCALES, handleFetchMeoLocales);
}

function* meoLocalizationSaga() {
  yield all([fork(watchFetchMeoLocalizations)]);
}

export default meoLocalizationSaga;