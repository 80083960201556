import { logService } from "npo-common";
import { all, apply, fork, put, takeLatest } from "redux-saga/effects";
import { IApiResponse } from "src/common/ApiResponse";
import featureFlagsService from "src/dataServices/FeatureFlagsService";
import localeService from "src/dataServices/LocaleService";
import { ActionType } from "typesafe-actions";
import { localizationRequest } from "../localization/actions";
import { setFlags } from "./actions";
import { FeatureFlagsActionTypes, IFeatureFlagsState } from "./types";

export function* handleFetchFlags() {
  try {
    const response: IApiResponse<IFeatureFlagsState> = yield apply(
      featureFlagsService,
      featureFlagsService.GetFeatureFlags,
      []
    );
    yield put(setFlags(response.value));
  } catch (error) {
    console.error("Failed to fetch feature flags", error);
  }
}

export function* handleSetFlags(action: ActionType<typeof setFlags>) {
  try {
    const flags = action.payload;

    // if locV2 is set, we need to reload localization so we're pulling from the v2 loc url
    if (flags?.LocalizationV2) {
      //Purge locales from local storage for loc reload
      localeService.purgeLocalStorage();

      logService.logInfo("Reloading localization based on feature flags.", { locV2Flag: flags?.LocalizationV2 });
      yield put(localizationRequest());
    }
  } catch (error) {
    console.error("Failed to reload localization based on feature flags.", error);
  }
}

function* watchFetchFlags() {
  yield takeLatest(FeatureFlagsActionTypes.FETCH_FLAGS, handleFetchFlags);
}

function* watchSetFlags() {
  yield takeLatest(FeatureFlagsActionTypes.SET_FLAGS, handleSetFlags);
}

function* featureFlagsSaga() {
  yield all([fork(watchFetchFlags), fork(watchSetFlags)]);
}

export default featureFlagsSaga;
