import htmlParser from 'html-react-parser';
import localeService from '../../dataServices/LocaleService';

interface IEligibilityPreCheckFailureScreen {
  onStartOver: () => void;
}

const EligibilityPreCheckFailureScreen = ({ onStartOver }: IEligibilityPreCheckFailureScreen) => {
  return (
    <div className="py-4 pl-0 container">
      <div className="row col">
        <p>{htmlParser(localeService.getText('GettingStartedV2', 'EligibilityPreCheckFailureDescription') ?? '')}</p>
      </div>
      <div className="row row-cols-1 row-cols-md-2">
        <div className="col col-md-auto">
          <a
            className="btn btn-outline-primary text-decoration-none"
            href={localeService.getText('GettingStartedV2', 'EligibilityPreCheckLearnMoreLink')}
            target="_blank"
            rel="noreferrer"
          >
            {localeService.getText('GettingStartedV2', 'EligibilityPreCheckLearnMoreText')}
          </a>
        </div>
        <div className="col col-md-auto d-flex align-items-center mt-2 mt-md-0">
          <button className="btn btn-link p-0 font-weight-semibold " onClick={onStartOver}>
            {localeService.getText('GettingStartedV2', 'EligibilityPreCheckStartOver')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EligibilityPreCheckFailureScreen;
