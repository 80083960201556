import * as React from "react";
import localeService from "src/dataServices/LocaleService";
import "./Banner.scss";
import RegisterButton from "./RegisterButton";

const Banner = () => (
  <section
    id="squares-banner"
    style={{
      background: `url(${process.env.REACT_APP_IMAGES_URL}/getting-started/banner-image.jpg)`,
      backgroundSize: "cover"
    }}
    aria-label={localeService.getText("Banner", "BannerTitle")}
    className="ms-Grid-row ms-sm12 ms-lg12"
  >
    <section id="banner-region">
      <h1 id="banner-title" className="biggest-heading ms-Grid-row ms-sm-12 ms-lg6 ms-md12">
        {localeService.getText("Banner", "BannerHeader")}
      </h1>
      <div id="banner-text" className=" ms-Grid-row ms-sm-6 ms-lg4 ms-md6">
        <p id="banner-sub-heading">
          <RegisterButton
            dontShowArrow={true}
            placement="Banner"
            secondaryText={localeService.getText("Banner", "BannerText2")}
          />
        </p>
      </div>
    </section>
  </section>
);

export default Banner;
