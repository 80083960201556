import localeService from '../../dataServices/LocaleService';

const GetStartedButton = () => (
  <a
    className={`btn btn-outline-primary text-decoration-none mt-2 mt-md-0`}
    href={process.env.REACT_APP_SIGNUP_URL}
    target="_blank"
    rel="noreferrer"
  >
    {localeService.getText('GettingStartedV2', 'EligibilityPreCheckGetStarted')}
  </a>
);

interface IStartOverButton {
  onStartOver: () => void;
}

const StartOverButton = ({ onStartOver }: IStartOverButton) => (
  <button className="btn btn-link p-0 font-weight-semibold " onClick={onStartOver}>
    {localeService.getText('GettingStartedV2', 'EligibilityPreCheckStartOver')}
  </button>
);

interface IEligibilityPreCheckSuccessScreen {
  onStartOver: () => void;
}

const EligibilityPreCheckSuccessScreen = ({ onStartOver }: IEligibilityPreCheckSuccessScreen) => {
  return (
    <div className="pt-4 pb-4 pl-0 container">
      <div className="row row-cols-1 row-cols-md-2">
        <div>
          <div className="row col">
            <p>{localeService.getText('GettingStartedV2', 'EligibilityPreCheckSuccessDescription')}</p>
          </div>
          {/*
            For layout purposes, this button will be displayed for screen sizes md and above and hidden for screen
            sizes below md.
            A different button will be displayed for screen sizes below md.
          */}
          <div className="row row-cols-2 d-none d-md-flex ">
            <div className="col col-auto">
              <GetStartedButton />
            </div>
            <div className="col d-flex">
              <StartOverButton onStartOver={onStartOver} />
            </div>
          </div>
        </div>
        <div className="col d-flex justify-content-center justify-content-md-start">
          <img
            alt={localeService.getText('GettingStartedV2', 'EligibilityPreCheckSuccessImgText')}
            src={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/GettingStarted/SuccessIcon.png`}
          />
        </div>
        {/*
          For layout purposes, this button will be displayed for screen sizes below md and hidden for screen
          sizes md and above.
          A different button will be displayed for screen sizes md and above.
        */}
        <div className="row row-cols-1 d-md-none">
          <div className="col">
            <GetStartedButton />
          </div>
          <div className="col mt-2">
            <StartOverButton onStartOver={onStartOver} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EligibilityPreCheckSuccessScreen;
