import { Reducer } from "redux";
import { DefaultMeoLocale, MeoLocaleState, MeoLocaleActionTypes, IMeoLocale } from "./types";

export const initialState: MeoLocaleState =  { 
  locales: [DefaultMeoLocale], 
  isLoading: false 
};

const reducer: Reducer<MeoLocaleState> = (state: MeoLocaleState = initialState, action): MeoLocaleState => {
  switch (action.type) {
    case MeoLocaleActionTypes.FETCH_MEOLOCALES: {
      return { ...state, isLoading: true };
    }
    case MeoLocaleActionTypes.SET_MEOLOCALES: {
      const locales: IMeoLocale[] | undefined = action.payload;
      return { ...state, locales: locales || [DefaultMeoLocale], isLoading: false };
    }
    default: {
      return state;
    }
  }
};

export { reducer as meolocalesReducer };