import { PrimaryButton } from "@fluentui/react/lib/Button";
import { Icon } from "@fluentui/react/lib/Icon";
import * as React from "react";
import Text from "src/components/text/Text";
import AppContext from "../app/AppContext";
import "./SubmissionResult.scss";

interface IOwnProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  textCategory: string;
  textId: string;
  buttonTextCategory?: string;
  buttonTextId?: string;
  ignoreAppContext?: boolean;
  children?: React.ReactNode;
}

export type SubmissionResultProps = IOwnProps & { isSuccessful?: boolean };

export const SubmissionResult: React.FunctionComponent<SubmissionResultProps> = props => {
  const { textCategory, textId, children, isSuccessful, ignoreAppContext } = props;
  const isWarning = typeof isSuccessful === "undefined";
  const buttonTextCategory = props.buttonTextCategory || "Shared";
  const buttonTextId = props.buttonTextId || (isSuccessful ? "GoBack" : "TryAgain");
  const handleClick = props.onClick;

  return (
    <div className="submission-result">
      {isWarning ? (
        <div className="result warning">
          <Icon iconName="Warning" />
          <div>
            <Text category={textCategory} id={textId} />
            <div id="result-details">{children}</div>
          </div>
        </div>
      ) : isSuccessful === null ? (
        <div className={"result success"}>
          <Icon iconName={"Accept"} />
          <div>
            <Text category={textCategory} id={textId} />
            <div id="result-details">{children}</div>
          </div>
        </div>
      ) : (
        <div className={isSuccessful ? "result success" : "result error"}>
          <Icon iconName={isSuccessful ? "Accept" : "ErrorBadge"} />
          <div>
            <Text category={textCategory} id={textId} />
            <div id="result-details">{children}</div>
          </div>
        </div>
      )}

      {handleClick && (
        <PrimaryButton
          onClick={handleClick}
          componentRef={node => {
            if (node !== null) {
              node.focus();
            }
          }}
        >
          <Text category={buttonTextCategory} id={buttonTextId} />
        </PrimaryButton>
      )}

      {isSuccessful === false && !ignoreAppContext && <AppContext />}
    </div>
  );
};

export const TryAgain: React.FunctionComponent<IOwnProps> = props => (
  <SubmissionResult {...props} isSuccessful={false} />
);

export const Successful: React.FunctionComponent<IOwnProps> = props => (
  <SubmissionResult {...props} isSuccessful={true} />
);

export const InProgress: React.FunctionComponent<IOwnProps> = props => (
  <SubmissionResult {...props} isSuccessful={undefined} />
);

export const Warning: React.FunctionComponent<IOwnProps> = props => (
  <SubmissionResult {...props} isSuccessful={undefined} />
);
