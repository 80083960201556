import * as React from "react";
import Text from "src/components/text/Text";

export class EnterpriseAgreement extends React.Component {
  public render() {
    return (
      <section id="enterprise-agreement">
        <p>
          <Text category="EligibilityChanges" id="EnterpriseAgreement" />
        </p>
        <p>
          <Text category="EligibilityChanges" id="EnterpriseAgreement_Line1" />
        </p>
      </section>
    );
  }
}

export default EnterpriseAgreement;
