import * as React from "react";
import Text from "src/components/text/Text";
import localeService from "src/dataServices/LocaleService";

const TechnologySolutionsBanner = () => {
  return (
    <section>
      <div className="card d-block mx-md-0 mt-5 mb-5">
        <div id="home-technology-solution-section" className="row no-gutters ml-5 mr-5">
          <div className="col-md-6">
            <picture>
              <img
                src={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/HomePage/technology_solution.jpg`}
                className="card-img img-object-cover"
                alt={localeService.getText("HomeV2", "TechnologySolutionImageAlt")}
              />
            </picture>
          </div>
          <div className="d-flex col-md">
            <div className="card-body align-self-center p-4 p-md-5">
              <h2 id="home-technology-solution-title" className="text-style h2">
                <Text category="HomeV2" id="TechnologySolutionTitle" />
              </h2>
              <div id="home-technology-solution-description" className="Detail-text mb-3 mt-2">
                <p>
                  <Text category="HomeV2" id="TechnologySolutionDescription" />
                </p>
              </div>
              <a
                id="home-technology-solution-button"
                href={localeService.getText("HomeV2", "PopularOffersLink")}
                className="cta"
                target="_blank"
                rel="noopener noreferrer"
              >
                {localeService.getText("HomeV2", "TechnologySolutionButton")}
                <span className="glyph" aria-hidden="true"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TechnologySolutionsBanner;
