import "@ms-mwf/moray/dist/css/main.css";
import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import AppContextV2 from "src/components/app/AppContextV2";
import Text from "src/components/text/Text";
import localeService from "src/dataServices/LocaleService";
import { IApplicationState } from "src/state";
import StatusBanner, { StatusIcon } from "../shared/StatusBanner";
import "./AzureRegistrationErrorMessageBanner.scss";

interface IAzureRegistrationErrorMessageBannerProps {
  statusTitle?: string;
  statusDescription: string;
  messageCode?: string;
}

interface IContextProps {
  values: { [key: string]: string };
}

export type IAzureRegistrationErrorMessgaeBannerProps = IAzureRegistrationErrorMessageBannerProps & IContextProps;

const AzureRegistrationErrorMessageBanner: React.FunctionComponent<
  IAzureRegistrationErrorMessgaeBannerProps
> = props => {
  const [showMore, setShowMore] = useState(false);
  props.values["MessageCode"] = props.messageCode ?? '';

  const { statusTitle } = props;

  return (
    <div id="banner">
      <StatusBanner iconType={StatusIcon.error} text={statusTitle}>
        <div className="mb-4">
          <p id="description">
            <Text category="AzureV2" id={props.statusDescription} substitutionData={props.values} />
          </p>
        </div>
        <div className="more-details-button">
          {showMore && <AppContextV2 />}
          <button className="action-trigger" onClick={() => setShowMore(!showMore)}>
            {showMore ? (
              <Text category="AzureV2" id="GrantErrorLessDetailsLinkTitle" />
            ) : (
              <Text category="AzureV2" id="GrantErrorMoreDetailsLinkTitle" />
            )}
          </button>
        </div>
        <div className="link-group mt-2">
          <a
            href="/contactus"
            className="cta"
            aria-label={localeService.getText("AzureV2", "GrantErrorContactSupportAriaLabel")}
            target="_blank"
          >
            <Text category="AzureV2" id="GrantErrorContactSupportLinkTitle" />
            <span className="glyph" aria-hidden="true"></span>
          </a>
        </div>
      </StatusBanner>
    </div>
  );
};

const mapStateToProps = ({ layout }: IApplicationState) => ({
  values: layout.appContext
});

export default connect(mapStateToProps)(AzureRegistrationErrorMessageBanner);
