import { ApplicationInsights } from "@microsoft/1ds-analytics-web-js";
import * as React from "react";
import { useLocation } from "react-router-dom";

const OneDS: React.FunctionComponent = () => {
  // initialize OneDS
  React.useEffect(() => {
    if (window.analytics === undefined) {
      window.analytics = new ApplicationInsights();
      var config = {
        instrumentationKey: process.env.REACT_APP_ONEDS_TENANT_TOKEN, // tenant key
        webAnalyticsConfiguration: {
          autoCapture: {
            lineage: true
          }
        }
      };
      //Initialize SDK
      window.analytics.initialize(config, []);
    }
  });

  const location = useLocation();
  React.useEffect(() => {
    if (window.analytics && location.pathname !== "/") {
      try {
        window.analytics.capturePageView();
      } catch (e) {
        console.error(e);
      }
    }
  }, [location]);

  return null;
};

export default OneDS;
