import "@ms-mwf/moray/dist/css/main.css";
import * as MorayComponents from "@ms-mwf/moray/dist/js/bundle";
import * as React from "react";
import Text from "src/components/text/Text";
import ResizableImage, { IResizableImage } from "./ResizeableImage";

export const MorayCardImageClassName: string = "card-img";

export interface IContentCard {
  title?: string;
  contents: JSX.Element;
  sectionAria?: string;
  imgSizes: IResizableImage;
  actionText?: string;
  onClickAria?: string;
  onClickAction?: () => void;
  isNew?: boolean;
}

interface IContentCardsSneakPeekProps {
  contentCards: IContentCard[];
  ariaLabel?: string;
}

export default function MorayContentCardsSneakPeek(props: IContentCardsSneakPeekProps) {
  const element = React.useRef<HTMLDivElement | null>(null);
  const controlElement = React.useRef<HTMLDivElement | null>(null);
  const slides = React.useRef<(HTMLElement | null)[]>([]);
  const [carousel, setCarousel] = React.useState(null);

  React.useEffect(() => {
    if(props.contentCards.length > 1) {
      setCarousel(
        new MorayComponents.Carousel({
          el: element.current,
          carouselControls: {
            el: element.current,
            slides: slides.current,
            initialSlideIndex: 0
          }
        })
      );
    }
  }, []);

  const renderIndicators = (contentCards: IContentCard[]) => {
    return contentCards.map((card, index) => {
      if (index === 0) {
        return <li key={index} className="active" />;
      } else {
        return <li key={index} />;
      }
    });
  };

  const renderSection = (cardContent: IContentCard, index: number) => {
    const { title, contents, sectionAria, actionText, onClickAria, onClickAction, isNew } = cardContent;
    const {
      root,
      imageSrc,
      imageClassName,
      imageAlt,
      fileSize1920,
      fileSize1400,
      fileSize1084,
      fileSize860,
      fileSize540,
      fileSize360
    } = cardContent.imgSizes;

    const renderTitle = (title: string | undefined, isNew: boolean) => (
      <h4 className="h4" style={{ marginBottom: '1rem' }}>
        {isNew && (
          <>
            <span className="h4" style={{ color: '#107C10' }}>
              <Text category="Common" id="NewOfferLabel" />
            </span>{' '}
          </>
        )}
        {title}
      </h4>
    );

    return (
      <section
        ref={(element) => {
          slides.current = slides.current.concat([element]);
        }}
        className={index === 0 ? 'carousel-item  active' : 'carousel-item'}
        tabIndex={0}
        aria-label={sectionAria}
        aria-roledescription="slide"
        key={index}
      >
        <div className="card material-card h-100" data-mount="click-group">
          <ResizableImage
            root={root}
            imageSrc={imageSrc}
            imageClassName={MorayCardImageClassName}
            imageAlt={imageAlt}
            fileSize1920={fileSize1920}
            fileSize1400={fileSize1400}
            fileSize1084={fileSize1084}
            fileSize860={fileSize860}
            fileSize540={fileSize540}
            fileSize360={fileSize360}
          />
          <div className="card-header pt-3 px-4 px-lg-5">{renderTitle(title, isNew ?? false)}</div>
          <div className="card-body px-4 px-lg-5">
            <div className="mb-3">{contents}</div>
          </div>

          <div className="card-footer pt-3 px-4 pb-4 px-lg-5">
            <div className="link-group">
              {onClickAction && (
                <a
                  tabIndex={0}
                  href="src/components/moray"
                  onClick={(e) => {
                    e.preventDefault();
                    onClickAction?.();
                  }}
                  className="cta"
                  aria-label={onClickAria}
                >
                  {actionText}
                  <span className="glyph" aria-hidden="true"></span>
                </a>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  };

  return (
    <div className="row">
      <div className="col-12 px-0 px-md-g">
        <section aria-label={props.ariaLabel} aria-roledescription="slideshow">
          <div
            ref={(el) => {
              element.current = el;
            }}
            className="carousel slide carousel-content-cards carousel-sneak-peek"
            data-mount="carousel"
          >
            <div>
              <div
                ref={(el) => {
                  controlElement.current = el;
                }}
                className="carousel-controls"
              >
                <button type="button" className="carousel-control-prev " data-slide="prev">
                  <span className="sr-only">Previous slide</span>
                </button>
                <ol className="carousel-indicators" aria-hidden="true">
                  {renderIndicators(props.contentCards)}
                </ol>
                <button type="button" className="carousel-control-next " data-slide="next">
                  <span className="sr-only">Next slide</span>
                </button>
              </div>
              <div className="carousel-inner">
                {props.contentCards.map((cardContent, index) => {
                  return renderSection(cardContent, index);
                })}
              </div>
            </div>
          </div>
        </section>
        <a id="mwf01f3bfc4" className="sr-only" tabIndex={-1} />
      </div>
    </div>
  );
}
