import * as React from "react";
import { connect } from "react-redux";

interface IPropsFromState {
  pathname: string;
}

interface IPropsFromParent {
  children?: React.ReactNode;
}

type IRefocusProps = IPropsFromState & IPropsFromParent;
class Refocus extends React.Component<IRefocusProps> {
  private node!: HTMLDivElement | null;

  public componentDidUpdate(prevProps: IRefocusProps) {
    if (this.node && this.props.pathname === "/" && this.props.pathname !== prevProps.pathname) {
      this.node.focus();
    }
  }

  public render() {
    return (
      <div id="refocus" ref={n => (this.node = n)} tabIndex={-1}>
        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps = (): IPropsFromState => ({
  pathname: window.location.pathname,
});

export default connect(mapStateToProps)(Refocus);
