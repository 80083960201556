import { useSelector } from 'react-redux';
import localeEnabledForV2, { LocaleConfigStrings } from '../../../common/localeEnabledForV2';
import { IApplicationState } from '../../../state';

interface IUseM365CopilotOfferUpdate {
  isM365CopilotOfferAvailable: boolean;
}

const useM365CopilotOfferUpdate = (): IUseM365CopilotOfferUpdate => {
  const featureFlags = useSelector((state: IApplicationState) => state.flags);
  const isM365CopilotOfferAvailable =
    featureFlags.M365CopilotOfferUpdateEnabled && localeEnabledForV2(LocaleConfigStrings.M365CopilotOfferUpdate);

  return {
    isM365CopilotOfferAvailable
  };
};

export default useM365CopilotOfferUpdate;
