import { action } from "typesafe-actions";
import { IEmailOfferRequestSubmissionForm, OffersRequestActionTypes } from "./types";

export const offersRequest = (values: IEmailOfferRequestSubmissionForm) =>
  action(OffersRequestActionTypes.OFFERS_REQUEST, values);
export const offersRequestSuccess = () => action(OffersRequestActionTypes.OFFERS_REQUEST_SUCCESS);
export const offersRequestError = () => action(OffersRequestActionTypes.OFFERS_REQUEST_ERROR);
export const offersRequestReset = () => action(OffersRequestActionTypes.OFFERS_REQUEST_RESET);

export const claimOfferRequest = (offerId: string, userConsent?: boolean) =>
  action(OffersRequestActionTypes.CLAIM_OFFER_REQUEST, { offerId, userConsent });
