import * as React from "react";
import localeService from "src/dataServices/LocaleService";
import LoadingButton from "../../utilities/LoadingButton";
import "./BulletBox.scss";
import { IFeatureFlagsState } from "src/state/feature-flags";
import { IApplicationState } from "src/state";
import { connect } from "react-redux";

const imageUrl = process.env.REACT_APP_IMAGES_URL;

interface IBulletBoxPropsFromState {
  featureFlags: IFeatureFlagsState;
}

const BulletBox = ({ featureFlags }: IBulletBoxPropsFromState) => (
  <section id="bullet-box" className="ms-Grid-row ms-sm12 ms-lg12">
    <div id="bullet-box-text" className="ms-Grid-col ms-sm-12 ms-lg9 ms-md9">
      <div id="bullet-box-header" className="biggest-heading">
        {localeService.getText("BulletBox", "BulletBoxHeader")}
      </div>
      <div id="bullet-box-content">
        <ul id="bullet-box-list">
          <li id="bullet-box-list-item-1">{localeService.getText("BulletBox", "BulletBoxContent1")}</li>
          <li id="bullet-box-list-item-2">{localeService.getText("BulletBox", "BulletBoxContent2New")}</li>
          <li id="bullet-box-list-item-3">{localeService.getText("BulletBox", "BulletBoxContent3")}</li>
        </ul>
      </div>
      <LoadingButton
        href={localeService.getText("Urls", "LearnMore")}
        target="_blank"
        isExternal={true}
        title={localeService.getText("BulletBox", "InnovateTitle")}
        text={localeService.getText("BulletBox", "LearnMore")}
        loading={false}
        role="link"
        properties={{ ButtonName: "GettingStarted-InnovateButton" }}
      />
    </div>
    <div id="bullet-box-image-wrapper" className="ms-Grid-col ms-sm-12 ms-lg3 ms-md3">
      <img
        id="bullet-box-image"
        src={`${imageUrl}/getting-started/bullet-box-image.jpg`}
        alt={localeService.getText("BulletBox", "BulletBoxImageTitle")}
      />
    </div>
  </section>
);

const mapStateToProps = ({ flags }: IApplicationState) => ({
  featureFlags: flags
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BulletBox);
