import * as React from "react";
import { connect } from "react-redux";
import FollowUsFooter from "src/components/followUsFooter/FollowUsFooter";
import { IApplicationState } from "src/state";
import { ISendToPartnerState } from "src/state/offers/software/send-to-partner/types";
import "./Software.scss";
import SoftwareEligibilityTopBanner from "./SoftwareEligibilityTopBanner";
import SoftwareGrantsAndDiscounts from "./SoftwareGrantsAndDiscounts";
import SoftwareSendEligibilityForm from "./SoftwareSendEligibilityForm";
import SoftwareSendEligibilitySuccess from "./SoftwareSendEligibilitySuccess";

interface IPropsFromState {
  sendToPartner: ISendToPartnerState;
}

export type ISoftwareV2Props = IPropsFromState;

export const SoftwareV2 = (props: ISoftwareV2Props) => {
  const sendToPartner = props.sendToPartner;

  return (
    <section>
      <SoftwareEligibilityTopBanner />
      {sendToPartner.sentSuccessfully && <SoftwareSendEligibilitySuccess />}
      <SoftwareGrantsAndDiscounts />
      {!sendToPartner.sentSuccessfully && <SoftwareSendEligibilityForm />}
      <FollowUsFooter />
    </section>
  );
};

const mapStateToProps = ({ offers }: IApplicationState) => ({
  sendToPartner: offers.software.sendToPartner
});

export default connect(mapStateToProps)(SoftwareV2);
