import * as React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "src/state";
import "./AppContextV2.scss";

export interface IContextProps {
  values: { [key: string]: string };
}

const AppContextV2: React.FunctionComponent<IContextProps> = props => {
  const { values } = props;
  return (
    <section id="app-context">
      <ul>
        {Object.keys(values).map(key => {
          return values[key] ? (
            <li key={key}>
              {key}: {values[key]}
            </li>
          ) : null;
        })}
      </ul>
    </section>
  );
};

const mapStateToProps = ({ layout }: IApplicationState) => ({
  values: layout.appContext
});

export default connect(mapStateToProps)(AppContextV2);
