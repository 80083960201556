import "@ms-mwf/moray/dist/css/main.css";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IApplicationState } from "src/state";
import { OfferId } from "src/state/nonprofit/types";
import { initiateAzureRegistrationForOfferId, initiateAzureRenewal } from "src/state/offers/azure/actions";
import {
  AzureExpirationStatus,
  AzureRegistrationResultCode,
  AzureTokenValidationCode,
  AzureTokenValidationResult
} from "src/state/offers/azure/types";
import ActionStepCard from "../shared/ActionStepCard";
import { AzureTransactionCode } from "./AzureTransactionCodes";
import { getAzureExpirationStatus } from "./Utils";
import { IFeatureFlagsState } from "src/state/feature-flags";

interface IPropsFromState {
  token: string;
  isRegistering: boolean;
  registrationResultCode: AzureRegistrationResultCode;
  parentCallback?: any;
  accountOwnerLiveId: string;
  startDate: string;
  endDate: string;
  tokenValidationResult: AzureTokenValidationResult;
  tokenValidationCode: AzureTokenValidationCode | string;
  transactionCode?: string | null;
  statusId?: number;
  featureFlags: IFeatureFlagsState;
}

interface IPropsFromDispatch {
  initiateAzureRegistrationForOfferId?: typeof initiateAzureRegistrationForOfferId;
  initiateAzureRenewal: typeof initiateAzureRenewal;
}

const step1IconPath = "hub-redesign/ActionStep_1_3x.png";

export type IAzureRegistrationV2Props = IPropsFromState & IPropsFromDispatch;

export const AzureRegistrationV2: React.FunctionComponent<IAzureRegistrationV2Props> = props => {
  const {
    token,
    accountOwnerLiveId,
    registrationResultCode,
    parentCallback,
    endDate,
    tokenValidationCode,
    transactionCode,
    initiateAzureRegistrationForOfferId,
    initiateAzureRenewal,
    statusId,
    featureFlags
  } = props;

  let registrationComponent = null;

  if (tokenValidationCode === AzureTokenValidationCode.Active) {
    registrationComponent = (
      <div>
        <div id="renewclaim" aria-live="polite">
          <ActionStepCard
            stepIconImagePath={step1IconPath}
            cardTextCategory="AzureV2"
            cardTitle="GrantRenewActionStepTitle"
            cardDescription={"GrantRenewActionStepDescriptionNew"}
            ctaText="GrantRenewNow"
            onClickAction={() => {
              props.initiateAzureRenewal(props.token);
            }}
            ctaDisabled={true}
          />
          <input
            hidden
            readOnly
            value={props.tokenValidationCode}
            onChange={props.parentCallback(getAzureRenewRegistrationCode(props.tokenValidationCode))}
          />
        </div>
      </div>
    );
  } else if (transactionCode != null && transactionCode != undefined) {
    registrationComponent = getRegistrationComponentFromTransactionCode(props);
  } else if (accountOwnerLiveId === null) {
    registrationComponent = (
      <div>
        <div id="newclaim" aria-live="polite">
          <ActionStepCard
            stepIconImagePath={step1IconPath}
            cardTextCategory="AzureV2"
            cardTitle="GrantClaimActionStepTitle"
            cardDescription={"GrantClaimActionStepDescriptionNew"}
            ctaText="GrantClaimNow"
            onClickAction={() => initiateAzureRegistrationForOfferId?.(OfferId.azure)}
            maxReloadsAfterSpinner={1}
          />
          <input
            hidden
            readOnly
            value={registrationResultCode}
            onChange={parentCallback(getAzureClaimRegistrationCode(registrationResultCode))}
          />
        </div>
      </div>
    );
  } else if (
    [AzureExpirationStatus.Expired.toString(), AzureExpirationStatus.Expiring.toString()].includes(
      getAzureExpirationStatus(endDate)
    )
  ) {
    registrationComponent = (
      <div>
        <div id="renewclaim" aria-live="polite">
          <ActionStepCard
            stepIconImagePath={step1IconPath}
            cardTextCategory="AzureV2"
            cardTitle="GrantRenewActionStepTitle"
            cardDescription={"GrantRenewActionStepDescriptionNew"}
            ctaText="GrantRenewNow"
            onClickAction={() => initiateAzureRenewal(token)}
            maxReloadsAfterSpinner={1}
            ctaDisabled={statusId === 99}
          />
          <input
            hidden
            readOnly
            value={tokenValidationCode}
            onChange={parentCallback(getAzureRenewRegistrationCode(tokenValidationCode))}
          />
        </div>
      </div>
    );
  } else {
    // TODO: Need to add more conditions
    registrationComponent = (
      <div>
        <div aria-live="polite">
          <ActionStepCard
            stepIconImagePath={step1IconPath}
            cardTextCategory="AzureV2"
            cardTitle="GrantClaimActionStepTitle"
            cardDescription={"GrantClaimActionStepDescriptionNew"}
            ctaText="GrantClaimNow"
            onClickAction={() => initiateAzureRegistrationForOfferId?.(OfferId.azure)}
            ctaDisabled={true}
          />
        </div>
      </div>
    );
  }

  return registrationComponent;
};

const getAzureClaimRegistrationCode = (registrationResultCode: AzureRegistrationResultCode) => {
  if (AzureRegistrationResultCode.Ok === registrationResultCode) {
    return null;
  }
  return registrationResultCode === undefined ? registrationResultCode : registrationResultCode.toString();
};

const getAzureRenewRegistrationCode = (tokenValidationCode: string | AzureTokenValidationCode | null | undefined) => {
  if (
    tokenValidationCode != null &&
    tokenValidationCode != undefined &&
    AzureTokenValidationCode.Active.toString() === tokenValidationCode.toString()
  ) {
    return AzureTransactionCode.AzureRenewalSuccess.toString();
  } else if (
    tokenValidationCode != null &&
    tokenValidationCode != undefined &&
    AzureTokenValidationCode.Error.toString() === tokenValidationCode.toString()
  ) {
    return AzureTransactionCode.AzureRenewalFailed.toString();
  } else {
    return null;
  }
};

const getRegistrationComponentFromTransactionCode = (props: IAzureRegistrationV2Props) => {
  // More cases can be added based on different transaction codes
  switch (props.transactionCode) {
    default:
      return null;
  }
};

const mapStateToProps = ({ auth, offers, flags }: IApplicationState) => ({
  accountOwnerLiveId: offers.azure.accountOwnerLiveId,
  isRegistering: offers.azure.isRegistering,
  registrationResultCode: offers.azure.registrationResultCode,
  startDate: offers.azure.startDate,
  endDate: offers.azure.endDate,
  tokenValidationResult: offers.azure.tokenValidationResult,
  tokenValidationCode: offers.azure.tokenValidationCode,
  statusId: offers.azure.statusId,
  featureFlags: flags
});

const mapDispatchToProps: (dispatch: Dispatch) => IPropsFromDispatch = dispatch => ({
  initiateAzureRegistrationForOfferId: (offerId: string) => dispatch(initiateAzureRegistrationForOfferId(offerId)),
  initiateAzureRenewal: (offerId: string) => dispatch(initiateAzureRenewal(offerId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AzureRegistrationV2);
