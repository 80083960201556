import { action } from "typesafe-actions";
import { IContactPermissionSettings, INonprofitEmailUpdate, IProfile, ProfileActionTypes } from "./types";

export const fetchProfileRequest = () => action(ProfileActionTypes.FETCH_PROFILE_REQUEST);
export const fetchProfileSuccess = (profile: IProfile) => action(ProfileActionTypes.FETCH_PROFILE_SUCCESS, { profile });
export const fetchContactPermissionSettings = (response: IContactPermissionSettings | undefined) =>
  action(ProfileActionTypes.FETCH_CONTACT_PERMISSION_SETTINGS, response);
export const profileFetchError = (error: string) => action(ProfileActionTypes.PROFILE_FETCH_ERROR, error);
export const initiateProfileEdit = () => action(ProfileActionTypes.INITIATE_PROFILE_EDIT);
export const profileFormUpdate = (profile: IProfile) => action(ProfileActionTypes.PROFILE_FORM_UPDATE, profile);
export const profileAckAttestation = () => action(ProfileActionTypes.PROFILE_ACK_ATTESTATION);
export const profileUpdateComplete = (validationRequired: boolean | undefined) =>
  action(ProfileActionTypes.PROFILE_UPDATE_COMPLETE, validationRequired);
export const cancelProfileEdit = () => action(ProfileActionTypes.CANCEL_PROFILE_EDIT);
export const cancelProfileAckAttestation = () => action(ProfileActionTypes.CANCEL_PROFILE_ACK_ATTESTATION);
export const profileUpdateError = (error: string | undefined) => action(ProfileActionTypes.PROFILE_UPDATE_ERROR, error);
export const profileFormSubmissionValidation = (profile: IProfile) =>
  action(ProfileActionTypes.PROFILE_FORM_SUBMISSION_VALIDATION, profile);
export const profileFormSubmissionValidationSuccess = () =>
  action(ProfileActionTypes.PROFILE_FORM_SUBMISSION_VALIDATION_SUCCESS);

// new profile creation
export const fetchProfileForCommercial = () => action(ProfileActionTypes.FETCH_PROFILE_COMMERCIAL);
export const fetchNewProfileSuccess = (profile: IProfile) =>
  action(ProfileActionTypes.FETCH_NEW_PROFILE_SUCCESS, { profile });
export const initiateNewProfileEdit = () => action(ProfileActionTypes.INITIATE_NEW_PROFILE_EDIT);
export const newProfileAckAttestation = () => action(ProfileActionTypes.NEW_PROFILE_ACK_ATTESTATION);
export const newProfileUpdateComplete = (nonprofitId: string | undefined) =>
  action(ProfileActionTypes.NEW_PROFILE_CREATE_COMPLETE, nonprofitId);
export const newProfileFormUpdate = (profile: IProfile) => action(ProfileActionTypes.NEW_PROFILE_FORM_UPDATE, profile);
export const cancelNewProfileAckAttestation = () => action(ProfileActionTypes.CANCEL_NEW_PROFILE_ACK_ATTESTATION);

export const initiateAgentEmailEdit = (nonprofitEmailUpdate: INonprofitEmailUpdate) =>
  action(ProfileActionTypes.INITIATE_AGENT_EMAIL_EDIT, nonprofitEmailUpdate);
export const agentEmailSubmissionResult = (result: boolean) =>
  action(ProfileActionTypes.AGENT_EMAIL_SUBMISSION_RESULT, result);
export const setEditMode = (editModeOn: boolean) => action(ProfileActionTypes.SET_EDIT_MODE, editModeOn);
export const setCommercial = (isCommercial: boolean) => action(ProfileActionTypes.SET_COMMERCIAL, isCommercial);
export const setShowConfirmation = (setShowConfirmation: boolean) =>
  action(ProfileActionTypes.SET_SHOW_CONFIRMATION, setShowConfirmation);
export const saveProfile = (profile: IProfile) => action(ProfileActionTypes.SAVE_PROFILE, profile);
export const createProfile = (profile: IProfile) => action(ProfileActionTypes.CREATE_PROFILE, profile);
