import * as React from "react";
import { FieldArray } from "react-final-form-arrays";
import localeService from "src/dataServices/LocaleService";
import { IRegulatoryBody } from "src/state/profile/types";
import { fetchRegulatoryBodiesForCountry } from "./ProfileSharedFunctions";
import { getLegalIdentifierValidator } from "./ProfileV2SharedComponents";
import RegistrationIdentifier, { IRegulatoryBodyExt } from "./RegistrationIdentifier";
import { IRegistrationIdentifier } from "src/state/nonprofit/types";

export interface IProfileLegalIdentifierProps {
  profileForm: any;
  registrationIdentifiers: IRegistrationIdentifier[];
  countryCode: string;
}

export interface IRegulatoryBodiesProps {
  fields: any;
  countryCode: string;
  extRegulatoryBodies: Map<string, IRegulatoryBodyExt>;
}

const RegistrationIdentifiersEdit = ({ profileForm , countryCode, registrationIdentifiers }: IProfileLegalIdentifierProps): JSX.Element => {
  const [extRegulatoryBodies, setExtRegulatoryBodies] = React.useState<Map<string, IRegulatoryBodyExt>>();

  React.useEffect(() => {
    let mounted = true;
    fetchRegulatoryBodiesForCountry(countryCode).then(regulatoryBodies => {
      const extObjs: Map<string, IRegulatoryBodyExt> = new Map<string, IRegulatoryBodyExt>();
      const localeContent = localeService.getCategoryContent("RegulatoryBodies").identifiers[countryCode] || [];
      (regulatoryBodies || []).map((regulatoryBody: IRegulatoryBody) => {
        const extObj = Object.assign({}, regulatoryBody) as IRegulatoryBodyExt;
        extObj.label = localeContent[extObj.textKey]["label"];
        extObj.tooltipText = localeContent[extObj.textKey]["tooltipText"];
        extObj.validator = getLegalIdentifierValidator(
          countryCode,
          regulatoryBody.textKey,
          regulatoryBody.regexPattern
        );
        extObjs.set(extObj.textKey, extObj);
      });

      if (mounted) {
        setExtRegulatoryBodies(extObjs);
      }
    });

    return () => {
      mounted = false;
    };
  }, [countryCode]);

  return (
    <FieldArray
      name="registrationIdentifiers"
      initialValue={registrationIdentifiers}
    >
      {({ fields }) => (
        <RenderIdentifiers
          fields={fields}
          props={{ countryCode, extRegulatoryBodies, registrationIdentifiers, profileForm }}
        />
      )}
    </FieldArray>
  );
};

interface IrenderJobRolesDropdown {
  fields: any, props: { countryCode: string, extRegulatoryBodies: any, registrationIdentifiers: any, profileForm: any }
};

const RenderIdentifiers = (props: IrenderJobRolesDropdown) => {
  const { fields, props: { countryCode, extRegulatoryBodies, registrationIdentifiers, profileForm }} = props;
  const numRegulatoryBodies: number = extRegulatoryBodies ? extRegulatoryBodies.size : 0;

  if (fields.length === 0) {
    fields.update(0, undefined)
  }

  return (
    <>
      {fields &&
        fields.map((field: any, index: number) => (
          <RegistrationIdentifier
            key={index}
            field={field}
            index={index}
            fields={fields}
            profileForm={profileForm}
            countryCode={countryCode}
            extRegulatoryBodies={extRegulatoryBodies}
            registrationIdentifiers={registrationIdentifiers}
            required={true}
          />
        ))}
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12">
          {regulatoryBodiesAddition(fields, numRegulatoryBodies) && (
            <button
              className="action-trigger glyph-prepend glyph-prepend-circle-plus mt-2"
              type="button"
              aria-label={localeService.getCategoryContent("Registration").AddRegistrationInformation}
              onKeyDown={(evt: { key: string }) => {
                if (evt.key === "Enter") {
                  fields.push(undefined);
                }
              }}
              onClick={() => {
                fields.push(undefined);
              }}
            >
              {localeService.getText("ProfileV2", "AddRegistartionInformation")}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

const regulatoryBodiesAddition = (fields: any, numRegulatoryBodies: number) => {
  return fields.length < numRegulatoryBodies;
};

export default RegistrationIdentifiersEdit;
