// import { ICardSectionStyles, ICardSectionTokens, ICardTokens } from "@uifabric/react-cards";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IApplicationState } from "src/state";
import { IOffer, OfferId } from "src/state/nonprofit";
import { initiateAzureRegistrationForOfferId, resetAzureRegistrationForOfferId } from "src/state/offers/azure/actions";
import { AzureRegistrationResultCode } from "src/state/offers/azure/types";
import { claimOfferRequest } from "src/state/offers/shared/actions";
import { IOfferRequestState } from "src/state/offers/shared/types";
import "./OfferCards.scss";

const chunk = (array: IOffer[], size: number): IOffer[][] => {
  if (!array || array.length === 0) {
    return [];
  }
  const firstChunk = array.slice(0, size);
  return [firstChunk].concat(chunk(array.slice(size, array.length), size));
};

export interface IOfferCardsProps {
  offers?: IOffer[];
  OffersMapping: Map<string, any>;
  offersState?: IOfferRequestState;
  azureOfferEndDate: string;
  azureOfferId: string;
  isAzureRegistering: boolean;
  isAzureClaimSuccess: boolean;
  isAzureClaimFailure: boolean;
}

interface IPropsFromDispatch {
  claimOfferRequest: typeof claimOfferRequest;
  initiateAzureRegistrationForOfferId: typeof initiateAzureRegistrationForOfferId;
  resetAzureRegistrationForOfferId: typeof resetAzureRegistrationForOfferId;
}

type RegisterProps = IOfferCardsProps & IPropsFromDispatch;

export class OfferCards extends React.Component<RegisterProps> {
  public render() {
    const {
      offers,
      OffersMapping: OfferTileMapping,
      offersState,
      azureOfferId,
      azureOfferEndDate,
      isAzureRegistering,
      isAzureClaimSuccess,
      isAzureClaimFailure
    } = this.props;
    const offerTiles = offers?.filter(o => OfferTileMapping.has(o.id));
    const covidAzureOfferClaimed =
      (azureOfferId !== null && azureOfferEndDate !== null && azureOfferId === OfferId.covid19_azure_credits) ||
      isAzureClaimSuccess;
    const shouldResetAzureCredits =
      azureOfferId !== null && azureOfferEndDate !== null && azureOfferId === OfferId.azure;

    // const footerCardSectionStyles: ICardSectionStyles = {
    //   root: {
    //     borderTop: "1px solid #F3F2F1"
    //   }
    // };
    // const backgroundImageCardSectionStyles: ICardSectionStyles = {
    //   root: {
    //     background: "#e8e8e8",
    //     backgroundPosition: "center center",
    //     backgroundSize: "cover",
    //     height: 40
    //   }
    // };
    // const textSection: ICardSectionStyles = {
    //   root: {
    //     height: 290
    //   }
    // };

    // const cardTokens: ICardTokens = { childrenMargin: 12, minWidth: 300, maxWidth: 400 };
    // const footerCardSectionTokens: ICardSectionTokens = { padding: "12px 0px 0px" };
    // const backgroundImageCardSectionTokens: ICardSectionTokens = { padding: 13 };

    if (!offerTiles || offerTiles.length === 0) {
      return null;
    }

    return chunk(offerTiles, 3).map((tileRow, index) => {
      if (!tileRow || tileRow.length === 0) {
        return null;
      }

      return (
        <div className="ms-Grid-row offerCardsSection" key={`row${index}`}>
          {tileRow.map((item: IOffer, itemIndex: number) => {
            return (
              <div className="ms-Grid-col ms-sm12 ms-lg12 ms-xl4 cardItem" key={`row${index}-item${itemIndex}`}>
                {/* <Card aria-label="Offer id with details" tokens={cardTokens}>
                  <Card.Section
                    fill
                    verticalAlign="end"
                    styles={backgroundImageCardSectionStyles}
                    tokens={backgroundImageCardSectionTokens}
                  >
                    <div className="ms-Grid-row headerSection">
                      <section className="ms-Grid-col ms-lg12 ms-xl12">
                        <p className="offerIcon">
                          <Icon iconName={OfferTileMapping.get(item.id).logo} />
                          <span>{item.name}</span>
                        </p>
                      </section>
                    </div>
                  </Card.Section>
                  <Card.Section className="detailComponent" styles={textSection}>
                    {OfferTileMapping.get(item.id).detailComponent}
                  </Card.Section>
                  <Card.Section className="progress-indicator-section">
                    {((offersState !== undefined && offersState.offerId === item.id && offersState.isSending) ||
                      (isAzureRegistering && item.id === OfferId.covid19_azure_credits)) && (
                      <ProgressIndicator className="progress-indicator" />
                    )}
                  </Card.Section>
                  {OfferTileMapping.get(item.id).requestButtonEnabled && (
                    <Card.Section horizontal styles={footerCardSectionStyles} tokens={footerCardSectionTokens}>
                      {item.id === OfferId.covid19_azure_credits && covidAzureOfferClaimed && (
                        <section className="claim-azure-success">
                          <Successful textCategory="Azure" textId="ClaimedLabel" />
                        </section>
                      )}
                      <Stack.Item grow={1}>
                        <span />
                      </Stack.Item>
                      {item.id === OfferId.covid19_azure_credits && covidAzureOfferClaimed ? (
                        <a
                          className="primary-button external"
                          href={localeService.getText("Urls", "AzurePortal")}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Icon iconName="ChevronRightSmall" />
                          {localeService.getText("Azure", "RegisterGoToAzure")}
                        </a>
                      ) : (
                        <div aria-live="polite">
                          {offersState.offerId !== undefined &&
                          offersState.offerId === item.id &&
                          offersState.sentSuccessfully ? (
                            <div aria-live="assertive" role="alert">
                              <Successful
                                textCategory={OfferTileMapping.get(item.id).category}
                                textId="RequestSuccess"
                              />
                            </div>
                          ) : (offersState.offerId !== undefined &&
                              offersState.offerId === item.id &&
                              offersState.failedToSend) ||
                            (isAzureClaimFailure && item.id === OfferId.covid19_azure_credits) ? (
                            <div aria-live="assertive" role="alert">
                              <TryAgain
                                textCategory={OfferTileMapping.get(item.id).category}
                                textId="RequestFailure"
                                onClick={this.initiateClaimRequest(item.id, shouldResetAzureCredits)}
                              />
                            </div>
                          ) : (
                            <LoadingButton
                              loading={
                                (offersState !== undefined &&
                                  offersState.offerId === item.id &&
                                  offersState.isSending) ||
                                (isAzureRegistering && item.id === OfferId.covid19_azure_credits)
                              }
                              type="submit"
                              disabled={
                                (offersState !== undefined &&
                                  offersState.offerId !== item.id &&
                                  offersState.isSending) ||
                                (isAzureRegistering && item.id !== OfferId.covid19_azure_credits)
                              }
                              id={`${item.name}-claimOffer`}
                              title={`${localeService.getText(
                                OfferTileMapping.get(item.id).category,
                                "SubmitRequest"
                              )}`}
                              text={`${localeService.getText(
                                OfferTileMapping.get(item.id).category,
                                "SubmitButtonText"
                              )}`}
                              width={160}
                              onClick={this.initiateClaimRequest(item.id, shouldResetAzureCredits)}
                              properties={{ ButtonName: `${item.name}-ClaimOffer` }}
                            />
                          )}
                        </div>
                      )}
                    </Card.Section>
                  )}
                </Card> */}
              </div>
            );
          })}
          {Array.from({ length: 3 - tileRow.length }).map((_, fillerIndex) => {
            return <div key={`row${index}-filler-${fillerIndex}`} className="ms-Grid-col ms-lg12 ms-xl4 cardItem" />; // Filler div for remaining columns
          })}
        </div>
      );
    });
  }

  private initiateClaimRequest(id: string, shouldResetAzureCredits: boolean) {
    if (id === OfferId.covid19_azure_credits) {
      if (shouldResetAzureCredits) {
        return () => this.props.resetAzureRegistrationForOfferId(id);
      } else {
        return () => this.props.initiateAzureRegistrationForOfferId(id);
      }
    }

    return () => this.props.claimOfferRequest(id);
  }
}

const mapStateToProps = ({ nonprofit, offers }: IApplicationState) => ({
  nonprofit: nonprofit.miniProfile,
  offersState: offers.sharedOffer,
  azureOfferEndDate: offers.azure.endDate,
  azureOfferId: offers.azure.offerId,
  isAzureRegistering: offers.azure.isRegistering,
  isAzureClaimSuccess:
    offers.azure.registrationResultCode ? offers.azure.registrationResultCode === AzureRegistrationResultCode.Ok : false,
  isAzureClaimFailure:
    offers.azure.registrationResultCode !== undefined &&
    offers.azure.registrationResultCode !== AzureRegistrationResultCode.Ok
});

const mapDispatchToProps: (dispatch: Dispatch) => IPropsFromDispatch = dispatch => ({
  claimOfferRequest: (offerId: string) => dispatch(claimOfferRequest(offerId)),
  initiateAzureRegistrationForOfferId: (offerId: string) => dispatch(initiateAzureRegistrationForOfferId(offerId)),
  resetAzureRegistrationForOfferId: (offerId: string) => dispatch(resetAzureRegistrationForOfferId(offerId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OfferCards);
