import * as MorayComponents from "@ms-mwf/moray/dist/js/bundle";
import * as React from "react";
import ProductCard, { IProductCard, ProductCardRow } from "src/components/offers/shared/ProductCard";
import localeService from "src/dataServices/LocaleService";
import "./ProductCardCarousel.scss";

export interface IProductCardCarousel {
  title?: string | null;
  linkAriaLabel?: string | null;
  productCardCarouselProps: IProductCard[];
  numCardsPerSlide: {
    xl: number;
    lg: number;
    md: number;
    sm: number;
    xs: number;
  };
}

/*
  We have implemented our own dots (number of slides) per viewport since we don't have access to the number of dots (slides) in the Carousel from Moray for React.

  Example: We have a total of 7 Product Cards with xl=4, lg=4, md=3, sm=2, xs=2
  We solve this by first creating 7 dots

  For xl and lg screens we will have 2 dots (slides) visible and will set the CSS Property display: none for the last 5
  For md we will have 3 dots (slides) visible and will set the CSS Property display: none for the last 4
  For sm and xs we will have 4 dots (slides) visible and will set the CSS Property display: none for the last 3

  The getDotClassName functions determine if a particular dot should be visible or invisible at xl, lg, md, sm and xs viewports
*/
const ProductCardCarousel = ({ title = null, productCardCarouselProps, numCardsPerSlide: { xl, lg, md, sm, xs } }: IProductCardCarousel) => {
  const element = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    new MorayComponents.Carousel({ el: element.current });
  }, []);

  const getDotClassNameXL = (index: number) => {
    if (index === 0 && xl >= productCardCarouselProps.length) {
      return "dot-display-none-xl";
    }
    return xl * index + 1 > productCardCarouselProps.length ? "dot-display-none-xl" : "";
  };

  const getDotClassNameLG = (index: number) => {
    if (index === 0 && lg >= productCardCarouselProps.length) {
      return "dot-display-none-lg";
    }
    return lg * index + 1 > productCardCarouselProps.length ? "dot-display-none-lg" : "";
  };

  const getDotClassNameMD = (index: number) => {
    if (index === 0 && md >= productCardCarouselProps.length) {
      return "dot-display-none-md";
    }
    return md * index + 1 > productCardCarouselProps.length ? "dot-display-none-md" : "";
  };

  const getDotClassNameSM = (index: number) => {
    if (index === 0 && sm >= productCardCarouselProps.length) {
      return "dot-display-none-sm";
    }
    return sm * index + 1 > productCardCarouselProps.length ? "dot-display-none-sm" : "";
  };

  const getDotClassNameXS = (index: number) => {
    if (index === 0 && xs >= productCardCarouselProps.length) {
      return "dot-display-none-xs";
    }
    return xs * index + 1 > productCardCarouselProps.length ? "dot-display-none-xs" : "";
  };

  return (
    <>
      {title ? <div className="container pt-5 px-2 ml-4">{title}</div> : null}
      <div className="row">
        <div className="col-12 px-0">
          <div
            ref={el => (element.current = el)}
            className="carousel slide carousel-product-card carousel-variable-height"
            data-mount="carousel"
          >
            <div>
              <div className="carousel-controls custom-carousel-controls">
                <button
                  type="button"
                  className="carousel-control-prev custom-carousel-prev"
                  data-slide="prev"
                  aria-label={localeService.getText("Shared", "PreviousSlide")}
                />
                <ol className="carousel-indicators custom-carousel-indicators dots" aria-hidden="true">
                  {productCardCarouselProps.map((prop, index) => (
                    <li
                      key={index}
                      className={`active
                          ${getDotClassNameXL(index)}
                          ${getDotClassNameLG(index)}
                          ${getDotClassNameMD(index)}
                          ${getDotClassNameSM(index)}
                          ${getDotClassNameXS(index)}`}
                    />
                  ))}
                </ol>
                <button
                  type="button"
                  className="carousel-control-next custom-carousel-next"
                  data-slide="next"
                  aria-label={localeService.getText("Shared", "NextSlide")}
                />
              </div>
              <div className="carousel-inner">
                <section className={`carousel-item active`}>
                  <ProductCardRow numCards={{ xl, lg, md, sm, xs }}>
                    {productCardCarouselProps.map((productCardProps, index) => (
                      <ProductCard key={index} {...productCardProps} linkAriaLabel={productCardProps.linkAriaLabel} />
                    ))}
                  </ProductCardRow>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCardCarousel;
