import { Reducer } from "redux";
import authenticationService from "src/dataServices/AuthenticationService";
import { AuthActionTypes, AuthState, AuthStatus } from "./types";

// Type-safe initialState!
const initialState: AuthState = {
  isAgentOkay: true,
  isAuthenticated: authenticationService.isAuthenticated,
  isRegistering: false,
  isSigningIn: false,
  user: authenticationService.isAuthenticated ? authenticationService.accountInfo : undefined,
  isLoading: true
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<AuthState> = (state = initialState, action): AuthState => {
  switch (action.type) {
    case AuthActionTypes.INITIATE_LOGIN: {
      return {
        ...state,
        isSigningIn: true,
        isLoading: false
      };
    }
    case AuthActionTypes.INITIATE_REGISTER: {
      return {
        ...state,
        isRegistering: true,
        isLoading: false
      };
    }
    case AuthActionTypes.SET_AUTH_STATUS: {
      const status: AuthStatus = action.payload;
      return {
        ...state,
        isAgentOkay: status.isAgentOkay,
        isAuthenticated: status.isAuthenticated,
        isRegistering: false,
        isSigningIn: false,
        user: status.user,
        isLoading: false
      };
    }
    default: {
      return state;
    }
  }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.ts`
export { reducer as authReducer };

