import { Spinner, SpinnerSize } from '@fluentui/react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { initiateLogin } from 'src/state/auth';

interface IPropsFromState {}

interface IPropsFromDispatch {
  initiateLogin: typeof initiateLogin;
}

type SigninProps = IPropsFromDispatch & IPropsFromState;

const SignInComponent = (props: SigninProps) => {
  const header = document.getElementById('uhfHeader');
  const footer = document.getElementById('uhfFooter');

  if (header) {
    header.hidden = true;
  }

  if (footer) {
    footer.hidden = true;
  }

  props.initiateLogin();

  return <Spinner size={SpinnerSize.large} />;
};

const mapStateToProps = (): IPropsFromState => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initiateLogin: () => dispatch(initiateLogin())
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInComponent);
