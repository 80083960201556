import { Icon } from "@fluentui/react";
import "@ms-mwf/moray/dist/css/main.css";
import * as React from "react";
import { connect } from "react-redux";
import localeService from "src/dataServices/LocaleService";
import { IApplicationState } from "src/state";
import { IContactUsState } from "src/state/contactus/types";

export interface IPropsFromState {
  contactUs: IContactUsState;
}
class ContactUsV2Success extends React.Component<IPropsFromState> {
  constructor(props: IPropsFromState) {
    super(props);
    this.state = {};
  }
  public render() {
    return this.props.contactUs.sentSuccessfully ? (
      <section
        id="contactusSuccess"
        className="material-surface"
        style={{
          padding: "20px"
        }}
      >
        <div
          style={{
            padding: "20px"
          }}
        >
          <div style={{}}>
            <Icon
              iconName={"completed"}
              style={{
                fontSize: "24px",
                lineHeight: "24px",
                color: "#107C10",
                float: "left",
                padding: "0 20px"
              }}
            />
          </div>
          <div style={{}}>
            <h2
              style={{
                marginBottom: "10px"
              }}
            >
              {localeService.getText("ContactUsGetMoreHelp", "ThankYouForContactingUs")}
            </h2>
            <p
              dangerouslySetInnerHTML={{
                __html: [
                  localeService.getText("ContactUsGetMoreHelp", "SuccessContact"),
                  localeService.getText("ContactUsGetMoreHelp", "HereIsYourRequestId"),
                  this.props.contactUs.supportTicketResponse?.Id + ".",
                  localeService.getText("ContactUsGetMoreHelp", "ReceiveEmail"),
                  localeService.getText("ContactUsGetMoreHelp", "ExploreOffers")
                ].join(" ")
              }}
            />
            <a className="cta" href={"/contactus"}>
              {localeService.getText("ContactUsGetMoreHelp", "INeedMoreHelp")}
              <span className="glyph" aria-hidden="true"></span>
            </a>
          </div>
        </div>
      </section>
    ) : (
      <React.Fragment />
    );
  }
}

const mapStateToProps = ({ contactUs }: IApplicationState): IPropsFromState => ({
  contactUs
});

export default connect(mapStateToProps)(ContactUsV2Success);
