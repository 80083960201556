import { Spinner, SpinnerSize } from "@fluentui/react";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import localeService from "src/dataServices/LocaleService";
import { IApplicationState } from "src/state";
import { fetchProfileForCommercial, fetchProfileRequest } from "src/state/profile/actions";
import { IProfile } from "src/state/profile/types";

interface IPropsFromDispatch {
  fetchProfileRequest: typeof fetchProfileRequest;
  fetchProfileForCommercial: typeof fetchProfileForCommercial;
  children: React.ReactNode;
}

interface IPropsFromState {
  profile: IProfile;
  isLoading: boolean;
  isCommercial: boolean;
}

export type IWithProfileProps = IPropsFromDispatch & IPropsFromState;

export class WithProfile extends React.Component<IWithProfileProps> {
  public componentDidMount() {
    if (this.props.isCommercial) {
      this.props.fetchProfileForCommercial();
    } else {
      this.props.fetchProfileRequest();
    }
  }

  public render() {
    const { profile, isLoading } = this.props;

    if (isLoading) {
      return (
        <Spinner
          size={SpinnerSize.large}
          label={localeService.getText("Shared", "SpinnerPleaseWait")}
          ariaLive="assertive"
        />
      );
    }

    return profile ? this.props.children : null;
  }
}

const mapStateToProps = ({ profile, nonprofit }: IApplicationState) => ({
  isLoading: profile.isLoading ?? false,
  profile: profile.profile,
  isCommercial: nonprofit.isCommercial
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchProfileRequest: () => dispatch(fetchProfileRequest()),
  fetchProfileForCommercial: () => dispatch(fetchProfileForCommercial())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithProfile);
