import * as React from "react";
import { connect } from "react-redux";
import AzureRegistrationErrorMessageBanner from "src/components/offers/azurev2/AzureRegistrationErrorMessageBanner";
import { StatusIcon } from "src/components/offers/shared/StatusBanner";
import localeService from "src/dataServices/LocaleService";
import { IApplicationState } from "src/state";
import { AzureExpirationStatus, AzureRegistrationResultCode } from "src/state/offers/azure/types";
import AzureRegistrationStatusBanner from "./AzureRegistrationStatusBanner";
import { AzureTransactionCode } from "./AzureTransactionCodes";
import { getAzureExpirationStatus, getRemainingDaysForAzureExpiration } from "./Utils";
import { IFeatureFlagsState } from "src/state/feature-flags";

export interface IAzureStatusBannerProps {
  transactionCode?: string | null;
  statusId: number;
  startDate: string;
  endDate: string;
  accountOwnerLiveId: string;
  parentCallback?: any;
  featureFlags: IFeatureFlagsState;
}

export const AzureStatusBanner: React.FunctionComponent<IAzureStatusBannerProps> = props => {
  const { transactionCode, statusId, startDate, endDate, accountOwnerLiveId } = props;

  const hasTransactionCode = transactionCode != null && transactionCode != undefined;

  let banner = null;
  if (hasTransactionCode) {
    banner = getBannerFromTransactionCode(props);
    if (banner != null) {
      return banner;
    } else {
      props.parentCallback(false);
      banner = (
        <AzureRegistrationErrorMessageBanner
          statusTitle={localeService.getText("AzureV2", "GrantGenericErrorTitle")}
          statusDescription="GrantGenericErrorDescription"
        />
      );
    }
  } else {
    if (endDate === undefined && accountOwnerLiveId === undefined && statusId === undefined) {
      return null;
    } else if (accountOwnerLiveId === null) {
      return null;
    } else if (statusId === 99) {
        banner = (
          <AzureRegistrationStatusBanner
            iconType={StatusIcon.recent}
            statusTitle={localeService.getText("AzureV2", "GrantInitiatedBannerTitle")}
            statusDescription={"GrantInitiatedBannerDescriptionNew"}
          />
        );
    } else if (
      endDate != null &&
      endDate != undefined &&
      AzureExpirationStatus.Expiring === getAzureExpirationStatus(endDate)
    ) {
      const remainingDays = getRemainingDaysForAzureExpiration(endDate);
      banner = (
        <AzureRegistrationStatusBanner
          grantStartDate={localeService.getDateString(startDate)}
          grantEndDate={localeService.getDateString(endDate)}
          iconType={StatusIcon.recent}
          statusTitle={localeService.getText("AzureV2", "GrantExpiringBannerTitle")}
          statusDescription={"GrantExpiringBannerDescriptionNew"}
          statusDescriptionSubstitutions={{ remainingDays: String(remainingDays) }}
          footerText="GrantExpiringBannerFooter"
        />
      );
    } else if (
      endDate != null &&
      endDate != undefined &&
      AzureExpirationStatus.Expired === getAzureExpirationStatus(endDate)
    ) {
      banner = (
        <AzureRegistrationStatusBanner
          grantStartDate={localeService.getDateString(startDate)}
          grantEndDate={localeService.getDateString(endDate)}
          iconType={StatusIcon.warning}
          statusTitle={localeService.getText("AzureV2", "GrantExpiredBannerTitle")}
          statusDescription={"GrantExpiredBannerDescriptionNew"}
          footerText={"GrantExpiredBannerFooterNew"}
        />
      );
    } else {
      banner = (
        <AzureRegistrationStatusBanner
          grantStartDate={localeService.getDateString(props.startDate)}
          grantEndDate={localeService.getDateString(props.endDate)}
          iconType={StatusIcon.success}
          statusTitle={localeService.getText("AzureV2", "GrantClaimedBannerTitle")}
          statusDescription={"GrantClaimedBannerDescriptionNew"}
          footerText="GrantClaimedBannerFooter"
        />
      );
    }
  }

  return banner;
};

const getBannerFromTransactionCode = (props: IAzureStatusBannerProps) => {
  switch (props.transactionCode) {
    case AzureTransactionCode.AzureRenewalSuccess.toString():
      return (
        <AzureRegistrationStatusBanner
          grantStartDate={localeService.getDateString(props.startDate)}
          grantEndDate={localeService.getDateString(props.endDate)}
          iconType={StatusIcon.success}
          statusTitle={localeService.getText("AzureV2", "GrantRenewedBannerTitle")}
          statusDescription={"GrantRenewedBannerDescriptionNew"}
          footerText="GrantRenewedBannerFooter"
        />
      );
    case AzureRegistrationResultCode.AgentValidationUnderReview.toString():
      props.parentCallback(false);
      return (
        <AzureRegistrationStatusBanner
          iconType={StatusIcon.recent}
          statusTitle={localeService.getText("AzureV2", "GrantClaimReviewTitle")}
          statusDescription="GrantClaimReviewDescription"
          footerText="GrantClaimReviewFooter"
        />
      );
    case AzureRegistrationResultCode.AgentValidationError.toString():
      props.parentCallback(false);
      return (
        <AzureRegistrationErrorMessageBanner
          statusTitle={localeService.getText("AzureV2", "GrantClaimErrorTitle")}
          statusDescription="GrantClaimErrorDescription"
          messageCode="920-123190"
        />
      );
    case AzureRegistrationResultCode.AgentError.toString():
      props.parentCallback(false);
      return (
        <AzureRegistrationErrorMessageBanner
          statusTitle={localeService.getText("AzureV2", "GrantClaimErrorTitle")}
          statusDescription="GrantClaimErrorDescription"
          messageCode="715-123190"
        />
      );
    case AzureTransactionCode.AzureRenewalFailed.toString():
      props.parentCallback(false);
      return (
        <AzureRegistrationErrorMessageBanner
          statusTitle={localeService.getText("AzureV2", "GrantRenewedErrorTitle")}
          statusDescription="GrantRenewedErrorDescription"
        />
      );
    default:
      return null;
  }
};

const mapStateToProps = ({ offers, flags }: IApplicationState) => ({
  statusId: offers.azure.statusId,
  startDate: offers.azure.startDate,
  endDate: offers.azure.endDate,
  accountOwnerLiveId: offers.azure.accountOwnerLiveId,
  featureFlags: flags
});

export default connect(
  mapStateToProps,
  null
)(AzureStatusBanner);
