import { PrimaryButton } from "@fluentui/react/lib/Button";
import { Field, Form } from "react-final-form";
import Text from "src/components/text/Text";
import localeService from "src/dataServices/LocaleService";
import "./EmailVerificationForm.scss";

interface IEmailVerificationFormProps {
  onSubmit(event: any): void;
}

const required = (value: string) => (value ? undefined : "Required");
const EmailVerificationForm = ((props: IEmailVerificationFormProps) => {
  const { onSubmit } = props;
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
      <form
        id="email-verification-form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm2 ms-md4" />
          <div className="ms-Grid-col ms-sm8 ms-md4 verify-code-row">
            <Field
              placeholder={localeService.getText("AgentVerification", "EnterCode")}
              id="token"
              name="token"
              component="input"
              type="text"
              className="inputfield"
              autoFocus
              validate={required}
            />
          </div>
          <div className="ms-Grid-col ms-sm2 ms-md4" />
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md12" id="code-submit-button">
            <PrimaryButton type="submit">
              <Text category="AgentVerification" id="Verify" />
            </PrimaryButton>
          </div>
        </div>
      </form>
      )}
    />
  );
});

export default EmailVerificationForm;
