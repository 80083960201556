import "@ms-mwf/moray/dist/css/main.css";
import * as React from "react";
import localeService from "src/dataServices/LocaleService";
import StatusBanner, { StatusIcon } from "../shared/StatusBanner";

const AccountGuardErrorMessageBanner = () => {
  return <StatusBanner iconType={StatusIcon.error} text={localeService.getText("AccountGuardV2", "ClaimError")} />;
};

export default AccountGuardErrorMessageBanner;
