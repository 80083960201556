import { IApiResponse } from "src/common/ApiResponse";
import { IQuerySubmissionFields } from "../state/query/types";
import { callApi } from "./SharedServiceFunctions";

export class QueryService {
  private API_URL = process.env.REACT_APP_CS_API_BASEURL;
  public async SubmitQueryRequest(formValues: IQuerySubmissionFields): Promise<IApiResponse<{}>> {
    const response = await callApi<{}>(
      `${this.API_URL}/customersupport/queryalchemy`,
      JSON.stringify(formValues),
      "POST"
    );
    return response;
  }
}

const queryService = new QueryService();
export default queryService;
