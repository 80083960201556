import { action } from "typesafe-actions";
import { INonprofitMiniProfile, NonprofitActionTypes, NonprofitError } from "./types";

export const fetchNonprofitRequest = (retryAttempts: number = 0) =>
  action(NonprofitActionTypes.FETCH_NONPROFIT_REQUEST, retryAttempts);
export const fetchNonprofitSuccess = (np: INonprofitMiniProfile) =>
  action(NonprofitActionTypes.FETCH_NONPROFIT_SUCCESS, np);
export const nonprofitError = (error: NonprofitError, nonprofitId?: string) =>
  action(NonprofitActionTypes.NONPROFIT_ERROR, { error, nonprofitId });
export const fetchNonprofitFromGraphSuccess = (np: INonprofitMiniProfile) =>
  action(NonprofitActionTypes.FETCH_NONPROFIT_FROM_GRAPH_SUCCESS, np);

export const initiateContactPermissionSubmission = (value: boolean | undefined) =>
  action(NonprofitActionTypes.INITIATE_CONTACT_PERMISSION_SUBMISSION, value);
export const ContactPermissionSuccess = () => action(NonprofitActionTypes.CONTACT_PERMISSION_SUCCESS);
export const ContactPermissionError = () => action(NonprofitActionTypes.CONTACT_PERMISSION_ERROR);
