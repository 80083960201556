import "@ms-mwf/moray/dist/css/main.css";
import * as React from "react";
import Text from "src/components/text/Text";
import localeService from "src/dataServices/LocaleService";

const JoinCommunity = () => (
  <div id="linkedin-join-community" className="material-surface p-5 text-center" data-mount="click-group">
    <h4 className="text-style h4 text-center mb-3">{localeService.getText("LinkedInV2", "JoinCommunityTitle")}</h4>
    <Text category="LinkedInV2" id="JoinCommunityDescription" />
  </div>
);

export default JoinCommunity;
