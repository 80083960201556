import ActionStepCard from "src/components/offers/shared/ActionStepCard";
import ActionSteps from "src/components/offers/shared/ActionSteps";
import localeService from "src/dataServices/LocaleService";
import { scrollOptions } from "src/common/utilities/Utilities";

export interface ICloudSteps {
  plansElement: HTMLElement | null;
  springVersionEnabled: boolean;
  setRef?: (el: HTMLDivElement | null) => void;
}

export default function CloudSteps(props: ICloudSteps) {
  const step1ImagePath = "hub-redesign/ActionStep_1_3x.png";
  const step2ImagePath = "hub-redesign/ActionStep_2_3x.png";
  const step3ImagePath = "hub-redesign/ActionStep_3_3x.png";
  const { plansElement, springVersionEnabled } = props;
  const headerText = localeService.getText("CloudV2", "HowToTakeAdvantageBannerText");

  return (
    <div
      className="cloud-steps"
      ref={(el) => {
        if (props.setRef !== undefined) {
          props.setRef(el);
        }
      }}
    >
      <ActionSteps
        stepsHeader={headerText}
        step1Card={
          <ActionStepCard
            stepIconImagePath={step1ImagePath}
            cardTextCategory="CloudV2"
            cardTitle="SelectIndustrySolutionStepTitle"
            cardDescription="SelectIndustrySolutionStepDescription"
            ctaText="SelectIndustrySolutionStepButton"
            onClickAction={() => {
              window.open(localeService.getText("Urls", "CloudViewGuideTour"));
            }}
          />
        }
        step2Card={
          <ActionStepCard
            stepIconImagePath={step2ImagePath}
            cardTextCategory="CloudV2"
            cardTitle="SelectPlanStepTitle"
            cardDescription="SelectPlanStepDescription"
            ctaText="SelectPlansStepButton"
            onClickAction={() => {
              if (plansElement !== null) {
                plansElement.scrollIntoView(scrollOptions);
              }
            }}
          />
        }
        step3Card={
          <ActionStepCard
            stepIconImagePath={step3ImagePath}
            cardTextCategory="CloudV2"
            cardTitle="ReadySolutionCenterStepTitle"
            cardDescription="ReadySolutionCenterStepDescription"
            ctaText="ReadySolutionCentersStepButton"
            onClickAction={() => {
              window.open(
                localeService.getText(
                  "Urls",
                  springVersionEnabled ? "CloudSolutionCenterSpring" : "CloudSolutionCenter"
                )
              );
            }}
          />
        }
      />
    </div>
  );
}
