import { Label } from '@fluentui/react';
import React from 'react';
import Text from 'src/components/text/Text';
import localeService from 'src/dataServices/LocaleService';
import { IProfile } from 'src/state/profile/types';
import './ProfileForm.scss';
import { getProfileOrganizationSizeText } from './ProfileV2SharedComponents';
import RegistrationIdentifiersView from './RegistrationIdentifiersView';
import './OrgInfoSection.scss';
import { IFeatureFlagsState } from 'src/state/feature-flags';
import { IApplicationState } from 'src/state';
import { connect } from 'react-redux';

interface IPropsFromState {
  featureFlags: IFeatureFlagsState;
}

interface IPropsFromParent {
  profile: IProfile;
}

export type IProfileProps = IPropsFromParent & IPropsFromState;

const renderField = (
  labelCategory: string,
  labelId: string,
  fieldName: string,
  fieldValue: string,
  prefix?: string
) => {
  return (
    <div>
      <Label tabIndex={0} htmlFor={fieldName}>
        <Text category={labelCategory} id={labelId} />
      </Label>
      {fieldValue && (
        <p className="org-info-text">
          <b>
            {prefix}
            {fieldValue}
          </b>
        </p>
      )}
    </div>
  );
};

const OrgInfoSection2View = ({ profile, featureFlags }: IProfileProps): JSX.Element => {
  return (
    <div id="org-info">
      <div id="profile-org-info-description">
        <p>{localeService.getText('ProfileV2', 'OrgInformationDescription')}</p>
      </div>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          {renderField('ProfileV2', 'OrganizationName', 'name', profile.name)}
        </div>
      </div>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          {renderField('ProfileV2', 'StreetAddress1', 'address.addressLine1', profile.address.addressLine1)}
        </div>
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          {renderField('ProfileV2', 'StreetAddress2', 'address.addressOther', profile.address.addressOther)}
        </div>
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          {renderField('Profile', 'City', 'address.city', profile.address.city)}
        </div>
      </div>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          {renderField('Profile', 'State', 'address.stateRegion', profile.address.stateRegion)}
        </div>
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          {renderField('Profile', 'Postcode', 'address.postalCode', profile.address.postalCode)}
        </div>
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          {renderField('Profile', 'Country', 'address.country', profile.address.country)}
        </div>
      </div>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          {renderField('Profile', 'Website', 'websiteUrl', profile.websiteUrl)}
        </div>
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          {renderField('ProfileV2', 'PhoneNumber', 'address.phone', profile.address.phone)}
        </div>
      </div>
      <RegistrationIdentifiersView registrationIdentifiers={profile.registrationIdentifiers} />
    </div>
  );
};

const mapStateToProps = ({ flags }: IApplicationState) => ({
  featureFlags: flags
});

export default connect(mapStateToProps)(OrgInfoSection2View);
