export const enum LocalizationActionTypes {
  LOCALIZATION_INIT_REQUEST = "@@localization/LOCALIZATION_INIT_REQUEST",
  SET_VALUE_IN_DICTIONARY = "@@localization/SET_VALUE_IN_DICTIONARY",
  ADD_TO_DICTIONARY_SUCCESS = "@@localization/ADD_TO_DICTIONARY_SUCCESS"
}

export interface ILocale {
  key: string;
  content: { [key: string]: any };
}

export interface IRouteDetail {
  isDisallowed: boolean;
  routeName?: string;
}

export interface RouteSettings {
  isValidForRoute: boolean;
  isValidForApp: boolean;
  currentRoute: string | undefined;
}

export interface LocaleDetail {
  locale: string | undefined;
  isValidForRoute: boolean;
  currentPath?: string;
}

export interface ILocalizationState {
  isLoading?: boolean;
  currentLocale: string;
  isSuccessfulRetrival?: boolean;
  defaultAppLanguage: string;
}

export enum ILocalizationResolverReason {
  FetchFromLocalStorage = "FetchFromLocalStorage",
  FetchFromCDN = "FetchFromCDN",
  None = "None"
}

export interface ILocalizationResolver {
  resolvedReason: ILocalizationResolverReason;
  languageToFetch: string;
}

export interface ILanguage {
  locale: string;
  language: string;
  country: string;
  disallowedRoutes?: string;
}
