import "@ms-mwf/moray/dist/css/main.css";
import * as React from "react";
import { useEffect, useState } from "react";
import Text from "src/components/text/Text";
import localeService from "src/dataServices/LocaleService";
import "./ActionStepCard.scss";

const imagesUrl = process.env.REACT_APP_IMAGES_URL;

export interface IActionStepCardProps {
  stepIconImagePath: string;
  cardTextCategory: string;
  cardTitle: string;
  cardDescription: string;
  ctaText: string;
  ctaDisabled?: boolean;
  // onClickLink will be given priority if both onClickLink and onClickAction are sent
  onClickLink?: string;
  onClickAction?: () => any;
  maxReloadsAfterSpinner?: number;
}

const ActionStepCard: React.FunctionComponent<IActionStepCardProps> = props => {
  const isOnClickLink = props.onClickLink != null && props.onClickLink != undefined;
  const isOnClickAction = props.onClickAction != null && props.onClickAction != undefined;
  const iconImageUrl = `${imagesUrl}/${props.stepIconImagePath}`;
  const isCtaDisabled = props.ctaDisabled === null || props.ctaDisabled === undefined ? false : props.ctaDisabled;

  /*
  Spinner Handling Logic
  */
  const maxReloads = props.maxReloadsAfterSpinner || 0;
  const loadCounterKey = "offer.action_step_card.load_count_after_spinner";
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    if (spinner) {
      const loadCountAfterSpinner = Number(window.localStorage.getItem(loadCounterKey));
      if (loadCountAfterSpinner > maxReloads) {
        setSpinner(false);
      } else {
        window.localStorage.setItem(loadCounterKey, String(loadCountAfterSpinner + 1));
      }
    }
  });

  const addSpinner = () => {
    window.localStorage.setItem(loadCounterKey, String(1));
    setSpinner(true);
  };

  return (
    <div id="stepcard" className="action-step-card card">
      <div className="icon-container-card card align-items-center justify-content-center" data-mount="click-group">
        <picture>
          <img id="iconimage" src={iconImageUrl} className="card-img" alt="" />
        </picture>
      </div>
      <div className="step-card-body card-body">
        <h3 id="title" className="h3">
          {localeService.getText(props.cardTextCategory, props.cardTitle)}
        </h3>
      </div>
      <div className="step-card-body card-body">
        <p id="description" className="base">
          <Text category={props.cardTextCategory} id={props.cardDescription} />
        </p>
      </div>
      <div className="action-link link-group">
        {!spinner && isOnClickLink && (
          <a
            id={`ctalink_${props.cardTitle}`}
            href={props.onClickLink}
            className={(isCtaDisabled ? "cta-disabled-style " : "cta-enabled-style ") + "cta"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Text category={props.cardTextCategory} id={props.ctaText} />
            <span className="glyph" aria-hidden="true"></span>
          </a>
        )}
        {!spinner && !isOnClickLink && isOnClickAction && (
          <a
            href="src/components/offers/shared"
            id={`ctaaction_${props.cardTitle}`}
            className={(isCtaDisabled ? "cta-disabled-style " : "cta-enabled-style ") + "cta"}
            onClick={e => {
              e.preventDefault();
              props.onClickAction?.();
              addSpinner();
            }}
          >
            <Text category={props.cardTextCategory} id={props.ctaText} />
            <span className="glyph" aria-hidden="true"></span>
          </a>
        )}
        {spinner && (
          <div id="spinner1" className="spinner " role="alert" aria-live="assertive">
            <span className="sr-only">Content loading...</span>
            <span role="presentation" className="spinner-dot spinner-dot-1" />
            <span role="presentation" className="spinner-dot spinner-dot-2" />
            <span role="presentation" className="spinner-dot spinner-dot-3" />
            <span role="presentation" className="spinner-dot spinner-dot-4" />
            <span role="presentation" className="spinner-dot spinner-dot-5" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ActionStepCard;
