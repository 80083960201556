import localeService from "src/dataServices/LocaleService";
import { IAddressResources } from "src/state/profile/types";
import FieldValidationError from "./FieldValidationError";

export const inputTextFieldComponent = (field: any) => {
  const {
    input,
    type,
    id,
    name,
    label,
    ariaLabel,
    required,
    readOnly,
    placeholder,
    maxLength,
    autoFocus,
    disabled,
    meta: { error, touched, active },
    hasServerValidationError
  } = field;
  return (
    <div>
      <input
        {...input}
        type={type}
        id={id}
        name={name}
        label={label}
        aria-label={ariaLabel}
        required={required}
        placeholder={placeholder}
        readOnly={readOnly}
        autoFocus={autoFocus}
        maxLength={maxLength}
        disabled={disabled}
        className="form-control"
      />
      {(hasServerValidationError || (touched && error)) && <FieldValidationError localeErrorTextId={error} />}
    </div>
  );
};

export const inputTextAreaComponent = (field: any) => {
  const {
    input,
    id,
    name,
    label,
    rows,
    required,
    readOnly,
    placeholder,
    maxLength,
    autoFocus,
    disabled,
    meta: { error, touched }
  } = field;
  return (
    <div>
      <textarea
        {...input}
        id={id}
        name={name}
        label={label}
        rows={rows}
        required={required}
        placeholder={placeholder}
        readOnly={readOnly}
        autoFocus={autoFocus}
        maxLength={maxLength}
        disabled={disabled}
        className="form-control"
      />
      {touched && error && <FieldValidationError localeErrorTextId={error} />}
    </div>
  );
};

export const composeRequiredFieldValidator = (name: string) => (value: string) =>
  value && value.trim() ? undefined : name + "RequiredError";

export const requiredFieldValidator = (value: string, name: string) =>
  value && value.trim() ? undefined : name + "RequiredError";

export const emailRequiredFieldValidator = (value: string) => (value && value.trim() ? undefined : "EmailRequiredError");

export const emailAddressValidator = (value: string) =>
  value && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/i.test(value) ? "EmailInvalidError" : undefined;

const websiteUrlValidator = (value: string, name: string) =>
  value && !/(https?:\/\/)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i.test(value)
    ? name + "InvalidError"
    : undefined;

export const composeWebsiteUrlValidator = (name: string) => (value: string) =>
  websiteUrlValidator(value, name)

const phoneNumberValidator = (value: string, name: string) =>
  value && !/^\d*$/i.test(value) ? name + "InvalidError" : undefined

export const composePhoneNumberValidator = (name: string) => (value: string) =>
  phoneNumberValidator(value, name)

const NumberOnlyValidator = (value: string, name: string) =>
  value && !/^\d*$/i.test(value) ? name + "InvalidError" : undefined

export const composeNumberOnlyValidator = (name: string) => (value: string) =>
  NumberOnlyValidator(value, name)

const maxLengthValidator = (value: string | any[], name: string) => {
  let max = 100;
  if (name === "operatingBudget") {
    max = 12;
  }
  return value && value.length > max ? name + "InvalidError" : undefined;
};

export const composeMaxLengthValidator = (name: string) => (value: string) =>
  maxLengthValidator(value, name)

export const getProfileOrganizationSizeOptions = () => {
  return [
    { key: "", text: localeService.getText("Profile", "OrganizationSizeOptionEmpty") },
    { key: "0-50", text: localeService.getText("Profile", "OrganizationSizeOption0-50") },
    { key: "51-100", text: localeService.getText("Profile", "OrganizationSizeOption51-100") },
    { key: "101-250", text: localeService.getText("Profile", "OrganizationSizeOption101-250") },
    { key: "251-500", text: localeService.getText("Profile", "OrganizationSizeOption251-500") },
    { key: "501-1000", text: localeService.getText("Profile", "OrganizationSizeOption501-1000") },
    { key: "1000+", text: localeService.getText("Profile", "OrganizationSizeOption1000+") }
  ];
};

export const getProfileOrganizationSizeText = (orgSize: string): string => {
  let text = "-";

  if (!orgSize) {
    return text;
  }

  const options = getProfileOrganizationSizeOptions();
  if (!options || options.length === 0) {
    return text;
  }

  const option = options.find(option => option.key === orgSize);
  text = option ? option.text || "-" : "-";

  return text;
};

const legalIdentifierValidators: Record<any, (value: string, formValues: any) => string | undefined> = {};
export const getLegalIdentifierValidator = (countryCode: string, identifierKey: string, regexPattern: RegExp) => {
  const key = countryCode + "_" + identifierKey;
  let val = legalIdentifierValidators[key];
  if (val === undefined || val == null) {
    val = (value: string, formValues: any) => validateLegalIdentifier(regexPattern, value);
    legalIdentifierValidators[key] = val;
  }
  return val;
};

export const regulatoryBodyRequiredFieldValidator = (value: string) =>
  value && value.trim() ? undefined : "regulatoryBodyRequiredError";
export const legalIdentifierRequiredFieldValidator = (value: string) =>
  value && value.trim() ? undefined : "legalIdentifierRequiredError";

const validateLegalIdentifier = (regexPattern: RegExp, identifier: string) => {
  if (identifier == undefined || identifier == null || identifier.length == 0) {
    return "legalIdentifierRequiredError";
  }

  if (regexPattern === undefined || regexPattern == null) {
    return undefined;
  }

  if (!regexPattern.test(identifier)) {
    return "legalIdentifierInvalidError";
  }

  return undefined;
};

const postalCodeValidators: Record<any, (value: string, formValues: any) => string | undefined> = {};
export const getPostalCodeValidator = (addressResources: IAddressResources) => {
  const key = addressResources !== undefined ? addressResources.countryCode : undefined;
  let val = postalCodeValidators[key as string];
  if (val === undefined || val == null) {
    val = (value: any, formValues: any) => postalCodeValidator(value, addressResources);
    postalCodeValidators[key as string] = val;
  }
  return val;
};

export const postalCodeValidator = (value: any, addressResources: IAddressResources) => {
  const postalCodeValidator =
    addressResources && addressResources.addressFields
      ? addressResources.addressFields.filter(x => x.fieldID === "PostalCode")[0]
      : undefined;
  const postalCodeRegex =
    postalCodeValidator && postalCodeValidator.fieldRegex ? RegExp(postalCodeValidator.fieldRegex) : undefined;
  const postalCodeExample =
    postalCodeValidator && postalCodeValidator.fieldExample ? postalCodeValidator.fieldExample : undefined;

  if (postalCodeRegex && !postalCodeRegex.test(value)) {
    return localeService
      .getText("ProfileV2", "address.postalCodeInvalidError")
      ?.replace("{postalCodeExample}", postalCodeExample as any);
  }

  return undefined;
};

export const composeValidators = (validators: any[] | undefined) => (value: any) => {
  if (!validators) {
    return validators;
  }
  
  return validators.reduce((error, validator) => error || validator(value), undefined)
}
