import { ILocale, LocalizationActionTypes } from "src/state/localization/types";
import { action } from "typesafe-actions";

export const localizationRequest = (value?: string) => action(LocalizationActionTypes.LOCALIZATION_INIT_REQUEST, value);

export const addContentToDictionary = (content: ILocale) =>
  action(LocalizationActionTypes.SET_VALUE_IN_DICTIONARY, content);

export const addedToDictionarySuccess = (value: string) =>
  action(LocalizationActionTypes.ADD_TO_DICTIONARY_SUCCESS, value);
