import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { logService, telemetryService } from 'npo-common';
import * as React from 'react';
import { connect } from 'react-redux';
import AppContext from 'src/components/app/AppContext';
import AgentError from 'src/components/error/AgentError';
import NotAdmin from 'src/components/error/NotAdmin';
import NotFound from 'src/components/error/NotFound';
import TenantError from 'src/components/error/TenantError';
import TenantNotReady from 'src/components/error/TenantNotReady';
import Unknown from 'src/components/error/Unknown';
import { IApplicationState } from 'src/state';
import { NonprofitError } from 'src/state/nonprofit/types';
import './ErrorPage.scss';

export interface IErrorPageProps {
  appContext: { [key: string]: any } | undefined;
  error: NonprofitError | undefined;
  nonprofitId?: string;
}

export class ErrorPage extends React.Component<IErrorPageProps> {
  public render() {
    const { error, appContext, nonprofitId } = this.props;

    logService.logInfo('ErrorPage shown', {
      error: error,
      appContext: appContext,
      nonprofitId: nonprofitId
    });

    if (error === undefined) {
      return <Unknown />;
    }

    const renderAppContext = () => {
      // don't render AppContext for NotAdminV2 and AgentError
      if (error !== NonprofitError.NotAdmin && error !== NonprofitError.AgentError) {
        return <AppContext />;
      } else {
        return null;
      }
    };

    return (
      <section id="error-page">
        {{
          [NonprofitError.AgentError]: <AgentError sessionId={appContext ? appContext.SessionId : undefined} />,
          [NonprofitError.TenantError]: <TenantError nonprofitId={nonprofitId} />,
          [NonprofitError.NotAdmin]: <NotAdmin />,
          [NonprofitError.NotFound]: <NotFound />,
          [NonprofitError.TenantNotReady]: <TenantNotReady />,
          [NonprofitError.Unknown]: <Unknown />,
          undefined: <Unknown />
        }[error] || <div>There is no error.</div>}
        {renderAppContext()}
      </section>
    );
  }
}

const mapStateToProps = ({ nonprofit, layout }: IApplicationState) => ({
  appContext: layout.appContext,
  error: nonprofit.error,
  nonprofitId: nonprofit.nonprofitId
});

export default connect(mapStateToProps)(withAITracking(telemetryService.aiReactPlugin, ErrorPage));
