import Text from "src/components/text/Text";
import localeService from "src/dataServices/LocaleService";

const AccountGuardFreeSecurityAssessmentBanner = () => {
  return (
    <section>
      <div className="card d-block mx-ng mx-md-0 mt-5 mb-5">
        <div id="account-guard-free-security-assessment-section" className="row no-gutters material-surface ml-5 mr-5">
          <div className="col-md-6">
            <picture>
              <source
                srcSet={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/AccountGuard/AcctGrd-Banner1-1920.jpg`}
                media="(min-width: 1400px)"
              />
              <source
                srcSet={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/AccountGuard/AcctGrd-Banner1-1400.jpg`}
                media="(min-width: 1084px)"
              />
              <source
                srcSet={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/AccountGuard/AcctGrd-Banner1-1084.jpg`}
                media="(min-width: 860px)"
              />
              <source
                srcSet={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/AccountGuard/AcctGrd-Banner1-860.jpg`}
                media="(min-width: 540px)"
              />
              <img
                src={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/AccountGuard/AcctGrd-Banner1-540.jpg`}
                className="card-img img-object-cover"
                alt={localeService.getText("AccountGuardV2", "FreeSecurityAssessmentPlaceholder")}
              />
            </picture>
          </div>
          <div className="d-flex col-md">
            <div className="card-body align-self-center p-4 p-md-5">
              <h2 id="account-guard-free-security-assessment-title" className="text-style h3">
                <Text category="AccountGuardV2" id="FreeSecurityAssessmentTitle" />
              </h2>
              <div id="account-guard-free-security-assessment-description" className="Detail-text mb-3 mt-2">
                <p>
                  <Text category="AccountGuardV2" id="FreeSecurityAssessmentDescription" />
                </p>
              </div>
              <a
                id="account-guard-free-security-assessment-button"
                href={localeService.getText("Urls", "SecurityAssessment")}
                className="cta"
                target="_blank"
                rel="noopener noreferrer"
              >
                {localeService.getText("AccountGuardV2", "FreeSecurityAssessmentButton")}
                <span className="glyph" aria-hidden="true"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccountGuardFreeSecurityAssessmentBanner;
