import {
  OfferCard,
  OfferCardButton,
  OfferCardContainer,
  OfferCardFauxColumn,
  OfferCardFeatureHeading,
  OfferCardLargeTitle,
  OfferCardSection,
  OfferCardSubHeading,
  OfferCardText
} from "src/components/moray/MorayOfferCard";
import { getLocalizedPath } from "src/components/utilities/Localization/Utils";
import localeService from "src/dataServices/LocaleService";

interface IIndustrySolutionPlansProps {
  setRef?: (el: HTMLElement | null) => void;
}

const IndustrySolutionPlans = (props: IIndustrySolutionPlansProps) => (
  <div
    className="industry-solution-plans"
    ref={el => {
      if (props.setRef !== undefined) {
        props.setRef(el);
      }
    }}
  >
    <OfferCardFeatureHeading>
      <h4 id={"IndustrySolutionHeaderTitle"} className="font-weight-semibold h4">
        {localeService.getText("CloudV2", "IndustrySolutionHeaderTitle")}
      </h4>
    </OfferCardFeatureHeading>
    <OfferCardContainer paddingBottom={false}>
      <OfferCard>
        <OfferCardSection id="offer-card-title">
          <OfferCardLargeTitle id={"Card1Title"} title={localeService.getText("CloudV2", "IndustrySolutionTitle1")} />
        </OfferCardSection>
        <OfferCardFauxColumn />
        <OfferCardSection id="offer-card-heading">
          <OfferCardSubHeading
            id={"Card1SubHeading1"}
            subHeading={localeService.getText("CloudV2", "IndustrySolutionFundraisingEngagement")}
          />
          <OfferCardText id={"Card1Text1"} text={localeService.getText("CloudV2", "IndustrySolutionAzureAppService")} />
          <OfferCardButton
            id="Card1Button1"
            buttonText={localeService.getText("CloudV2", "IndustrySolutionBuyNow")}
            buttonLink={getLocalizedPath(
              localeService.getText("CloudV2", "IndustrySolutionFundraisingEngagementLink1")
            )}
          />
        </OfferCardSection>
        <OfferCardFauxColumn />
        <OfferCardSection id="offer-card-heading">
          <OfferCardSubHeading
            id={"Card1SubHeading2"}
            subHeading={localeService.getText("CloudV2", "IndustrySolutionFundraisingEngagement")}
          />
          <OfferCardText
            id={"Card1Text2"}
            text={localeService.getText("CloudV2", "IndustrySolutionDynamics365SalesEnterprise")}
          />
          <OfferCardButton
            id="Card1Button2"
            buttonText={localeService.getText("CloudV2", "IndustrySolutionBuyNow")}
            buttonLink={localeService.getText("CloudV2", "IndustrySolutionFundraisingEngagementLink2")}
          />
        </OfferCardSection>
        <OfferCardFauxColumn />
        <OfferCardSection id="offer-card-heading">
          <OfferCardSubHeading
            id={"Card1SubHeading3"}
            subHeading={localeService.getText("CloudV2", "IndustrySolutionMarketingJourneys")}
          />
          <OfferCardText
            id={"Card1Text3"}
            text={localeService.getText("CloudV2", "IndustrySolutionDynamics365Marketing")}
          />
          <OfferCardButton
            id="Card1Button3"
            buttonText={localeService.getText("CloudV2", "IndustrySolutionBuyNow")}
            buttonLink={localeService.getText("CloudV2", "IndustrySolutionMarketingJourneysLink")}
          />
        </OfferCardSection>
      </OfferCard>
      <OfferCard>
        <OfferCardSection id="offer-card-title">
          <OfferCardLargeTitle id={"Card2Title"} title={localeService.getText("CloudV2", "IndustrySolutionTitle2")} />
        </OfferCardSection>
        <OfferCardFauxColumn />
        <OfferCardSection id="offer-card-heading">
          <OfferCardSubHeading
            id={"Card2SubHeading1"}
            subHeading={localeService.getText("CloudV2", "IndustrySolutionVolunteerManagement")}
          />
          <OfferCardText id={"Card2Text1"} text={localeService.getText("CloudV2", "IndustrySolutionPowerApps")} />
          <OfferCardButton
            id="Card2Button1"
            buttonText={localeService.getText("CloudV2", "IndustrySolutionBuyNow")}
            buttonLink={localeService.getText("CloudV2", "IndustrySolutionVolunteerManagementLink")}
          />
        </OfferCardSection>
        <OfferCardFauxColumn />
        <OfferCardSection id="offer-card-heading">
          <OfferCardSubHeading
            id={"Card2SubHeading2"}
            subHeading={localeService.getText("CloudV2", "IndustrySolutionVolunteerEngagement")}
          />
          <OfferCardText id={"Card2Text2"} text={localeService.getText("CloudV2", "IndustrySolutionPowerApps")} />
          <OfferCardButton
            id="Card2Button2"
            buttonText={localeService.getText("CloudV2", "IndustrySolutionBuyNow")}
            buttonLink={localeService.getText("CloudV2", "IndustrySolutionVolunteerEngagementLink1")}
          />
        </OfferCardSection>
        <OfferCardFauxColumn />
        <OfferCardSection id="offer-card-heading">
          <OfferCardSubHeading
            id={"Card2SubHeading3"}
            subHeading={localeService.getText("CloudV2", "IndustrySolutionVolunteerEngagement")}
          />
          <OfferCardText id={"Card2Text3"} text={localeService.getText("CloudV2", "IndustrySolutionPowerPortal")} />
          <OfferCardButton
            id="Card2Button3"
            buttonText={localeService.getText("CloudV2", "IndustrySolutionBuyNow")}
            buttonLink={localeService.getText("CloudV2", "IndustrySolutionVolunteerEngagementLink2")}
          />
        </OfferCardSection>
        <OfferCardFauxColumn />
        <OfferCardSection id="offer-card-heading">
          <OfferCardSubHeading
            id={"Card2SubHeading4"}
            subHeading={localeService.getText("CloudV2", "IndustrySolutionManageVolunteersTemplate")}
          />
          <OfferCardText id={"Card2Text4"} text={localeService.getText("CloudV2", "IndustrySolutionMicrosoftTeams")} />
          <OfferCardButton
            id="Card2Button4"
            buttonText={localeService.getText("CloudV2", "IndustrySolutionBuyNow")}
            buttonLink={localeService.getText("CloudV2", "IndustrySolutionManageVolunteersTemplateLink")}
          />
        </OfferCardSection>
        <OfferCardFauxColumn />
        <OfferCardSection id="offer-card-heading">
          <OfferCardSubHeading
            id={"Card2SubHeading5"}
            subHeading={localeService.getText("CloudV2", "IndustrySolutionVolunteerCenterTemplate")}
          />
          <OfferCardText id={"Card2Text5"} text={localeService.getText("CloudV2", "IndustrySolutionSharepoint")} />
          <OfferCardButton
            id="Card2Button5"
            buttonText={localeService.getText("CloudV2", "IndustrySolutionBuyNow")}
            buttonLink={localeService.getText("CloudV2", "IndustrySolutionVolunteerCenterTemplateLink")}
          />
        </OfferCardSection>
      </OfferCard>
      <OfferCard>
        <OfferCardSection id="offer-card-title">
          <OfferCardLargeTitle id={"Card3Title"} title={localeService.getText("CloudV2", "IndustrySolutionTitle3")} />
        </OfferCardSection>
        <OfferCardFauxColumn />
        <OfferCardSection id="offer-card-heading">
          <OfferCardSubHeading
            id={"Card3SubHeading1"}
            subHeading={localeService.getText("CloudV2", "IndustrySolutionProgramImpactDashboard")}
          />
          <OfferCardText id={"Card3Text1"} text={localeService.getText("CloudV2", "IndustrySolutionPowerBI")} />
          <OfferCardButton
            id="Card3Button1"
            buttonText={localeService.getText("CloudV2", "IndustrySolutionBuyNow")}
            buttonLink={localeService.getText("CloudV2", "IndustrySolutionProgramImpactDashboardLink")}
          />
        </OfferCardSection>
      </OfferCard>
    </OfferCardContainer>
  </div>
);

export default IndustrySolutionPlans;
