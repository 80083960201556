import { action } from "typesafe-actions";
import { LayoutActionTypes } from "./types";

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions
//
// Remember, you can also pass parameters into an action creator. Make sure to
// type them properly as well.
export const fetchError = (message: string) => action(LayoutActionTypes.FETCH_ERROR, message);
export const setAppContext = (context: { [key: string]: any }) => action(LayoutActionTypes.SET_APP_CONTEXT, context);
