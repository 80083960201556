import "@ms-mwf/moray/dist/css/main.css";
import * as React from "react";
import Text from "src/components/text/Text";
import localeService from "src/dataServices/LocaleService";
import StatusBanner, { StatusIcon } from "../shared/StatusBanner";

const AccountGuardSuccessBanner = () => {
  return (
    <StatusBanner iconType={StatusIcon.success} text={localeService.getText("AccountGuardV2", "ClaimSuccessTitle")}>
      <p id="description" className="mb-0">
        <Text category="AccountGuardV2" id="ClaimSuccessDescription" />
      </p>
    </StatusBanner>
  );
};

export default AccountGuardSuccessBanner;
