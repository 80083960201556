import { Spinner, SpinnerSize } from "@fluentui/react";
import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import FollowUsFooter from "src/components/followUsFooter/FollowUsFooter";
import localeService from "src/dataServices/LocaleService";
import { IApplicationState } from "src/state";
import { IFeatureFlagsState } from "src/state/feature-flags";
import ActionStepCard from "../shared/ActionStepCard";
import ActionSteps from "../shared/ActionSteps";
import { ContactUsSection } from "../shared/ContactUsSection";
import InfoSection from "../shared/InfoSection";
import NavigationBar from "../shared/NavigationBar";
import PartnersBanner from "../shared/PartnersBanner";
import PricingDisclaimer from "../shared/PricingDisclaimer";
import StandardTrainingSection from "../shared/StandardTrainingSection";
import AzureGrantDisclaimer from "./AzureGrantDisclaimer";
import AzureIntroBanner from "./AzureIntroBanner";
import AzureRegistrationV2 from "./AzureRegistrationV2";
import AzureStatusBanner from "./AzureStatusBanner";
import AzureUnclaimedBanner from "./AzureUnclaimedBanner";

interface IPropsFromState {
  token: string;
  accountOwnerLiveId: string;
  startDate: string;
  endDate: string;
  registrationInfoError: boolean;
  statusId: number;
  featureFlags: IFeatureFlagsState;
}

export type AzureProps = IPropsFromState;

export const AzureV2: React.FunctionComponent<AzureProps> = props => {
  const { token, accountOwnerLiveId, startDate, endDate, registrationInfoError, statusId, featureFlags } = props;
  const [registrationCode, setRegistrationCode] = useState(null);
  const [isShowRegistration, setIsShowRegistration] = useState(true);
  const [offerSection, setOfferSection] = useState<HTMLElement | null>(null);
  const [trainingSection, setTrainingSection] = useState<HTMLElement | null>(null);

  const step2ImagePath = "hub-redesign/ActionStep_2_3x.png";
  const step3ImagePath = "hub-redesign/ActionStep_3_3x.png";

  return (
    <section>
      <AzureStatusBanner transactionCode={registrationCode} parentCallback={setIsShowRegistration} />
      
      {accountOwnerLiveId === undefined && registrationInfoError === undefined ? (
        <Spinner
          size={SpinnerSize.large}
          label={localeService.getText("Shared", "SpinnerPleaseWait")}
          ariaLive="assertive"
        />
      ) : null}

      {registrationInfoError ? (
        <p>An error occurred when getting your existing registrations. Please refresh the page and try again.</p>
      ) : null}

      {accountOwnerLiveId === null && isShowRegistration && (
        <NavigationBar offersElement={offerSection} trainingElement={trainingSection} />
      )}
      {accountOwnerLiveId === null && isShowRegistration && <AzureUnclaimedBanner />}
      {accountOwnerLiveId === null && isShowRegistration && (
        <InfoSection
          category="AzureV2"
          imagePath1="optimize-costs.png"
          title1="OptimizeCosts"
          description1="OptimizeCostsDetail"
          imagePath2="work-from-anywhere.png"
          title2="WorkFromAnywhere"
          description2="WorkFromAnywhereDetail"
          imagePath3="stay-secure.png"
          title3="StaySecure"
          description3="StaySecureDetail"
        />
      )}
      {accountOwnerLiveId === null && isShowRegistration && <AzureIntroBanner setRef={setOfferSection} />}
      {isShowRegistration && (
        <ActionSteps
          stepsHeader={localeService.getText("AzureV2", "AzureClaimActionBannerText")}
          step1Card={
            <AzureRegistrationV2
              transactionCode={registrationCode}
              parentCallback={setRegistrationCode}
              token={token}
            />
          }
          step2Card={
            <ActionStepCard
              stepIconImagePath={step2ImagePath}
              cardTextCategory="AzureV2"
              cardTitle="SetupGrantActionStepTitle"
              cardDescription="SetupGrantActionStepDescription"
              ctaText="WatchNowText"
              onClickLink="https://aka.ms/Nonprofit_AzureCredits"
            />
          }
          step3Card={
            <ActionStepCard
              stepIconImagePath={step3ImagePath}
              cardTextCategory="AzureV2"
              cardTitle="ConnectSpecialistActionStepTitle"
              cardDescription="ConnectSpecialistActionStepDescription"
              ctaText="ScheduleNowText"
              onClickLink="https://nonprofits.tsi.microsoft.com/Azure-Onboarding-Concierge-and-Success-Center/"
            />
          }
        />
      )}
      {isShowRegistration && <AzureGrantDisclaimer />}
      <PartnersBanner />
      <StandardTrainingSection setRef={setTrainingSection} />
      <ContactUsSection />
      <PricingDisclaimer pricingDisclaimer1="PricingDisclaimerCommonParagraph" pricingDisclaimer2="AzureDisclaimer" />
      <FollowUsFooter />
    </section>
  );
};

const mapStateToProps = ({ offers, flags }: IApplicationState) => ({
  accountOwnerLiveId: offers.azure.accountOwnerLiveId,
  startDate: offers.azure.startDate,
  endDate: offers.azure.endDate,
  registrationInfoError: offers.azure.registrationInfoError,
  statusId: offers.azure.statusId,
  featureFlags: flags
});

export default connect(
  mapStateToProps,
  null
)(AzureV2);
