import * as React from "react";
import Text from "src/components/text/Text";
import TextLink from "src/components/text/TextLink";

export class Hardware extends React.Component {
  public render() {
    return (
      <section id="hardware">
        <p>
          <Text category="EligibilityChanges" id="Hardware" />
        </p>
        <p>
          <TextLink category="EligibilityChanges" id="Hardware_Line1" href="Surface" />
        </p>
        <p>
          <Text category="EligibilityChanges" id="Hardware_Line2" />
        </p>
      </section>
    );
  }
}

export default Hardware;
