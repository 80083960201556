import * as msal from "@azure/msal-browser";
import { ActionType, Behavior } from "@microsoft/1ds-analytics-web-js";
import * as jwt from "jsonwebtoken";
import moment from "moment";
import { logService, SloDefinitions, TelemetryEventType } from 'npo-common';
import { extractLocale, isNullOrUndefined } from 'src/common/utilities/Utilities';
import { isLocalizationEnabled } from 'src/components/utilities/Localization/Utils';
import { history } from 'src/index';

declare const window: any;

enum AuthenticatedStateType {
  SignedIn = '1',
  NotSignedIn = '3'
}

export class AuthenticationService {
  private debugMode: boolean;

  private msalInstance: msal.PublicClientApplication;
  private msalToken!: string;
  private msalAccount!: msal.AccountInfo | null;
  private sessionLocale: any;

  private msalEndSessionRequest: msal.EndSessionRequest = {};

  private msalLoginRequest: msal.RedirectRequest = {
    scopes: [process.env.REACT_APP_AAD_CLIENT_ID + "/.default"]
  };

  private msalSilentRequest: msal.SilentRequest = {
    scopes: [process.env.REACT_APP_AAD_CLIENT_ID + "/.default"],
    forceRefresh: false
  };

  constructor() {
    if (isLocalizationEnabled()) {
      const localeFromSessionStorage = sessionStorage.getItem("locale");
      this.sessionLocale =
        !isNullOrUndefined(localeFromSessionStorage) && localeFromSessionStorage?.length == 5
          ? extractLocale(localeFromSessionStorage)
          : null;
    }

    const msalConfig: msal.Configuration = {
      auth: {
        clientId: process.env.REACT_APP_AAD_CLIENT_ID ?? '',
        authority: `${process.env.REACT_APP_AAD_LOGIN_INSTANCE}common`,
        navigateToLoginRequestUrl: false,
        redirectUri: window.location.pathname.includes("/signup-complete")
          ? `${window.location.origin}/registration-complete`
          : window.location.origin,
        postLogoutRedirectUri: isLocalizationEnabled()
          ? window.location.origin + "/" + this.sessionLocale + "/getting-started"
          : window.location.origin + "/getting-started"
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: this.isEdgeIE()
      }
    };

    this.msalInstance = new msal.PublicClientApplication(msalConfig);

    var accounts = this.msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      // TODO: Add account selection logic but just pick the first account for now.
      this.msalAccount = accounts[0];
    }

    this.debugMode = process.env.REACT_APP_DEBUG_MODE === "1";

    this.loadMeControl();
  }

  public async login() {
    await this.msalInstance.handleRedirectPromise();

    if (window.location.pathname.includes('/convert-account')) {
      await this.msalInstance.loginRedirect({ ...this.msalLoginRequest, state: JSON.stringify({ href: '/new/profile' }) });
    } else {
      await this.msalInstance.loginRedirect(this.msalLoginRequest);
    }
  }

  public async logout() {
    sessionStorage.clear();

    if (this.msalAccount) {
      this.msalEndSessionRequest.account = this.msalAccount;
    }

    await this.msalInstance.handleRedirectPromise();

    await this.msalInstance.logoutRedirect(this.msalEndSessionRequest);
  }

  public handleLoginPromise(): Promise<boolean> {
    if (this.msalAccount) {
      return Promise.resolve(true);
    }

    return this.isAuthenticatedPromise().then((isAuthenticated: boolean) => {
      if (isAuthenticated) {
        return true;
      } else {
        return this.msalInstance.handleRedirectPromise().then((tokenResponse: msal.AuthenticationResult | null) => {
          if (tokenResponse != null) {
            this.msalToken = tokenResponse.accessToken;
            this.msalAccount = tokenResponse.account;

            window.analytics.capturePageAction(null, {
              behavior: Behavior.SIGNIN,
              actionType: ActionType.CLICKLEFT,
              contentTags: {
                authenticationPlatform: "AAD"
              }
            });

            logService.logEvent({
              Name: "Signin Attempt",
              EventType: TelemetryEventType.PrimaryWorkflow,
              SloId: SloDefinitions.NonprofitSignIn,
              ButtonName: "Signin-CTA",
              Result: "Success",
            });

            if (tokenResponse.state) {
              const tokenResponseState = JSON.parse(tokenResponse.state);

              if (tokenResponseState.href && !history.location.pathname.includes(tokenResponseState.href)) {
                history.push(tokenResponseState.href);
              }
            }

            this.loadMeControl();

            return true;
          }

          logService.logEvent({
            Name: "Signin Attempt",
            EventType: TelemetryEventType.PrimaryWorkflow,
            SloId: SloDefinitions.NonprofitSignIn,
            ButtonName: "Signin-CTA",
            Result: "Failure",
          });

          return false;
        });
      }
    });
  }

  public isAuthenticatedPromise(): Promise<boolean> {
    return this.getToken().then(tokenResponse => {
      return tokenResponse !== null;
    });
  }

  public async getToken(): Promise<string | null> {
    try {
      if (this.msalAccount) {
        this.msalSilentRequest.account = this.msalAccount;
      }

      const response = await this.msalInstance.acquireTokenSilent(this.msalSilentRequest);

      if(history.location.pathname.includes('/convert-account')) {
        history.push('/new/profile');
      }

      this.msalToken = response.accessToken;
      this.msalAccount = response.account;

      return response.accessToken;
    } catch (e) {
      logService.logInfo("Error acquiring token in AuthenticationService: " + e, e as any);

      if (e instanceof msal.InteractionRequiredAuthError) {
        this.msalInstance.acquireTokenRedirect(this.msalLoginRequest);
      }

      return null;
    }
  }

  public get cachedToken(): string {
    return this.msalToken;
  }

  public get accountInfo(): msal.AccountInfo | null {
    return this.msalAccount;
  }

  public getTokenExpirationInMinutes(token: string): number {
    if (!token) {
      return moment.duration(0).minutes();
    }
    const decodedToken = jwt.decode(token) as { [key: string]: any };
    if (!decodedToken.exp) {
      return moment.duration(0).minutes();
    }
    const expirationDate = new Date(decodedToken.exp * 1000);
    return moment.duration(moment(expirationDate).diff(new Date())).minutes();
  }

  public get isAuthenticated() {
    return Boolean(this.msalToken && this.accountInfo && this.getTokenExpirationInMinutes(this.msalToken) >= 0);
  }

  private isEdgeIE(): boolean {
    const ua = window.navigator.userAgent;

    const isMsie = ua.indexOf("MSIE ") > -1;
    const isMsie11 = ua.indexOf("Trident/") > -1;
    const isEdge = ua.indexOf("Edg/") > -1;

    return isMsie || isMsie11 || isEdge;
  }

  private parseMsalAccountForMeControl() {
    const ipdType = 'aad';

    if(this.msalAccount && this.msalAccount.name) {
      const [firstName = '', lastName = ''] = this.msalAccount.name.split(' ')

      return {
        idp: ipdType,
        firstName: firstName,
        lastName: lastName,
        memberName: this.msalAccount.username,
        cid: process.env.REACT_APP_AAD_CLIENT_ID ?? '',
        authenticatedState: AuthenticatedStateType.SignedIn
      }
    }

    return {
      idp: ipdType,
      firstName: '',
      lastName: '',
      memberName: '',
      cid: '',
      authenticatedState: AuthenticatedStateType.NotSignedIn
    };
  }

  public loadMeControl() {
    const shellOptions = {
      meControlOptions: {
        apiGeneration: 'GEN1',
        rpData: {
          msaInfo: {
            signInUrl: 'mecontrol/signin',
            signOutUrl: 'mecontrol/signout'
          }
        },
        userData: this.parseMsalAccountForMeControl(),
        extensibleLinks: []
      }
    };

    // If msCommonShell already exists, load it
    if (window.msCommonShell) {
      window.msCommonShell.load(shellOptions);
    }

    // If msCommonShell doesn't yet exist...
    else {
      // Load the me control once msCommonShell is ready
      window.onShellReadyToLoad = function () {
          window.onShellReadyToLoad = null;
          window.msCommonShell.load(shellOptions);
      };
    }
  }
}

const authenticationService = new AuthenticationService();
export default authenticationService;
