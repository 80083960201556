import { withAITracking } from "@microsoft/applicationinsights-react-js";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import LoadingButton from "../../utilities/LoadingButton";
import localeService from "src/dataServices/LocaleService";
import { telemetryService } from "npo-common";
import { IApplicationState } from "src/state";
import { initiateLogin } from "src/state/auth";

interface IPropsFromState {
  isSigningIn: boolean;
}
interface IPropsFromDispatch {
  initiateLogin: typeof initiateLogin;
}
type SigninProps = IPropsFromDispatch & IPropsFromState;
class SignInButton extends React.Component<SigninProps> {
  public handleKeyDown = (evt: { key: string }) => {
    if (evt.key === "Enter") {
      this.props.initiateLogin();
    }
  };
  public render() {
    const { initiateLogin, isSigningIn } = this.props;
    const signInText = localeService.getText("GettingStarted", "SignIn");
    return (
      <LoadingButton
        isExternal={true}
        tabIndex={0}
        onKeyDown={this.handleKeyDown}
        title={localeService.getText("GettingStarted", "LoginTitle")}
        onClick={initiateLogin}
        text={signInText}
        loading={isSigningIn}
        role="link"
        properties={{ ButtonName: "Signin-GettingStarted" }}
      />
    );
  }
}
const mapStateToProps = ({ auth }: IApplicationState): IPropsFromState => ({
  isSigningIn: auth.isSigningIn
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  initiateLogin: () => dispatch(initiateLogin())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAITracking(telemetryService.aiReactPlugin, SignInButton));
