import * as React from "react";
import localeService from "src/dataServices/LocaleService";
import NavigationCardContent from "../../../navigation-cards/NavigationCardContent";

const TrainingLiveTrainingCard = () => {
  return (
    <NavigationCardContent
      imgSrc={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/Training/schedule-live-training.jpg`}
      imgAlt={localeService.getText("TrainingV2", "TrainingSection2Title")}
      title={localeService.getText("TrainingV2", "TrainingSection2Title")}
      ctaText={localeService.getText("TrainingV2", "TrainingSection2CtaText")}
      ctaLink={`https://digitalreservations.microsoft.com/en-us/appointment/create/?storeid=9ca510a6-c9a3-ec11-b3fe-000d3a10148a&serviceid=94d064ca-3caa-ec11-9840-000d3a1f4123&icid=Workshops_SMBpersonaltraining`}
    >
      <p>{`${localeService.getText("TrainingV2", "TrainingSection2Description1")}`}</p>
      <p>{`${localeService.getText("TrainingV2", "TrainingSection2Description2")}`}</p>
    </NavigationCardContent>
  );
};

export default TrainingLiveTrainingCard;
