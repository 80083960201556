import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import FollowUsFooter from "src/components/followUsFooter/FollowUsFooter";
import { ActionHeadingBanner } from "src/components/shared/ActionHeadingBanner";
import { IApplicationState } from "src/state";
import { accountOfferRequest, fetchAccountGuardInfo } from "src/state/offers/accountguard/actions";
import { IClaimOfferRequestState } from "src/state/offers/accountguard/types";
import { ContactUsSection } from "../shared/ContactUsSection";
import NavigationBar from "../shared/NavigationBar";
import StandardTrainingSection from "../shared/StandardTrainingSection";
import AccountGuardActionSteps from "./AccountGuardActionSteps";
import AccountGuardFeatures from "./AccountGuardFeature";
import AccountGuardFreeSecurityAssessmentBanner from "./AccountGuardFreeSecurity";
import AccountGuardPolicyDisclaimer from "./AccountGuardPolicyDisclaimer";
import AccountGuardStatusBanner from "./AccountGuardStatusBanner";
import AccountGuardTopBanner from "./AccountGuardTopBanner";

interface IPropsFromState {
  accountGuard: IClaimOfferRequestState;
}

interface IPropsFromDispatch {
  fetchAccountGuardInfo: typeof fetchAccountGuardInfo;
  accountOfferRequest: typeof accountOfferRequest;
}

export type IAccountGuardV2Props = IPropsFromState & IPropsFromDispatch;

export const AccountGuardV2 = (props: IAccountGuardV2Props) => {
  const { accountGuard, fetchAccountGuardInfo, accountOfferRequest } = props;

  const [featureSection, setFeatureSection] = React.useState<HTMLElement | null>(null);
  const [trainingSection, setTrainingSection] = React.useState<HTMLElement | null>(null);
  const [stepsSection, setStepsSection] = React.useState<HTMLElement | null>(null);

  const success = accountGuard.claimStatus || accountGuard.alreadyClaim || accountGuard.sentSuccessfully;
  const failure = accountGuard.failedToSend || accountGuard.accountGuardInfoError || accountGuard.languageSupportError;
  const showElement = !failure && !success;

  React.useEffect(() => {
    fetchAccountGuardInfo();
  }, []);

  return (
    <section>
      <AccountGuardStatusBanner />
      {showElement && <NavigationBar offersElement={featureSection} trainingElement={trainingSection} />}
      {showElement && <AccountGuardTopBanner stepsElement={stepsSection} />}
      {showElement && <AccountGuardFeatures setRef={setFeatureSection} />}
      {showElement && <AccountGuardFreeSecurityAssessmentBanner />}
      {!failure && (
        <ActionHeadingBanner headingCatalog="AccountGuardV2" headingText="AccountGuardClaimActionBannerText" />
      )}
      {!failure && (
        <AccountGuardActionSteps
          setRef={setStepsSection}
          accountGuard={accountGuard}
          accountOfferRequest={accountOfferRequest}
        />
      )}
      {!failure && <AccountGuardPolicyDisclaimer />}
      <StandardTrainingSection setRef={setTrainingSection} />
      <ContactUsSection />
      <FollowUsFooter />
    </section>
  );
};

const mapStateToProps = ({ offers }: IApplicationState) => ({
  accountGuard: offers.accountGuardOffer
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAccountGuardInfo: () => dispatch(fetchAccountGuardInfo()),
  accountOfferRequest: () => dispatch(accountOfferRequest())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountGuardV2);
