import * as React from "react";
import FollowUsFooter from "src/components/followUsFooter/FollowUsFooter";
import localeService from "src/dataServices/LocaleService";
import InfoSection from "../shared/InfoSection";
import NavigationBar from "../shared/NavigationBar";
import JoinCommunity from "./JoinCommunity";
import { LinkedinContactUs } from "./LinkedinContactUs";
import LinkedInDiscountBanner from "./LinkedInDiscountBanner";
import LinkedInTopBanner from "./LinkedInTopBanner";
import LinkedinTrainingSection from "./LinkedinTrainingSection";
import "./LinkedInV2.scss";

const LinkedInV2 = () => {
  const [trainingSection, setTrainingSection] = React.useState<HTMLElement | null>(null);
  const [featureSection, setFeatureSection] = React.useState<HTMLElement | null>(null);

  return (
    <section>
      <NavigationBar
        offersTitle={localeService.getText("LinkedInV2", "NavigationBarOffersTitle")}
        offersElement={featureSection}
        trainingElement={trainingSection}
      />
      <LinkedInTopBanner />
      <InfoSection
        category="LinkedInV2"
        imagePath1="hub-redesign/LinkedIn/LinkedIn-Feature1-1920.jpg"
        title1="HireTheBrightestTitle"
        description1="HireTheBrightestDescription"
        imagePath2="hub-redesign/LinkedIn/LinkedIn-Feature2-1920.jpg"
        title2="EngageProfessionalsTitle"
        description2="EngageProfessionalsDescription"
        imagePath3="hub-redesign/LinkedIn/LinkedIn-Feature3-1920.jpg"
        title3="ElevateFundraisingTitle"
        description3="ElevateFundraisingDescription"
        imagePath4="hub-redesign/LinkedIn/LinkedIn-Feature4-1920.jpg"
        title4="UnlockPotentialTitle"
        description4="UnlockPotentialDescription"
        setRef={setFeatureSection}
      />
      <LinkedInDiscountBanner />
      <LinkedinTrainingSection setRef={setTrainingSection} />
      <JoinCommunity />
      <LinkedinContactUs />
      <FollowUsFooter />
    </section>
  );
};

export default LinkedInV2;
