import Text from "src/components/text/Text";
import localeService from "src/dataServices/LocaleService";
import { IProductCard } from "../../shared/ProductCard";
import ProductCardCarousel from "../../shared/ProductCardCarousel";
import { IFeatureFlagsState } from "src/state/feature-flags";
import { IApplicationState } from "src/state";
import { connect } from "react-redux";

interface IProductDemosAzureCardPropsFromState {
  featureFlags: IFeatureFlagsState;
}

const ProductDemosAzureCard = ({featureFlags} : IProductDemosAzureCardPropsFromState) => {
  const images = ["azure-icon.svg", "windows-virtual-desktop-icon.svg"];
  const productCardCarouselProps = localeService.getCategoryContent("TrainingV2")
    .ProductDemosSection1ProductCards as IProductCard[];

  for (let i = 0; i < images.length; i++) {
    productCardCarouselProps[i].imageSrc = `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/Training/${images[i]}`;
  }

  const numCardsPerSlide = { xl: 4, lg: 4, md: 3, sm: 2, xs: 1 };

  return (
    <>
      <div className="container pt-5 px-2 ml-4">
        <Text category="TrainingV2" id="ProductDemosSectionSubtitleNew" />
      </div>
      <ProductCardCarousel productCardCarouselProps={productCardCarouselProps} numCardsPerSlide={numCardsPerSlide} />
    </>
  );
};

const mapStateToProps = ({ flags }: IApplicationState) => ({
  featureFlags: flags
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDemosAzureCard);
