import { Spinner, SpinnerSize } from "@fluentui/react";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import localeService from "src/dataServices/LocaleService";
import { IApplicationState } from "src/state";
import { contactusResourcesRequest } from "src/state/contactus/actions";

interface IPropsFromDispatch {
  contactusResourcesRequest?: typeof contactusResourcesRequest;
}

interface IPropsFromParent {
  children: React.ReactNode;
}

interface IWithContactUsOwnProps {
  isLoadingResources: boolean;
}

export type IWithContactUsProps = IPropsFromDispatch & IWithContactUsOwnProps & IPropsFromParent;

export class WithContactUs extends React.Component<IWithContactUsProps> {
  public componentDidMount() {
    this.props.contactusResourcesRequest?.();
  }

  public render() {
    const { isLoadingResources } = this.props;

    if (isLoadingResources) {
      return (
        <Spinner
          size={SpinnerSize.large}
          label={localeService.getText("Shared", "SpinnerPleaseWait")}
          ariaLive="assertive"
        />
      );
    }
    return this.props.children;
  }
}

const mapStateToProps = ({ contactUs }: IApplicationState) => ({
  isLoadingResources: contactUs.isLoadingResources ?? false
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  contactusResourcesRequest: () => dispatch(contactusResourcesRequest())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithContactUs);
