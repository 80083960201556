import * as MorayComponents from '@ms-mwf/moray/dist/js/bundle';
import HelpersUtil from '@ms-mwf/moray/src/js/util/helpers';
import { useEffect, useRef, useState } from 'react';
import localeService from '../../dataServices/LocaleService';

export interface IMorayDropdownOption {
  value: string;
  displayName: string;
  children?: IMorayDropdownOption[];
}

export interface IMorayDropdownProps {
  optionName: string;
  options: IMorayDropdownOption[];
  onSelect: (value: IMorayDropdownOption | undefined) => void;
}

interface INestedMorayDropdownProps extends IMorayDropdownProps {
  optionName: string;
  options: IMorayDropdownOption[];
  onSelect: (value: IMorayDropdownOption | undefined) => void;
}

const NestedMorayDropdown = ({ optionName, options, onSelect }: INestedMorayDropdownProps) => {
  const element = useRef<HTMLButtonElement | null>(null);

  const [morayDropdownButton, setMorayDropdownButton] = useState<any>(null);

  useEffect(() => {
    setMorayDropdownButton(new MorayComponents.Dropdown({ el: element.current }));
  }, []);

  const ctrlId = `mwf${HelpersUtil.getUid()}`;

  const getSelectedOption = (value: string) => {
    return options.find((option) => option.value === value);
  };

  return (
    <>
      <button
        ref={(el) => (element.current = el)}
        className="d-flex dropdown-item "
        data-mount="dropdown"
        aria-expanded="false"
        aria-controls={ctrlId}
        type="button"
      >
        {optionName}
        <span className="ml-auto glyph" aria-hidden="true"></span>
      </button>
      <ul className="dropdown-menu " id={ctrlId}>
        {options.map((option, index) =>
          option.children ? (
            <li key={index} className="dropright">
              <NestedMorayDropdown
                optionName={option.displayName}
                options={option.children!}    
                onSelect={(value) => {
                  morayDropdownButton.hide();
                  onSelect(value);
                }}
              />
            </li>
          ) : (
            <li key={index}>
              <a
                className="dropdown-item"
                href='#'
                onClick={() => {
                  morayDropdownButton.hide();
                  onSelect(getSelectedOption(option.value));
                }}
              >
                {option.displayName}
              </a>
            </li>
          )
        )}
      </ul>
    </>
  );
};

const MorayDropdown = ({ optionName, options, onSelect }: IMorayDropdownProps) => {
  const element = useRef<HTMLButtonElement | null>(null);
  const ctrlId = `mwf${HelpersUtil.getUid()}`;
  const ctrlId2 = `mwf${HelpersUtil.getUid()}`;

  const [morayDropdownButton, setMorayDropdownButton] = useState<any>(null);

  const getSelectedOption = (value: string) => {
    return options.find((option) => option.value === value);
  };

  useEffect(() => {
    setMorayDropdownButton(new MorayComponents.Dropdown({ el: element.current }));
  }, []);

  return (
    <div className="dropdown ">
      <button
        role="combobox"
        aria-required
        ref={(el) => (element.current = el)}
        className="btn btn-faint-secondary dropdown-toggle "
        id={ctrlId}
        data-mount="dropdown"
        aria-expanded="false"
        aria-controls={ctrlId2}
        type="button"
        aria-label={localeService.getText('GettingStartedV2', 'EligibilityPreCheckAriaLabelSelectOneButton')}
      >
        {optionName}
        <span className="glyph" aria-hidden="true"></span>
      </button>
      <ul className="dropdown-menu dropdown-multi-level " id={ctrlId2} aria-labelledby={ctrlId}>
        {options.map((option, index) =>
          option.children ? (
            <li key={index} className="dropright d-flex">
              <NestedMorayDropdown
                optionName={option.displayName}
                options={option.children!}
                onSelect={(value) => {
                  morayDropdownButton.hide();
                  onSelect(value);
                }}
              />
            </li>
          ) : (
            <li key={index}>
              <a
                className="dropdown-item"
                role="button"
                href='#'
                onClick={() => {
                  morayDropdownButton.hide();
                  onSelect(getSelectedOption(option.value));
                }}
              >
                {option.displayName}
              </a>
            </li>
          )
        )}
      </ul>
    </div>
  );
};

export default MorayDropdown;
