import * as React from 'react';
import { connect } from 'react-redux';
import FollowUsFooter from 'src/components/followUsFooter/FollowUsFooter';
import localeService from 'src/dataServices/LocaleService';
import { IApplicationState } from 'src/state';
import { IFeatureFlagsState } from 'src/state/feature-flags';
import { ContactUsSection } from '../shared/ContactUsSection';
import InfoSection from '../shared/InfoSection';
import NavigationBar from '../shared/NavigationBar';
import PartnersBanner from '../shared/PartnersBanner';
import PricingDisclaimer from '../shared/PricingDisclaimer';
import StandardTrainingSection from '../shared/StandardTrainingSection';
import D365Offers from './D365Offers';
import D365Steps from './D365Steps';
import D365TopBanner from './D365TopBanner';
import './Dynamic365V2.scss';
import SolutionTabs from './SolutionTabs';

interface IDynamics365V2Props {
  featureFlags: IFeatureFlagsState;
}

const Dynamic365V2 = ({ featureFlags }: IDynamics365V2Props) => {
  const [offerSection, setOfferSection] = React.useState<HTMLDivElement | null>(null);
  const [solutionSection, setSolutionSection] = React.useState<HTMLElement | null>(null);
  const [trainingSection, setTrainingSection] = React.useState<HTMLElement | null>(null);

  return (
    <section>
      <NavigationBar
        offersTitle={localeService.getText('Dynamic365V2', 'NavigationBarOffersTitle')}
        offersElement={offerSection}
        trainingElement={trainingSection}
      />
      <D365TopBanner offersElement={offerSection} />
      <InfoSection
        category="Dynamic365V2"
        imagePath1="hub-redesign/D365-Feature1-1920.jpg"
        title1="IncreaseFundingTitle"
        description1="IncreaseFundingDetail"
        imagePath2="hub-redesign/D365-Feature2-1920.jpg"
        title2="MaximizeDeliveryTitle"
        description2="MaximizeDeliveryDetail"
        imagePath3="hub-redesign/D365-Feature3-1920.jpg"
        title3="MeasureImpactTitle"
        description3="MeasureImpactDetail"
      />
      <D365Offers setRef={setOfferSection} />
      <D365Steps solutionsElement={solutionSection} />
      <SolutionTabs setRef={setSolutionSection} />
      <PartnersBanner />
      <StandardTrainingSection setRef={setTrainingSection} />
      <ContactUsSection />
      <section>
          <PricingDisclaimer showAsterisk={false} pricingDisclaimer1={'PricingDisclaimerCommonParagraph'} />
      </section>
      <FollowUsFooter />
    </section>
  );
};

const mapStateToProps = ({ flags }: IApplicationState) => ({
  featureFlags: flags
});

export default connect(mapStateToProps)(Dynamic365V2);
