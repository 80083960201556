import { fetchLanguageDetail } from "src/components/utilities/Localization/Utils";
import { isNullOrUndefined } from "src/common/utilities/Utilities";
import { ILanguage } from "src/state/localization/types";
import { logService } from "npo-common";

export class LocalizationService {
  public getSupportedLanguages(): ILanguage[] {
    var localeInfo: any[] = [];
    var languageList: ILanguage[] = [];
    let jsonStr;

    const pathName = window.location.pathname.toLowerCase();
    try {
      jsonStr = process.env.REACT_APP_SUPPORTED_LANGUAGES ?? '';
      if (!isNullOrUndefined(jsonStr)) {
        localeInfo = JSON.parse(jsonStr) as ILanguage[];

        localeInfo.forEach(currentOption => {
          const isValidLocale = fetchLanguageDetail(pathName, currentOption.locale).isValidForRoute;
          if (isValidLocale) {
            languageList.push(currentOption);
          }
        });
      }
    } catch (error) {
      logService.logError("getSupportedLanguages error", { error });
    }
    if (isNullOrUndefined(localeInfo) || localeInfo.length === 0) {
      languageList.push(this.getDefaultSupportedLanguage());
    }

    return languageList;
  }

  private getDefaultSupportedLanguage(): ILanguage {
    return { locale: "en-us", language: "English", country: "United States" };
  }

  public getLangauge(locale: string): ILanguage | undefined {
    var languages = this.getSupportedLanguages();
    return languages.find(l => l.locale === locale);
  }
}

const localizationService = new LocalizationService();
export default localizationService;
