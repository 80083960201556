import * as React from "react";
import { Link } from "react-router-dom";
import localeService from "src/dataServices/LocaleService";
import { MenuItem } from "src/state/layout";
import {
  getLocalizedPath,
  getRouteSetting,
  isCurrentLocaleDefault,
  isLocalizationEnabled
} from "../utilities/Localization/Utils";
import "./NavigationMenu.scss";

export interface INavigationMenuProps {
  initialItems: MenuItem[];
  currentPath: string;
}

export interface INavigationState {
  isMobileMenuVisible: boolean;
  items: MenuItem[];
}

export default class NavigationMenu extends React.PureComponent<INavigationMenuProps, INavigationState> {
  constructor(props: INavigationMenuProps) {
    super(props);
    this.state = { isMobileMenuVisible: false, items: props.initialItems };
  }

  public render() {
    const { items, isMobileMenuVisible } = this.state;
    if (!items || items.length === 0) {
      return null;
    }

    const menuItems = (isMobile: boolean) =>
      items.map((menuItem, index) => {
        if (menuItem.submenu != null && menuItem.submenu.length > 0) {
          const className = menuItem.expanded ? "has-menu expanded" : "has-menu not-expanded";
          const ref = React.createRef<HTMLButtonElement>();

          return (
            <li className={className} key={"menu-" + index.toString()}>
              <div>
                <button
                  aria-label={menuItem.title}
                  role="button"
                  ref={ref}
                  onFocus={isMobile ? undefined : () => this.collapseAll()}
                  onKeyDown={e => this.handleKeyDown(e, index, ref, isMobile)}
                  onClick={() => this.handleSubMenuClick(index)}
                  aria-expanded={menuItem.expanded ? menuItem.expanded : false}
                >
                  {menuItem.title}
                </button>
                {this.subMenu(menuItem.submenu, index, ref, isMobile)}
              </div>
            </li>
          );
        } else {
          return <li key={"menu-" + index.toString()}>{this.itemLink(menuItem, isMobile)}</li>;
        }
      });

    return (
      <nav id="nonprofit-nav">
        <button
          className="menu-mobile"
          aria-label={localeService.getText("Shared", "Menu")}
          aria-expanded={this.state.isMobileMenuVisible}
          onClick={isMobileMenuVisible ? this.hideMenu.bind(this) : this.showMenu.bind(this)}
        />
        {isMobileMenuVisible && <ul className="menu-mobile">{menuItems(true)}</ul>}
        <ul>{menuItems(false)}</ul>
      </nav>
    );
  }

  private subMenu(
    submenu: MenuItem[],
    parentItemIndex: number,
    parentRef: React.RefObject<HTMLButtonElement>,
    isMobile: boolean = false
  ) {
    return (
      <ul>
        {submenu.map((menuItem, index) => (
          <li key={"submenu-" + index.toString()}>{this.itemLink(menuItem, isMobile, parentItemIndex, parentRef)}</li>
        ))}
      </ul>
    );
  }

  private itemLink(
    item: MenuItem,
    isMobile: boolean = false,
    parentItemIndex?: number,
    parentRef?: React.RefObject<HTMLButtonElement>
  ) {
    return item.url?.indexOf("http") === 0 ? (
      <a
        href={item.url}
        target={item.target}
        onFocus={() => (isMobile || parentItemIndex ? undefined : this.collapseAll())}
        onClick={() => this.hideMenu()}
        onKeyDown={parentItemIndex ? e => this.handleKeyDown(e, parentItemIndex, parentRef, isMobile) : undefined}
        aria-label={item.ariaLabel}
      >
        {item.title}
      </a>
    ) : (
      this.renderInternalLink(item, isMobile, parentItemIndex, parentRef)
    );
  }

  private renderInternalLink(
    item: MenuItem,
    isMobile: boolean,
    parentItemIndex: number | undefined,
    parentRef: React.RefObject<HTMLButtonElement> | undefined
  ) {
    const routeSetting = getRouteSetting(this.props.currentPath);
    const navBarItemRouteSetting = getRouteSetting(item.url);

    // if current path we are in is disallowed or nav bar item is disallowed, routes from here shouldn't use Link Component(Link component does route matching with Router, we have to move out of current locale path in this case).
    return isLocalizationEnabled() &&
      (routeSetting.isDisallowed || navBarItemRouteSetting.isDisallowed) &&
      !isCurrentLocaleDefault() ? (
      <a
        href={getLocalizedPath(item.url)}
        target={item.target}
        onFocus={() => (isMobile || parentItemIndex ? undefined : this.collapseAll())}
        onClick={() => {
          this.hideMenu();
          if (window.location.pathname === getLocalizedPath(item.url)) window.location.reload();
        }}
        onKeyDown={parentItemIndex ? e => this.handleKeyDown(e, parentItemIndex, parentRef, isMobile) : undefined}
        aria-label={item.ariaLabel}
      >
        {item.title}
      </a>
    ) : (
      <Link
        to={getLocalizedPath(item.url)}
        target={item.target}
        onFocus={() => (isMobile || parentItemIndex ? undefined : this.collapseAll())}
        onClick={() => {
          this.hideMenu();
          if (window.location.pathname === getLocalizedPath(item.url)) window.location.reload();
        }}
        onKeyDown={parentItemIndex ? e => this.handleKeyDown(e, parentItemIndex, parentRef, isMobile) : undefined}
        aria-label={item.ariaLabel}
      >
        {item.title}
      </Link>
    );
  }

  private showMenu() {
    this.collapseAll();
    this.setState({ isMobileMenuVisible: true });
  }

  private hideMenu() {
    this.collapseAll();
    this.setState({ isMobileMenuVisible: false });
  }

  private collapseAll() {
    const newItems = [...this.state.items];
    newItems.forEach(item => {
      item.expanded = false;
    });
    this.setState({ items: newItems });
  }

  private handleSubMenuClick(index: number) {
    const newItems = [...this.state.items];
    newItems[index].expanded = !newItems[index].expanded;
    this.setState({ items: newItems });
  }

  private handleKeyDown = (
    e: React.KeyboardEvent,
    index: number,
    ref: React.RefObject<HTMLButtonElement> | undefined,
    isMobile: boolean = false
  ) => {
    if (e.key === "Escape") {
      if (isMobile) {
        this.hideMenu();
      } else {
        const newItems = [...this.state.items];
        newItems[index].expanded = false;
        this.setState({ items: newItems });
        ref?.current?.focus();
      }
    }
  };
}
