import * as React from "react";
import Text from "src/components/text/Text";
import TextLink from "src/components/text/TextLink";

export interface IAgentErrorProps {
  sessionId: string;
}

const AgentError: React.FunctionComponent<IAgentErrorProps> = ({ sessionId }) => {
  return (
    <div className="agent-error">
      <h2>
        <Text category="ErrorAgent" id="Header" />
      </h2>
      <p>
        <TextLink
          category="ErrorAgent"
          id="SubText"
          href="ContactUsLoginIssue"
          substitutionData={{ SessionId: sessionId }}
        />
      </p>
    </div>
  );
};

export default AgentError;
