import { IApiResponse } from "src/common/ApiResponse";
import { httpRequest } from "src/common/HttpRequest";
import { BingAdsRegistrationInfo, ClaimAdsOfferRequest } from "src/state/offers/bing-ads/types";
import authenticationService from "./AuthenticationService";
import { callApi, extractData } from "./SharedServiceFunctions";

export class BingAdsService {
  private API_URL = process.env.REACT_APP_INTERNAL_API_BASEURL_V2;

  public async getAdsRegistration(nonprofitId: string): Promise<IApiResponse<BingAdsRegistrationInfo>> {
    return await callApi<BingAdsRegistrationInfo>(`${this.API_URL}/bingads/${nonprofitId}`);
  }

  public async claimAdsOffer(
    nonprofitId: string,
    request: ClaimAdsOfferRequest
  ): Promise<IApiResponse<BingAdsRegistrationInfo>> {
    const token = await authenticationService.getToken();
    const response = await httpRequest(
      `${this.API_URL}/bingads/${nonprofitId}`,
      token,
      JSON.stringify(request),
      "POST"
    );
    if (response.status) {
      return { status: response.status, value: await extractData<BingAdsRegistrationInfo>(response) };
    } else {
      throw new Error(response.statusText);
    }
  }
}

const bingAdsService = new BingAdsService();
export default bingAdsService;
