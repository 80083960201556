export const enum QueryActionTypes {
  QUERY_SUBMITREQUEST = "@@query/QUERY_SUBMITREQUEST",
  QUERY_ERROR = "@@query/QUERY_ERROR",
  QUERY_SUCCESS = "@@query/QUERY_SUCCESS",
  QUERY_SHOW_MORE_RESULTS = "@@query/QUERY_SHOW_MORE_RESULTS",
  QUERY_SHOW_FEWER_RESULTS = "@@query/QUERY_SHOW_FEWER_RESULTS"
}

export interface IQueryState {
  query: string;
  error: string;
  queryResponse: any;
  isSending: boolean;
  numberOfSolutions: any;
}

export interface IQuerySubmissionForm {
  query: any;
}
export interface IQuerySubmissionFields {
  Text: string;
  Locale: string;
  ClientContext: any;
}

export interface IQueryResponse {
  Theme: {
    Name: "sampleTheme";
    Confidence: 0.5;
  };
  Solutions: [
    {
      Title: "sampleTitle";
      Description: "sampleDescription";
      Confidence: 0.5;
      URI: "sampleURI";
      Source: "sampleSource";
    }
  ];
  Insights: [
    {
      Title: "sampleTitle";
      Description: "sampleDescription";
      TextFormat: "sampleTextFormat";
      PlainText: "samplePlainText";
      BucketId: "sampleBucketId";
      BucketName: "sampleBucketName";
      BucketConfidence: 0.5;
      BucketPercentile: 0.5;
      RuleId: 1;
      SolutionType: "CustomText";
    }
  ];
  CorrelationId: "sampleCorrelationId";
  DebugInfo: "sampleDebugInfo";
}
