import { Icon } from "@fluentui/react";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { BingAdsOfferId, isBingAdsOfferAvailable } from "src/components/offers/bingAds/BingAdsUtils";
import localeService from "src/dataServices/LocaleService";
import { IApplicationState } from "src/state";
import { IFeatureFlagsState } from "src/state/feature-flags";
import { MenuItem, SubmenuTypes } from "src/state/layout";
import { INonprofitMiniProfile, OfferId } from 'src/state/nonprofit';
import localeEnabledForV2, { LocaleConfigStrings } from '../../common/localeEnabledForV2';
import {
  getLocalizedPath,
  getRouteSetting,
  isCurrentLocaleDefault,
  isLocalizationEnabled
} from "../utilities/Localization/Utils";
import "./NavigationMenuV2.scss";

export interface INavigationMenuV2OwnProps {
  initialItems: MenuItem[];
  currentPath: string;
}

export interface INavigationMenuV2PropsFromState {
  featureFlags: IFeatureFlagsState;
  nonprofit: INonprofitMiniProfile;
}

export type INavigationMenuV2Props = INavigationMenuV2OwnProps & INavigationMenuV2PropsFromState;

export interface INavigationState {
  isMobileMenuVisible: boolean;
  items: MenuItem[];
}

export class NavigationMenuV2 extends React.PureComponent<INavigationMenuV2Props, INavigationState> {
  constructor(props: INavigationMenuV2Props) {
    super(props);
    this.state = { isMobileMenuVisible: false, items: props.initialItems };
  }

  public render() {
    const { items, isMobileMenuVisible } = this.state;
    if (!items || items.length === 0) {
      return null;
    }

    const menuItems = (isMobile: boolean) =>
      items.map((menuItem, index) => {
        const menuItemIsCards = menuItem.submenuType == SubmenuTypes.CARDS;
        if (menuItem.submenu != null && menuItem.submenu.length > 0) {
          const className = menuItem.expanded ? "has-menu expanded" : "has-menu not-expanded";
          const ref = React.createRef<HTMLButtonElement>();

          return (
            <li className={"nav-bar-li " + className} key={"menu-" + index.toString()}>
              <div className={"nav-bar-li-div"}>
                <button
                  className="nav-bar-li-div-button px-2"
                  aria-label={menuItem.title}
                  ref={ref}
                  onFocus={isMobile ? undefined : () => this.collapseAll()}
                  onKeyDown={e => this.handleKeyDown(e, index, ref, isMobile)}
                  onClick={() => this.handleSubMenuClick(index)}
                  aria-expanded={menuItem.expanded ? menuItem.expanded : false}
                >
                  {menuItem.title}
                </button>
                {menuItemIsCards && !isMobile ? this.cardsSubmenu(menuItem.submenu, index, ref, isMobile) : <></>}
                {!menuItemIsCards && !isMobile ? this.subMenu(menuItem.submenu, index, ref, isMobile) : <></>}
              </div>

              {isMobile ? this.subMenu(menuItem.submenu, index, ref, isMobile) : <></>}
            </li>
          );
        } else {
          return (
            <li className={"nav-bar-li px-2"} key={"menu-" + index.toString()}>
              {this.itemLink(menuItem, isMobile)}
            </li>
          );
        }
      });

    return (
      <div id="nav-bar" className="ml-2">
        <button
          className="menu-mobile"
          aria-label={localeService.getText("Shared", "Menu")}
          aria-expanded={this.state.isMobileMenuVisible}
          onClick={isMobileMenuVisible ? this.hideMenu.bind(this) : this.showMenu.bind(this)}
        />
        {isMobileMenuVisible && <ul className="nav-bar-ul menu-mobile">{menuItems(true)}</ul>}
        <ul className="nav-bar-ul menu-desktop">{menuItems(false)}</ul>
      </div>
    );
  }

  private subMenu(
    submenu: MenuItem[],
    parentItemIndex: number,
    parentRef: React.RefObject<HTMLButtonElement>,
    isMobile: boolean = false
  ) {
    const filteredSubmenu = submenu.filter(submenuItem => {
      if (
        submenuItem.id === BingAdsOfferId &&
        !isBingAdsOfferAvailable(this.props.nonprofit, this.props.featureFlags)
      ) {
        return false;
      }

      if (submenuItem.id === OfferId.community_training) {
        return localeEnabledForV2(LocaleConfigStrings.CommunityTraining);
      }

      if (submenuItem.id === "d365") {
        return false;
      }

      return true;
    });

    return (
      <ul className="ul-submenu">
        {filteredSubmenu.map((menuItem, index) => (
          <li key={"submenu-" + index.toString()}>{this.itemLink(menuItem, isMobile, parentItemIndex, parentRef)}</li>
        ))}
      </ul>
    );
  }

  private cardsSubmenu(
    submenu: MenuItem[],
    parentItemIndex: number,
    parentRef: React.RefObject<HTMLButtonElement>,
    isMobile: boolean = false
  ) {
    const filteredSubmenu = submenu.filter(submenuItem => {
      if (
        submenuItem.id === BingAdsOfferId &&
        !isBingAdsOfferAvailable(this.props.nonprofit, this.props.featureFlags)
      ) {
        return false;
      }

      if (submenuItem.id === "d365") {
        return false;
      }

      return true;
    });
    return (
      <ul className="cards-submenu-container">
        <div className="cards-submenu">
          {filteredSubmenu.map((menuItem, index) => (
            <li className="cards-list-item" key={"submenu-" + index.toString()}>
              {this.itemCard(menuItem, isMobile, parentItemIndex, parentRef)}
            </li>
          ))}
        </div>
      </ul>
    );
  }

  private itemLink(
    item: MenuItem,
    isMobile: boolean = false,
    parentItemIndex?: number,
    parentRef?: React.RefObject<HTMLButtonElement>
  ) {
    return item.url?.indexOf("http") === 0 ? (
      <div className="nav-bar-li-div">
        <a
          className="nav-bar-li-div-a"
          href={item.url}
          target={item.target}
          onFocus={() => (isMobile || parentItemIndex ? undefined : this.collapseAll())}
          onClick={() => this.hideMenu()}
          onKeyDown={parentItemIndex ? e => this.handleKeyDown(e, parentItemIndex, parentRef, isMobile) : undefined}
          aria-label={item.ariaLabel}
        >
          {item.title}
        </a>
      </div>
    ) : (
      this.renderInternalLink(item, isMobile, parentItemIndex, parentRef)
    );
  }

  private itemCard(
    item: MenuItem,
    isMobile: boolean = false,
    parentItemIndex?: number,
    parentRef?: React.RefObject<HTMLButtonElement>
  ) {
    return (
      <Link
        className="card"
        to={getLocalizedPath(item.url)}
        onKeyDown={parentItemIndex ? e => this.handleKeyDown(e, parentItemIndex, parentRef, isMobile) : undefined}
        onClick={() => {
          this.hideMenu();
        }}
      >
        <div className="card-top">
          <p>
            {item.id === "azure" ? (
              <b className="bold-card-text">{item.boldTextNew}</b>
            ) : (
              <b className="bold-card-text">{item.boldText}</b>
            )}
            {item.text}
          </p>
        </div>
        <Icon className="chevron-right" iconName="ChevronRightMed" />
        <div className="card-separater" />
        <p aria-label={item.ariaLabel} className={"card-title"}>
          {item.title}
        </p>
      </Link>
    );
  }

  private renderInternalLink(
    item: MenuItem,
    isMobile: boolean,
    parentItemIndex: number | undefined,
    parentRef: React.RefObject<HTMLButtonElement> | undefined
  ) {
    const routeSetting = getRouteSetting(this.props.currentPath);
    const navBarItemRouteSetting = getRouteSetting(item.url);

    // if current path we are in is disallowed or nav bar item is disallowed, routes from here shouldn't use Link Component(Link component does route matching with Router, we have to move out of current locale path in this case).
    return isLocalizationEnabled() &&
      (routeSetting.isDisallowed || navBarItemRouteSetting.isDisallowed) &&
      !isCurrentLocaleDefault() ? (
      <div className="nav-bar-li-div">
        <a
          className="nav-bar-li-div-a"
          href={getLocalizedPath(item.url)}
          target={item.target}
          onClick={() => {
            this.hideMenu();
            if (window.location.pathname === getLocalizedPath(item.url)) window.location.reload();
          }}
          onKeyDown={parentItemIndex ? e => this.handleKeyDown(e, parentItemIndex, parentRef, isMobile) : undefined}
          aria-label={item.ariaLabel}
        >
          {item.title}
        </a>
      </div>
    ) : (
      <div className="nav-bar-li-div">
        <Link
          className="nav-bar-li-div-a"
          to={getLocalizedPath(item.url)}
          target={item.target}
          onClick={() => {
            this.hideMenu();
            if (window.location.pathname === getLocalizedPath(item.url)) window.location.reload();
          }}
          onKeyDown={parentItemIndex ? e => this.handleKeyDown(e, parentItemIndex, parentRef, isMobile) : undefined}
          aria-label={item.ariaLabel}
        >
          {item.title}
        </Link>
      </div>
    );
  }

  private showMenu() {
    this.collapseAll();
    this.setState({ isMobileMenuVisible: true });
  }

  private hideMenu() {
    this.collapseAll();
    this.setState({ isMobileMenuVisible: false });
  }

  private collapseAll(args?: any) {
    const inNavBar =
      args && args.path && args.path.find((pathItem: any) => pathItem.className && pathItem.className.includes("expanded"));
    if (!inNavBar) {
      const newItems: MenuItem[] = Object.assign([], this.state.items);
      newItems.forEach(item => {
        item.expanded = false;
      });
      this.setState({ items: newItems });
    }
  }

  public componentDidMount() {
    document.addEventListener("mouseup", this.collapseAll.bind(this));
  }

  private handleSubMenuClick(index: number) {
    const newItems: MenuItem[] = Object.assign([], this.state.items);
    newItems[index].expanded = !newItems[index].expanded;
    this.setState({ items: newItems });
  }

  private handleKeyDown = (
    e: React.KeyboardEvent,
    index: number,
    ref: React.RefObject<HTMLButtonElement> | undefined,
    isMobile: boolean = false
  ) => {
    if (e.key === "Escape") {
      if (isMobile) {
        this.hideMenu();
      } else {
        const newItems: MenuItem[] = Object.assign([], this.state.items);
        newItems[index].expanded = false;
        this.setState({ items: newItems });
        ref?.current?.focus();
      }
    }
  };
}

const mapStateToProps = ({ flags, nonprofit }: IApplicationState) => ({
  featureFlags: flags,
  nonprofit: nonprofit.miniProfile
});

export default connect(mapStateToProps)(NavigationMenuV2);
