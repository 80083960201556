import "@ms-mwf/moray/dist/css/main.css";
import * as React from "react";
import Text from "src/components/text/Text";
import "./StepCard.scss";

const imagesUrl = process.env.REACT_APP_IMAGES_URL;

export interface IStepCardProps {
  stepIconImagePath: string;
  cardTitle: string;
  cardDescription: string;
  category: string;
  iconBackgroundColor: string;
  ctaText?: string;
  ctaLink?: string;
}

const StepCard: React.FunctionComponent<IStepCardProps> = props => {
  const iconImageUrl = `${imagesUrl}/${props.stepIconImagePath}`;
  const iconStyleOverride = {
    backgroundColor: props.iconBackgroundColor
  };
  const ctaAvailable =
    props.ctaText !== null && props.ctaText !== undefined && props.ctaLink !== null && props.ctaLink !== undefined;

  return (
    <div id="stepcard" className="step-card card mb-md-0 px-0 pb-0">
      <div
        className="icon-container-card card align-items-center justify-content-center"
        data-mount="click-group"
        style={iconStyleOverride}
      >
        <picture>
          <img src={iconImageUrl} className="card-img" alt="" />
        </picture>
      </div>
      <div className="step-card-body card-body mt-0 mb-0 pb-0">
        <h4 className="title-text h4">
          <Text category={props.category} id={props.cardTitle} />
        </h4>
      </div>
      <div className="step-card-body card-body mt-0 mb-0 pb-0">
        <p className="detail-text base">
          <Text category={props.category} id={props.cardDescription} />
        </p>
      </div>
      {ctaAvailable && (
        <div className="action-link link-group">
          <a
            id={"ctalink" + props.ctaText}
            href={props.ctaLink}
            className={" cta-style cta"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Text category={props.category} id={props.ctaText as string} />
            <span className="glyph" aria-hidden="true"></span>
          </a>
        </div>
      )}
    </div>
  );
};

export default StepCard;
