import { IApiResponse } from "src/common/ApiResponse";
import { httpRequest } from "src/common/HttpRequest";
import { AgentSettings, ITokenVerificationSubmission } from "src/state/agent-verification/types";
import authenticationService from "./AuthenticationService";

export class AgentSettingsService {
  private API_URL = process.env.REACT_APP_INTERNAL_API_BASEURL;

  public async getAgentSettings(): Promise<IApiResponse<AgentSettings>> {
    const response = await httpRequest(`${this.API_URL}/resource/agentsettings`);
    if (response.ok) {
      return { status: response.status, value: await response.json() };
    } else {
      throw new Error(response.statusText);
    }
  }

  public async checkAgentRegistration(sessionId: string): Promise<IApiResponse<boolean>> {
    try {
      const response = await httpRequest(`${this.API_URL}/agentstatus/registration`);
      return { status: response.status, value: response.status !== 400 };
    } catch (err) {
      return { status: undefined, value: true }; // even if the agent API call fails, we still want to let the agent through
    }
  }

  public async initiateResendEmail(): Promise<IApiResponse<{}>> {
    const token = await authenticationService.getToken();
    const response = await httpRequest(`${this.API_URL}/agentstatus/verify/resend`, token, null, "PUT");
    return { status: response.status };
  }

  public async verifyAgentToken(tokenSubmitted: ITokenVerificationSubmission): Promise<IApiResponse<{}>> {
    const tokenValue = tokenSubmitted.token;
    const token = await authenticationService.getToken();
    const response = await httpRequest(`${this.API_URL}/agentstatus/verify/${tokenValue}`, token, null, "PUT");
    return { status: response.status };
  }
}

const agentSettingsService = new AgentSettingsService();
export default agentSettingsService;
