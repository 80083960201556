import { withAITracking } from "@microsoft/applicationinsights-react-js";
import * as React from "react";
import EnterpriseAgreement from "src/components/eligibilitychanges/EnterpriseAgreement";
import Hardware from "src/components/eligibilitychanges/Hardware";
import ItInfrastructure from "src/components/eligibilitychanges/ItInfrastructure";
import OnPremisesSoftware from "src/components/eligibilitychanges/OnPremisesSoftware";
import ProductivityApplications from "src/components/eligibilitychanges/ProductivityApplications";
import Text from "src/components/text/Text";
import { telemetryService } from "npo-common";
import "./EligibilityChangesPage.scss";

export class EligibilityChangesPage extends React.Component<{}> {
  public render() {
    return (
      <section id="eligibility-changes-page">
        <h1>
          <Text category="EligibilityChanges" id="Title" />
        </h1>
        <p>
          <Text category="EligibilityChanges" id="TitleDescription" />
        </p>
        <ProductivityApplications />
        <ItInfrastructure />
        <OnPremisesSoftware />
        <Hardware />
        <EnterpriseAgreement />
      </section>
    );
  }
}

export default withAITracking(telemetryService.aiReactPlugin, EligibilityChangesPage);
