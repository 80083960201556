import * as React from "react";
import { IResizableImage } from "src/components/moray/ResizeableImage";
import HighlightBanner from "src/components/offers/shared/HighlightBanner";
import localeService from "src/dataServices/LocaleService";

const PartnersV2TopBanner = () => {
  const resizableImageProps: IResizableImage = {
    root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/Partners`,
    imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/partners-banner.jpg`,
    imageClassName: "partners-top-banner-image card-img",
    pictureClassName: "partners-top-banner-container",
    imageAlt: localeService.getText("PartnersV2", "TopBannerImageTitle"),
    fileSize1920: "partners-banner.jpg",
    fileSize1400: "partners-banner.jpg",
    fileSize1084: "partners-banner.jpg",
    fileSize860: "partners-banner.jpg",
    fileSize540: "partners-banner.jpg",
    fileSize360: "partners-banner.jpg"
  };

  return (
    <HighlightBanner
      reverseTextColor={true}
      titleText={localeService.getText("PartnersV2", "TopBannerTitle")}
      resizableImageProps={resizableImageProps}
    />
  );
};

export default PartnersV2TopBanner;
