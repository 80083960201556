import localeService from "src/dataServices/LocaleService";

export const singleEmailIsValid = (value: string) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(value);
export const multiEmailIsValid = (value: string) =>
  /^(\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+(;\s*)?)*$/.test(value);
export const singleUrlIsValid = (value: string) =>
  /(https?:\/\/)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i.test(value);

export function isNullOrUndefined(obj: any): boolean {
  return obj === null || obj === undefined;
}

export interface IMobileIconProps {
  /**
   * set true if image has to be rendered. set false if Icon has to be rendered.
   */
  isImage: boolean;

  /**
   * set icon name or image src.
   */
  name: string;

  /**
   * Additional image props.
   */
  imageProps?: { width: number; height: number };
}

export function extractLocale(text: string | null | undefined): string | null {
  if (isNullOrUndefined(text)) {
    return null;
  }

  const pattern = "[a-zA-Z]{2}-[a-zA-Z]{2}";
  const matches = text?.match(pattern);
  return !isNullOrUndefined(matches) && matches && matches.length > 0 ? matches[0] : null;
}

export const encodeGetParams = (p: { [key: string]: any }): string =>
  Object.entries(p)
    .map(kv => kv.map(encodeURIComponent).join("="))
    .join("&");

export const getLocaleText = (category: string, id: string): string | null => {
  const localeContent = localeService.getLocaleContent();

  if (!localeContent) {
    return null;
  }

  const localeCategory = localeContent[category];
  const localeText = localeCategory ? localeCategory[id] : null;

  if (localeText) {
    return localeText;
  } else {
    return null;
  }
};

export const scrollOptions: ScrollOptions = {
  behavior: "smooth"
};
