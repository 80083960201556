import { Stack, StackItem } from '@fluentui/react';
import { Link } from '@fluentui/react/lib/Link';
import * as React from 'react';
import { connect } from 'react-redux';
import localeService from 'src/dataServices/LocaleService';
import { IApplicationState } from 'src/state';
import { HyperLink } from 'src/state/layout';
import { ILocalizationState } from 'src/state/localization/types';
import { isWcpCookieConsentRequired, manageConsent } from '../cookie-consent/WcpCookieConsent';
import { LanguageSelector } from '../localization/LanguageSelector';
import './Footer.scss';

interface IProps {
  localeState: ILocalizationState;
}
export class Footer extends React.Component<IProps> {
  public render() {
    const localeContent = localeService.getLocaleContent();
    if (!localeContent) {
      return null;
    }

    const links: HyperLink[] = localeContent.FooterLinks;

    return (
      <footer id={'nonprofit-footer'}>
        <Stack horizontal horizontalAlign="space-between" wrap={true}>
          <StackItem align="start">
            <LanguageSelector
              localeState={this.props.localeState}
              pathName={window.location.pathname}
              id="footer-language-selector"
            />
          </StackItem>
          <StackItem align="end">
            <ul>
              <li>
                {isWcpCookieConsentRequired() && (
                  <Link id="managecookielink" onClick={manageConsent}>
                    {localeService.getText('CookieConsent', 'ManageCookies')}
                  </Link>
                )}
              </li>
              {links.map((hyperLink, index) => {
                return (
                  <li key={'footer-' + index.toString()}>
                    <a target={hyperLink.target} href={hyperLink.url} aria-label={hyperLink.ariaLabel}>
                      {hyperLink.title}
                    </a>
                  </li>
                );
              })}
            </ul>
          </StackItem>
        </Stack>
      </footer>
    );
  }
}
const mapStateToProps = ({ locale }: IApplicationState) => ({
  localeState: locale
});

export default connect(mapStateToProps)(Footer);
