import { ActionType, Behavior } from '@microsoft/1ds-analytics-web-js';
import { logService } from 'npo-common';
import { useState } from 'react';
import MorayCollapse from 'src/components/moray/MorayCollapse';
import localeService from 'src/dataServices/LocaleService';
import { EligibilityPreCheckScn } from '../../common/Constants';
import EligibilityPreCheckForm, { EligibilityStatus } from './EligibilityPreCheckForm';

const EligibilityPreCheckSection = () => {
  const [isFormCollapsed, setIsFormCollapsed] = useState<boolean>(true);
  const [startButtonAlreadyClicked, setStartButtonAlreadyClicked] = useState<boolean>(false);

  const handleStartButtonClick = () => {
    // We only capture start the first time user clicks the start button
    if (!startButtonAlreadyClicked) {
      window.analytics.capturePageAction(null, {
        behavior: Behavior.STARTPROCESS,
        actionType: ActionType.CLICKLEFT,
        contentTags: {
          scn: EligibilityPreCheckScn,
          status: EligibilityStatus.NotCompleted
        }
      });
      logService.logInfo("EligibilityPreCheck: Started", { status: EligibilityStatus.NotCompleted });

      setStartButtonAlreadyClicked(true);
    }
  };

  return (
    <div className="container bg-white eligibility-precheck-container">
      <div className="py-3 px-0 px-md-4 px-lg-0">
        <h4 className="font-weight-semibold h4 py-2">
          {localeService.getText('GettingStartedV2', 'EligibilityPreCheckTitle')}
        </h4>
        <p>{localeService.getText('GettingStartedV2', 'EligibilityPreCheckDescription')}</p>
        <MorayCollapse
          title={localeService.getText('GettingStartedV2', 'EligibilityPreCheckStart')}
          description={<EligibilityPreCheckForm />}
          onHide={() => setIsFormCollapsed(true)}
          onShow={() => {
            handleStartButtonClick();
            setIsFormCollapsed(false);
          }}
          headerClassNames={isFormCollapsed ? '' : 'bg-gray-100'}
          titleButtonClassNames={`font-weight-semibold ${isFormCollapsed ? 'text-blue' : ''}`}
          hideOnMount={true}
        />
      </div>
    </div>
  );
};

export default EligibilityPreCheckSection;
