import * as React from "react";
import FollowUsFooter from "src/components/followUsFooter/FollowUsFooter";
import NavigationCard from "../../navigation-cards/NavigationCard";
import { ContactUsSection } from "../shared/ContactUsSection";
import ExploreAdoptionGuidesCard from "./content-cards/ExploreAdoptionGuidesCard";
import ExploreD365Card from "./content-cards/ExploreD365Card";
import ExploreM365Card from "./content-cards/ExploreM365Card";
import ExploreMicrosoftLearnCard from "./content-cards/ExploreMicrosoftLearnCard";
import ExploreTechCommunityCard from "./content-cards/ExploreTechCommunityCard";
import GuidedDemosAzureServicesCard from "./content-cards/GuidedDemosAzureServicesCard";
import GuidedDemosM365AdminCenterCard from "./content-cards/GuidedDemosM365AdminCenterCard";
import GuidedDemosSolutionCenterCard from "./content-cards/GuidedDemosSolutionCenterCard";
import ProductDemosAzureCard from "./content-cards/ProductDemosAzureCard";
import ProductDemosD365Card from "./content-cards/ProductDemosD365Card";
import ProductDemosM365Card from "./content-cards/ProductDemosM365Card";
import TrainingDigitalSkillsCard from "./content-cards/TrainingDigitalSkillsCard";
import TrainingLiveTrainingCard from "./content-cards/TrainingLiveTrainingCard";
import TrainingTopBanner from "./TrainingTopBanner";

const TrainingV2 = () => {
  return (
    <section>
      <TrainingTopBanner />
      <NavigationCard
        category="TrainingV2"
        navigationCardTitle="GuidedDemosTitle"
        navigationCardDescription="GuidedDemosDescription"
        navigationCardNeedMoreHelp="GuidedDemosNeedHelp"
        navigationCardTab1="GuidedDemosSection1"
        navigationCardTab2="GuidedDemosSection2"
        navigationCardTab3="GuidedDemosSection3"
        navigationCardContent1={<GuidedDemosM365AdminCenterCard />}
        navigationCardContent2={<GuidedDemosAzureServicesCard />}
        navigationCardContent3={<GuidedDemosSolutionCenterCard />}
      />
      <NavigationCard
        category="TrainingV2"
        navigationCardTitle="ProductDemosTitle"
        navigationCardDescription="ProductDemosDescription"
        navigationCardNeedMoreHelp="ProductDemosNeedHelp"
        navigationCardTab1="ProductDemosSection1"
        navigationCardTab2="ProductDemosSection2"
        navigationCardTab3="ProductDemosSection3"
        navigationCardContent1={<ProductDemosAzureCard />}
        navigationCardContent2={<ProductDemosM365Card />}
        navigationCardContent3={<ProductDemosD365Card />}
      />
      <NavigationCard
        category="TrainingV2"
        navigationCardTitle="TrainingTitle"
        navigationCardDescription="TrainingDescription"
        navigationCardTab1="TrainingSection1"
        navigationCardTab2="TrainingSection2"
        navigationCardContent1={<TrainingDigitalSkillsCard />}
        navigationCardContent2={<TrainingLiveTrainingCard />}
      />
      <NavigationCard
        category="TrainingV2"
        navigationCardTitle="ExploreTitle"
        navigationCardDescription="ExploreDescription"
        navigationCardTab1="ExploreSection1"
        navigationCardTab2="ExploreSection2"
        navigationCardTab3="ExploreSection3"
        navigationCardTab4="ExploreSection4"
        navigationCardTab5="ExploreSection5"
        navigationCardContent1={<ExploreMicrosoftLearnCard />}
        navigationCardContent2={<ExploreM365Card />}
        navigationCardContent3={<ExploreTechCommunityCard />}
        navigationCardContent4={<ExploreD365Card />}
        navigationCardContent5={<ExploreAdoptionGuidesCard />}
      />
      <ContactUsSection />
      <FollowUsFooter />
    </section>
  );
};

export default TrainingV2;
