export const enum MeoLocaleActionTypes {
  FETCH_MEOLOCALES = "@@meo-localization/FETCH_MEOLOCALES",
  SET_MEOLOCALES = "@@meo-localization/SET_MEOLOCALES"
}

export interface IMeoLocale {
  /**
   * The culture code for the locale. Ex: 'de-de'.
   */
  readonly cultureCode: string;

  /**
   * The english name of the locale. Ex: 'German (Germany)'.
   */
  readonly language: string;

  /**
   * The localized display name of the locale. Ex: 'Deutsch'.
   */
  readonly displayName: string;

  /**
   * The optional fallback culture code for the locale. Ex: 'en-us'.
   *
   * This is only used in exceptional cases where the locale is not supported directly
   * by MEO Localization and is instead rendered in a similar locale.
   */
  readonly fallbackCulture?: string;
}

export type MeoLocaleState = { 
  locales: IMeoLocale[],
  isLoading: boolean 
};

export const DefaultMeoLocale: IMeoLocale = {
  cultureCode: "en-us",
  language: "English (United States)",
  displayName: "English (United States)",
  fallbackCulture: undefined
};