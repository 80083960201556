import localeService from "src/dataServices/LocaleService";
import StepCard from "../shared/StepCard";
import TrainingSection from "../shared/TrainingSection";

interface ILinkedinTrainingSectionProps {
  setRef?: (el: HTMLDivElement | null) => void;
}

export default function LinkedinTrainingSection(props: ILinkedinTrainingSectionProps) {
  return (
    <div
      id="trainingSection"
      className="trainingSection"
      ref={el => {
        if (props.setRef !== undefined) {
          props.setRef(el);
        }
      }}
    >
      <TrainingSection
        sectionHeader={localeService.getText("LinkedInV2", "TrainingSectionTitle")}
        sectionContent={localeService.getText("LinkedInV2", "TrainingSectionDescription")}
        ctaLink={"https://nonprofit.linkedin.com/"}
        sectionBackgroundColor={"#ffffff"}
        stepCard1={
          <StepCard
            stepIconImagePath="hub-redesign/LinkedIn/LinkedinStep1.svg"
            cardTitle="TrainingSectionCard1Title"
            cardDescription="TrainingSectionCard1Details"
            category="LinkedInV2"
            iconBackgroundColor="#e6e6e6"
            ctaText="TrainingSectionCard1CtaText"
            ctaLink="https://nonprofit.linkedin.com/"
          />
        }
        stepCard2={
          <StepCard
            stepIconImagePath="hub-redesign/LinkedIn/LinkedinStep2.svg"
            cardTitle="TrainingSectionCard2Title"
            cardDescription="TrainingSectionCard2Details"
            category="LinkedInV2"
            iconBackgroundColor="#e6e6e6"
            ctaText="TrainingSectionCard2CtaText"
            ctaLink="https://nonprofit.linkedin.com/"
          />
        }
        stepCard3={
          <StepCard
            stepIconImagePath="hub-redesign/LinkedIn/LinkedinStep3.svg"
            cardTitle="TrainingSectionCard3Title"
            cardDescription="TrainingSectionCard3Details"
            category="LinkedInV2"
            iconBackgroundColor="#e6e6e6"
            ctaText="TrainingSectionCard3CtaText"
            ctaLink="https://nonprofit.linkedin.com/"
          />
        }
      />
    </div>
  );
}
