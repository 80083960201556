import * as React from "react";
import Text from "src/components/text/Text";
import TextLink from "src/components/text/TextLink";

export class OnPremisesSoftware extends React.Component {
  public render() {
    return (
      <section id="on-premises-software">
        <p>
          <Text category="EligibilityChanges" id="OnPremisesSoftware" />
        </p>
        <p>
          <TextLink category="EligibilityChanges" id="Software_Line1" href="Licensing" />
        </p>
        <p>
          <Text category="EligibilityChanges" id="Software_Line2" />
        </p>
      </section>
    );
  }
}

export default OnPremisesSoftware;
