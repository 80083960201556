import { Reducer } from "redux";
import { INonprofitMiniProfile, INonprofitState, NonprofitActionTypes } from "./types";

const initialState: INonprofitState = {
  error: undefined,
  isLoading: undefined,
  miniProfile: undefined as unknown as INonprofitMiniProfile,
  retryAttempts: 0,
  isCommercial: false,
  nonprofitId: undefined,
  contactPermission: undefined
};

const reducer: Reducer<INonprofitState> = (state = initialState, action) => {
  switch (action.type) {
    case NonprofitActionTypes.FETCH_NONPROFIT_REQUEST: {
      return { ...state, miniProfile: undefined as unknown as INonprofitMiniProfile, error: undefined, isLoading: true, retryAttempts: action.payload };
    }
    case NonprofitActionTypes.FETCH_NONPROFIT_SUCCESS: {
      return {
        ...state,
        miniProfile: action.payload,
        error: undefined,
        isLoading: false,
        retryAttempts: 0,
        isCommercial: false
      };
    }
    case NonprofitActionTypes.FETCH_NONPROFIT_FROM_GRAPH_SUCCESS: {
      return {
        ...state,
        miniProfile: action.payload,
        error: undefined,
        isLoading: false,
        retryAttempts: 0,
        isCommercial: true
      };
    }
    case NonprofitActionTypes.NONPROFIT_ERROR: {
      return {
        ...state,
        miniProfile: undefined as unknown as INonprofitMiniProfile,
        error: action.payload.error,
        isLoading: false,
        retryAttempts: 0,
        nonprofitId: action.payload.nonprofitId
      };
    }
    case NonprofitActionTypes.INITIATE_CONTACT_PERMISSION_SUBMISSION: {
      return {
        ...state,
        contactPermission: {
          contactPermissionInProcess: true,
          contactPermissionSuccess: false,
          contactPermissionError: false
        }
      };
    }
    case NonprofitActionTypes.CONTACT_PERMISSION_SUCCESS: {
      return {
        ...state,
        contactPermission: {
          contactPermissionInProcess: false,
          contactPermissionSuccess: true,
          contactPermissionError: false
        }
      };
    }
    case NonprofitActionTypes.CONTACT_PERMISSION_ERROR: {
      return {
        ...state,
        contactPermission: {
          contactPermissionInProcess: false,
          contactPermissionSuccess: false,
          contactPermissionError: true
        }
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as nonprofitReducer };

