import * as React from "react";
import Text from "src/components/text/Text";
import localeService from "src/dataServices/LocaleService";
import "./AzureOfferClaimed.scss";

interface IAzureSpecialOfferClaimedProps {
  endDate: string;
}

export const AzureSpecialOfferClaimed: React.FunctionComponent<IAzureSpecialOfferClaimedProps> = props => {
  const { endDate } = props;

  return (
    <section id="azure-special-offer-claimed">
      <p>
        <b>
          <Text category="Azure" id="EndDateLabel" />:
        </b>{" "}
        {localeService.getDateString(endDate)}
      </p>
    </section>
  );
};
