import { Label, TooltipHost } from "@fluentui/react";
import * as React from "react";
import { Field } from "react-final-form";
import Text from "src/components/text/Text";
import localeService from "src/dataServices/LocaleService";
import { IRegistrationIdentifier } from "src/state/nonprofit/types";
import { IRegulatoryBody } from "src/state/profile/types";
import FieldValidationError from "./FieldValidationError";
import { IconCallout } from "./IconCallout";
import {
  inputTextFieldComponent,
  legalIdentifierRequiredFieldValidator,
  regulatoryBodyRequiredFieldValidator
} from "./ProfileV2SharedComponents";

export interface IRegulatoryBodyExt extends IRegulatoryBody {
  label: string;
  tooltipText: string;
  validator: (value: any, formValues: any) => string | undefined;
}

export interface IRegistrationIdentifierProps {
  field: string;
  index: number;
  fields: any;
  countryCode: string;
  extRegulatoryBodies: Map<string, IRegulatoryBodyExt>;
  registrationIdentifiers: IRegistrationIdentifier[];
  required: boolean;
  profileForm: any;
}

const RegistrationIdentifier = (
  props: IRegistrationIdentifierProps
): JSX.Element => {
  const { profileForm: { change }, field, index, fields, extRegulatoryBodies, required, registrationIdentifiers } = props;
  const [identifierObj, setIdentifierObj] = React.useState<IRegulatoryBodyExt>();

  if (
    extRegulatoryBodies &&
    extRegulatoryBodies.size > 0 &&
    registrationIdentifiers &&
    registrationIdentifiers.length > index &&
    registrationIdentifiers[index]
  ) {
    const key = getIdentifierKey(extRegulatoryBodies, registrationIdentifiers[index].regulatoryBody);
    registrationIdentifiers[index].identifierKey = key as string;
  }

  React.useEffect(() => {
    if (identifierObj != null) {
      change(`${field}.regulatoryBody`, identifierObj.acronym);
      change(`${field}.regulatoryBodyAcronym`, identifierObj.regulatoryBodyAcronym);
      change(`${field}.identifierAcronym`, identifierObj.acronym);
    }
  }, [identifierObj]);

  return (
    <div className="ms-Grid-row mt-3" key={index}>
      <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-xl6">
        <Label tabIndex={0} id={`${field}.regulatoryBody`}>
          {required && index === 0 ? "* " : ""}
          <Text category="ProfileV2" id="RegistrationType" />
        </Label>
        {extRegulatoryBodies && extRegulatoryBodies.size > 0 ? (
          <Field
            id={`${field}.identifierKey`}
            name={`${field}.identifierKey`}
            required={required}
            validate={regulatoryBodyRequiredFieldValidator}
            component={renderDropdownField}
            props={{ extRegulatoryBodies: extRegulatoryBodies, setIdentifierObj: setIdentifierObj }}
          />
        ) : (
          <Field
            name={`${field}.regulatoryBody`}
            id={`${field}.regulatoryBody`}
            required={required}
            component={inputTextFieldComponent}
            type="text"
            validate={regulatoryBodyRequiredFieldValidator}
            maxLength={200}
            label={`Regulatory Body #${index + 1}`}
          />
        )}
      </div>
      <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 ms-xl4">
        <div className="d-flex justify-content-between">
          <div className="legal-identifier-label">
            <Label tabIndex={0} id={"label" + `${field}.legalIdentifier`}>
              {required && index === 0 ? "* " : ""}
              {getIdentifierLabel(identifierObj)}
            </Label>
          </div>
          <div className="legal-identifier-icon">
            <TooltipHost>
              <IconCallout content={getTooltipText(identifierObj)} />
            </TooltipHost>
          </div>
        </div>
        <Field
          name={`${field}.legalIdentifier`}
          id={`${field}.legalIdentifier`}
          required={required}
          component={inputTextFieldComponent}
          type="text"
          maxLength={200}
          label={`Legal Identifier #${index + 1}`}
          ariaLabel={`IRS EIN Number`}
          validate={getValidator(identifierObj)}
        />
      </div>
      <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2 ms-xl2 mt-4 pt-3">
        {index !== 0 && (
          <button
            className="action-trigger glyph-prepend glyph-prepend-delete"
            type="button"
            aria-label={localeService.getText("ProfileV2", `Delete`)}
            onKeyDown={(evt: { key: string }) => {
              if (evt.key === "Enter") {
                fields.remove(index);
              }
            }}
            onClick={() => {
              fields.remove(index);
            }}
          >
            {localeService.getText("ProfileV2", `Delete`)}
          </button>
        )}
      </div>
    </div>
  );
};

const renderRegulatoryBodiesOptions = (extRegulatoryBodies: Map<string, IRegulatoryBodyExt>) => {
  if (extRegulatoryBodies && extRegulatoryBodies.size > 0) {
    const values: IRegulatoryBodyExt[] = [];
    extRegulatoryBodies.forEach(val => {
      values.push(val);
    });
    if (values && values.length > 0) {
      return values.map((reg, index) => (
        <option key={index} value={reg.textKey} aria-label={reg.name}>
          {reg.name}
        </option>
      ));
    }
  }

  return null;
};

interface IrenderDropdownField {
  input: any, meta: { touched: boolean, error: string }, props: { extRegulatoryBodies: Map<string, IRegulatoryBodyExt>, setIdentifierObj: any }
};

const renderDropdownField = ({ input, meta: { touched, error }, props: { extRegulatoryBodies, setIdentifierObj }}: IrenderDropdownField) => {
  setIdentifierObj(getIdentifierObj(extRegulatoryBodies, input.value));
  
  return (
    <div>
      <div className="custom-select">
        <select
          required
          onBlur={input.onBlur}
          onChange={e => {
            input.onChange(e);
            setIdentifierObj(getIdentifierObj(extRegulatoryBodies, e.target.value));
          }}
          className="custom-select-input"
          value={input.value}
          aria-label={
            input.value == "" ? localeService.getText("ProfileV2", `RegistrationTypePlaceholder`) : localeService.getText("ProfileV2", 'RegistrationType')
          }
        >
          <option value="">{localeService.getText("ProfileV2", `RegistrationTypePlaceholder`)}</option>
          {renderRegulatoryBodiesOptions(extRegulatoryBodies)}
        </select>
        <span className="custom-select-arrow" aria-hidden="true" />
      </div>
      {touched && error && <FieldValidationError localeErrorTextId={error} />}
    </div>
  );
};

const getIdentifierObj = (
  extRegulatoryBodies: Map<string, IRegulatoryBodyExt>,
  identifierKey: string
): IRegulatoryBodyExt | null | undefined => {
  return extRegulatoryBodies && identifierKey ? extRegulatoryBodies.get(identifierKey) : null;
};

const getIdentifierLabel = (identifierObj: IRegulatoryBodyExt | undefined): string | undefined => {
  return identifierObj ? identifierObj.label : localeService.getText("Profile", "LegalIdentifier");
};

const getTooltipText = (identifierObj: IRegulatoryBodyExt | undefined): string => {
  return identifierObj ? identifierObj.tooltipText : "";
};

const getValidator = (identifierObj: IRegulatoryBodyExt | undefined): ((value: any, formValues: any) => string | undefined) => {
  const validator = identifierObj ? identifierObj.validator : null;
  return validator || legalIdentifierRequiredFieldValidator;
};

const getIdentifierKey = (extRegulatoryBodies: Map<string, IRegulatoryBodyExt>, identifierAcronym: string): string | null => {
  let identifierKey = null;
  if (extRegulatoryBodies && identifierAcronym) {
    const keys = Array.from(extRegulatoryBodies.keys());
    for (let i = 0; i < keys.length; i++) {
      const value = extRegulatoryBodies.get(keys[i]) as IRegulatoryBodyExt;
      if (value.acronym === identifierAcronym) {
        identifierKey = keys[i];
        break;
      }
    }
  }

  return identifierKey;
};

export default RegistrationIdentifier;
