import "@ms-mwf/moray/dist/css/main.css";
import Moment from "moment";
import { connect } from "react-redux";
import localeService from "src/dataServices/LocaleService";
import { IApplicationState } from "src/state";
import { BingAdsRegistrationState } from "src/state/offers/bing-ads/types";
import StatusBanner, { StatusIcon } from "../shared/StatusBanner";
import "./BingAds.scss";
import { BingAdsStatus, NoContentStatusCode } from "./BingAdsUtils";

interface IPropsFromParent {
  showErrorBanner?: boolean;
  tryAgainAction: () => void;
}

interface IPropsFromState {
  bingAds: BingAdsRegistrationState;
}

export type IBingAdsStatusBannerProps = IPropsFromState & IPropsFromParent;

export const BingAdsStatusBanner = (props: IBingAdsStatusBannerProps) => {
  let banner = null;

  if (
    props.bingAds &&
    props.bingAds.adsRegistrationInfo &&
    props.bingAds.adsRegistrationInfo.status === BingAdsStatus.Active
  ) {
    banner = (
      <StatusBanner iconType={StatusIcon.success} text={localeService.getText("BingAds", "ThankYouForActivatingAds")}>
        <>
          {Moment(props.bingAds.adsRegistrationInfo.createdDate)
            .add(2, "days")
            .isAfter(Moment()) ? (
            <div id="banner-description">{localeService.getText("BingAds", "YouWillReceiveAnEmail")}</div>
          ) : (
            <></>
          )}
        </>
      </StatusBanner>
    );
  }

  if (
    props.showErrorBanner &&
    props.bingAds &&
    !props.bingAds.adsRegistrationInfo &&
    props.bingAds.httpStatusCode !== NoContentStatusCode &&
    props.bingAds.errorMessage
  ) {
    banner = (
      <StatusBanner
        iconType={StatusIcon.warning}
        text={localeService.getText("BingAds", "ActivateBingAdsModalFailureTitle")}
      >
        <div id="error-banner-description" className="d-flex flex-column">
          <p className="mb-16">{localeService.getText("BingAds", "ActivateBingAdsModalFailureDescription")}</p>
          <div className="try-again">
            <a className="cta" onClick={() => props.tryAgainAction()}>
              {localeService.getText("Shared", "TryAgain")}
              <span className="glyph" aria-hidden="true"></span>
            </a>
          </div>
        </div>
      </StatusBanner>
    );
  }

  return banner;
};

const mapStateToProps = ({ offers }: IApplicationState) => ({
  bingAds: offers.bingAds
});

export default connect(mapStateToProps)(BingAdsStatusBanner);
