import "@ms-mwf/moray/dist/css/main.css";
import * as React from "react";
import localeService from "src/dataServices/LocaleService";
import "./ContactUsSection.scss";

export function ContactUsSection() {
  return (
    <div id="contact-us-section" className="material-surface text-center mb-0" data-mount="click-group">
      <h2 className="contact-us-header mb-3 h2">
        {localeService.getText("ContactUsSection", "ContactUsSectionHeader")}
      </h2>
      <p id="contact-us-content" className="base">
        {localeService.getText("ContactUsSection", "ContactUsSectionContent")}
      </p>
      <a id="contact-us-button" href="/contactus" className="cta" target="_blank">
        {localeService.getText("ContactUsSection", "ContactUsSectionButton")}
        <span className="glyph" aria-hidden="true"></span>
      </a>
    </div>
  );
}
