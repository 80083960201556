import * as React from "react";
import { IResizableImage } from "src/components/moray/ResizeableImage";
import localeService from "src/dataServices/LocaleService";
import HighlightBanner from "../shared/HighlightBanner";

const LinkedInTopBanner = () => {
  const resizableImageProps: IResizableImage = {
    root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/LinkedIn`,
    imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/LinkedIn-Highlight-540.jpg`,
    imageClassName: "img-fill-container card-img",
    imageAlt: localeService.getText("LinkedInV2", "HighlightBannerTitle"),
    fileSize1920: "LinkedIn-Highlight-1920.jpg",
    fileSize1400: "LinkedIn-Highlight-1400.jpg",
    fileSize1084: "LinkedIn-Highlight-1084.jpg",
    fileSize860: "LinkedIn-Highlight-860.jpg",
    fileSize540: "LinkedIn-Highlight-540.jpg",
    fileSize360: "LinkedIn-Highlight-360.jpg"
  };

  return (
    <HighlightBanner
      reverseTextColor={true}
      titleText={localeService.getText("LinkedInV2", "HighlightBannerTitle")}
      descriptionText={localeService.getText("LinkedInV2", "HighlightBannerDescription")}
      buttonText={localeService.getText("LinkedInV2", "HighlightBannerButton")}
      buttonOnClick={() => window.open(localeService.getText("LinkedInV2", "LinkedInUrl"))}
      buttonAriaLabel={localeService.getText("LinkedInV2", "HighlightBannerButton")}
      resizableImageProps={resizableImageProps}
    />
  );
};

export default LinkedInTopBanner;
