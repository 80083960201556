import { Reducer } from "redux";
import { ISendToPartnerRequest, ISendToPartnerState, SendToPartnerActionTypes } from "./types";

const initialState: ISendToPartnerState = {
  failedToSend: undefined,
  isSending: undefined,
  sentSuccessfully: undefined
};

const reducer: Reducer<ISendToPartnerState> = (state = initialState, action) => {
  switch (action.type) {
    case SendToPartnerActionTypes.INITIATE_SEND_TO_PARTNER_REQUEST: {
      return { ...state, isSending: true, sentSuccessfully: false, failedToSend: false };
    }
    case SendToPartnerActionTypes.SEND_TO_PARTNER_SUCCESS: {
      return { ...state, isSending: false, sentSuccessfully: true, failedToSend: false };
    }
    case SendToPartnerActionTypes.SEND_TO_PARTNER_ERROR: {
      return { ...state, isSending: false, sentSuccessfully: false, failedToSend: true };
    }
    case SendToPartnerActionTypes.SEND_TO_PARTNER_RESET: {
      return { ...state, isSending: undefined, sentSuccessfully: undefined, failedToSend: undefined };
    }
    default: {
      return state;
    }
  }
};

export { reducer as sendToPartnerReducer };
