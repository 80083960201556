import htmlParser from "html-react-parser";
import * as React from "react";
import localeService from "src/dataServices/LocaleService";
import doTextSubstitution from "src/common/doTextSubstitution";

interface ITextProps {
  category: string;
  id: string | undefined;
  substitutionData?: { [key: string]: any };
  error?: string;
  children?: React.ReactNode
}

export default class Text extends React.Component<ITextProps> {
  public render() {
    const localeContent = localeService.getLocaleContent();

    if (!localeContent) {
      return null;
    }

    const category = localeContent[this.props.category];
    let text = null;
    if (category && category[this.props.id ?? '']) {
      text = category[this.props.id ?? ''];
    } else if (this.props.error) {
      text = this.props.error;
    }

    if (!text) {
      return <span />;
    }

    if (text) {
      return htmlParser(doTextSubstitution(text, this.props.substitutionData));
    }

    return this.props.children;
  }
}
