import * as React from 'react';
import { Label } from '@fluentui/react';
import { Field } from 'react-final-form';
import Text from 'src/components/text/Text';
import localeService from 'src/dataServices/LocaleService';
import ResourceService from 'src/dataServices/ResourceService';
import { IAddressResources, IProfile } from 'src/state/profile/types';
import { ICountryCode } from 'src/state/shared';
import FieldValidationError from './FieldValidationError';
import {
  composePhoneNumberValidator,
  composeRequiredFieldValidator,
  composeWebsiteUrlValidator,
  getPostalCodeValidator,
  requiredFieldValidator
} from './ProfileV2SharedComponents';
import RegistrationIdentifiersEdit from './RegistrationIdentifiersEdit';
import TextInputV2 from './TextInputV2';
import './ProfileForm.scss';

interface IProfileProps {
  profile: IProfile;
  profileForm: any;
  onFieldChange: (field: string, value: string) => void;
}

const OrgInfoSection2Edit = ({ profile, profileForm, onFieldChange }: IProfileProps): JSX.Element => {
  const [countries, setCountries] = React.useState<ICountryCode[]>([]);
  const [selectedCountryCode, setSelectedCountryCode] = React.useState<string>(profile.address.countryCode);
  const [addressResources, setAddressResources] = React.useState<IAddressResources>();

  React.useEffect(() => {
    async function fetchAddressResourceData() {
      const response = await ResourceService.getAddressResourcesForCountry(selectedCountryCode);
      setAddressResources(response.value);
    }

    fetchAddressResourceData();
  }, [selectedCountryCode]);

  React.useEffect(() => {
    ResourceService.getCountryCodes()
      .then((data) => setCountries(data.value as ICountryCode[]))
      .catch((error) => console.error(error));
  }, []);

  return (
    <div id="org-info">
      <div id="profile-org-info-description">
        <p>{localeService.getText('ProfileV2', 'OrgInformationDescription')}</p>
      </div>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          <TextInputV2
            id="name"
            localeCategoryId="ProfileV2"
            localeLabelId="OrganizationName"
            localeErrorCategoryId="ProfileV2"
            required={true}
            autoFocus={true}
            validators={[composeRequiredFieldValidator('name')]}
          />
        </div>
      </div>
      <div className="ms-Grid-row mt-3">
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          <TextInputV2
            id="address.addressLine1"
            localeCategoryId="ProfileV2"
            localeLabelId="StreetAddress1"
            localeErrorCategoryId="ProfileV2"
            required={true}
            validators={[composeRequiredFieldValidator('address.addressLine1')]}
          />
        </div>
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          <TextInputV2
            id="address.addressOther"
            localeCategoryId="ProfileV2"
            localeLabelId="StreetAddress2"
            required={false}
          />
        </div>
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          <TextInputV2
            id="address.city"
            localeCategoryId="Profile"
            localeLabelId="City"
            required={true}
            validators={[composeRequiredFieldValidator('address.city')]}
          />
        </div>
      </div>
      <div className="ms-Grid-row mt-3">
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          <TextInputV2
            id="address.stateRegion"
            localeCategoryId="Profile"
            localeLabelId="State"
            localeErrorCategoryId="ProfileV2"
            required={true}
            validators={[composeRequiredFieldValidator('address.stateRegion')]}
          />
        </div>
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          <TextInputV2
            id="address.postalCode"
            localeCategoryId="Profile"
            localeLabelId="Postcode"
            required={true}
            validators={[
              composeRequiredFieldValidator('address.postalCode'),
              getPostalCodeValidator(addressResources as IAddressResources)
            ]}
          />
        </div>
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          <Label tabIndex={0} htmlFor="address.countryCode">
            <span aria-hidden="true">* </span>
            <Text category="Profile" id="Country" />
          </Label>
          <Field
            name="address.countryCode"
            id="address.countryCode"
            component={renderCountriesDropdown}
            props={{
              countries: countries,
              setSelectedCountryCode: setSelectedCountryCode,
              onFieldChange: onFieldChange,
              disableCountryEdit: profile.disableCountryEdit
            }}
            validate={(value) => requiredFieldValidator(value, 'address.countryCode')}
          />
        </div>
      </div>
      <div className="ms-Grid-row mt-3">
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          <TextInputV2
            id="websiteUrl"
            localeCategoryId="Profile"
            localeLabelId="Website"
            required={true}
            validators={[composeRequiredFieldValidator('websiteUrl'), composeWebsiteUrlValidator('websiteUrl')]}
          />
        </div>
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          <TextInputV2
            id="address.phone"
            localeCategoryId="ProfileV2"
            localeLabelId="PhoneNumber"
            localeErrorCategoryId="ProfileV2"
            required={true}
            validators={[composeRequiredFieldValidator('address.phone'), composePhoneNumberValidator('address.phone')]}
          />
        </div>
      </div>
      <RegistrationIdentifiersEdit
        profileForm={profileForm}
        registrationIdentifiers={profile.registrationIdentifiers}
        countryCode={selectedCountryCode}
      />
    </div>
  );
};

interface IrenderCountriesDropdown {
  input: any;
  meta: { touched: boolean; error: string };
  props: {
    countries: ICountryCode[];
    setSelectedCountryCode: any;
    onFieldChange: any;
    disableCountryEdit: boolean;
  };
}

const renderCountriesDropdown = ({
  input,
  meta: { touched, error },
  props: { countries, setSelectedCountryCode, onFieldChange, disableCountryEdit }
}: IrenderCountriesDropdown) => {
  return (
    <div>
      <div className="custom-select">
        <select
          className="custom-select-input"
          required
          onBlur={input.onBlur}
          onChange={(e) => {
            input.onChange(e);
            setSelectedCountryCode(e.target.value);
            onFieldChange(input.name, e.target.value);
          }}
          value={input.value}
          aria-label={input.value === '' ? localeService.getText('ProfileV2', `CountryPlaceholder`) : input.value}
          disabled={disableCountryEdit}
        >
          <option value="">{localeService.getText('ProfileV2', `CountryPlaceholder`)}</option>
          {(countries || []).map((countryCode, index) => (
            <option key={index} value={countryCode.code}>
              {countryCode.name + ' (' + countryCode.code + ')'}
            </option>
          ))}
        </select>
        <span className="custom-select-arrow" aria-hidden="true" />
      </div>
      {touched && error && <FieldValidationError localeErrorTextId={error} />}
    </div>
  );
};

export default OrgInfoSection2Edit;
