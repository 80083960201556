import * as React from "react";
import ResizableImage from "src/components/moray/ResizeableImage";
import localeService from "src/dataServices/LocaleService";
import "./SolutionTabs.scss";
import { IFeatureFlagsState } from "src/state/feature-flags";
import { IApplicationState } from "src/state";
import { connect } from "react-redux";
import useD365CopilotOfferUpdate from './useD365CopilotOfferUpdate';
const { useState } = React;

interface ISolutionTabsProps {
  setRef?: (el: HTMLDivElement | null) => void;
  featureFlags: IFeatureFlagsState;
}

const SolutionTabs = (props: ISolutionTabsProps) => {
  const { isD365CopilotOfferAvailable } = useD365CopilotOfferUpdate();

  const [[tab, orgComplexity], setTab] = useState('00');
  const low = '0';
  const med = '1';
  const high = '2';
  const complexities = ['low', 'med', 'high'];
  const tabSelected = (selectedTab: string | number) => (selectedTab == tab ? 'tab-selected' : '');
  const subTabSelected = (selectedTab: string | number) => (selectedTab == orgComplexity ? 'tab-selected' : '');

  const highlighted = (onLowMedHigh: { [x: string]: any; low?: string; med?: string; high?: string }) =>
    onLowMedHigh[complexities[Number(orgComplexity)]] ? 'highlighted' : '';
  const imageProps = {
    root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}`,
    imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/power-platform-1920.png`,
    imageClassName: 'power-platform-background-img',
    imageAlt: localeService.getText('Dynamic365V2', 'PowerPlatform'),
    fileSize1920: 'power-platform-1920.png',
    fileSize1400: 'power-platform-1920.png',
    fileSize1084: 'power-platform-1920.png',
    fileSize860: 'power-platform-1920.png',
    fileSize540: 'power-platform-1920.png',
    fileSize360: 'power-platform-1920.png'
  };
  return (
    <section className="dynamic365V2">
      <div
        className=" "
        ref={(el) => {
          if (props.setRef !== undefined) {
            props.setRef(el);
          }
        }}
      >
        <div className="solutions-tabs">
          <div className="header-section">
            <h2 className="solutions-header h2">{localeService.getText('Dynamic365V2', 'Solutions')}</h2>
            <p className="solutions-paragraph">{localeService.getText('Dynamic365V2', 'WeOfferSolutions')}</p>
          </div>
          <div className="solutions-tab-group">
            <a
              tabIndex={0}
              href={''}
              className={'tablinks ' + tabSelected(0)}
              onClick={(e) => {
                e.preventDefault();
                setTab('00');
              }}
            >
              {localeService.getText('Dynamic365V2', 'ModernizeProcesses')}
            </a>
            <a
              tabIndex={0}
              href={''}
              className={'tablinks ' + tabSelected(1)}
              onClick={(e) => {
                e.preventDefault();
                setTab('10');
              }}
            >
              {localeService.getText('Dynamic365V2', 'AutomateOperations')}
            </a>
            <a
              tabIndex={0}
              href={''}
              className={'tablinks ' + tabSelected(2)}
              onClick={(e) => {
                e.preventDefault();
                setTab('20');
              }}
            >
              {localeService.getText('Dynamic365V2', 'EarnDonors')}
            </a>
          </div>
        </div>

        <div className="power-platform-background" hidden={tab != '0'}>
          <div className="power-platform">
            <h1 className="power-platform-h1 h1">{localeService.getText('Dynamic365V2', 'PowerPlatform')}</h1>
            <h3 className="power-platform-h3">{localeService.getText('Dynamic365V2', 'PowerAppsPerApp')}</h3>
            <p className="power-platform-p">{localeService.getText('Dynamic365V2', 'PerUserPerMonth')}</p>
            {isD365CopilotOfferAvailable ? (
              <>
                <h3 className="power-platform-h3">{localeService.getText('Dynamic365V2', 'PowerAppsPremium')}</h3>
                <p className="power-platform-p">{localeService.getText('Dynamic365V2', '5PerUserPerMonth')}</p>
              </>
            ) : (
              <>
                <h3 className="power-platform-h3">{localeService.getText('Dynamic365V2', 'PowerAppsPerUser')}</h3>
                <p className="power-platform-p">{localeService.getText('Dynamic365V2', '10PerUserPerMonth')}</p>
                <h3 className="power-platform-h3">{localeService.getText('Dynamic365V2', 'PowerVirtualAgent')}</h3>
                <p className="power-platform-p">{localeService.getText('Dynamic365V2', '250AddOnPerMonth')}</p>
              </>
            )}
            <div className="power-platform-a">
              <a
                className="cta"
                href={
                  isD365CopilotOfferAvailable
                    ? localeService.getText('Dynamic365V2', 'ComparePlansLink')
                    : 'https://admin.microsoft.com/Adminportal/Home?source=applauncher#/catalog'
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {localeService.getText('Dynamic365V2', 'ComparePlans')}
                <span className="glyph" aria-hidden="true"></span>
              </a>
            </div>
          </div>
          <ResizableImage {...imageProps} />
        </div>

        <div className="ops-earn" hidden={tab != '1'}>
          <div className="ops-earn-header">
            <div>
              <h3 className="ops-earn-h3 h3">
                {localeService.getText('Dynamic365V2', 'AutomateYourFinancialOperations')}
              </h3>
              <p className="ops-earn-p">{localeService.getText('Dynamic365V2', 'ChooseFromLowMediumHigh')}</p>
              <div className="ops-earn-a">
                <a
                  className="cta"
                  href="https://admin.microsoft.com/Adminportal/Home?source=applauncher#/catalog"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {localeService.getText('Dynamic365V2', 'ComparePlans')}
                  <span className="glyph" aria-hidden="true"></span>
                </a>
              </div>
            </div>
            <div className="ops-earn-group">
              <a
                tabIndex={0}
                href={''}
                className={'tablinks ' + subTabSelected(0)}
                onClick={(e) => {
                  e.preventDefault();
                  setTab('10');
                }}
              >
                {localeService.getText('Dynamic365V2', 'Low')}
              </a>
              <a
                tabIndex={0}
                href={''}
                className={'tablinks ' + subTabSelected(1)}
                onClick={(e) => {
                  e.preventDefault();
                  setTab('11');
                }}
              >
                {localeService.getText('Dynamic365V2', 'Medium')}
              </a>
              <a
                tabIndex={0}
                href={''}
                className={'tablinks ' + subTabSelected(2)}
                onClick={(e) => {
                  e.preventDefault();
                  setTab('12');
                }}
              >
                {localeService.getText('Dynamic365V2', 'High')}
              </a>
            </div>
          </div>
          <div className="highlightable-cards">
            <div className={'one highlightable-card ' + highlighted({ low })}>
              <div className="inner-card">
                <h3>{localeService.getText('Dynamic365V2', 'NonprofitOperationsToolkit')}</h3>
                <div className="divider-line" />
                <p>{localeService.getText('Dynamic365V2', 'PowerAppsPerAppLicensingPlanRequired')}</p>
              </div>
            </div>
            <div className={'two highlightable-card ' + highlighted({ med })}>
              <div className="inner-card">
                <h3>{localeService.getText('Dynamic365V2', 'Dynamics365BusinessCentralEssentials')}</h3>
              </div>
            </div>
            <div className={'three highlightable-card ' + highlighted({ med })}>
              <div className="inner-card">
                <h3>{localeService.getText('Dynamic365V2', 'Dynamics365BusinessCentralPremium')}</h3>
              </div>
            </div>
            <div className={'four highlightable-card ' + highlighted({ med })}>
              <div className="inner-card">
                <h3>{localeService.getText('Dynamic365V2', 'Dynamics365BusinessCentralTeamMember')}</h3>
              </div>
            </div>
            <div className={'five highlightable-card ' + highlighted({ high })}>
              <div className="inner-card">
                <h3>{localeService.getText('Dynamic365V2', 'Dynamics365Finance')}</h3>
              </div>
            </div>
            <div className={'six highlightable-card ' + highlighted({ high })}>
              <div className="inner-card">
                <h3>{localeService.getText('Dynamic365V2', 'Dynamics365SupplyChain')}</h3>
              </div>
            </div>
            <div className={'seven highlightable-card ' + highlighted({ high })}>
              <div className="inner-card">
                <h3>{localeService.getText('Dynamic365V2', 'Dynamics365Commerce')}</h3>
              </div>
            </div>
            <div className={'eight highlightable-card ' + highlighted({ high })}>
              <div className="inner-card">
                <h3>{localeService.getText('Dynamic365V2', 'Dynamics365Talent')}</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="ops-earn" hidden={tab != '2'}>
          <div className="ops-earn-header">
            <div>
              <h3 className="ops-earn-h3 h3">{localeService.getText('Dynamic365V2', 'EarnDonorAndVolunteers')}</h3>
              <p className="ops-earn-p">{localeService.getText('Dynamic365V2', 'ChooseFromLowMediumHigh')}</p>
              <div className="ops-earn-a">
                <a
                  className="cta"
                  href="https://admin.microsoft.com/Adminportal/Home?source=applauncher#/catalog"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {localeService.getText('Dynamic365V2', 'ComparePlans')}
                  <span className="glyph" aria-hidden="true"></span>
                </a>
              </div>
            </div>
            <div className="ops-earn-group">
              <a
                tabIndex={0}
                href={''}
                className={'tablinks ' + subTabSelected(0)}
                onClick={(e) => {
                  e.preventDefault();
                  setTab('20');
                }}
              >
                {localeService.getText('Dynamic365V2', 'Low')}
              </a>
              <a
                tabIndex={0}
                href={''}
                className={'tablinks ' + subTabSelected(1)}
                onClick={(e) => {
                  e.preventDefault();
                  setTab('21');
                }}
              >
                {localeService.getText('Dynamic365V2', 'Medium')}
              </a>
              <a
                tabIndex={0}
                href={''}
                className={'tablinks ' + subTabSelected(2)}
                onClick={(e) => {
                  e.preventDefault();
                  setTab('22');
                }}
              >
                {localeService.getText('Dynamic365V2', 'High')}
              </a>
            </div>
          </div>
          <div className="highlightable-cards">
            <div className={'one-new highlightable-card ' + highlighted({ low })}>
              <div className="inner-card">
                <h3>{localeService.getText('Dynamic365V2', 'Dynamics365SalesProfessional')}</h3>
              </div>
            </div>
            <div className={'two-new highlightable-card ' + highlighted({ low })}>
              <div className="inner-card">
                <h3>{localeService.getText('Dynamic365V2', 'Dynamics365CustomerServiceProfessional')}</h3>
              </div>
            </div>
            <div className={'four-new highlightable-card ' + highlighted({ low, med, high })}>
              <div className="inner-card">
                <h3>{localeService.getText('Dynamic365V2', 'Dynamics365TeamMember')}</h3>
              </div>
            </div>
            <div className={'five-new highlightable-card ' + highlighted({ high })}>
              <div className="inner-card">
                <h3>{localeService.getText('Dynamic365V2', 'Dynamics365FieldServiceAutomation')}</h3>
              </div>
            </div>
            <div className={'six-new highlightable-card ' + highlighted({ med, high })}>
              <div className="inner-card">
                <h3>{localeService.getText('Dynamic365V2', 'Dynamics365CustomerServiceEnterprise')}</h3>
              </div>
            </div>
            <div className={'seven-new highlightable-card ' + highlighted({ high })}>
              <div className="inner-card">
                <h3>{localeService.getText('Dynamic365V2', 'Dynamics365CustomerInsights')}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = ({ flags }: IApplicationState) => ({
  featureFlags: flags
});

export default connect(mapStateToProps)(SolutionTabs)
