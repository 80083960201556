import FollowUsFooter from '../../followUsFooter/FollowUsFooter';
import { ContactUsSection } from '../shared/ContactUsSection';
import PartnersBanner from '../shared/PartnersBanner';
import PricingDisclaimer from '../shared/PricingDisclaimer';
import StandardTrainingSection from '../shared/StandardTrainingSection';
import CommunityTrainingActionSteps from './CommunityTrainingActionSteps';
import CommunityTrainingPlanFeatureCard from './CommunityTrainingPlanFeatureCard';
import CommunityTrainingTopBanner from './CommunityTrainingTopBanner';

const CommunityTraining = () => {
  return (
    <section>
      <CommunityTrainingTopBanner />
      <CommunityTrainingActionSteps />
      <CommunityTrainingPlanFeatureCard />
      <PartnersBanner />
      <StandardTrainingSection />
      <ContactUsSection />
      <PricingDisclaimer pricingDisclaimer1="PricingDisclaimerCommonParagraph" />
      <FollowUsFooter />
    </section>
  );
};

export default CommunityTraining;
