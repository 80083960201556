import { Reducer } from "redux";
import { IOfferRequestState, OffersRequestActionTypes } from "./types";

const initialState: IOfferRequestState = {
  failedToSend: undefined,
  isSending: undefined,
  sentSuccessfully: undefined,
  offerId: undefined
};

const reducer: Reducer<IOfferRequestState> = (state = initialState, action) => {
  switch (action.type) {
    case OffersRequestActionTypes.OFFERS_REQUEST: {
      return { ...state, isSending: true, sentSuccessfully: false, failedToSend: false };
    }
    case OffersRequestActionTypes.OFFERS_REQUEST_SUCCESS: {
      return { ...state, isSending: false, sentSuccessfully: true, failedToSend: false };
    }
    case OffersRequestActionTypes.OFFERS_REQUEST_ERROR: {
      return { ...state, isSending: false, sentSuccessfully: false, failedToSend: true };
    }
    case OffersRequestActionTypes.OFFERS_REQUEST_RESET: {
      return { ...state, isSending: undefined, sentSuccessfully: undefined, failedToSend: undefined };
    }
    case OffersRequestActionTypes.CLAIM_OFFER_REQUEST: {
      return {
        ...state,
        isSending: true,
        sentSuccessfully: false,
        failedToSend: false,
        offerId: action.payload.offerId
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as sharedOfferReducer };
