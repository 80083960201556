import * as React from "react";
import Text from "src/components/text/Text";

export function WVDHelpdesk() {
  return (
    <section id="wvd-helpdesk">
      <p>
        <Text category="WVDHelpdesk" id="Detail1" />
      </p>
      <div className="list-section">
        <ul>
          <li>
            <Text category="WVDHelpdesk" id="List1" />
          </li>
          <li>
            <Text category="WVDHelpdesk" id="List2" />
          </li>
          <li>
            <Text category="WVDHelpdesk" id="List3" />
          </li>
          <li>
            <Text category="WVDHelpdesk" id="List4" />
          </li>
          <li>
            <Text category="WVDHelpdesk" id="List5" />
          </li>
        </ul>
      </div>
    </section>
  );
}
