import * as React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "src/state";
import TextLink from "../text/TextLink";
import "./AppContext.scss";

export interface IContextProps {
  values: { [key: string]: string };
}

export class AppContext extends React.Component<IContextProps> {
  public render() {
    const { values } = this.props;
    return (
      <section id="app-context">
        <TextLink category="ErrorContext" id="Header" href="ContactUs" target="_blank" />
        <ul>
          {Object.keys(values).map(key => {
            return values[key] ? (
              <li key={key}>
                {key}: {values[key]}
              </li>
            ) : null;
          })}
        </ul>
      </section>
    );
  }
}

const mapStateToProps = ({ layout }: IApplicationState) => ({
  values: layout.appContext
});

export default connect(mapStateToProps)(AppContext);
