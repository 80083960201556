import * as React from "react";
import Text from "src/components/text/Text";

export function CommunityTraining() {
  return (
    <section id="comminity-training">
      <p>
        <Text category="CommunityTraining" id="Detail1" />
      </p>
      <div className="list-section">
        <ul>
          <li>
            <Text category="CommunityTraining" id="Training1" />
          </li>
          <li>
            <Text category="CommunityTraining" id="Training2" />
          </li>
          <li>
            <Text category="CommunityTraining" id="Training3" />
          </li>
          <li>
            <Text category="CommunityTraining" id="Training4" />
          </li>
          <li>
            <Text category="CommunityTraining" id="Training5" />
          </li>
        </ul>
      </div>
    </section>
  );
}
