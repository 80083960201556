import * as msal from "@azure/msal-browser";
import { IconButton, Modal } from "@fluentui/react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import TextInput, { ITextInputValidation } from "src/components/shared/TextInput";
import Text from "src/components/text/Text";
import localeService from "src/dataServices/LocaleService";
import { singleUrlIsValid } from "src/common/utilities/Utilities";
import { IApplicationState } from "src/state";
import { submitClaimAdsOffer } from "src/state/offers/bing-ads/actions";
import {
  BingAdsRegistrationState,
  ClaimAdsOfferRequest,
  SubmitClaimAdsOfferRequest
} from "src/state/offers/bing-ads/types";
import { IProfile } from "src/state/profile/types";
import "./BingAdsClaimOfferModal.scss";

interface IPropsFromState {
  profile: IProfile;
  user: msal.AccountInfo | null | undefined;
  bingAds: BingAdsRegistrationState;
}

interface IPropsFromDispatch {
  submitClaimAdsOffer: typeof submitClaimAdsOffer;
}

interface IPropsFromParent {
  isOpen: boolean;
  onComplete: () => void;
  onDismiss: () => void;
}

interface IInfoSection {
  children: React.ReactNode;
}

const InfoSection = ({ children }: IInfoSection) => (
  <div className="bing-ads-info-section">
    <img
      className="banner-icon"
      src={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/icons/info-icon.svg`}
      alt="information icon"
    />
    <p className="banner-text">{children}</p>
  </div>
);

export type IBingAdsClaimOfferModalProps = IPropsFromState & IPropsFromDispatch & IPropsFromParent;

export const BingAdsClaimOfferModal = (props: IBingAdsClaimOfferModalProps) => {
  const BingAdsWebsiteUrlValidations: ITextInputValidation[] = [
    {
      validationRule: (text: string) => {
        return text.length > 0;
      },
      validationText: localeService.getText("BingAds", "ActivateBingAdsModalWebsiteRequiredError")
    },
    {
      validationRule: (text: string) => {
        // only show this validation if text length is greater than 0
        return text.length === 0 || singleUrlIsValid(text);
      },
      validationText: localeService.getText("BingAds", "ActivateBingAdsModalWebsiteInvalidError")
    }
  ];

  const initiateClaim = (event: any) => {
    let isValid = true;

    event.preventDefault();

    const claimRequest = {
      nonprofitId: props.profile.id,
      request: {
        tenantId: props.user?.tenantId,
        websiteUrl: event.target.websiteUrlInput.value
      } as ClaimAdsOfferRequest
    } as SubmitClaimAdsOfferRequest;

    BingAdsWebsiteUrlValidations.forEach(validation => {
      if (!validation.validationRule(claimRequest.request.websiteUrl)) {
        isValid = false;
        event.target.websiteUrlInput.focus();
        event.target.websiteUrlInput.blur();
      }
    });

    if (isValid) {
      props.submitClaimAdsOffer(claimRequest);
      props.onComplete();
    }
  };

  return (
    <Modal isOpen={props.isOpen} onDismiss={props.onDismiss} isBlocking={true}>
      <div className="bing-ads-modal">
        <div className="header">
          <h2 className="bing-ads-title">
            <Text id={"ActivateBingAdsModalTitle"} category={"BingAds"} />
          </h2>
          <IconButton iconProps={{ iconName: "Cancel" }} ariaLabel="Close popup modal" onClick={props.onDismiss} />
        </div>
        <p>
          <Text id={"ActivateBingAdsModalDescription"} category={"BingAds"} />
        </p>
        <form className="form-group" onSubmit={initiateClaim}>
          <TextInput
            required={true}
            id={"websiteUrlInput"}
            label={localeService.getText("BingAds", "ActivateBingAdsModalWebsite")}
            placeholder={localeService.getText("BingAds", "ActivateBingAdsModalWebsite")}
            defaultValue={props.profile.websiteUrl}
            validationRules={BingAdsWebsiteUrlValidations}
          />

          <InfoSection>
            <Text id={"ActivateBingAdsModalResources"} category={"BingAds"} />
          </InfoSection>
          <ul className="bing-ads-additional-resources">
            <li tabIndex={0}>
              <a href={localeService.getText("BingAds", "ActivateBingAdsModalLink1")} target="_blank" rel="noopener noreferrer">
                <Text category="BingAds" id="ActivateBingAdsModalLink1Text" />
              </a>
            </li>
            <li tabIndex={0}>
              <a href={localeService.getText("BingAds", "ActivateBingAdsModalLink2")} target="_blank" rel="noopener noreferrer">
                <Text category="BingAds" id="ActivateBingAdsModalLink2Text" />
              </a>
            </li>
          </ul>
          <div className="actions">
            <button id="continue-button" className="btn btn-primary">
              {localeService.getText("Shared", "Continue")}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ profile, auth, offers }: IApplicationState) => ({
  profile: profile.profile,
  user: auth.user,
  bingAds: offers.bingAds
});

const mapDispatchToProps: (dispatch: Dispatch) => IPropsFromDispatch = dispatch => ({
  submitClaimAdsOffer: formValues => dispatch(submitClaimAdsOffer(formValues))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BingAdsClaimOfferModal);
