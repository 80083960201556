import { all, apply, fork, put, takeEvery } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import { IApiResponse } from "src/common/ApiResponse";
import accountGuardService from "src/dataServices/AccountGuardService";
import {
  accountOfferRequest,
  accountOfferRequestSuccess,
  accountOffersRequestAlreadyClaim,
  accountOffersRequestError,
  accountOffersRequestLanguageSupport,
  setAccountGuardInfoError,
  setAccountGuardInfoSuccess
} from "./actions";
import { ClaimOffersRequestActionTypes } from "./types";

export function* handleClaimOfferRequest(action: ActionType<typeof accountOfferRequest>) {
  try {
    const response: IApiResponse<{}> = yield apply(accountGuardService, accountGuardService.initiateRequest, []);
    if (response.status === 200) {
      yield put(accountOfferRequestSuccess());
    } else if (response.status === 409) {
      yield put(accountOffersRequestAlreadyClaim());
    } else if (response.status === 404) {
      yield put(accountOffersRequestLanguageSupport());
    } else {
      yield put(accountOffersRequestError());
    }
  } catch (e) {
    yield put(accountOffersRequestError());
  }
}

export function* handleFetchAccountGuardInfo() {
  try {
    const response: IApiResponse<boolean> = yield apply(
      accountGuardService,
      accountGuardService.getAccountGuardInfo,
      []
    );
    if (response.status === 200) {
      yield put(setAccountGuardInfoSuccess(response.value));
    } else {
      yield put(setAccountGuardInfoError());
    }
  } catch (e) {
    yield put(setAccountGuardInfoError());
  }
}

function* watchClaimOfferRequest() {
  yield takeEvery(ClaimOffersRequestActionTypes.ACCOUNT_OFFER_REQUEST, handleClaimOfferRequest);
}
function* watchAccountGuardInfo() {
  yield takeEvery(ClaimOffersRequestActionTypes.FETCH_ACCOUNTGUARD_INFO, handleFetchAccountGuardInfo);
}

function* accountGaurdOffersRequestSaga() {
  yield all([fork(watchClaimOfferRequest), fork(watchAccountGuardInfo)]);
}

export default accountGaurdOffersRequestSaga;
