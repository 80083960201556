import Text from "src/components/text/Text";
import localeService from "src/dataServices/LocaleService";
import "./AzureIntroBanner.scss";

interface AzureIntroBannerProps {
  setRef?: (el: HTMLDivElement | null) => void;
}
const AzureIntroBanner = (props: AzureIntroBannerProps) => {
  return (
    <section>
      <div
        id="azureIntro"
        className="azureIntro"
        ref={el => {
          if (props.setRef !== undefined) {
            props.setRef(el);
          }
        }}
      >
        <div className="card d-block mx-ng mx-md-0 mt-5 mb-5">
          <div className="row no-gutters material-surface ml-5 mr-5">
            <div className="col-md-6">
              <picture>
                <source
                  srcSet={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/azure-introduction-banner-1920.jpg`}
                  media="(min-width: 1400px)"
                />
                <source
                  srcSet={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/azure-introduction-banner-1400.jpg`}
                  media="(min-width: 1084px)"
                />
                <source
                  srcSet={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/azure-introduction-banner-1084.jpg`}
                  media="(min-width: 860px)"
                />
                <source
                  srcSet={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/azure-introduction-banner-860.jpg`}
                  media="(min-width: 540px)"
                />
                <img
                  src={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/azure-introduction-banner-540.jpg`}
                  className="card-img img-object-cover"
                  alt={localeService.getText("AzureV2", "WhatIsAzureBannerPlaceholder")}
                />
              </picture>
            </div>
            <div className="d-flex col-md">
              <div className="card-body align-self-center p-4 p-md-5">
                <h2 id="azure-intro-banner-title" className="azure-intro-banner-title h2">
                  <Text category="AzureV2" id="WhatIsAzureBannerTitle" />
                </h2>
                <div id="azure-intro-banner-description" className="mb-3 mt-2">
                  <p className="description-text">
                    <Text category="AzureV2" id="WhatIsAzureBannerDescription" />
                  </p>
                  <p className="description-text">
                    <Text category="AzureV2" id="WhatIsAzureBannerFooter" />
                  </p>
                </div>
                <div id="azure-intro-banner-button" className="link-group">
                  <a
                    href={localeService.getText("AzureV2", "WhatIsAzureBannerLink")}
                    className="btn btn-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Text category="AzureV2" id="WhatIsAzureBannerButton" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AzureIntroBanner;
