import { AppSettingsBase, EnvironmentStrToPrefix } from "npo-common";
import { LogLevel } from "../logging/log-level.enum";
import { EnumTools } from "../utilities/enum.tools";
import { Environment } from "./Environment";

declare global {
  interface Window {
    AppSettings: any;
  }
}

window.AppSettings = window.AppSettings || {};

export class AppSettings extends AppSettingsBase {
  // environment
  public nodeEnv: 'development' | 'test' | 'production' | undefined = process.env.NODE_ENV;
  public environmentStr?: string = process.env.REACT_APP_ENV;
  public environment: Environment = EnumTools.parseEnum(Environment, process.env.REACT_APP_ENV || '');
  public websiteName?: string = process.env.REACT_APP_WEBSITE_NAME || '';
  public version?: string = process.env.REACT_APP_VERSION;
  public environmentPrefix?: string = this.environmentStr ? EnvironmentStrToPrefix[this.environmentStr] : '';
  public scs?: string = process.env.REACT_APP_SCS;
  public serviceName?: string = process.env.REACT_APP_SERVICE_NAME;
  public serviceRegion?: string = process.env.REACT_APP_SERVICE_REGION;
  public serviceVersion?: string = process.env.REACT_APP_SERVICE_VERSION;
  public serviceId?: string = process.env.REACT_APP_SERVICE_ID;
  public serviceType?: string = process.env.REACT_APP_SERVICE_TYPE;

  // authentication
  public aadTenantId?: string = process.env.REACT_APP_AAD_TENANT_ID;

  // loggging
  public logLevelStr?: string = process.env.REACT_APP_LOG_LEVEL;
  public logLevel: LogLevel = EnumTools.parseEnum(LogLevel, process.env.REACT_APP_LOG_LEVEL || 'warning');

  // cor services
  public corApiBaseUrl?: string = process.env.REACT_APP_COR_API_BASE_URL;
  public internalApiBaseUrl = process.env.REACT_APP_INTERNAL_API_BASEURL;
  public internalApiBaseUrlV2 = process.env.REACT_APP_INTERNAL_API_BASEURL_V2;
  public agentVerificationApiBaseUrl = process.env.REACT_APP_AGENT_VERIFICATION_API_BASEURL;

  initialize() {
    window.AppSettings.log = () => {
      console.log('AppSettings', this);
      console.log('process.env', process.env);
    };
  }
}

// export singleton instance
export const appSettings = new AppSettings();
