import { withAITracking } from "@microsoft/applicationinsights-react-js";
import moment from "moment";
import { connect } from "react-redux";
import localeService from "src/dataServices/LocaleService";
import { telemetryService } from "npo-common";
import { IApplicationState } from "src/state";

export const OrgRegistrationInfo = ({ Profile }: { Profile: any }) => {
  return (
    <section id="profile-org-registration-information">
      <div id="profile-org-registration-information-description">
        <p>{localeService.getText("ProfileV2", "OrgRegistrationInformationDescription")}</p>
      </div>
      <div id="profile-org-registration-information ms-Grid">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md4">
            <p>{localeService.getText("ProfileV2", "OrgStatus")}</p>
            <p>
              <b>{Profile.status}</b>
            </p>
          </div>
          <div className="ms-Grid-col ms-sm12 ms-md4">
            <p>{localeService.getText("ProfileV2", "OrgEffectiveDate")}</p>
            <p>
              <b>{moment(Profile.effectiveDateTime).format("MMMM DD, YYYY")}</b>
            </p>
          </div>
          <div className="ms-Grid-col ms-sm12 ms-md4">
            <p>{localeService.getText("ProfileV2", "OrgDomain")}</p>
            <p>
              <b>{Profile.domain}</b>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
const mapStateToProps = ({ profile }: IApplicationState) => ({
  Profile: profile.profile
});
export default connect(mapStateToProps)(withAITracking(telemetryService.aiReactPlugin, OrgRegistrationInfo));
