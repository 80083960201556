import '@ms-mwf/moray/dist/css/main.css';
import * as React from 'react';
import localeService from 'src/dataServices/LocaleService';
import htmlParser from 'html-react-parser';

const CopilotInfoSection = () => (
  <div className="bg-gray-100 plan-section-container">
    <div className="bg-white p-4">
      <p>{htmlParser(localeService.getText('Microsoft365V2', 'CopilotInfoDescription1') ?? '')}</p>
      <p>{htmlParser(localeService.getText('Microsoft365V2', 'CopilotInfoDescription2') ?? '')}</p>
      <p>{htmlParser(localeService.getText('Microsoft365V2', 'CopilotInfoDescription3') ?? '')}</p>
    </div>
  </div>
);

export default CopilotInfoSection;
