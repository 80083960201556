import { DefaultMeoLocale, IMeoLocale } from "src/state/meo-localization/types";
import { callApi } from "./SharedServiceFunctions";
import { IApiResponse } from "src/common/ApiResponse";
import { logService } from "npo-common";

export class MeoLocaleService {
  private MEO_LOCALIZATION_URL = process.env.REACT_APP_CDN_URL;

  public async getMeoLocales(): Promise<IApiResponse<IMeoLocale[]>> {
    const url = `${this.MEO_LOCALIZATION_URL}/email/meo-locales.json`;
    try {
      return await callApi<IMeoLocale[]>(url);
    } catch (error) {
      logService.logError("Failed to fetch meo-locales.json", { error });
      return {status: 500};
    }
  }

  public resolveLocale(cultureCode: string | undefined, meoLocales: IMeoLocale[]): IMeoLocale {
    const nonprofitLocale = meoLocales.find(locale => locale.cultureCode === cultureCode);
    return nonprofitLocale || DefaultMeoLocale;
  }
}

const meoLocaleService = new MeoLocaleService();
export default meoLocaleService;