import "./ProductCard.scss";

export interface IProductCardRow {
  numCards: {
    xl: number;
    lg: number;
    md: number;
    sm: number;
    xs: number;
  };
  children: any;
}

export const ProductCardRow = ({ numCards: { xl, lg, md, sm, xs }, children }: IProductCardRow) => (
  <div className="container px-2 ml-4">
    <div
      className={`row row-cols-${xs} row-cols-sm-${sm} row-cols-md-${md} row-cols-lg-${lg} row-cols-xl-${xl} py-5 mx-n2`}
    >
      {children}
    </div>
  </div>
);

export interface IProductCard {
  title: string;
  description: string;
  image: string;
  imageSrc: string;
  imageAlt: string;
  ctaText: string;
  ctaLink: string;
  linkAriaLabel?: string | null;
  ctaText2?: string | null;
  ctaLink2?: string | null;
  linkSectionTitle?: string | null;
}

const ProductCard = ({
  title,
  description,
  ctaText,
  ctaLink,
  imageSrc,
  imageAlt,
  linkAriaLabel = null,
  ctaText2 = null,
  ctaLink2 = null,
  linkSectionTitle = null
}: IProductCard) => (
  <div className="slide-item px-2">
    <div className="card h-100 material-card depth-4 depth-8-hover pb-4" data-mount="click-group">
      <div className="img-flex-auto img-min-height bg-gray-200 p-5 d-flex align-items-center justify-content-center">
        <img alt={imageAlt} src={imageSrc} />
      </div>
      <div className="card-header pt-3 px-3">
        <h5 className="h5">{title}</h5>
      </div>
      <div className="card-body px-3">
        <p className="mb-2">{description}</p>
      </div>
      {renderFooterSection(ctaText, ctaLink, ctaText2, ctaLink2, linkSectionTitle, linkAriaLabel)}
    </div>
  </div>
);

// helper function determines type of card footer to render
const renderFooterSection = (
  ctaText: string,
  ctaLink: string,
  ctaText2: string | null,
  ctaLink2: string | null,
  linkSectionTitle: string | null,
  linkAriaLabel: string | null,
) => {
  if (linkSectionTitle !== null) {
    return (
      <div className="card-footer ">
        <div className="px-3">
          <span className="product-card-links-title">{linkSectionTitle}</span>
        </div>
        <div className="pt-2 px-3">
          <a className="cta" href={ctaLink} target="_blank" rel="noopener noreferrer">
            {ctaText}
            <span className="glyph" aria-hidden="true"></span>
          </a>
        </div>
        <div className="pt-2 px-3">
          <a className="cta" href={ctaLink2 ?? undefined} target="_blank" rel="noopener noreferrer">
            {ctaText2}
            <span className="glyph" aria-hidden="true"></span>
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="card-footer pt-3 px-3">
      <a className="cta " href={ctaLink} target="_blank" rel="noopener noreferrer" aria-label={ linkAriaLabel == undefined ? ctaText : linkAriaLabel }>
        {ctaText}
        <span className="glyph" aria-hidden="true"></span>
      </a>
    </div>
  );
};

export default ProductCard;
