import "@ms-mwf/moray/dist/css/main.css";
import * as React from "react";
import "./ActionSteps.scss";

export interface IActionStepsProps {
  stepsHeader?: string;
  stepsFooterCTAText?: string;
  stepsFooterCTALink?: string;
  step1Card: JSX.Element;
  step2Card: JSX.Element;
  step3Card: JSX.Element;
}

function ActionSteps(props: IActionStepsProps) {
  const { stepsHeader, stepsFooterCTAText, stepsFooterCTALink, step1Card, step2Card, step3Card } = props;

  return (
    <>
      {stepsHeader !== undefined && (
        <div className="action-steps-header-container">
          <h2 id="actionStepsHeader" className="h2 text-center mb-0">
            {stepsHeader}
          </h2>
        </div>
      )}
      <div id="actionsteps" className="action-steps-container-style">
        <div className="action-step" id="step1">
          {step1Card}
        </div>
        <div className="action-step" id="step2">
          {step2Card}
          {stepsFooterCTAText !== undefined && stepsFooterCTALink !== undefined && (
            <div className="text-center">
              <a
                id="ctalink"
                href={stepsFooterCTALink}
                className={"cta base"}
                target="_blank"
                rel="noopener noreferrer"
              >
                {stepsFooterCTAText}
                <span className="glyph" aria-hidden="true"></span>
              </a>
            </div>
          )}
        </div>
        <div className="action-step" id="step3">
          {step3Card}
        </div>
      </div>
    </>
  );
}

export default ActionSteps;
