import localeService from "src/dataServices/LocaleService";
import { IProductCard } from "../../shared/ProductCard";
import ProductCardCarousel from "../../shared/ProductCardCarousel";

const ExploreTechCommunityCard = () => {
  const images = ["customer-stories-icon.svg", "demos-icon.svg", "podcasts-and-shows-icon.svg"];
  const productCardCarouselProps = localeService.getCategoryContent("TrainingV2")
    .ExploreSection3ProductCards as IProductCard[];

  for (let i = 0; i < images.length; i++) {
    productCardCarouselProps[i].imageSrc = `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/Training/${images[i]}`;
  }

  const numCardsPerSlide = { xl: 4, lg: 4, md: 3, sm: 2, xs: 1 };
  return (
    <ProductCardCarousel
      title={localeService.getText("TrainingV2", "ExploreSection3Subtitle")}
      productCardCarouselProps={productCardCarouselProps}
      numCardsPerSlide={numCardsPerSlide}
    />
  );
};

export default ExploreTechCommunityCard;
