import { Reducer } from "redux";
import { FeatureFlags } from "src/common/FeatureFlags";
import { FeatureFlagsActionTypes, IFeatureFlagsState } from "./types";

export const initialState: IFeatureFlagsState = FeatureFlags;
const reducer: Reducer<IFeatureFlagsState> = (state: IFeatureFlagsState = initialState, action): IFeatureFlagsState => {
  switch (action.type) {
    case FeatureFlagsActionTypes.FETCH_FLAGS: {
      return { ...state, isLoading: true };
    }
    case FeatureFlagsActionTypes.SET_FLAGS: {
      const flags = action.payload as IFeatureFlagsState;
      return { ...state, ...flags, isLoading: false };
    }
    default: {
      return state;
    }
  }
};

export { reducer as featureFlagsReducer };
