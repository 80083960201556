import Text from 'src/components/text/Text';
import TextLink, { ILink } from 'src/components/text/TextLink';
import localeService from 'src/dataServices/LocaleService';
import { HomePageStatusBanner } from './HomePageStatusBanner';
import { INonprofitMiniProfile, TSComplianceStatus, TSDataIssue, TSReasonCode, TSStatus } from 'src/state/nonprofit';
import { logService } from 'npo-common';
import { InProgressIconGlyph, InProgressIconGlyphColor } from 'src/common/Constants';

export enum HomePageStatus {
  RequestDeclined,
  RequestClosed,
  RequestInProgress,
  RequestNotEligible,
  RequestActionNeeded
}

export function getHomePageStatusBanner(status: HomePageStatus, metadata?: { [key: string]: string }) {
  switch (status) {
    case HomePageStatus.RequestDeclined:
      return getRequestDeclinedBanner();
    case HomePageStatus.RequestClosed:
      return getRequestClosedBanner();
    case HomePageStatus.RequestInProgress:
      return getRequestInProgressBannerExt(metadata);
    case HomePageStatus.RequestNotEligible:
      return getRequestIneligibleBanner(metadata);
    case HomePageStatus.RequestActionNeeded:
      return getRequestActionNeededBanner();
    default:
      return null;
  }
}

function getRequestDeclinedBanner() {
  let eligibilityUrl = localeService.getText('Urls', 'Eligibility');
  const title = <Text category="HomeV2" id="BannerDeclinedTitle" />;
  const para1 = <TextLink category="HomeV2" id="BannerDeclinedDesc1" href={eligibilityUrl} target="_blank" />;
  const para2 = <TextLink category="HomeV2" id="BannerDeclinedDesc2" href="/contactus" target="_blank" />;
  return (
    <HomePageStatusBanner
      iconGlyph="glyph-prepend-button-x"
      iconGlyphColor="#DC3545"
      statusTitle={title}
      statusDescription={[para1, para2]}
    />
  );
}

function getRequestClosedBanner() {
  const title = <Text category="HomeV2" id="BannerClosedTitle" />;
  const para1 = <Text category="HomeV2" id="BannerClosedDesc1" />;
  const para2 = (
    <TextLink
      category="HomeV2"
      id="BannerClosedDesc2"
      href="https://nonprofit.microsoft.com/getting-started"
      target="_blank"
    />
  );
  const para3 = <TextLink category="HomeV2" id="BannerClosedDesc3" href="/contactus" target="_blank" />;
  return (
    <HomePageStatusBanner
      iconGlyph="glyph-prepend-button-x"
      iconGlyphColor="#DC3545"
      statusTitle={title}
      statusDescription={[para1, para2, para3]}
    />
  );
}

function getRequestInProgressBanner() {
  const title = <Text category="HomeV2" id="BannerInProgressTitle" />;
  const para1 = <Text category="HomeV2" id="BannerInProgressDesc1" />;
  const para2 = <Text category="HomeV2" id="BannerInProgressDesc2" />;
  const para3 = <TextLink category="HomeV2" id="BannerInProgressDesc3" href="/contactus" target="_blank" />;
  return (
    <HomePageStatusBanner
      iconGlyph={InProgressIconGlyph}
      iconGlyphColor={InProgressIconGlyphColor}
      statusTitle={title}
      statusDescription={[para1, para2, para3]}
    />
  );
}

function getRequestIneligibleBanner(metadata: { [key: string]: string } | undefined) {
  let ineligibilityDate = '';
  if (metadata !== null && metadata !== undefined && 'ineligibilityDate' in metadata) {
    ineligibilityDate = metadata['ineligibilityDate'];
  }
  const title = <Text category="HomeV2" id="BannerIneligibleTitle" />;
  const para1 = (
    <Text category="HomeV2" id="BannerIneligibleDesc1" substitutionData={{ ineligibilityDate: ineligibilityDate }} />
  );
  const para2 = <TextLink category="HomeV2" id="BannerIneligibleDesc3" href="/contactus" target="_blank" />;
  const description = [para1, para2];

  return (
    <HomePageStatusBanner
      iconGlyph="glyph-prepend-warning"
      iconGlyphColor={InProgressIconGlyphColor}
      statusTitle={title}
      statusDescription={description}
    />
  );
}

function getRequestActionNeededBanner() {
  const title = <Text category="HomeV2" id="BannerActionNeededTitle" />;
  const para1 = <Text category="HomeV2" id="BannerActionNeededDesc1" />;
  const para2 = <TextLink category="HomeV2" id="BannerActionNeededDesc2" href="/contactus" target="_blank" />;
  return (
    <HomePageStatusBanner
      iconGlyph="glyph-prepend-warning"
      iconGlyphColor={InProgressIconGlyphColor}
      statusTitle={title}
      statusDescription={[para1, para2]}
    />
  );
}

function getRequestInProgressBannerExt(metadata: { [key: string]: string } | undefined) {
  logService.logInfo('InProgressBannerExt: MetaData: ', metadata);
  const tradeScreenEnabled = metadata?.tradeScreenEnabled === 'true';
  if (!tradeScreenEnabled) {
    return getRequestInProgressBanner();
  }

  const nonprofit: INonprofitMiniProfile = JSON.parse(metadata?.nonprofit ?? '{}');
  const nonprofitId = nonprofit?.id ?? '';
  const tsComplianceStatus: TSComplianceStatus | undefined = nonprofit?.tsComplianceStatus;

  if (!tsComplianceStatus || !tsComplianceStatus.status) {
    return getTS803Banner(nonprofitId);
  }

  switch (tsComplianceStatus.status.toLowerCase()) {
    case TSStatus.Allowed:
      return getRequestInProgressBanner();
    case TSStatus.UnderReview:
      return getTSUnderReviewBanner(nonprofitId, tsComplianceStatus, nonprofit);
    case TSStatus.IngestionError:
      return getTSIngestionErrorBanner();
    case TSStatus.Error:
      return getTS803Banner(nonprofitId);
    default:
      return getTSInProgressBanner();
  }
}

function getTSUnderReviewBanner(
  nonprofitId: string,
  tsComplianceStatus: TSComplianceStatus,
  nonprofit: INonprofitMiniProfile
) {
  try {
    if (!tsComplianceStatus.reasonCode) {
      return getTSInProgressBanner();
    }

    switch (tsComplianceStatus.reasonCode.toLowerCase()) {
      case TSReasonCode.SSI_D:
      case TSReasonCode.SSI_E:
      case TSReasonCode.SSI_F:
      case TSReasonCode.LIC_R:
        return getTS801Banner(nonprofitId);
      case TSReasonCode.DataIssue:
        return getTSDataIssueBanner(nonprofitId, tsComplianceStatus.dataIssueCode, nonprofit);
      default:
        return getTSInProgressBanner();
    }
  } catch (error) {
    logService.logError('getTSUnderReviewBanner: ', { error, nonprofitId, tsComplianceStatus });
    return getTSInProgressBanner();
  }
}

function getTSInProgressBanner() {
  const title = <Text category={getTSTextCategory()} id="BannerTSInProgressTitle" />;
  const para = (
    <TextLink category={getTSTextCategory()} id="BannerTSInProgressDesc" href="/contactus" target="_blank" />
  );
  return getInProgressBanner(title, [para]);
}

function getTS801Banner(nonprofitId: string) {
  const title = <Text category={getTSTextCategory()} id="BannerTS801Title" />;
  const para = (
    <TextLink
      category={getTSTextCategory()}
      id="BannerTS801Desc"
      href="/contactus"
      target="_blank"
      substitutionData={{ nonprofitId: nonprofitId }}
    />
  );
  return getInProgressBanner(title, [para]);
}

function getTS803Banner(nonprofitId: string) {
  const title = <Text category={getTSTextCategory()} id="BannerTS803Title" />;
  const para = (
    <TextLink
      category={getTSTextCategory()}
      id="BannerTS803Desc"
      href="/contactus"
      target="_blank"
      substitutionData={{ nonprofitId: nonprofitId }}
    />
  );
  return getInProgressBanner(title, [para]);
}

function getTSDataIssueBanner(nonprofitId: string, dataIssueCode: string, nonprofit: INonprofitMiniProfile) {
  const links: ILink[] = [
    { href: '/profile', target: '_blank' },
    { href: '/contactus', target: '_blank' }
  ];
  const title = <Text category={getTSTextCategory()} id="BannerTSDataIssueTitle" />;
  const descId = getDataIssueDescriptionId(nonprofitId, dataIssueCode);
  const profileData = getProfileData(nonprofit);
  const para = <TextLink category={getTSTextCategory()} id={descId} links={links} substitutionData={profileData} />;
  return getInProgressBanner(title, [para]);
}

function getDataIssueDescriptionId(nonprofitId: string, dataIssueCode: string) {
  try {
    if (!dataIssueCode) {
      return 'BannerTSDataIssueDefaultDesc';
    }

    dataIssueCode = dataIssueCode.trim().toLocaleLowerCase();

    switch (dataIssueCode) {
      case TSDataIssue.Name:
        return 'BannerTSDataIssueNameDesc';
      case TSDataIssue.Org:
        return 'BannerTSDataIssueOrgDesc';
      case TSDataIssue.Address:
        return 'BannerTSDataIssueAddressDesc';
      case TSDataIssue.LegalId:
        return 'BannerTSDataIssueLegalIdDesc';
      default:
        return 'BannerTSDataIssueDefaultDesc';
    }
  } catch (error) {
    logService.logError('getDataIssueFieldText: ', { error, nonprofitId, dataIssueCode });
    return 'BannerTSDataIssueDefaultDesc';
  }
}

function getProfileData(nonprofit: INonprofitMiniProfile) {
  try {
    if (!nonprofit) return { agentName: '', orgName: '', regId: '' };

    const agentName = getAgentName(nonprofit);
    const orgName = getOrgName(nonprofit);
    const regId = getRegId(nonprofit);

    return { agentName, orgName, regId };
  } catch (error) {
    logService.logError('getProfileData: ', { error, nonprofitId: nonprofit?.id });
    return { agentName: '', orgName: '', regId: '' };
  }
}

function getAgentName(nonprofit: INonprofitMiniProfile) {
  try {
    if (!nonprofit) return '';

    const firstName = nonprofit.primaryContact?.firstName ?? '';
    const middleName = nonprofit.primaryContact?.middleName ?? '';
    const lastName = nonprofit.primaryContact?.lastName ?? '';
    let agentName = (firstName + ' ' + middleName + ' ' + lastName)?.trim();
    return agentName ? `[${agentName}]` : '';
  } catch (error) {
    logService.logError('getAgentName: ', { error, nonprofitId: nonprofit?.id });
    return '';
  }
}

function getOrgName(nonprofit: INonprofitMiniProfile) {
  try {
    if (!nonprofit) return '';

    return nonprofit.name ? `[${nonprofit.name}]` : '';
  } catch (error) {
    logService.logError('getOrgName: ', { error, nonprofitId: nonprofit?.id });
    return '';
  }
}

function getRegId(nonprofit: INonprofitMiniProfile) {
  try {
    if (!nonprofit?.registrationIdentifiers?.length) return '';

    const regId =
      nonprofit.registrationIdentifiers[nonprofit.registrationIdentifiers.length - 1]?.legalIdentifier ?? '';
    return regId ? `[${regId}]` : '';
  } catch (error) {
    logService.logError('getRegId: ', { error, nonprofitId: nonprofit?.id });
    return '';
  }
}

function getTSIngestionErrorBanner() {
  const links: ILink[] = [
    { href: '/profile', target: '_blank' },
    { href: '/contactus', target: '_blank' }
  ];
  const title = <Text category={getTSTextCategory()} id="BannerTSDataIssueTitle" />;
  const para = <TextLink category={getTSTextCategory()} id="BannerTSDataIssueDefaultDesc" links={links} />;
  return getInProgressBanner(title, [para]);
}

function getInProgressBanner(title: JSX.Element, description: JSX.Element[]) {
  return (
    <HomePageStatusBanner
      iconGlyph={InProgressIconGlyph}
      iconGlyphColor={InProgressIconGlyphColor}
      statusTitle={title}
      statusDescription={description}
    />
  );
}

function getTSTextCategory() {
  return 'TradeScreen';
}
