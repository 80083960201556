import { INonprofitBaseProfile } from "../nonprofit";

export const enum ProfileActionTypes {
  FETCH_PROFILE_REQUEST = "@@profile/FETCH_PROFILE_REQUEST",
  FETCH_PROFILE_SUCCESS = "@@profile/FETCH_PROFILE_SUCCESS",
  FETCH_CONTACT_PERMISSION_SETTINGS = "@@profile/FETCH_CONTACT_PERMISSION_SETTINGS",
  PROFILE_FETCH_ERROR = "@@profile/PROFILE_FETCH_ERROR",
  INITIATE_PROFILE_EDIT = "@@profile/INITIATE_PROFILE_EDIT",
  PROFILE_FORM_UPDATE = "@@profile/PROFILE_FORM_UPDATE",
  PROFILE_ACK_ATTESTATION = "@@profile/PROFILE_ACK_ATTESTATION",
  PROFILE_UPDATE_COMPLETE = "@@profile/PROFILE_UPDATE_COMPLETE",
  CANCEL_PROFILE_EDIT = "@@profile/CANCEL_PROFILE_EDIT",
  CANCEL_PROFILE_ACK_ATTESTATION = "@@profile/CANCEL_PROFILE_ACK_ATTESTATION",
  PROFILE_UPDATE_ERROR = "@@profile/PROFILE_UPDATE_ERROR",
  FETCH_PROFILE_COMMERCIAL = "@@profile/FETCH_PROFILE_COMMERCIAL",
  FETCH_NEW_PROFILE_SUCCESS = "@@profile/FETCH_NEW_PROFILE_SUCCESS",
  INITIATE_NEW_PROFILE_EDIT = "@@profile/INITIATE_NEW_PROFILE_EDIT",
  NEW_PROFILE_ACK_ATTESTATION = "@@profile/NEW_PROFILE_ACK_ATTESTATION",
  NEW_PROFILE_CREATE_COMPLETE = "@@profile/NEW_PROFILE_CREATE_COMPLETE",
  NEW_PROFILE_FORM_UPDATE = "@@profile/NEW_PROFILE_FORM_UPDATE",
  CANCEL_NEW_PROFILE_ACK_ATTESTATION = "@@profile/CANCEL_NEW_PROFILE_ACK_ATTESTATION",
  PROFILE_FORM_SUBMISSION_VALIDATION = "@@profile/PROFILE_FORM_SUBMISSION_VALIDATION",
  PROFILE_FORM_SUBMISSION_VALIDATION_SUCCESS = "@@profile/PROFILE_FORM_SUBMISSION_VALIDATION_SUCCESS",
  INITIATE_AGENT_EMAIL_CHANGE = "@@profile/INITIATE_AGENT_EMAIL_CHANGE",
  INITIATE_AGENT_EMAIL_EDIT = "@@profile/INITIATE_AGENT_EMAIL_EDIT",
  AGENT_EMAIL_SUBMISSION_RESULT = "@@profile/AGENT_EMAIL_SUBMISSION_IN_PROCESS",
  SET_EDIT_MODE = "@@profile/SET_EDIT_MODE",
  SET_COMMERCIAL = "@@profile/SET_COMMERCIAL",
  SET_SHOW_CONFIRMATION = "@@profile/SET_SHOW_CONFIRMATION",
  SAVE_PROFILE = "@@SAVE_PROFILE",
  CREATE_PROFILE = "@@CREATE_PROFILE"
}

export interface IProfile extends INonprofitBaseProfile {
  missionStatement: string;
  operatingBudget: string;
  organizationSize: string;
  activityCode: string;
  domain: string;
  primaryContact: IContact;
  websiteUrl: string;
  notificationEmails: string[];
  isEditable: boolean;
}

// Additional fields to hold display
export interface IProfileDisplay {
  activityCodeDisplay: string;
  organizationSizeDisplay: string;
  effectiveDateDisplay: string;
}
export interface IContact {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  emailLanguagePreference: string;
  jobTitle: string;
}

export interface IProfileState {
  readonly profile: IProfile;
  readonly isLoading?: boolean;
  readonly validationRequired?: boolean;
  readonly error?: string;
  readonly isNewProfile?: boolean;
  readonly contactPermissionSettings?: IContactPermissionSettings;
  readonly isProfileSubmissionValidationInProgress?: boolean;
  readonly isProfileValidationSuccess?: boolean;
  readonly editEmailState: IEditEmailState;
  readonly editMode: boolean;
  readonly showConfirmation: boolean;
  readonly isCommercial: boolean;
}

export interface IContactPermissionSettings {
  visible: boolean;
  checkedByDefault: boolean;
  shouldLoadBlockingPage: boolean;
}

export interface IRegulatoryBody {
  acronym: string;
  countryCode: string;
  regexPattern: RegExp;
  regulatoryBodyAcronym: string;
  required: boolean;
  textKey: string;
  name: string;
}

export interface IProfileFormObject {
  registeredFields: IProfile;
  values: IProfile;
  initial: IProfile;
  fields: IProfile;
  anyTouched: boolean;
  syncErrors: IProfile;
  submitFailed: boolean;
}

export interface INonprofitEmailUpdate {
  agentEmail: string;
}

export interface IEditEmailState {
  inProgress: boolean;
  result: boolean;
}

export interface IAddressResources {
  countryCode: string;
  addressFields: IAddressField[];
  responseCode: string;
  correlationId: string;
}

export interface IAddressField {
  fieldID: string;
  required: boolean;
  label: string;
  title: string;
  fieldItems?: IFieldItem[];
  fieldRegex?: string;
  fieldExample?: string;
}

export interface IFieldItem {
  regionId: string;
  fieldId: string;
  value: string;
  label: string;
}

export const defaultAddressResponseUS = {
  addressFields: [
    {
      fieldID: "PostalCode",
      required: true,
      fieldRegex: "^\\d{5}(-\\d{4})?$",
      fieldExample: "98052",
      fieldItems: null,
      label: "Postal code",
      title: "Postal code"
    }
  ],
  responseCode: "",
  correlationId: "",
  countryCode: "US"
} as unknown as IAddressResources;

export const defaultAddressResponse = {
  addressFields: [
    {
      fieldID: "PostalCode",
      required: true,
      fieldRegex: "",
      fieldExample: "",
      fieldItems: null,
      label: "Postal code",
      title: "Postal code"
    }
  ],
  responseCode: "",
  correlationId: ""
} as unknown as IAddressResources;
