import { all, fork } from "redux-saga/effects";
import accountGuardOffersRequestSaga from "./accountguard/sagas";
import azureOfferSaga from "./azure/sagas";
import bingAdsOfferSaga from "./bing-ads/sagas";
import OffersRequestSaga from "./shared/sagas";
import sendToPartnerSaga from "./software/send-to-partner/sagas";

function* offersSaga() {
  yield all([
    fork(azureOfferSaga),
    fork(sendToPartnerSaga),
    fork(OffersRequestSaga),
    fork(accountGuardOffersRequestSaga),
    fork(bingAdsOfferSaga)
  ]);
}

export default offersSaga;
