import { Icon } from "@fluentui/react/lib/Icon";
import "./ActionCard.scss";

interface IActionCardProps {
  icon: string;
  header?: string;
  content?: string;
  button: any;
}

const ActionCard = (props: IActionCardProps) => (
  <section className="ms-Grid-col ms-sm-12 ms-lg6 ms-md12 action-card-section">
    <div className="action-card-section-header">
      <div className="action-card-section-icon">
        <Icon iconName={props.icon} />
      </div>
      {props.header}
    </div>
    <p className="action-card-description">{props.content}</p>
    {props.button}
  </section>
);

export default ActionCard;
