import { History } from "history";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { IApplicationState } from "src/state";
import { isConsentProvided } from "../cookie-consent/WcpCookieConsent";
import { MarketingCookieManager } from "./MarketingCookieManager";

declare global {
  // eslint:disable-next-line:interface-name
  interface Window {
    dataLayer: any;
  }
}

const WcpConsent = window.WcpConsent;

export interface IOwnProps {
  history: History;
}
export interface IPropsFromState {
  hasConsent: boolean;
}

type IMarketingTagsProps = IPropsFromState & IOwnProps;
export class MarketingTags extends React.Component<IMarketingTagsProps> {
  private googleMarketingId: string = "DC-8219576";

  public componentDidUpdate(prevProps: IMarketingTagsProps) {
    this.loadMarketingTags();
  }

  public render() {
    return null;
  }

  /**
   * Load marketing tags based on the consent provided.
   *
   * This method only decides which scripts should be added to the page and does not manage the deletion of any related
   * cookies. Instead, that task is handled by the MarketingCookieManager whenever consent changes.
   */
  private loadMarketingTags() {
    const hasAnalyticsConsent = isConsentProvided(WcpConsent.consentCategories.Analytics);
    const hasAdvertisingConsent = isConsentProvided(WcpConsent.consentCategories.Advertising);

    if (hasAnalyticsConsent && hasAdvertisingConsent) {
      this.addGoogleTagManager();
    }

    // Apparently adobe tag manager leverages google under the hood as well. This means some google cookies
    // like IDE and _gcl_au will be included even if we aren't explicitly loading google tag manager.
    //
    // This means any restrictions being placed on google tag manager should probably be place here as well.
    if (hasAnalyticsConsent && hasAdvertisingConsent) {
      this.addAdobeTagManager();
    }

    if (hasAnalyticsConsent && hasAdvertisingConsent) {
      this.addClarity();
    }

    // ensure we start out with a clean set of cookies
    MarketingCookieManager.cleanupMarketingCookies();
  }

  private addGoogleTagManager() {
    const gtmScriptExists = document.getElementById("gtmTag");
    if (!gtmScriptExists) {
      this.addScriptSource(`https://www.googletagmanager.com/gtag/js?id=${this.googleMarketingId}`, "gtmTag");
      this.addGoogleMarketingTag();

      this.props.history.listen(() => {
        this.addGoogleMarketingTag();
      });
    }
  }

  private addGoogleMarketingTag() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ js: new Date() });
    window.dataLayer.push({ config: this.googleMarketingId });
  }

  private addAdobeTagManager() {
    const adobeScriptExists = document.getElementById("launchTag");
    if (!adobeScriptExists) {
      this.addScriptSource("//assets.adobedtm.com/launch-ENdb9a12c41c734ad3ab4a01a64f2019d1.min.js", "launchTag", true);
    }
  }

  private addClarity() {
    const existingScript = document.getElementById("clarity-script");
    if (!existingScript) {
      const projectCode = process.env.REACT_APP_NONPROFIT_PORTAL_CLARITY_CODE;
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.innerHTML = `(function(c,l,a,r,i,t,y){ \n \
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};\n \
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;\n \
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);\n \
      })(window, document, "clarity", "script", "${projectCode}");`;
      script.id = "clarity-script";
      document.head.appendChild(script);
    }
  }

  private addScriptSource(src: string, scriptId?: string, addToHead: boolean = false) {
    const script = document.createElement("script");
    if (scriptId) {
      script.id = scriptId;
    }
    script.src = src;
    script.type = "text/javascript";
    script.async = true;
    if (addToHead) {
      document.head.appendChild(script);
    } else {
      document.body.appendChild(script);
    }
  }
}

const mapStateToProps = ({ wcpCookieConsent }: IApplicationState): IPropsFromState => ({
  hasConsent: wcpCookieConsent.hasConsent
});

export default withRouter(connect(mapStateToProps)(MarketingTags));
