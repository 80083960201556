import { Reducer } from "redux";
import { BingAdsActionTypes, BingAdsRegistrationInfo, BingAdsRegistrationState } from "./types";

const initialState: BingAdsRegistrationState = {
  adsRegistrationInfo: undefined as unknown as BingAdsRegistrationInfo,
  httpStatusCode: undefined as unknown as string,
  errorMessage: undefined as unknown as string,
  isLoading: false
};

const reducer: Reducer<BingAdsRegistrationState> = (
  state: BingAdsRegistrationState = initialState,
  action
): BingAdsRegistrationState => {
  switch (action.type) {
    case BingAdsActionTypes.FETCH_ADS_REGISTRATION_INFO: {
      return { ...state, isLoading: true };
    }
    case BingAdsActionTypes.SUBMIT_CLAIM_ADS_OFFER: {
      return { ...state, isLoading: true };
    }
    case BingAdsActionTypes.SET_ADS_REGISTRATION_INFO: {
      const registrationInfo: BingAdsRegistrationInfo = action.payload;
      return {
        ...state,
        adsRegistrationInfo: registrationInfo,
        httpStatusCode: "200",
        errorMessage: undefined as unknown as string,
        isLoading: false
      };
    }
    case BingAdsActionTypes.SET_ADS_REGISTRATION_INFO_ERROR: {
      const errorState: BingAdsRegistrationState = action.payload;
      return errorState;
    }
    default: {
      return state;
    }
  }
};

export { reducer as bingAdsReducer };

