import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import signinLogo from "src/assets/images/signin.png";
import localeService from "src/dataServices/LocaleService";
import { IMobileIconProps } from "src/common/utilities/Utilities";
import { IApplicationState } from "src/state";
import { initiateLogin } from "src/state/auth";
import LoadingButton from "../utilities/LoadingButton";
import "./SignInCTA.scss";

interface IPropsFromState {
  isSigningIn: boolean;
}

interface IPropsFromDispatch {
  initiateLogin: typeof initiateLogin;
}

export type ISignInCTAProps = IPropsFromDispatch & IPropsFromState;

export class SignInCTA extends React.Component<ISignInCTAProps> {
  public render() {
    const { initiateLogin, isSigningIn } = this.props;
    const alreadyRegistered = localeService.getText("UserPanel", "AlreadyRegistered");
    const alreadyRegisteredCTA = localeService.getText("UserPanel", "AlreadyRegisteredCTA");
    const signInText = localeService.getText("UserPanel", "SignIn");
    const mobileImageIconProps = {
      isImage: true,
      name: signinLogo,
      imageProps: { width: 35, height: 35 }
    } as IMobileIconProps;

    return (
      <section id="sign-in-cta">
        <div className="cta-text-div">
          <span className="cta-text">{alreadyRegistered}</span>
        </div>
        <div className="signin-button">
          <div className="ms-Grid-col ms-hiddenSm">
            <LoadingButton
              isExternal={true}
              tabIndex={0}
              onKeyDown={this.handleKeyDown}
              onClick={initiateLogin}
              title={alreadyRegisteredCTA}
              text={signInText}
              loading={isSigningIn}
              role="link"
              properties={{ ButtonName: "Signin-CTA" }}
            />
          </div>
          <div className="ms-Grid-col ms-hiddenMdUp mobileView">
            <LoadingButton
              mobileIconProps={mobileImageIconProps}
              tabIndex={0}
              onKeyDown={this.handleKeyDown}
              onClick={initiateLogin}
              title={alreadyRegisteredCTA}
              text={signInText}
              loading={isSigningIn}
              role="link"
              properties={{ ButtonName: "Signin-CTA-Mobile" }}
            />
          </div>
        </div>
      </section>
    );
  }

  private handleKeyDown = (evt: { key: string }) => {
    if (evt.key === "Enter") {
      this.props.initiateLogin();
    }
  };
}

const mapStateToProps = ({ auth }: IApplicationState): IPropsFromState => ({
  isSigningIn: auth.isSigningIn
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initiateLogin: () => dispatch(initiateLogin())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignInCTA);
