import { ActionButton, IIconProps } from "@fluentui/react";
import * as React from "react";
import localeService from "src/dataServices/LocaleService";
import localizationService from "src/dataServices/LocalizationService";
import { logService, TelemetryEventType } from "npo-common";
import { extractLocale, isNullOrUndefined } from "src/common/utilities/Utilities";
import { ILanguage, ILocalizationState } from "src/state/localization/types";
import { isLocalizationEnabled } from "../utilities/Localization/Utils";
import "./LanguageSelector.scss";

interface IPropsFromState {
  localeState: ILocalizationState;
  pathName: string;
  id: string;
}

interface ILanguageSelectorLocalState {
  showLanguageContextMenu: boolean;
  contextMenuTarget: HTMLElement | null;
}

const contextMenuIcon: IIconProps = { iconName: "world" };

export class LanguageSelector extends React.Component<IPropsFromState, ILanguageSelectorLocalState> {
  private localizationEnabled = isLocalizationEnabled();
  private contextMenuItems = this.getLanguageContextMenuItems();

  constructor(props: IPropsFromState) {
    super(props);

    this.state = {
      showLanguageContextMenu: false,
      contextMenuTarget: null
    };
  }

  public componentDidUpdate(prevProps: IPropsFromState) {
    if (prevProps.pathName !== this.props.pathName) {
      this.contextMenuItems = this.getLanguageContextMenuItems();
    }
  }

  public render() {
    return (
      this.localizationEnabled &&
      !isNullOrUndefined(this.props.localeState) &&
      !isNullOrUndefined(this.props.localeState.currentLocale) && (
        <div id={this.props.id} className="language-selector">
          <ActionButton
            iconProps={contextMenuIcon}
            menuProps={{
              id: this.props.id + "-menu",
              items: this.contextMenuItems.length > 1 ? this.contextMenuItems : []
            }}
            persistMenu={true}
            onClick={this.onContextMenuClick}
            onRenderMenuIcon={() => <div />}
            ariaLabel={
              localeService.getText("LanguageSelector", "AriaLabel") + " " + this.getCurrentLanguageFormattedText()
            }
            ariaDescription={localeService.getText("LanguageSelector", "AriaDescription")}
          >
            {this.getCurrentLanguageFormattedText()}
          </ActionButton>
        </div>
      )
    );
  }

  private onContextMenuClick() {
    logService.logEvent({
      Name: 'ButtonClick',
      ButtonName: "Language-ContextMenu",
      EventType: TelemetryEventType.SecondaryWorkflow,
      SloId: ''
    });
  }

  private onContextMenuItemClick(itemKey: string): boolean | void {
    if (!isNullOrUndefined(itemKey)) {
      const selectedLocale = itemKey;
      const newPath = this.getNewPath(window.location.pathname, selectedLocale);
      if (!isNullOrUndefined(newPath)) {
        window.location.assign(window.location.origin + newPath);
      }
      logService.logEvent({
        Name: 'ButtonClick',
        ButtonName: "Language-ContextMenuItem",
        SelectedLocale: selectedLocale,
        EventType: TelemetryEventType.SecondaryWorkflow,
        SloId: ''
      });
    }
    this.setState({ showLanguageContextMenu: false });
  }

  private getLanguageContextMenuItems() {
    const languages = localizationService.getSupportedLanguages();

    if (isNullOrUndefined(languages) || languages.length === 0) {
      return [];
    }
    return languages.map(l => {
      return {
        key: l.locale,
        text: this.formatLanguageText(l),
        ["data-locale"]: l.locale,
        onClick: () => {
          this.onContextMenuItemClick(l.locale);
        }
      };
    });
  }

  private getCurrentLanguageFormattedText(): string {
    return this.formatLanguageText(localizationService.getLangauge(this.props.localeState.currentLocale));
  }

  private formatLanguageText(l: ILanguage | undefined): string {
    return isNullOrUndefined(l) ? "" : l?.country + " - " + l?.language;
  }

  private getNewPath(path: string, locale: string): string | null {
    if (isNullOrUndefined(path) || isNullOrUndefined(locale)) {
      return null;
    }

    const localeFromUrl = extractLocale(path);
    return !isNullOrUndefined(localeFromUrl) && localeFromUrl ? path.replace(localeFromUrl, locale) : "/" + locale + path;
  }
}

export default LanguageSelector;
