import { useSelector } from 'react-redux';
import localeEnabledForV2, { LocaleConfigStrings } from '../../../common/localeEnabledForV2';
import { IApplicationState } from '../../../state';

interface IUseD365CopilotOfferUpdate {
  isD365CopilotOfferAvailable: boolean;
}

const useD365CopilotOfferUpdate = (): IUseD365CopilotOfferUpdate => {
  const featureFlags = useSelector((state: IApplicationState) => state.flags);
  const isD365CopilotOfferAvailable =
    featureFlags.D365CopilotOfferUpdateEnabled && localeEnabledForV2(LocaleConfigStrings.D365CopilotOfferUpdate);

  return {
    isD365CopilotOfferAvailable
  };
};

export default useD365CopilotOfferUpdate;
