import * as React from "react";
import Text from "src/components/text/Text";
import localeService from "src/dataServices/LocaleService";

const LinkedInDiscountBanner = () => {
  return (
    <section>
      <div className="card d-block mx-ng mx-md-0 mt-5 mb-5">
        <div id="linkedin-discount-section" className="row no-gutters material-surface ml-5 mr-5">
          <div className="col-md-6">
            <picture>
              <source
                srcSet={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/LinkedIn/LinkedIn-Banner-1920.jpg`}
                media="(min-width: 1400px)"
              />
              <source
                srcSet={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/LinkedIn/LinkedIn-Banner-1400.jpg`}
                media="(min-width: 1084px)"
              />
              <source
                srcSet={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/LinkedIn/LinkedIn-Banner-1084.jpg`}
                media="(min-width: 860px)"
              />
              <source
                srcSet={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/LinkedIn/LinkedIn-Banner-860.jpg`}
                media="(min-width: 540px)"
              />
              <img
                src={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/LinkedIn/LinkedIn-Banner-540.jpg`}
                className="card-img img-object-cover"
                alt={localeService.getText("LinkedInV2", "DiscountBannerPlaceHolder")}
              />
            </picture>
          </div>
          <div className="d-flex col-md">
            <div className="card-body align-self-center p-4 p-md-5">
              <h2 id="linkedin-discount-title" className="text-style h2">
                <Text category="LinkedInV2" id="DiscountBannerTitle" />
              </h2>
              <div id="linkedin-discount-description" className="Detail-text mb-3 mt-2">
                <p>
                  <Text category="LinkedInV2" id="DiscountBannerDescription" />
                </p>
              </div>
              <a
                id="linkedin-discount-button"
                href={localeService.getText("LinkedInV2", "LinkedInUrl")}
                className="cta"
                target="_blank"
                rel="noopener noreferrer"
              >
                {localeService.getText("LinkedInV2", "DiscountBannerButton")}
                <span className="glyph" aria-hidden="true"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LinkedInDiscountBanner;
