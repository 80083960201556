import * as React from "react";
import Text from "src/components/text/Text";
import TextLink, { ILink } from "src/components/text/TextLink";
import "./AzureGrantDisclaimer.scss";

const AzureGrantDisclaimer = () => {
  const links: ILink[] = [
    { href: "https://privacy.microsoft.com/en-us/privacystatement", target: "_blank" },
    { href: "https://azure.microsoft.com/en-us/offers/ms-azr-0036p/", target: "_blank" }
  ];
  return (
    <div className="azure-grant-disclaimer card mx-ng mx-md-0">
      <div className="row no-gutters material-surface">
        <div className="disclaimer-text">
          <blockquote className="card-body m-0">
            <p className="disclaimer-paragraph">
              <Text category="AzureV2" id={"Disclaimer1"} />
            </p>
            <br />
            <p className="disclaimer-paragraph">
              <TextLink category="AzureV2" id={"Disclaimer2"} links={links} />
            </p>
          </blockquote>
        </div>
      </div>
    </div>
  );
};

export default AzureGrantDisclaimer;
