import ActionStepCard from "src/components/offers/shared/ActionStepCard";
import ActionSteps from "src/components/offers/shared/ActionSteps";
import localeService from "src/dataServices/LocaleService";
import { scrollOptions } from "src/common/utilities/Utilities";

export interface IM365Steps {
  plansElement: HTMLElement | null;
  setRef?: (el: HTMLDivElement | null) => void;
}

export default function M365Steps(props: IM365Steps) {
  const step1ImagePath = "hub-redesign/ActionStep_1_3x.png";
  const step2ImagePath = "hub-redesign/ActionStep_2_3x.png";
  const step3ImagePath = "hub-redesign/ActionStep_3_3x.png";
  const { plansElement } = props;

  return (
    <div
      ref={el => {
        if (props.setRef !== undefined) {
          props.setRef(el);
        }
      }}
    >
      <ActionSteps
        stepsHeader={localeService.getText("Microsoft365V2", "StepsHeader")}
        step1Card={
          <ActionStepCard
            stepIconImagePath={step1ImagePath}
            cardTextCategory="Microsoft365V2"
            cardTitle="StepOneTitle"
            cardDescription="StepOneDescription"
            ctaText="StepOneAction"
            onClickAction={() => {
              if (plansElement !== null) {
                plansElement.scrollIntoView(scrollOptions);
              }
            }}
          />
        }
        step2Card={
          <ActionStepCard
            stepIconImagePath={step2ImagePath}
            cardTextCategory="Microsoft365V2"
            cardTitle="StepTwoTitle"
            cardDescription="StepTwoDescription"
            ctaText="StepTwoAction"
            onClickAction={() => {
              window.open(localeService.getText("Urls", "ActivateM365"));
            }}
          />
        }
        step3Card={
          <ActionStepCard
            stepIconImagePath={step3ImagePath}
            cardTextCategory="Microsoft365V2"
            cardTitle="StepThreeTitle"
            cardDescription="StepThreeDescription"
            ctaText="StepThreeAction"
            onClickAction={() => {
              window.open(localeService.getText("Urls", "M365CustomerStories"));
            }}
          />
        }
      />
    </div>
  );
}
