import { ActionButton } from "@fluentui/react";
import * as React from "react";
import { connect } from "react-redux";
import Text from "src/components/text/Text";
import TextLink from "src/components/text/TextLink";
import localeService from "src/dataServices/LocaleService";
import { IApplicationState } from "src/state";
import { IOffer, OfferId } from "src/state/nonprofit";
import { AzureRegistrationResultCode } from "src/state/offers/azure/types";
import { AzureSpecialOffer } from "../licensing-and-credits/AzureOffer";
import { AzureSpecialOfferClaimed } from "../licensing-and-credits/AzureOfferClaimed";
import { Windows10EnterpriseE3Offer } from "../licensing-and-credits/Windows10EnterpriseE3Offer";
import { WindowsUpgradeOffer } from "../licensing-and-credits/WindowsUpgradeOffer";
import OfferCards from "../shared/OfferCards";
import { AzureOnboardingConcierge } from "../support-onboarding-services/AzureOnboardingConcierge";
import { CommunityTraining } from "../support-onboarding-services/CommunityTraining";
import { WVDHelpdesk } from "../support-onboarding-services/WVDHelpdesk";
import "./Covid19.scss";
import { MoreInfo } from "./MoreInfo";

export interface ICovid19Props {
  availableOffers?: IOffer[];
  azureOfferEndDate: string;
  azureOfferId: string;
  accountOwnerLiveId: string;
  isAzureClaimSuccess: boolean;
}

export class Covid19 extends React.Component<
  ICovid19Props,
  {
    readMore: boolean;
  }
> {
  constructor(props: ICovid19Props) {
    super(props);
    this.state = { readMore: false };
  }

  public render() {
    const { availableOffers, azureOfferId, azureOfferEndDate, isAzureClaimSuccess } = this.props;
    const azureOfferExists = azureOfferId !== null && azureOfferEndDate !== null;

    const LicensingAndCreditsOffers: Map<string, any> = new Map([
      [
        OfferId.covid19_windows_upgrade,
        {
          category: "WindowsUpgradeOffer",
          logo: "WindowsLogo",
          detailComponent: <WindowsUpgradeOffer />,
          requestButtonEnabled: true
        }
      ],
      [
        OfferId.covid19_win10_E3_licenses,
        {
          category: "Windows10EnterpriseE3Offer",
          logo: "WindowsLogo",
          detailComponent: <Windows10EnterpriseE3Offer />,
          requestButtonEnabled: true
        }
      ],
      [
        OfferId.covid19_azure_credits,
        {
          category: "AzureSpecialOffer",
          logo: "AzureLogo",
          detailComponent:
            azureOfferExists && (azureOfferId === OfferId.covid19_azure_credits || isAzureClaimSuccess) ? (
              <AzureSpecialOfferClaimed endDate={azureOfferEndDate} />
            ) : (
              <AzureSpecialOffer />
            ),
          requestButtonEnabled: true
        }
      ]
    ]);

    const SupportAndOnboardingServicesOffers: Map<string, any> = new Map([
      [
        OfferId.covid19_azure_onboarding_concierge,
        {
          category: "AzureOnboardingConcierge",
          logo: "AzureLogo",
          detailComponent: <AzureOnboardingConcierge />,
          requestButtonEnabled: true
        }
      ],
      [
        OfferId.covid19_wvd_helpdesk,
        { category: "WVDHelpdesk", logo: "WindowsLogo", detailComponent: <WVDHelpdesk />, requestButtonEnabled: true }
      ],
      [
        OfferId.covid19_ms_community_training,
        {
          category: "CommunityTraining",
          logo: "AzureLogo",
          detailComponent: <CommunityTraining />,
          requestButtonEnabled: true
        }
      ]
    ]);
    const LicensingAndCreditsOffersExists =
      availableOffers && availableOffers.filter(o => LicensingAndCreditsOffers.has(o.id)).length > 0;
    const SupportAndOnboardingServicesExists =
      availableOffers && availableOffers.filter(o => SupportAndOnboardingServicesOffers.has(o.id)).length > 0;

    return LicensingAndCreditsOffersExists || SupportAndOnboardingServicesExists ? (
      <section id="covid19">
        <p>
          <Text category="Covid19Details" id="Info1" />
        </p>
        {!this.state.readMore && (
          <ActionButton
            id="more-info-command-button"
            className="truncate-text-button"
            iconProps={{ iconName: "CirclePlus" }}
            ariaLabel={localeService.getText("Covid19Details", "ReadMore")}
            ariaDescription={localeService.getText("Covid19Details", "ReadMoreDescription")}
            onClick={this.setReadMore(true)}
            text={localeService.getText("Covid19Details", "ReadMore")}
          />
        )}
        {this.state.readMore && (
          <>
            <MoreInfo />
            <ActionButton
              id="less-info-command-button"
              className="truncate-text-button"
              iconProps={{ iconName: "SkypeCircleMinus" }}
              ariaLabel={localeService.getText("Covid19Details", "ShowLess")}
              ariaDescription={localeService.getText("Covid19Details", "ShowLessDescription")}
              onClick={this.setReadMore(false)}
              text={localeService.getText("Covid19Details", "ShowLess")}
            />
          </>
        )}
        {LicensingAndCreditsOffersExists && (
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12">
              <p className="offer-section-header">
                <Text category="Covid19Details" id="Header1" />
              </p>
              <section className="category-help-text">
                <p>
                  <Text category="Covid19Details" id="Attestation1" />
                </p>
                <p>
                  <TextLink category="Covid19Details" id="Attestation2" href="Eligibility" target="_blank" />
                </p>
              </section>
              <OfferCards offers={availableOffers} OffersMapping={LicensingAndCreditsOffers} />
            </div>
          </div>
        )}
        {SupportAndOnboardingServicesExists && (
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12">
              <p className="offer-section-header">
                <Text category="Covid19Details" id="Header2" />
              </p>
              <section className="category-help-text">
                <p>
                  <Text category="Covid19Details" id="OnlyEnglishSupport" />
                </p>
              </section>
              <OfferCards offers={availableOffers} OffersMapping={SupportAndOnboardingServicesOffers} />
            </div>
          </div>
        )}
      </section>
    ) : null;
  }

  private setReadMore = (readMoreValue: boolean): (() => void) => {
    return (): void => {
      this.setState({ readMore: readMoreValue });
    };
  };
}

const mapStateToProps = ({ nonprofit, offers }: IApplicationState) => ({
  availableOffers: nonprofit.miniProfile.availableOffers,
  azureOfferEndDate: offers.azure.endDate,
  azureOfferId: offers.azure.offerId,
  accountOwnerLiveId: offers.azure.accountOwnerLiveId,
  isAzureClaimSuccess:
    offers.azure.registrationResultCode ? offers.azure.registrationResultCode === AzureRegistrationResultCode.Ok : false
});

export default connect(mapStateToProps)(Covid19);
