import { Icon } from "@fluentui/react";
import "@ms-mwf/moray/dist/css/main.css";
import * as React from "react";
import { Field, Form } from "react-final-form";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import localeService from "src/dataServices/LocaleService";
import { IApplicationState } from "src/state";
import { createSupportTicket } from "src/state/contactus/actions";
import {
  IContactUsState,
  IContactUsSubmissionFields,
  IContactUsSubmissionForm,
  IRecaptchaProps
} from "src/state/contactus/types";
import { inputTextFieldValidators } from "../shared/TextInputComponent";
import Text from "../text/Text";
import LoadingButton from "../utilities/LoadingButton";
import ContactUsV2TextInput from "./ContactUsV2TextInput";

export interface IPropsFromState {
  contactUsProps: IContactUsState;
  recaptcha: IRecaptchaProps;
  category?: string;
  query?: string;
  isSending: boolean;
  subCategory?: string;
}

export interface IPropsFromDispatch {
  createSupportTicket: typeof createSupportTicket;
}

export type ContactUsFormProps = IPropsFromState &
  IPropsFromDispatch;

let recaptchaInstance: React.RefObject<ReCAPTCHA>;

export class ContactUsV2GetMoreHelp extends React.Component<ContactUsFormProps, IRecaptchaProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      isRecaptchaValid: false,
      isRecaptchaExpired: false
    };

    this.handleChange = this.handleChange.bind(this);
    recaptchaInstance = React.createRef<ReCAPTCHA>();
  }

  conditionalDisplay = (shouldDisplay: boolean, componentWhenTrue: JSX.Element, componentWhenFalse: JSX.Element) => {
    if (shouldDisplay) {
      return componentWhenTrue;
    }
    return componentWhenFalse;
  };
  public render() {
    const { contactUsProps, query, isSending } = this.props;

    if (this.props.recaptcha.isRecaptchaExpired) {
      recaptchaInstance.current?.reset();
      this.props.recaptcha.isRecaptchaExpired = true;
      this.props.recaptcha.isRecaptchaValid = false;
    }
    const rolesWithCategories = localeService.getCategoryContent(
      "ContactUsRoleCategoryMap",
      "en-us"
    );
    const hideForm = !query || isSending;
    const errorStyle = { border: "0.125rem solid #E81123" };

    return this.conditionalDisplay(
      !hideForm,
      this.conditionalDisplay(
        contactUsProps.sentSuccessfully ?? false,
        <React.Fragment />,
        <div
          style={{
            margin: "21px 1%"
          }}
        >
          <section id="contactus-form" className="contactus-form-v2" dir="ltr">
          <Form
            onSubmit={this.submitForm}
            render={({ handleSubmit, form }) => {
              const formState = form.getState();
              const formErrors = form.getState().errors;

              const role = formState.values.role || "";
              const roleWithcategories = rolesWithCategories.find((rolesWithCategory: { key: string; }) => rolesWithCategory.key == role) || {};
              const categories = roleWithcategories.categories || [];

              let showCategoryError = false, countryCodeError = false, showRoleError = false;
              if (formState.touched && formErrors) {
                showCategoryError = formState.touched.category && formErrors.category;
                countryCodeError = formState.touched.countryCode && formErrors.countryCode;
                showRoleError = formState.touched.role && formErrors.role;
              }

              const disableSubmitButton = !(formState.valid && (this.state.isRecaptchaValid))

              return (
              <form
                id="contactus"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit()
                }}
                onKeyDown={e => {
                  if (
                    e.key === "Enter" &&
                    document.activeElement?.id !== "submitSupportRequest" &&
                    document.activeElement?.id !== "privacyStatementUrl"
                  ) {
                    e.preventDefault(); // prevent implicit form submission via enter key (unless submit button is focused)
                  }
                }}
              >
                <div style={{ margin: "10px" }}>
                  <h2 style={{ marginBottom: "10px" }} className=" ">
                    {localeService.getText("ContactUsGetMoreHelp", "Title")}
                  </h2>
                  <p className="text-muted">
                    {localeService.getText("ContactUsGetMoreHelp", "NeedHelpWithSomething")}
                  </p>
                  <p className="text-muted">
                    {localeService.getText("ContactUsGetMoreHelp", "PleaseNotePrivacyStatement")}{" "}
                    <a
                      id="privacyStatementUrl"
                      href={localeService.getText("Links", "urlPrivacyStatement") ?? undefined}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {localeService.getText("ContactUsGetMoreHelp", "PrivacyStatement")}
                    </a>
                    .
                  </p>
                  <div className="form-group">
                    <div className="form-row row-height-px">
                      <div className="col">
                        <label htmlFor="userFirstName">
                          <span aria-hidden="true">*</span> {localeService.getText("ContactUsGetMoreHelp", "FirstName")}
                        </label>
                        <ContactUsV2TextInput
                          category="ContactUsGetMoreHelp"
                          label="FirstName"
                          type="text"
                          id="userFirstName"
                          multiline={false}
                          required={true}
                          aria-invalid="false"
                        />
                        <div className="invalid-feedback" id="input-firstname-feedback" />
                      </div>
                      <div className="col">
                        <label htmlFor="userLastName">
                          <span aria-hidden="true">*</span> {localeService.getText("ContactUsGetMoreHelp", "LastName")}
                        </label>
                        <ContactUsV2TextInput
                          multiline={false}
                          category="ContactUsGetMoreHelp"
                          label="LastName"
                          type="text"
                          id="userLastName"
                          required={true}
                          aria-invalid="false"
                        />
                        <div className="invalid-feedback " id="input-lastname-feedback" />
                      </div>
                    </div>
                  </div>

                  <div className="form-group ">
                    <div className=" form-row row-height-px ">
                      <div className="col-6">
                        <label htmlFor="countryCodeV2">
                          <span aria-hidden="true">*</span>{" "}
                          {localeService.getText("ContactUsGetMoreHelp", "countryCodePlaceHolder")}
                        </label>
                        <div className="custom-select ">
                          <Field
                            style={countryCodeError ? errorStyle : undefined}
                            name="countryCode"
                            component="select"
                            className="custom-select-input "
                            placeholder={localeService.getText("ContactUsGetMoreHelp", "SelectPlaceholder")}
                            id="countryCodeV2"
                            required={true}
                            aria-invalid="false"
                            aria-label={localeService.getText("ContactUsGetMoreHelp", "countryCodePlaceHolder")}
                            validate={inputTextFieldValidators(true, "text")}
                          >
                            <option value="">{localeService.getText("ContactUsGetMoreHelp", "SelectPlaceholder")}</option>
                            {(contactUsProps.countryCodes || []).map((countryCode: { code: string | number | readonly string[] | undefined; name: string; }) => (
                              <option value={countryCode.code}>{countryCode.name + " (" + countryCode.code + ")"}</option>
                            ))}
                          </Field>
                          <span className="custom-select-arrow" aria-hidden="true" />
                        </div>
                        {countryCodeError && (
                          <div role="alert" className="error">
                            <Icon
                              iconName={"warning"}
                              style={{
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#E81123"
                              }}
                            />
                            <Text category="ContactUsGetMoreHelp" id={`countryCode${formErrors?.category}`} />
                          </div>
                        )}
                        <div className="invalid-feedback " id="helpCategorySelect-feedback" />
                      </div>
                    </div>
                  </div>
                  <div className="form-group ">
                    <div className=" form-row row-height-px ">
                      <div className="col">
                        <label htmlFor="userPhone">
                          <span aria-hidden="true">*</span> {localeService.getText("ContactUsGetMoreHelp", "Phone")}
                        </label>

                        <ContactUsV2TextInput
                          id="userPhone"
                          multiline={false}
                          required={true}
                          category="ContactUsGetMoreHelp"
                          label="Phone"
                          type="phone"
                          aria-invalid="false"
                        />
                        <div className="invalid-feedback" id="input-phonenumber-feedback" />
                      </div>
                      <div className="col">
                        <label htmlFor="userEmail">
                          <span aria-hidden="true">*</span> {localeService.getText("ContactUsGetMoreHelp", "Email")}
                        </label>
                        <ContactUsV2TextInput
                          id="userEmail"
                          multiline={false}
                          required={true}
                          category="ContactUsGetMoreHelp"
                          label="Email"
                          type="email"
                          aria-invalid="false"
                        />
                        <div className="invalid-feedback " id="input-contactemail-feedback" />
                      </div>
                    </div>
                  </div>

                  <div className="form-group row-height-px">
                    <label htmlFor="nonprofitName">
                      <span aria-hidden="true">*</span>{" "}
                      {localeService.getText("ContactUsGetMoreHelp", "OrganizationName")}
                    </label>
                    <ContactUsV2TextInput
                      multiline={false}
                      category="ContactUsGetMoreHelp"
                      label="OrganizationName"
                      type="text"
                      id="nonprofitName"
                      required={true}
                      aria-invalid="false"
                    />
                    <div className="invalid-feedback" id="input-nonprofitname-feedback" />
                  </div>

                  <div className="form-group ">
                    <div className=" form-row row-height-px ">
                      <div className="col-6">
                        <label htmlFor="input-role" className="">
                          <span aria-hidden="true">*</span> {localeService.getText("ContactUsGetMoreHelp", "Iama")}
                        </label>
                        <div className="custom-select ">
                          <Field
                            title={localeService.getText("ContactUsGetMoreHelp", "SelectPlaceholder")}
                            style={showRoleError ? errorStyle : undefined}
                            name="role"
                            component="select"
                            className="custom-select-input "
                            placeholder={localeService.getText("ContactUsGetMoreHelp", "SelectPlaceholder")}
                            id="role"
                            required={true}
                            aria-invalid="false"
                            aria-label={localeService.getText("ContactUsGetMoreHelp", "Iama")}
                            validate={inputTextFieldValidators(true, "text")}
                          >
                            <option value="">{localeService.getText("ContactUsGetMoreHelp", "SelectPlaceholder")}</option>
                            {rolesWithCategories.map((rolesWithCategory: { key: string | number | readonly string[] | undefined; text: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }) => (
                              <option value={rolesWithCategory.key}>{rolesWithCategory.text}</option>
                            ))}
                          </Field>
                          <span className="custom-select-arrow" aria-hidden="true" />
                        </div>
                        {showRoleError && (
                          <div role="alert" className="error">
                            <Icon
                              iconName={"warning"}
                              style={{
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#E81123"
                              }}
                            />
                            <Text category="ContactUsGetMoreHelp" id={`role${formErrors?.role}`} />
                          </div>
                        )}
                        <div className="invalid-feedback " id="roleSelect-feedback" />
                      </div>
                    </div>
                  </div>

                  <div className="form-group ">
                    <div className=" form-row row-height-px ">
                      <div className="col-6">
                        <label htmlFor="input-helpcategory" className="">
                          <span aria-hidden="true">*</span>{" "}
                          {localeService.getText("ContactUsGetMoreHelp", "WhatCanWeHelp")}
                        </label>
                        <div className="custom-select ">
                          <Field
                            title={localeService.getText("ContactUsGetMoreHelp", "SelectPlaceholder")}
                            style={showCategoryError ? errorStyle : undefined}
                            name="category"
                            component="select"
                            className="custom-select-input "
                            placeholder={localeService.getText("ContactUsGetMoreHelp", "SelectPlaceholder")}
                            id="category"
                            required={true}
                            aria-invalid="false"
                            aria-label={localeService.getText("ContactUsGetMoreHelp", "WhatCanWeHelp")}
                            validate={inputTextFieldValidators(true, "text")}
                          >
                            <option value="">{localeService.getText("ContactUsGetMoreHelp", "SelectPlaceholder")}</option>
                            {categories.map((category: { key: string | number | readonly string[] | undefined; text: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }) => (
                              <option value={category.key}>{category.text}</option>
                            ))}
                          </Field>
                          <span className="custom-select-arrow" aria-hidden="true" />
                        </div>
                        {showCategoryError && (
                          <div role="alert" className="error">
                            <Icon
                              iconName={"warning"}
                              style={{
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#E81123"
                              }}
                            />
                            <Text category="ContactUsGetMoreHelp" id={`category${formErrors?.category}`} />
                          </div>
                        )}
                        <div className="invalid-feedback " id="helpCategorySelect-feedback" />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="textarea-description-element">
                      <span aria-hidden="true">*</span> {localeService.getText("ContactUsGetMoreHelp", "HowCanWeHelp")}
                    </label>

                    <ContactUsV2TextInput
                      id="issue"
                      multiline={true}
                      required={true}
                      category="ContactUsGetMoreHelp"
                      label="HowCanWeHelp?"
                      type="text"
                      aria-invalid="false"
                    />
                    <div>
                      <span className="text-muted" aria-hidden="true" />
                      <span
                        className="sr-only"
                        data-sr-low-char-warning-lvl="5"
                        role="status"
                        aria-live="polite"
                        aria-atomic="true"
                      />
                    </div>
                  </div>
                    <ReCAPTCHA
                      className="recaptcha"
                      ref={recaptchaInstance}
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY ?? ""}
                      onChange={this.handleChange}
                    />
                  <div id="submitSupportRequestLoadingButton">
                    <LoadingButton
                      loading={contactUsProps.isSending ?? false}
                      type="submit"
                      id="submitSupportRequest"
                      disabled={disableSubmitButton}
                      title={`${localeService.getText("ContactUs", "SubmitRequest")}`}
                      text={`${localeService.getText("ContactUsGetMoreHelp", "SubmitRequest")}`}
                      width={160}
                      properties={{ ButtonName: "ContactUsForm-Submit" }}
                    />
                  </div>
                  {this.conditionalDisplay(
                    contactUsProps.failedToSend ?? false,
                    <div
                      style={{
                        fontSize: "24px",
                        lineHeight: "24px",
                        color: "#E81123",
                        padding: "20px 0"
                      }}
                    >
                      <Icon
                        iconName={"error"}
                        style={{
                          float: "left",
                          padding: "0 20px"
                        }}
                      />
                      <p
                        style={{
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#E81123"
                        }}
                      >
                        {localeService.getText("ContactUsSelfHelp", "ErrorResults")}
                      </p>
                    </div>,
                    <React.Fragment />
                  )}
                </div>
              </form>
            )}}
          />
          </section>
        </div>
      ),
      <React.Fragment />
    );
  }

  private submitForm = (contactUsSubmissionForm: IContactUsSubmissionForm) => {
    const recaptchaValue = recaptchaInstance.current?.getValue();
    const contactUsSubmissionFields = {
      userFirstName: contactUsSubmissionForm.userFirstName,
      userLastName: contactUsSubmissionForm.userLastName,
      userEmail: contactUsSubmissionForm.userEmail,
      issue: contactUsSubmissionForm.issue,
      nonprofitName: contactUsSubmissionForm.nonprofitName,
      userPhone: contactUsSubmissionForm.userPhone,
      category: contactUsSubmissionForm.category,
      recaptcha: recaptchaValue,
      countryCode: contactUsSubmissionForm.countryCode,
      userLocale: localeService.getlocale(),
      role: contactUsSubmissionForm.role
    } as IContactUsSubmissionFields;

    this.props.createSupportTicket(contactUsSubmissionFields);
  };

  private handleChange = (value: any) => {
    if (value === null) {
      this.setState({ isRecaptchaValid: false, isRecaptchaExpired: true });
    } else {
      this.setState({ isRecaptchaValid: true, isRecaptchaExpired: false });
    }
  };
}

const mapStateToProps = ({ contactUs, query, form, flags }: IApplicationState): IPropsFromState => ({
  contactUsProps: contactUs,
  query: query.query,
  isSending: query.isSending,
  recaptcha: { isRecaptchaExpired: false, isRecaptchaValid: false },
});

const mapDispatchToProps: (dispatch: Dispatch) => IPropsFromDispatch = dispatch => ({
  createSupportTicket: (formValues: IContactUsSubmissionFields) => dispatch(createSupportTicket(formValues))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactUsV2GetMoreHelp);
