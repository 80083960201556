import * as React from "react";
import { Link } from "react-router-dom";
import Text from "src/components/text/Text";
import { getLocalizedPath } from "src/components/utilities/Localization/Utils";
import localeService from "src/dataServices/LocaleService";

const OnPremiseSoftwareLicenses = () => {
  return (
    <div className="mt-4 p-5 software-licenses">
      <h2 className="h2">
        <Text category={"PartnersV2"} id={"SoftwareLicensesTitle"} />
      </h2>
      <p>
        <Text category={"PartnersV2"} id={"SoftwareLicensesDescription"} />
      </p>
      <Link to={getLocalizedPath("/offers/software")} className="cta ">
        {localeService.getText("PartnersV2", "LearnMore")}
        <span className="glyph" aria-hidden="true"></span>
      </Link>
    </div>
  );
};

export default OnPremiseSoftwareLicenses;
