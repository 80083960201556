import { Label } from '@fluentui/react';
import Text from 'src/components/text/Text';
import localeService from 'src/dataServices/LocaleService';
import { IProfile } from 'src/state/profile/types';
import './ProfileForm.scss';
import { IFeatureFlagsState } from 'src/state/feature-flags';
import { connect } from 'react-redux';
import { IApplicationState } from 'src/state';
import './PrimaryContactView.scss';
import { MeoLocaleState } from 'src/state/meo-localization/types';
import meoLocaleService from 'src/dataServices/MeoLocaleService';

interface IPropsFromState {
  featureFlags: IFeatureFlagsState;
  meoLocale: MeoLocaleState;
}

interface IPropsFromParent {
  profile: IProfile;
}

type PrimaryContactViewProps = IPropsFromState & IPropsFromParent;

const renderField = (labelCategory: string, labelId: string, fieldName: string, fieldValue: string) => {
  return (
    <div>
      <Label tabIndex={0} htmlFor={fieldName}>
        <Text category={labelCategory} id={labelId} />
      </Label>
      <p className="primary-contact-text">
        <b>{fieldValue}</b>
      </p>
    </div>
  );
};

const PrimaryContactView = ({ profile, featureFlags, meoLocale }: PrimaryContactViewProps): JSX.Element => {
  const locale = meoLocaleService.resolveLocale(profile.primaryContact.emailLanguagePreference, meoLocale.locales);

  return (
    <div id="org-info-part3">
      <div id="profile-primary-contact-view-description">
        <p>{localeService.getText('ProfileV2', 'PrimaryContactDescription')}</p>
      </div>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          {renderField('ProfileV2', 'FirstName', 'First name', profile.primaryContact.firstName)}
        </div>
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          {renderField('ProfileV2', 'MiddleName', 'Middle name', profile.primaryContact.middleName)}
        </div>
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          {renderField('ProfileV2', 'LastName', 'Last name', profile.primaryContact.lastName)}
        </div>
      </div>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          {renderField('ProfileV2', 'PrimaryEmailAddress', 'Primary email address', profile.primaryContact.email)}
        </div>
        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
          {renderField('ProfileV2', 'EmailLanguagePreferenceLabel', 'Email Language Preference', locale.displayName)}
        </div>
      </div>
      {profile.notificationEmails && profile.notificationEmails.length > 0 && (
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xl4">
            <Label tabIndex={0} htmlFor="Alternate Email Address">
              <Text category="ProfileV2" id="AlternateEmailAddress" />
            </Label>
            <p>
              {profile.notificationEmails.map((email) => (
                <b>
                  {email}
                  <br />
                </b>
              ))}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ flags, meoLocale }: IApplicationState) => ({
  featureFlags: flags,
  meoLocale: meoLocale
});

export default connect(mapStateToProps)(PrimaryContactView);
