import "@ms-mwf/moray/dist/css/main.css";
import localeService from "src/dataServices/LocaleService";

export function LinkedinContactUs() {
  return (
    <div id="linkedin-contact-us-section" className="material-surface p-5 text-center mb-0" data-mount="click-group">
      <h2 className="contact-us-header text-style h2 text-center mb-3">
        {localeService.getText("ContactUsSection", "LinkedinContactUsTitle")}
      </h2>
      <p id="linkedin-contact-us-content" className="contact-us-section-content">
        {localeService.getText("ContactUsSection", "LinkedinContactUsDescription")}
      </p>
      <a
        id="linkedin-contact-us-button"
        href="https://nonprofit.linkedin.com/faq/contact-us"
        className="cta"
        target="_blank"
        rel="noopener noreferrer"
      >
        {localeService.getText("ContactUsSection", "ContactUsSectionButton")}
        <span className="glyph" aria-hidden="true"></span>
      </a>
    </div>
  );
}
