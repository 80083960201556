import { isEqual } from "lodash";
import { AgentVerificationState } from "src/state/agent-verification/types";
import { IAgentEmailVerification, INonprofitState, NonprofitStatus } from "src/state/nonprofit";
import { IProfile } from "src/state/profile/types";

export function isEmailVerificationRequired(nonprofit: INonprofitState, agentVerification: AgentVerificationState) {
  return (
    nonprofit.miniProfile &&
    ((nonprofit.miniProfile.status === NonprofitStatus.InProgress &&
      nonprofit.miniProfile.emailVerified === IAgentEmailVerification.No) ||
      (nonprofit.miniProfile.status === NonprofitStatus.Approved &&
        nonprofit.miniProfile.emailVerified !== IAgentEmailVerification.Yes)) &&
    !agentVerification.isTokenVerificationSuccess
  );
}

export function mungeEmailAddress(s: string) {
  const i = s.indexOf("@");
  // eslint:disable-next-line: no-bitwise
  const startIndex = (i * 0.2) | 0;
  // eslint:disable-next-line: no-bitwise
  const endIndex = (i * 0.9) | 0;

  return s.slice(0, startIndex) + s.slice(startIndex, endIndex).replace(/./g, "*") + s.slice(endIndex);
}

export function isNonprofitValidationRequired(formInitialValues: Partial<IProfile>, formValues: IProfile) {
  const sortKey = "identifierKey";
  const legalId = "legalIdentifier";
  if (formInitialValues === undefined || formValues === undefined) {
    return false;
  }

  const sortedInitialIdentifiers = getSortedValues(formInitialValues.registrationIdentifiers, sortKey);
  const sortedIdentifiers = getSortedValues(formValues.registrationIdentifiers, sortKey);
  const hasEqualIdentifiers = sortedInitialIdentifiers.every(
    (identifier, index) =>
      sortedIdentifiers[index] &&
      sortedIdentifiers[index][legalId] == identifier[legalId] &&
      sortedIdentifiers[index][sortKey] == identifier[sortKey]
  );

  return (
    formInitialValues !== undefined && formValues !== undefined &&
    (formInitialValues.name !== formValues.name ||
      formInitialValues.address?.city !== formValues.address.city ||
      formInitialValues.address.countryCode !== formValues.address.countryCode ||
      !hasEqualIdentifiers)
  );
}

export function getSortedValues(object: any, sortKey: string) {
  const list: any[] = Object.values(object);

  return list.sort((a, b) => {
    let x = a[sortKey];
    let y = b[sortKey];

    return x < y ? -1 : x > y ? 1 : 0;
  });
}
