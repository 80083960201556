import * as MorayComponents from "@ms-mwf/moray/dist/js/bundle";
import * as React from "react";
import localeService from "src/dataServices/LocaleService";
import { IHighlightBanner } from "./HighlightBanner";

export interface IHighlightBannerCarousel {
  children: React.ReactElement<IHighlightBanner> | React.ReactElement<IHighlightBanner>[];
}

const HighlightBannerCarousel = ({ children }: IHighlightBannerCarousel) => {
  const element = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    new MorayComponents.Carousel({ el: element.current });
  }, []);

  return (
    <div className="row">
      <div className="col-12 px-0 ">
        <section aria-label="Microsoft products" aria-roledescription="slideshow">
          <div
            ref={el => (element.current = el)}
            className="carousel slide carousel-highlight carousel-variable-height"
            data-mount="carousel"
          >
            <div>
              <div className="carousel-controls">
                <button
                  type="button"
                  className="carousel-control-prev "
                  data-slide="prev"
                  aria-label={localeService.getText("Shared", "PreviousSlide")}
                />
                <ol className="carousel-indicators" aria-hidden="true">
                  {React.Children.map(children, (child, index) =>
                    index === 0 ? <li key={index} className="active" /> : <li key={index} />
                  )}
                </ol>
                <button
                  type="button"
                  className="carousel-control-next "
                  data-slide="next"
                  aria-label={localeService.getText("Shared", "NextSlide")}
                />
              </div>
              <div className="carousel-inner">
                {React.Children.map(children, (child, index) => (
                  <section
                    key={index}
                    className={`carousel-item depth-4 depth-md-none ${index === 0 ? "active" : ""}`}
                    tabIndex={0}
                  >
                    {child}
                  </section>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default HighlightBannerCarousel;
