import { all, apply, fork, put, takeEvery } from "redux-saga/effects";
import { IApiResponse } from "src/common/ApiResponse";
import sendToPartnerService from "src/dataServices/SendToPartnerService";
import { ActionType } from "typesafe-actions";
import { initiateSendToPartnerRequest, sendToPartnerError, sendToPartnerSuccess } from "./actions";
import { SendToPartnerActionTypes } from "./types";

export function* handleSendToPartnerRequest(action: ActionType<typeof initiateSendToPartnerRequest>) {
  try {
    const formValues = action.payload;
    const response: IApiResponse<{}> = yield apply(sendToPartnerService, sendToPartnerService.sendToPartner, [
      formValues
    ]);
    if (response.status === 200) {
      yield put(sendToPartnerSuccess());
    } else {
      yield put(sendToPartnerError());
    }
  } catch (e) {
    console.error("Error sending to partner.", e);
    yield put(sendToPartnerError());
  }
}

function* watchSendToPartnerRequest() {
  yield takeEvery(SendToPartnerActionTypes.INITIATE_SEND_TO_PARTNER_REQUEST, handleSendToPartnerRequest);
}

function* sendToPartnerSaga() {
  yield all([fork(watchSendToPartnerRequest)]);
}

export default sendToPartnerSaga;
