import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { logService } from "npo-common";
import { encodeGetParams } from "src/common/utilities/Utilities";
import { IApplicationState } from "src/state";
import { loadedAgentVerification } from "src/state/agent-verification/actions";
import GreenId from "./GreenId";

interface IPropsFromState {
  sessionId: string;
  verificationProfileUrl: string;
  verificationTagsJsUrl: string;
  verificationIsEnabled: boolean;
  hasConsent: boolean;
  isAuthenticated: boolean;
}

interface IPropsFromDispatch {
  loadedAgentVerification: typeof loadedAgentVerification;
}

type IAgentVerificationProps = IPropsFromState & IPropsFromDispatch;
class AgentVerification extends React.PureComponent<IAgentVerificationProps> {
  private greenIdUrl!: string;

  public componentDidMount() {
    const { sessionId } = this.props;

    if (sessionId) {
      this.greenIdUrl = `${process.env.REACT_APP_GREEN_ID_TAGS_URL}?${encodeGetParams({
        session_id: this.props.sessionId
      })}`;
    }

    const {
      verificationIsEnabled,
      verificationTagsJsUrl,
      hasConsent,
      loadedAgentVerification,
      isAuthenticated
    } = this.props;

    const scriptId = "sentinel";

    if (
      document.getElementById(scriptId) === null &&
      typeof sessionId !== "undefined" &&
      verificationIsEnabled &&
      this.isWcpCookieConsentedOrAuthenticated(hasConsent, isAuthenticated)
    ) {
      const js = document.createElement("script");
      js.setAttribute("id", scriptId);
      js.setAttribute("src", verificationTagsJsUrl);
      document.body.appendChild(js);

      js.onerror = e => {
        logService.logError("AgentVerification: Failed to load tags.js", { e });
        loadedAgentVerification(true);
      };
      js.onload = () => loadedAgentVerification(true);
    }
  }

  public render() {
    return <>{this.greenIdUrl && <GreenId url={this.greenIdUrl} />}</>;
  }

  private isWcpCookieConsentedOrAuthenticated(hasConsent: boolean, isAuthenticated: boolean) {
    if (!hasConsent) {
      return isAuthenticated;
    }

    return hasConsent;
  }
}

const mapStateToProps = ({
  agentVerification,
  wcpCookieConsent: wcpCookieConsent,
  auth
}: IApplicationState): IPropsFromState => ({
  ...agentVerification,
  hasConsent: wcpCookieConsent.hasConsent,
  isAuthenticated: auth.isAuthenticated
});

const mapDispatchToProps = (dispatch: Dispatch): IPropsFromDispatch => ({
  loadedAgentVerification: (loadedSuccessfully: boolean) => dispatch(loadedAgentVerification(loadedSuccessfully))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgentVerification);
