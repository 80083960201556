import * as React from "react";
import "./StatusBanner.scss";

export enum StatusIcon {
  success,
  recent,
  warning,
  error,
  info
}

export interface IStatusBannerProps {
  iconType: StatusIcon;
  text?: string;
  children?: React.ReactNode;
}

const StatusBanner = (props: IStatusBannerProps) => {
  const { iconType, text, children } = props;
  const iconBase = (iconName: string, iconColor: string) => (
    <p id="icon" className={`glyph-prepend glyph-prepend-large ${iconName} w-auto h-auto ${iconColor} mb-1`} />
  );
  const getIcon = (type: StatusIcon): JSX.Element => {
    switch (type) {
      case StatusIcon.success:
        return iconBase("glyph-prepend-completed", "text-green");
      case StatusIcon.recent:
        return iconBase("glyph-prepend-recent", "text-light-orange");
      case StatusIcon.warning:
        return iconBase("glyph-prepend-warning", "text-red");
      case StatusIcon.error:
        return iconBase("glyph-prepend-button-x", "text-red");
      case StatusIcon.info:
        return iconBase("glyph-prepend-info", "text-blue");
    }
  };

  const getHeaderStyle = (type: StatusIcon): string => {
    if (type === StatusIcon.error) {
      return "h4 error-text";
    } else {
      return "h4";
    }
  };

  return (
    <div className="status-banner">
      <div className="icon">{getIcon(iconType)}</div>
      <div>
        <h4 className={getHeaderStyle(iconType)} role="alert">{text}</h4>
        {children}
      </div>
    </div>
  );
};

export default StatusBanner;
