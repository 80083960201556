export const enum AgentVerificationActionTypes {
  FETCH_AGENT_SETTINGS_REQUEST = "@@agent/FETCH_AGENT_SETTINGS_REQUEST",
  FETCH_AGENT_SETTINGS_SUCCESS = "@@agent/FETCH_AGENT_SETTINGS_SUCCESS",
  LOADED_AGENT_VERIFICATION = "@@agent/LOADED_AGENT_VERIFICATION",
  RESEND_CONFIRMATION_EMAIL = "@@agent/RESEND_CONFIRMATION_EMAIL",
  RESEND_CONFIRMATION_EMAIL_SUCCESS = "@@agent/RESEND_CONFIRMATION_EMAIL_SUCCESS",
  RESEND_CONFIRMATION_EMAIL_ERROR = "@@agent/RESEND_CONFIRMATION_EMAIL_ERROR",
  INITIATE_TOKEN_VERIFICATION = "@@agent/INITIATE_TOKEN_VERIFICATION",
  TOKEN_VERIFICATION_SUCCESS = "@@agent/TOKEN_VERIFICATION_SUCCESS",
  TOKEN_VERIFICATION_ERROR = "@@agent/TOKEN_VERIFICATION_ERROR"
}

export interface AgentSettings {
  readonly sessionId: string;
  readonly verificationProfileUrl: string;
  readonly verificationTagsJsUrl: string;
  readonly verificationIsEnabled: boolean;
  readonly resendEmailInProcess: boolean;
  readonly isresendEmailSuccess: boolean;
  readonly tokenVerificationInProcess: boolean;
  readonly isTokenVerificationSuccess: boolean;
  readonly isTokenVerificationFailure: boolean;
}

export interface ITokenVerificationSubmission {
  token: string;
}

export type AgentVerificationState = AgentSettings & { loadedSuccessfully: boolean };
