import { Reducer } from "redux";
import {
  AzureOfferActionTypes,
  AzureOfferState,
  AzureRegistrationInfo,
  AzureRegistrationResultCode,
  AzureTokenValidationCode,
  AzureTokenValidationResult
} from "./types";

const initialState: AzureOfferState = {
  accountOwnerLiveId: undefined as unknown as string,
  startDate: undefined as unknown as string,
  endDate: undefined as unknown as string,
  registrationInfoError: false,
  isRegistering: false,
  registrationResultCode: undefined as unknown as AzureRegistrationResultCode,
  isRenewing: false,
  tokenValidationResult: undefined  as unknown as AzureTokenValidationResult,
  tokenValidationCode: AzureTokenValidationCode.InitialCode,
  offerId: undefined  as unknown as string,
  isFetchingNpAzureRegistration: false,
  statusId: undefined  as unknown as number
};

const reducer: Reducer<any> = (state = initialState, action) => {
  switch (action.type) {
    case AzureOfferActionTypes.FETCH_REGISTRATION_INFO: {
      return {
        ...state,
        accountOwnerLiveId: undefined,
        endDate: undefined,
        registrationInfoError: undefined,
        isFetchingNpAzureRegistration: true,
        statusId: undefined
      };
    }
    case AzureOfferActionTypes.SET_REGISTRATION_INFO: {
      const registrationInfo: AzureRegistrationInfo = action.payload;
      return {
        ...state,
        accountOwnerLiveId: registrationInfo ? registrationInfo.accountOwnerLiveId : null,
        startDate: registrationInfo ? registrationInfo.startDate : null,
        endDate: registrationInfo ? registrationInfo.endDate : null,
        registrationInfoError: false,
        offerId: registrationInfo ? registrationInfo.offerId : null,
        isFetchingNpAzureRegistration: false,
        statusId: registrationInfo ? registrationInfo.statusId : null
      };
    }
    case AzureOfferActionTypes.SET_REGISTRATION_INFO_ERROR: {
      return { ...state, registrationInfoError: true, isFetchingNpAzureRegistration: false };
    }
    case AzureOfferActionTypes.INITIATE_REGISTRATION:
    case AzureOfferActionTypes.INITIATE_REGISTRATION_FOR_OFFER_ID:
    case AzureOfferActionTypes.RESET_REGISTRATION_FOR_OFFER_ID: {
      return { ...state, registrationResultCode: undefined, isRegistering: true };
    }
    case AzureOfferActionTypes.SET_REGISTRATION_RESULT: {
      const code: AzureRegistrationResultCode = action.payload;
      return { ...state, registrationResultCode: code, isRegistering: false };
    }
    case AzureOfferActionTypes.INITIATE_RENEWAL: {
      return { ...state, tokenValidationResult: undefined, isRenewing: true };
    }
    case AzureOfferActionTypes.SET_TOKEN_RESULT: {
      const result: AzureTokenValidationResult = action.payload;
      return { ...state, tokenValidationResult: result, tokenValidationCode: result.code, isRenewing: false };
    }
    default: {
      return state;
    }
  }
};

export { reducer as azureOfferReducer };

