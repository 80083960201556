import { logService } from "npo-common";
import { IApiResponse } from "src/common/ApiResponse";
import { httpRequest } from "src/common/HttpRequest";
import authenticationService from "./AuthenticationService";

export async function callApi<T>(url: string, body?: string | null, method?: string): Promise<IApiResponse<T>> {
  const token = await authenticationService.getToken();
  const response = await httpRequest(url, token, body, method);
  return response.ok
    ? { status: response.status, value: await extractData<T>(response) }
    : { status: response.status, error: await extractError(response) };
}

export async function extractData<T>(response: Response): Promise<T | undefined> {
  try {
    return await response.json();
  } catch (e) {
    logService.logError("Error extracting response data to JSON.", { e });
    return undefined;
  }
}

export async function extractError(response: Response): Promise<{ [key: string]: string } | string | undefined> {
  try {
    return await response.json();
  } catch (e) {
    try {
      logService.logError("Error extracting response error to JSON, falling back to text.", { e });
      return await response.text();
    } catch (e) {
      logService.logError("Error extracting response error to text, falling back to statusText.", { e });
      return await response.statusText;
    }
  }
}
