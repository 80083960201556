import { getLocaleText } from "src/common/utilities/Utilities";

interface GreenIdProps {
  url: string;
}

const GreenId = (props: GreenIdProps) => {
  const { url } = props;
  const title = getLocaleText("AgentVerification", "Title");

  return (
    <iframe
      id={"greenIdIframe"}
      data-testid={"greenIdIframe"}
      src={url}
      title={title ?? undefined}
      style={{
        color: "rgb(0, 0, 0)",
        float: "left",
        position: "absolute",
        top: "-200px",
        left: "-200px",
        border: "0px"
      }}
      sandbox="allow-scripts"
    />
  );
};

export default GreenId;
