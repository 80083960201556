import { Spinner, SpinnerSize } from "@fluentui/react";
import * as React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Dispatch } from "redux";
import { getLocalizedPath } from "src/components/utilities/Localization/Utils";
import localeService from "src/dataServices/LocaleService";
import { IApplicationState } from "src/state";
import { fetchNonprofitRequest, INonprofitMiniProfile, NonprofitError } from "src/state/nonprofit";

interface IPropsFromDispatch {
  fetchNonprofitRequest: typeof fetchNonprofitRequest;
}

interface IPropsFromState {
  miniProfile: INonprofitMiniProfile;
  isLoading: boolean;
  error: NonprofitError | undefined;
}

interface IPropsFromParent {
  children: React.ReactNode;
}

export type IWithNonprofitProps = IPropsFromDispatch & IPropsFromState & IPropsFromParent;

export class WithNonprofit extends React.Component<IWithNonprofitProps> {
  public componentDidMount() {
    this.props.fetchNonprofitRequest();
  }

  public render() {
    const { error, isLoading, miniProfile } = this.props;
    if (error) {
      return <Redirect to={getLocalizedPath(`/error/${error.toLowerCase()}`) ?? ''} />
    }

    if (isLoading) {
      return (
        <Spinner
          size={SpinnerSize.large}
          label={localeService.getText("Shared", "SpinnerPleaseWait")}
          ariaLive="assertive"
        />
      );
    }

    return miniProfile ? this.props.children : null;
  }
}

const mapStateToProps = ({ nonprofit, offers }: IApplicationState) => ({
  error: nonprofit.error,
  isLoading: nonprofit.isLoading ?? false,
  miniProfile: nonprofit.miniProfile
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchNonprofitRequest: () => dispatch(fetchNonprofitRequest())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithNonprofit);
