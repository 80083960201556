import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './';
import { agentVerificationReducer } from './agent-verification/reducer';
import { authReducer } from './auth';
import { contactUsReducer } from './contactus/reducer';
import { wcpCookieConsentReducer } from './cookie-consent/reducer';
import { featureFlagsReducer } from './feature-flags';
import { layoutReducer } from './layout';
import { localizationReducer } from './localization/reducer';
import { nonprofitReducer } from './nonprofit';
import { accountGuardOfferReducer } from './offers/accountguard/reducer';
import { azureOfferReducer } from './offers/azure/reducer';
import { bingAdsReducer } from './offers/bing-ads/reducer';
import { sharedOfferReducer } from './offers/shared/reducer';
import { softwareReducer } from './offers/software/reducer';
import { IOffersState } from './offers/types';
import { profileReducer } from './profile/reducer';
import { queryReducer } from './query/reducer';
import { meolocalesReducer } from './meo-localization/reducer';
// import { reducer as formReducer } from 'redux-form';

const sagaMiddleware = createSagaMiddleware();
export const store = configureStore({
  reducer: {
    locale: localizationReducer,
    agentVerification: agentVerificationReducer,
    auth: authReducer,
    wcpCookieConsent: wcpCookieConsentReducer,
    layout: layoutReducer,
    nonprofit: nonprofitReducer,
    offers: combineReducers<IOffersState>({
      azure: azureOfferReducer,
      sharedOffer: sharedOfferReducer,
      software: softwareReducer,
      accountGuardOffer: accountGuardOfferReducer,
      bingAds: bingAdsReducer,
    }),
    profile: profileReducer,
    flags: featureFlagsReducer,
    contactUs: contactUsReducer,
    query: queryReducer,
    meoLocale: meolocalesReducer
    
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    }).concat(sagaMiddleware),
  preloadedState: {}
});
sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
