import EligibilityPreCheckSection from './EligibilityPreCheckSection';
import GettingStartedTopBanner from './GettingStartedTopBanner';
import GrantsSection from './GrantsSection';
import SignInSection from './SignInSection';
import { connect } from 'react-redux';
import { IApplicationState } from 'src/state';
import { useEffect, useState } from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react';
import localeService from 'src/dataServices/LocaleService';
import './GettingStartedV2.scss';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { logService, telemetryService } from 'npo-common';
import Unknown from '../../components/error/Unknown';
import { CustomPageView, PageNames, PageRenderingErrorMessage } from '../../common/Constants';

interface IPropsFromState {
  isAuthenticated: boolean;
}

const GettingStartedV2 = (props: IPropsFromState) => {
  const [UFHLoading, setUHFLoading] = useState(true);

  // runs UHF scripts on mount for getting started page
  useEffect(() => {
    window.dispatchEvent(new Event('loadUHF'));
    logService.logInfo(CustomPageView, { pageName: PageNames.GettingStarted, locale: localeService.getlocale() });
  }, []);

  window.addEventListener(
    'UHFDoneLoading',
    () => {
      setUHFLoading(false);
    },
    false
  );

  if (UFHLoading) {
    return (
      <Spinner
        size={SpinnerSize.large}
        label={localeService.getText('Shared', 'SpinnerPleaseWait')}
        ariaLive="assertive"
      />
    );
  }

  return (
    <AppInsightsErrorBoundary
      onError={() => {
        logService.logError(PageRenderingErrorMessage, { pageName: PageNames.GettingStarted }, { console: true });
        return <Unknown />;
      }}
      appInsights={telemetryService.aiReactPlugin}
    >
      <div className="getting-started-v2-page">
        <div className="col p-0">
          <div className="px-5 py-2 getting-started-background d-block d-md-none" />
          <GettingStartedTopBanner />
        </div>
        <div className="px-5 py-2 getting-started-background" />
        <div className="col p-0">
          <EligibilityPreCheckSection />
        </div>
        <div className="px-5 py-2 getting-started-background" />
        <div className="row row-cols-md-2 row-cols-1">
          <div className="col h-auto p-0 pb-3 pr-md-2 pb-md-0">
            <GrantsSection />
            <div className="px-5 py-2 getting-started-background" />
          </div>
          <div className="col h-auto p-0 pb-3 pl-md-2 pb-md-0">
            <SignInSection />
            <div className="px-5 py-2 getting-started-background" />
          </div>
        </div>
      </div>
    </AppInsightsErrorBoundary>
  );
};

const mapStateToProps = ({ auth }: IApplicationState) => ({
  isAuthenticated: auth.isAuthenticated
});

export default connect(mapStateToProps)(GettingStartedV2);
