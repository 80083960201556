import * as React from "react";
import { connect } from "react-redux";
import Text from "src/components/text/Text";
import { IApplicationState } from "src/state";
import "./BannerNotification.scss";

interface IPropsFromState {
  isCommercial: boolean;
}

export const DiscountAndDonationPolicies = (props: IPropsFromState) => (
  <div className="discount-donation-policies">
    <h6 className="h6">
      <Text id={"DiscountHeader"} category={"ProfileV2"} />
    </h6>
    <p>
      <Text id={"DiscountBody"} category={"ProfileV2"} />
    </p>
    {props.isCommercial && (
      <p>
        <Text id={"DiscountCommercial"} category={"ProfileV2"} />
      </p>
    )}
  </div>
);

const mapStateToProps = ({ nonprofit }: IApplicationState) => ({
  isCommercial: nonprofit.isCommercial
});

export default connect(mapStateToProps)(DiscountAndDonationPolicies);
