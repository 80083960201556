import { IApiResponse } from "src/common/ApiResponse";
import { ISendToPartnerRequest } from "src/state/offers/software/send-to-partner/types";
import { callApi } from "./SharedServiceFunctions";

export class SendToPartnerService {
  private API_URL = process.env.REACT_APP_INTERNAL_API_BASEURL;

  public async sendToPartner(formValues: ISendToPartnerRequest): Promise<IApiResponse<{}>> {
    return await callApi(`${this.API_URL}/nonprofitoffers/sendtopartner`, JSON.stringify(formValues), "POST");
  }
}

const sendToPartnerService = new SendToPartnerService();
export default sendToPartnerService;
