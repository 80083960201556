import { Link } from "react-router-dom";
import ResizableImage from "src/components/moray/ResizeableImage";
import { getLocalizedPath } from "src/components/utilities/Localization/Utils";
import "./FeatureCard.scss";

interface IDefaultFeatureCardInfo {
  title?: string;
  titleClassName?: string;
  description?: string;
  boldSubtext?: string;
  ctaText?: string;
  ctaLink?: string;
}

const DefaultFeatureCardInfo = ({
  title,
  titleClassName = 'h4',
  description,
  boldSubtext,
  ctaText,
  ctaLink
}: IDefaultFeatureCardInfo) => (
  <div className={`card-body align-self-center p-4 p-md-5 }`}>
    <h4 id="PartnersBannerTitle" className={`font-weight-semibold ${titleClassName}`}>
      {title}
    </h4>
    <div className="my-3">
      <p className="base">{description}</p>
    </div>
    {boldSubtext && <p className="base font-weight-bold">{boldSubtext}</p>}
    {ctaText && (
      <div className="link-group">
        <Link to={getLocalizedPath(ctaLink)} className="cta ">
          {ctaText}
          <span className="glyph" aria-hidden="true"></span>
        </Link>
      </div>
    )}
  </div>
);

export interface IFeatureCard {
  title?: string;
  containerClassNames?: string;
  titleClassName?: string;
  description?: string;
  boldSubtext?: string;
  ctaText?: string;
  ctaLink?: string;
  customFeatureCardInfoComponent?: React.ReactNode;
  reverseTextAlign?: boolean;
  offset?: boolean;
  imageProps: {
    id: string;
    root: string;
    imageSrc: string;
    imageClassName: string;
    imageAlt?: string;
  };
}

const FeatureCard = ({
  title,
  containerClassNames = 'my-5',
  titleClassName = 'h4',
  description,
  reverseTextAlign,
  offset,
  imageProps,
  boldSubtext,
  ctaText,
  ctaLink,
  customFeatureCardInfoComponent
}: IFeatureCard) => (
  <section className={`${containerClassNames}`}>
    <div className={`${offset ? 'card-offset' : 'card'} d-block mx-ng mx-md-0`}>
      <div className={`row no-gutters material-surface ${reverseTextAlign ? 'flex-row-reverse' : ''}`}>
        <div className="col-md-6">
          <ResizableImage {...(imageProps as any)} />
        </div>
        <div className={`d-flex col-md ${offset ? '' : 'order-md-first bg-gray-100'} `}>
          {customFeatureCardInfoComponent ?? (
            <DefaultFeatureCardInfo
              title={title}
              titleClassName={titleClassName}
              description={description}
              boldSubtext={boldSubtext}
              ctaText={ctaText}
              ctaLink={ctaLink}
            />
          )}
        </div>
      </div>
    </div>
  </section>
);

export default FeatureCard;
