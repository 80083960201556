import { ContactUsActionTypes, IContactUsSubmissionFields, ISupportTicketResponse } from "src/state/contactus/types";
import { action } from "typesafe-actions";
import { ICountryCode } from "../shared";

export const contactusResourcesRequest = () => action(ContactUsActionTypes.CONTACT_RESOURCES_REQUEST);
export const contactusResourcesSuccess = (countryCodes: ICountryCode[] | undefined) =>
  action(ContactUsActionTypes.CONTACT_RESOURCES_SUCCESS, { countryCodes });
export const contactusResourcesError = (error: string) => action(ContactUsActionTypes.CONTACT_RESOURCES_ERROR, error);

export const createSupportTicket = (contactUsPayload: IContactUsSubmissionFields) =>
  action(ContactUsActionTypes.CONTACTUS_CREATESUPPORTTICKET, contactUsPayload);
export const createSupportTicketSuccess = (supportTicketResponse: ISupportTicketResponse | undefined) =>
  action(ContactUsActionTypes.CONTACTUS_CREATESUPPORTTICKET_SUCCESS, { supportTicketResponse });
export const createSupportTicketError = () => action(ContactUsActionTypes.CONTACTUS_CREATESUPPORTTICKET_ERROR);
