import * as React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "src/state";
import { IClaimOfferRequestState } from "src/state/offers/accountguard/types";
import AccountGuardErrorMessageBanner from "./AccountGuardErrorMessageBanner";
import AccountGuardSuccessBanner from "./AccountGuardSuccessBanner";

interface IPropsFromState {
  accountGuard: IClaimOfferRequestState;
}

export type IAccountGuardStatusBannerProps = IPropsFromState;

export const AccountGuardStatusBanner = (props: IAccountGuardStatusBannerProps) => {
  const [statusSection, setStatusSection] = React.useState<HTMLElement | null>(null);
  const { accountGuard } = props;
  const success = accountGuard.claimStatus || accountGuard.alreadyClaim || accountGuard.sentSuccessfully;
  const failure = accountGuard.failedToSend || accountGuard.accountGuardInfoError || accountGuard.languageSupportError;

  if (statusSection !== null && (success || failure)) {
    statusSection.scrollIntoView({ behavior: "smooth", block: "center" });
  }

  return (
    <section ref={ref => setStatusSection(ref)} id="offer-request">
      <div>
        {success && !failure && <AccountGuardSuccessBanner />}
        {failure && <AccountGuardErrorMessageBanner />}
      </div>
    </section>
  );
};

const mapStateToProps = ({ offers }: IApplicationState) => ({
  accountGuard: offers.accountGuardOffer
});

export default connect(mapStateToProps)(AccountGuardStatusBanner);
