import { Reducer } from "redux";
import { AgentSettings, AgentVerificationActionTypes, AgentVerificationState } from "./types";

const initialState: AgentVerificationState = {
  sessionId: "",
  verificationIsEnabled: false,
  verificationTagsJsUrl: "",
  verificationProfileUrl: "",
  loadedSuccessfully: false,
  resendEmailInProcess: false,
  isresendEmailSuccess: false,
  tokenVerificationInProcess: false,
  isTokenVerificationSuccess: false,
  isTokenVerificationFailure: false
};

const reducer: Reducer<AgentVerificationState> = (
  state: AgentVerificationState = initialState,
  action
): AgentVerificationState => {
  switch (action.type) {
    case AgentVerificationActionTypes.FETCH_AGENT_SETTINGS_SUCCESS: {
      const data: AgentSettings = action.payload;
      return { ...state, ...data };
    }
    case AgentVerificationActionTypes.LOADED_AGENT_VERIFICATION: {
      const loadedSuccessfully: boolean = action.payload;
      return { ...state, loadedSuccessfully };
    }
    case AgentVerificationActionTypes.RESEND_CONFIRMATION_EMAIL: {
      return {
        ...state,
        resendEmailInProcess: true,
        isresendEmailSuccess: false
      };
    }
    case AgentVerificationActionTypes.RESEND_CONFIRMATION_EMAIL_SUCCESS: {
      return {
        ...state,
        resendEmailInProcess: false,
        isresendEmailSuccess: true
      };
    }
    case AgentVerificationActionTypes.RESEND_CONFIRMATION_EMAIL_ERROR: {
      return {
        ...state,
        resendEmailInProcess: false,
        isresendEmailSuccess: false
      };
    }
    case AgentVerificationActionTypes.INITIATE_TOKEN_VERIFICATION: {
      return {
        ...state,
        tokenVerificationInProcess: true,
        isTokenVerificationSuccess: false,
        isTokenVerificationFailure: false
      };
    }
    case AgentVerificationActionTypes.TOKEN_VERIFICATION_SUCCESS: {
      return {
        ...state,
        tokenVerificationInProcess: false,
        isTokenVerificationSuccess: true,
        isTokenVerificationFailure: false
      };
    }
    case AgentVerificationActionTypes.TOKEN_VERIFICATION_ERROR: {
      return {
        ...state,
        tokenVerificationInProcess: false,
        isTokenVerificationSuccess: false,
        isTokenVerificationFailure: true
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as agentVerificationReducer };

