import * as React from "react";
import InfoSection from "src/components/offers/shared/InfoSection";
import localeService from "src/dataServices/LocaleService";
import "./GuidedDemosM365AdminCenterCard.scss";

const GuidedDemosM365AdminCenterCard = () => {
  const [featureSection, setFeatureSection] = React.useState<HTMLElement | null>(null);
  return (
    <InfoSection
      category="TrainingV2"
      imagePath1="hub-redesign/Training/guided_demo_card1.jpg"
      title1="GuidedDemosSection1Card1Title"
      description1="GuidedDemosSection1Card1Content"
      cardButton1="GuidedDemosViewDemoButton"
      cardLink1={localeService.getText("TrainingV2", "GuidedDemosSection1Card1Link")}
      cardButtonAriaLabel1={localeService.getText("TrainingV2", "GuidedDemosSection1Card1LinkAria")}
      imagePath2="hub-redesign/Training/guided_demo_card2.jpg"
      title2="GuidedDemosSection1Card2Title"
      description2="GuidedDemosSection1Card2Content"
      cardButton2="GuidedDemosViewDemoButton"
      cardLink2={localeService.getText("TrainingV2", "GuidedDemosSection1Card2Link")}
      cardButtonAriaLabel2={localeService.getText("TrainingV2", "GuidedDemosSection1Card2LinkAria")}
      imagePath3="hub-redesign/Training/guided_demo_card3.jpg"
      title3="GuidedDemosSection1Card3Title"
      description3="GuidedDemosSection1Card3Content"
      cardButton3="GuidedDemosViewDemoButton"
      cardLink3={localeService.getText("TrainingV2", "GuidedDemosSection1Card3Link")}
      cardButtonAriaLabel3={localeService.getText("TrainingV2", "GuidedDemosSection1Card3LinkAria")}
      imagePath4="hub-redesign/Training/guided_demo_card4.png"
      title4="GuidedDemosSection1Card4Title"
      description4="GuidedDemosSection1Card4Content"
      cardButton4="GuidedDemosViewDemoButton"
      cardLink4={localeService.getText("TrainingV2", "GuidedDemosSection1Card4Link")}
      cardButtonAriaLabel4={localeService.getText("TrainingV2", "GuidedDemosSection1Card4LinkAria")}
      setRef={setFeatureSection}
    />
  );
};
export default GuidedDemosM365AdminCenterCard;
