export enum MorayButtonType {
  Active,
  Disabled,
  Outline,
  Faint,
  Link
}

export interface IMorayButtonProps {
  text: string | undefined;
  onClick: () => void;
  type?: MorayButtonType;
  disabled?: boolean;
}

const MorayButton = (props: IMorayButtonProps) => {
  switch (props.type) {
    case MorayButtonType.Active:
      return (
        <button className="btn btn-primary" disabled={props.disabled} onClick={props.onClick}>
          {props.text}
        </button>
      );
    case MorayButtonType.Disabled:
      return (
        <button className="btn btn-primary disabled" disabled={props.disabled} onClick={props.onClick}>
          {props.text}
        </button>
      );
    case MorayButtonType.Outline:
      return (
        <button className="btn btn-outline-primary" disabled={props.disabled} onClick={props.onClick}>
          {props.text}
        </button>
      );
    case MorayButtonType.Faint:
      return (
        <button className="btn btn-faint-primary" disabled={props.disabled} onClick={props.onClick}>
          {props.text}
          <span className="glyph glyph-chevron-right" aria-hidden="true"></span>
        </button>
      );
    case MorayButtonType.Link:
      return (
        <button className="btn btn-link" disabled={props.disabled} onClick={props.onClick}>
          {props.text}
        </button>
      );
    default:
      return (
        <button className="btn btn-primary" disabled={props.disabled} onClick={props.onClick}>
          {props.text}
        </button>
      );
  }
};

export default MorayButton;
