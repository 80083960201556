import HelpersUtil from '@ms-mwf/moray/src/js/util/helpers';
import htmlParser from 'html-react-parser';

export interface IMorayRadioProps {
  title: string;
  options: string[];
  selectedOption: string;
  onChange: (value: string) => void;
}

const MorayRadio = ({ title, options, selectedOption, onChange }: IMorayRadioProps) => {
  return (
    <fieldset className="form-group form-group-inline" aria-labelledby="inlineLegendId">
      <legend className="" id="inlineLegendId">
        * {htmlParser(title)}
      </legend>
      <p />
      {options.map((optionName, index) => {
        const ctrlId = `mwf${HelpersUtil.getUid()}`;
        return (
          <div className="custom-control custom-radio custom-control-inline mb-0" key={index}>
            <input
              id={ctrlId}
              type="radio"
              className="custom-control-input "
              name="inlineRadios"
              value={optionName}
              checked={optionName === selectedOption}
              onChange={(e) => onChange(e.target.value)}
            />
            <label className="custom-control-label " htmlFor={ctrlId}>
              <span className="custom-control-glyph" aria-hidden="true"></span>
              {optionName}
            </label>
          </div>
        );
      })}
    </fieldset>
  );
};

export default MorayRadio;
