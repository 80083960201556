// import { Card, ICardTokens } from "@uifabric/react-cards";
import { FontWeights, ITextStyles } from "@fluentui/react";
import "./StatusCardSection.scss";

const textStyles: ITextStyles = {
  root: {
    fontFamily: "Segoe UI light",
    fontSize: 16,
    color: "#000000",
    fontWeight: FontWeights.regular
  }
};
// const cardTokens: ICardTokens = { childrenMargin: 15, maxWidth: 1200, minWidth: 0 };
const StatusCardSection = () => {
  return (
    <section id="registration-complete-card">
      {/* <Card
        aria-label={localeService.getText("RegistrationComplete", "StatusCardArialabel")}
        horizontal
        tokens={cardTokens}
      >
        <Card.Section>
          <Text>
            <span>
              <Icon iconName="ActivityFeed" className="card-title-icon" />
            </span>
            <span className="card-title">{localeService.getText("RegistrationComplete", "Title")}</span>
          </Text>
          <Text styles={textStyles}>{localeService.getText("RegistrationComplete", "StatusCardSectionText")}</Text>
          <Text className="chevron-icon">
            <Link href="/" target="_blank">
              {localeService.getText("RegistrationComplete", "LearnMoreButton")} <Icon iconName="ChevronRightSmall" />
            </Link>
          </Text>
        </Card.Section>
      </Card> */}
    </section>
  );
};

export default StatusCardSection;
