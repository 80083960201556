export const enum FeatureFlagsActionTypes {
  FETCH_FLAGS = '@@feature-flags/FETCH_FLAGS',
  SET_FLAGS = '@@feature-flags/SET_FLAGS'
}

// ADD NEW FEATURE FLAGS HERE
export interface IFeatureFlagsState {
  readonly isLoading: boolean;
  readonly LocalizationV2: boolean;
  readonly BingAdsOfferEnabled: boolean;
  readonly UHFEnabled: boolean;
  readonly TSEnabled: boolean;
  readonly M365CopilotOfferUpdateEnabled: boolean;
  readonly D365CopilotOfferUpdateEnabled: boolean;
  readonly MC4NPOfferUpdateEnabled: boolean;
}
