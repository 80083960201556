import * as React from "react";
import { connect } from "react-redux";
import FollowUsFooter from "src/components/followUsFooter/FollowUsFooter";
import localeService from "src/dataServices/LocaleService";
import { isNullOrUndefined } from "src/common/utilities/Utilities";
import { IApplicationState } from "src/state";
import { OfferId } from "src/state/nonprofit";
import { IOfferRequestState } from "src/state/offers/shared/types";
import { ContactUsSection } from "../shared/ContactUsSection";
import InfoSection from "../shared/InfoSection";
import NavigationBar from "../shared/NavigationBar";
import PricingDisclaimer from "../shared/PricingDisclaimer";
import StandardTrainingSection from "../shared/StandardTrainingSection";
import HardwareDiscount from "./HardwareDiscount";
import M365Offers from "./M365Offers";
import M365Steps from "./M365Steps";
import M365TopBanner from "./M365TopBanner";
import "./M365V2.scss";
import { Over300Employees } from "./Over300Employees";
import PlanSection from "./PlanSection";
import CopilotInfoSection from './CopilotInfoSection';
import useM365CopilotOfferUpdate from './useM365CopilotOfferUpdate';

interface IPropsFromState {
  offers: IOfferRequestState;
}

const Microsoft365V2 = ({ children, offers }: IPropsFromState & { children: any }) => {
  const { isM365CopilotOfferAvailable } = useM365CopilotOfferUpdate();
  const isHardwareEnabled =
    !isNullOrUndefined(children) && children && children.some((o: { id: OfferId }) => o.id === OfferId.hardware);
  const [offerSection, setOfferSection] = React.useState<HTMLElement | null>(null);
  const [planSection, setPlanSection] = React.useState<HTMLElement | null>(null);
  const [stepsSection, setStepsSection] = React.useState<HTMLElement | null>(null);
  const [hardwareSection, setHardwareSection] = React.useState<HTMLElement | null>(null);
  const [trainingSection, setTrainingSection] = React.useState<HTMLElement | null>(null);
  const [topBannerSection, setTopBannerSection] = React.useState<HTMLElement | null>(null);

  React.useEffect(() => {
    if (window.location.pathname.includes('hardware') && hardwareSection !== null) {
      hardwareSection.scrollIntoView({ block: 'center' });
    }
  }, [hardwareSection]);

  React.useEffect(() => {
    if (window.location.hash.includes('m365banner') && topBannerSection !== null) {
      topBannerSection.scrollIntoView({ block: 'center' });
    }
  }, [topBannerSection]);

  React.useEffect(() => {
    if (window.location.hash.includes('m365offer') && offerSection !== null) {
      offerSection.scrollIntoView({ block: 'center' });
    }
  }, [offerSection]);

  return (
    <section>
      <NavigationBar
        offersTitle={localeService.getText('Microsoft365V2', 'NavigationBarOffersTitle')}
        offersElement={offerSection}
        stepsElement={stepsSection}
        trainingElement={trainingSection}
      />
      <M365TopBanner stepsElement={stepsSection} offersElement={offerSection} setRef={setTopBannerSection} />
      <InfoSection
        category="Microsoft365V2"
        imagePath1="hub-redesign/M365-Feature1-1920.jpg"
        title1="ConnectFromAnywhereTitle"
        description1="ConnectFromAnywhereDetail"
        imagePath2="hub-redesign/M365-Feature2-1920.jpg"
        title2="CollaborateAndCreateTitle"
        description2="CollaborateAndCreateDetail"
        imagePath3="hub-redesign/M365-Feature3-1920.jpg"
        title3="ProtectImportantTitle"
        description3="ProtectImportantDetail"
      />
      <M365Offers setRef={setOfferSection} />
      <M365Steps setRef={setStepsSection} plansElement={planSection} />
      <PlanSection setRef={setPlanSection} />
      {isM365CopilotOfferAvailable && <CopilotInfoSection />}
      <Over300Employees />
      {isHardwareEnabled ? <HardwareDiscount offers={offers} setRef={setHardwareSection} /> : undefined}
      <StandardTrainingSection setRef={setTrainingSection} />
      <ContactUsSection />
      <PricingDisclaimer pricingDisclaimer1="PricingDisclaimerCommonParagraph" pricingDisclaimer2="M365Disclaimer" />
      <FollowUsFooter />
    </section>
  );
};

const mapStateToProps = ({ offers }: IApplicationState): IPropsFromState => ({
  offers: offers.sharedOffer
});

export default connect(
  mapStateToProps,
  null
)(Microsoft365V2);
