import { telemetryService } from "npo-common";
import { STRONG_CONSISTENCY_HEADER_NAME, STRONG_CONSISTENCY_HEADER_VALUE } from "./Constants";

export async function httpRequest(
  url: string,
  token?: string | null,
  body?: string | null,
  method: string = "GET"
): Promise<Response> {
  const opts: RequestInit = {};

  opts.headers = {};

  if (telemetryService.getContext() && telemetryService.getContext().SessionId) {
    opts.headers = { ...opts.headers, CorrelationId: telemetryService.getContext().SessionId };
  }

  if (telemetryService.getContext() && telemetryService.getContext().NonprofitId) {
    opts.headers = { ...opts.headers, NonprofitId: telemetryService.getContext().NonprofitId };
  }

  if (token) {
    opts.headers = { ...opts.headers, Authorization: `Bearer ${token}` };
  }

  if (document.referrer) {
    opts.headers = { ...opts.headers, Referrer: document.referrer };
  }

  const isSignupRedirection = sessionStorage.getItem("IsSignupRedirection");
  if (isSignupRedirection) {
    opts.headers[STRONG_CONSISTENCY_HEADER_NAME] = STRONG_CONSISTENCY_HEADER_VALUE;
  }

  opts.method = method;

  if (body) {
    opts.body = body;
  }

  const methods = ["post", "put", "patch"];
  if (methods.indexOf(method.toLowerCase()) >= 0) {
    opts.headers = {
      ...opts.headers,
      Accept: "application/json",
      "Content-Type": "application/json"
    };
  }

  const response: Response = await fetch(url, opts);
  return response;
}
