import { IResizableImage } from "src/components/moray/ResizeableImage";
import localeService from "src/dataServices/LocaleService";
import HighlightBanner from "../shared/HighlightBanner";
import HighlightBannerCarousel from "../shared/HighlightBannerCarousel";

export interface IAccountGuardTopBannerProps {
  stepsElement: HTMLElement | null;
}

const AccountGuardTopBanner = (props: IAccountGuardTopBannerProps) => {
  const resizableImageProps: IResizableImage[] = [
    {
      root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/AccountGuard`,
      imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/AccountGuard/AcctGrd-Highlight1-540.jpg`,
      imageClassName: "account-guard-banner card-img",
      imageAlt: localeService.getText("AccountGuardV2", "TopBannerTitle1"),
      fileSize1920: "AcctGrd-Highlight1-1920.jpg",
      fileSize1400: "AcctGrd-Highlight1-1400.jpg",
      fileSize1084: "AcctGrd-Highlight1-1084.jpg",
      fileSize860: "AcctGrd-Highlight1-860.jpg",
      fileSize540: "AcctGrd-Highlight1-540.jpg",
      fileSize360: "AcctGrd-Highlight1-360.jpg"
    },
    {
      root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/AccountGuard`,
      imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/AccountGuard/AcctGrd-Highlight2-540.jpg`,
      imageClassName: "account-guard-banner card-img",
      imageAlt: localeService.getText("AccountGuardV2", "TopBannerTitle2"),
      fileSize1920: "AcctGrd-Highlight2-1920.jpg",
      fileSize1400: "AcctGrd-Highlight2-1400.jpg",
      fileSize1084: "AcctGrd-Highlight2-1084.jpg",
      fileSize860: "AcctGrd-Highlight2-860.jpg",
      fileSize540: "AcctGrd-Highlight2-540.jpg",
      fileSize360: "AcctGrd-Highlight2-360.jpg"
    }
  ];

  return (
    <HighlightBannerCarousel>
      <HighlightBanner
        reverseTextAlign={true}
        titleText={localeService.getText("AccountGuardV2", "TopBannerTitle1")}
        descriptionText={localeService.getText("AccountGuardV2", "TopBannerDescription1")}
        buttonText={localeService.getText("AccountGuardV2", "TopBannerButton1")}
        buttonOnClick={() => props.stepsElement?.scrollIntoView({ behavior: "smooth", block: "center" })}
        buttonAriaLabel={localeService.getText("AccountGuardV2", "TopBannerButton1")}
        resizableImageProps={resizableImageProps[0]}
      />
      <HighlightBanner
        titleText={localeService.getText("AccountGuardV2", "TopBannerTitle2")}
        descriptionText={localeService.getText("AccountGuardV2", "TopBannerDescription2")}
        buttonText={localeService.getText("AccountGuardV2", "TopBannerButton2")}
        buttonOnClick={() => window.open(localeService.getText("AccountGuardV2", "AccountGuardSecurity"))}
        buttonAriaLabel={localeService.getText("AccountGuardV2", "TopBannerButton2")}
        resizableImageProps={resizableImageProps[1]}
      />
    </HighlightBannerCarousel>
  );
};

export default AccountGuardTopBanner;
