import { IApiResponse } from "src/common/ApiResponse";
import { IOfferPayload } from "src/state/offers/shared/types";
import { callApi } from "./SharedServiceFunctions";

export class OffersService {
  private API_URL = process.env.REACT_APP_INTERNAL_API_BASEURL;

  public async initiateOfferRequest(values: IOfferPayload): Promise<IApiResponse<{}>> {
    return await callApi<{}>(`${this.API_URL}/nonprofitoffers/claim`, JSON.stringify(values), "POST");
  }
}

const offersService = new OffersService();
export default offersService;
