import { all, apply, fork, put, takeEvery } from "redux-saga/effects";
import { IApiResponse } from "src/common/ApiResponse";
import bingAdsService from "src/dataServices/BingAdsService";
import { ActionType } from "typesafe-actions";
import {
  fetchBingAdsRegistrationInfo, setBingAdsRegistrationInfo,
  setBingAdsRegistrationInfoError, submitClaimAdsOffer
} from "./actions";
import { BingAdsActionTypes, BingAdsRegistrationInfo, BingAdsRegistrationState } from "./types";

export function* handleFetchBingAdsRegistrationInfo(action: ActionType<typeof fetchBingAdsRegistrationInfo>) {
  const nonprofitId = action.payload;
  try {
    const response: IApiResponse<BingAdsRegistrationInfo> = yield apply(
      bingAdsService,
      bingAdsService.getAdsRegistration,
      [nonprofitId]
    );
    if (response && response.status == 204) {
      yield put(
        setBingAdsRegistrationInfoError({
          adsRegistrationInfo: null,
          httpStatusCode: response.status.toString(),
          errorMessage: "No Ads Registration found",
          isLoading: false
        } as unknown as BingAdsRegistrationState)
      );
    } else if (response && response.value && response.status == 200) {
      yield put(setBingAdsRegistrationInfo(response.value));
    } else {
      const errorState: BingAdsRegistrationState = {
        adsRegistrationInfo: null as unknown as BingAdsRegistrationInfo,
        httpStatusCode: response.status?.toString() as unknown as string,
        errorMessage: `Exception while fetching registration ${response.error as string}`,
        isLoading: false
      };
      yield put(setBingAdsRegistrationInfoError(errorState));
    }
  } catch (err: any) {
    const errorState: BingAdsRegistrationState = {
      adsRegistrationInfo: null as unknown as BingAdsRegistrationInfo,
      httpStatusCode: "500",
      errorMessage: err.message,
      isLoading: false
    };
    yield put(setBingAdsRegistrationInfoError(errorState));
  }
}

export function* handleSubmitClaimAdsOffer(action: ActionType<typeof submitClaimAdsOffer>) {
  const submitRequest = action.payload;
  try {
    const response: IApiResponse<BingAdsRegistrationInfo> = yield apply(bingAdsService, bingAdsService.claimAdsOffer, [
      submitRequest.nonprofitId,
      submitRequest.request
    ]);
    if (response && response.value && response.status == 200) {
      yield put(setBingAdsRegistrationInfo(response.value));
    } else {
      const errorState: BingAdsRegistrationState = {
        adsRegistrationInfo: null as unknown as BingAdsRegistrationInfo,
        httpStatusCode: response.status?.toString() as unknown as string,
        errorMessage: `Exception while claiming registration ${response.error as string}`,
        isLoading: false
      };
      yield put(setBingAdsRegistrationInfoError(errorState));
    }
  } catch (err: any) {
    const errorState: BingAdsRegistrationState = {
      adsRegistrationInfo: null as unknown as BingAdsRegistrationInfo,
      httpStatusCode: "500",
      errorMessage: err.message,
      isLoading: false
    };
    yield put(setBingAdsRegistrationInfoError(errorState));
  }
}

function* watchFetchBingAdsRegistrationInfo() {
  yield takeEvery(BingAdsActionTypes.FETCH_ADS_REGISTRATION_INFO, handleFetchBingAdsRegistrationInfo);
}

function* watchSubmitClaimAdsOffer() {
  yield takeEvery(BingAdsActionTypes.SUBMIT_CLAIM_ADS_OFFER, handleSubmitClaimAdsOffer);
}

function* bingAdsOfferSaga() {
  yield all([fork(watchFetchBingAdsRegistrationInfo), fork(watchSubmitClaimAdsOffer)]);
}

export default bingAdsOfferSaga;
