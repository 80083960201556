export const enum BingAdsActionTypes {
  FETCH_ADS_REGISTRATION_INFO = "@@offers/bing-ads/FETCH_ADS_REGISTRATION_INFO",
  SET_ADS_REGISTRATION_INFO = "@@offers/bing-ads/SET_ADS_REGISTRATION_INFO",
  SET_ADS_REGISTRATION_INFO_ERROR = "@@offers/bing-ads/SET_ADS_REGISTRATION_INFO_ERROR",
  SUBMIT_CLAIM_ADS_OFFER = "@@offers/bing-ads/SUBMIT_CLAIM_ADS_OFFER"
}

export interface BingAdsRegistrationInfo {
  id: string;
  nonprofitId: string;
  status: string;
  provider: string;
  website: string;
  countryCode: string;
  language: string;
  currencyCode: string;
  accountId: string;
  accountNumber: string;
  customerId: string;
  customerNumber: string;
  createdDate: string;
  createdBy: string;
  lastUpdatedDate: string;
  lastUpdatedBy: string;
}

export interface ClaimAdsOfferRequest {
  tenantId: string;
  websiteUrl: string;
}

export interface SubmitClaimAdsOfferRequest {
  nonprofitId: string;
  request: ClaimAdsOfferRequest;
}

export interface BingAdsRegistrationState {
  adsRegistrationInfo: BingAdsRegistrationInfo;
  isLoading: boolean;
  httpStatusCode: string;
  errorMessage: string;
}
