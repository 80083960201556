import "@ms-mwf/moray/dist/css/main.css";
import CollapseControls from "@ms-mwf/moray/src/components/collapse/collapse-controls";
import HelpersUtil from "@ms-mwf/moray/src/js/util/helpers";
import * as React from "react";
import MorayCollapse from "src/components/moray/MorayCollapse";
import localeService from "src/dataServices/LocaleService";
import "./ProfileCollapse.scss";

interface IProfileCollapseProps {
  organizationRegistrationInfo?: JSX.Element;
  organizationInfo: JSX.Element;
  primaryContact: JSX.Element;
}

export const ProfileCollapse: React.FunctionComponent<IProfileCollapseProps> = props => {
  const element = React.useRef<HTMLDivElement | null>(null);
  const ctrlId = `mwf${HelpersUtil.getUid()}`;

  React.useEffect(() => {
    new CollapseControls({
      el: element.current
    });
  }, []);

  return (
    <section className="profile-collapse">
      <div
        ref={el => {
          element.current = el;
        }}
        className="btn-group align-items-center mb-g"
        role="group"
        data-mount="collapse-controls"
        data-target={`#${ctrlId}`}
      >
        <button className="btn btn-link" aria-disabled="false" aria-pressed="false" data-action="expand" type="button">
          {localeService.getText("ProfileV2", "Expand")}
        </button>
        <span aria-hidden="true"> | </span>
        <button
          className="btn btn-link"
          aria-disabled="false"
          aria-pressed="false"
          data-action="collapse"
          type="button"
        >
          {localeService.getText("ProfileV2", "Collapse")}
        </button>
      </div>
      <ul className="accordion" id={ctrlId}>
        {props.organizationRegistrationInfo && (
          <li className="profile-section">
            <MorayCollapse
              title={localeService.getText("ProfileV2", "CollapseTitle1")}
              description={props.organizationRegistrationInfo}
            />
          </li>
        )}
        <li className="profile-section">
          <MorayCollapse
            title={localeService.getText("ProfileV2", "CollapseTitle2")}
            description={props.organizationInfo}
          />
        </li>
        <li className="profile-section">
          <MorayCollapse
            title={localeService.getText("ProfileV2", "CollapseTitle3")}
            description={props.primaryContact}
          />
        </li>
      </ul>
    </section>
  );
};
