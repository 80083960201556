import * as React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "src/state";
import { IFeatureFlagsState } from "src/state/feature-flags";

interface IOwnProps {
  name: string;
  component?: React.ComponentType;
  render?: () => React.ReactNode;
  fallbackComponent?: React.ComponentType;
  fallbackRender?: () => React.ReactNode;
  children?: React.ReactNode;
}

interface IPropsFromState {
  flags: IFeatureFlagsState;
}

export type IFlagProps = IOwnProps & IPropsFromState;
// The flag class makes rendering decisions based on the same logic as
// https://github.com/garbles/flag.
export class Flag extends React.Component<IFlagProps> {
  public render() {
    const { children, component, name, flags, render, fallbackComponent, fallbackRender } = this.props;
    if (flags[name as keyof IFeatureFlagsState]) {
      if (children) {
        return children;
      } else if (component) {
        return React.createElement(component);
      } else if (render) {
        return render();
      } else {
        return null;
      }
    } else {
      if (fallbackComponent) {
        return React.createElement(fallbackComponent);
      } else if (fallbackRender) {
        return fallbackRender();
      } else {
        return null;
      }
    }
  }
}

const MapStateToProps = ({ flags }: IApplicationState): IPropsFromState => ({ flags });
export default connect(MapStateToProps)(Flag);
