import { IApiResponse } from "src/common/ApiResponse";
import authenticationService from "./AuthenticationService";
import { callApi } from "./SharedServiceFunctions";

export class AccountGuardService {
  private API_URL = process.env.REACT_APP_INTERNAL_API_BASEURL;

  public async initiateRequest(): Promise<IApiResponse<{}>> {
    const token = await authenticationService.getToken();
    return await callApi<{}>(`${this.API_URL}/accountguard/`, token, "POST");
  }

  public async getAccountGuardInfo(): Promise<IApiResponse<boolean>> {
    return await callApi<boolean>(`${this.API_URL}/accountguard/claimstatus`);
  }
}

const accountGuardService = new AccountGuardService();
export default accountGuardService;
