import { all, apply, fork, put, takeEvery } from "redux-saga/effects";
import { IApiResponse } from "src/common/ApiResponse";
import hardwareOfferService from "src/dataServices/HardwareOfferService";
import linkedInOfferService from "src/dataServices/LinkedInOfferService";
import offersService from "src/dataServices/OffersService";
import { ActionType } from "typesafe-actions";
import { claimOfferRequest, offersRequest, offersRequestError, offersRequestSuccess } from "./actions";
import { IOfferPayload, OffersRequestActionTypes } from "./types";

export function* handleOffersRequest(action: ActionType<typeof offersRequest>) {
  try {
    const formValues = action.payload;
    const formType = formValues.type;
    const service = formType === "LinkedIn" ? linkedInOfferService : hardwareOfferService;

    const response: IApiResponse<{}> = yield apply(service, service.initiateRequest, [formValues]);
    if (response.status === 200) {
      yield put(offersRequestSuccess());
    } else {
      yield put(offersRequestError());
    }
  } catch (e) {
    console.error(`Error sending ${action.payload.type} request.`, e);
    yield put(offersRequestError());
  }
}

export function* handleClaimOfferRequest(action: ActionType<typeof claimOfferRequest>) {
  try {
    const formValues = action.payload;
    const offerPayload = { offerId: formValues.offerId, userConsent: formValues.userConsent } as IOfferPayload;

    const response: IApiResponse<{}> = yield apply(offersService, offersService.initiateOfferRequest, [offerPayload]);
    if (response.status === 200) {
      yield put(offersRequestSuccess());
    } else {
      console.error(`Error sending ${action.payload} request.`);
      yield put(offersRequestError());
    }
  } catch (e) {
    console.error(`Error sending ${action.payload} request.`, e);
    yield put(offersRequestError());
  }
}

function* watchOffersRequest() {
  yield takeEvery(OffersRequestActionTypes.OFFERS_REQUEST, handleOffersRequest);
}

function* watchClaimOfferRequest() {
  yield takeEvery(OffersRequestActionTypes.CLAIM_OFFER_REQUEST, handleClaimOfferRequest);
}

function* OffersRequestSaga() {
  yield all([fork(watchOffersRequest), fork(watchClaimOfferRequest)]);
}

export default OffersRequestSaga;
