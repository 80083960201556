import * as React from "react";
import Text from "src/components/text/Text";
import TextLink from "src/components/text/TextLink";
import "./MoreInfo.scss";

export function MoreInfo() {
  return (
    <section id="more-info-section">
      <p>
        <i>
          <Text category="Covid19Details" id="Info2" />
        </i>
      </p>
      <p>
        <i>
          <Text category="Covid19Details" id="Info3" />
        </i>
      </p>
      <p>
        <Text category="Covid19Details" id="Info4" />
      </p>
      <table className="table-section">
        <thead>
          <tr>
            <th>
              <Text category="Covid19Details" id="TableCol1Title" />
            </th>
            <th>
              <Text category="Covid19Details" id="TableCol2Title" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Text category="Covid19Details" id="TableItem1OS" />
            </td>
            <td>
              <Text category="Covid19Details" id="TableItem1RequiredLicence" />
            </td>
          </tr>
          <tr>
            <td>
              <Text category="Covid19Details" id="TableItem2OS" />
            </td>
            <td>
              <Text category="Covid19Details" id="TableItem2RequiredLicence" />
            </td>
          </tr>
          <tr>
            <td>
              <Text category="Covid19Details" id="TableItem3OS" />
            </td>
            <td>
              <Text category="Covid19Details" id="TableItem3RequiredLicence" />
            </td>
          </tr>
        </tbody>
      </table>

      <Text category="Covid19Details" id="Info5" />
      <ul>
        <li>
          <TextLink category="Covid19Details" id="Info5_1" href="AzureActiveDirectory" target="_blank" />
        </li>
        <li>
          <Text category="Covid19Details" id="Info5_2" />
          <ul>
            <li>
              <Text category="Covid19Details" id="Info5_2_1" />
            </li>
            <li>
              <Text category="Covid19Details" id="Info5_2_2" />
            </li>
          </ul>
        </li>
        <li>
          <Text category="Covid19Details" id="Info5_3" />
        </li>
      </ul>

      <Text category="Covid19Details" id="Info7" />
      <ul>
        <li>
          <TextLink category="Covid19Details" id="Info7_1" href="AdDsComparison HybridAzAd" target="_blank" />
        </li>
        <li>
          <TextLink category="Covid19Details" id="Info7_2" href="SupportedOSImages" target="_blank" />
        </li>
      </ul>
      <p>
        <TextLink category="Covid19Details" id="Info8" href="VirtualDesktopOverview" target="_blank" />
      </p>
      <p>
        <TextLink category="Covid19Details" id="Info9" href="MCT" target="_blank" />
      </p>
      <p>
        <TextLink
          category="Covid19Details"
          id="Info10"
          href="CommunityTrainingPricing CommunityTrainingInstallation"
          target="_blank"
        />
      </p>
    </section>
  );
}
