import { AccountInfo } from "@azure/msal-browser";
import * as React from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import localeService from "src/dataServices/LocaleService";
import localeEnabledForV2, { LocaleConfigStrings } from "src/common/localeEnabledForV2";
import { IApplicationState } from "src/state";
import Text from "../text/Text";
import Navigation from "../navigation/Navigation";
import NavigationV2 from "../navigationV2/NavigationV2";
import UserPanel from "../user/UserPanel";
import { getLocalizedPath, getRouteSetting } from "../utilities/Localization/Utils";
import "./Header.scss";
import SignInCTA from "./SignInCTA";

interface IPropsFromState {
  user: AccountInfo | null | undefined;
  currentPath?: string;
}

export type IHeaderProps = IPropsFromState & RouteComponentProps;

export class Header extends React.Component<IHeaderProps> {
  private renderNavigationV2() {
    if (localeEnabledForV2(LocaleConfigStrings.Navigation)) {
      return <NavigationV2 />;
    } else {
      return <Navigation />;
    }
  }

  public render() {
    const { user } = this.props;
    const navigationHiddenForUrls: string[] = new Array<string>(
      "/register",
      "/signin",
      "/azurerenew",
      "/getting-started"
    );
    const navigationEnabled = !navigationHiddenForUrls.some(u => window.location.pathname.indexOf(u) >= 0);
    const userPanelHiddenForUrls: string[] = new Array<string>(
      "/register",
      "/signin",
      "/azurerenew",
      "/getting-started"
    );
    const userPanelEnabled = user && !userPanelHiddenForUrls.some(u => window.location.pathname.indexOf(u) >= 0);
    const signInEnabled = window.location.pathname.indexOf("/getting-started") >= 0;
    const routeSetting = getRouteSetting(this.props.currentPath);

    return (
      <div id="header-panel" className="px-5">
        <a href="#home-page" className="btn btn-link sr-only-focusable w-100 skip-to-main-link">
          {localeService.getText("HomeV2", "SkipToMainContentLink")}
        </a>
        <div id="header-container">
          <Link
            id="header-name"
            to={getLocalizedPath("/")}
            onClick={() => (`/${localeService.getlocale()}/` === window.location.pathname ? window.location.reload() : null)}
          >
            <Text category="Header" id="Nonprofits" />
          </Link>
          {navigationEnabled && this.renderNavigationV2()}
          {userPanelEnabled ? <UserPanel /> : null}
          {signInEnabled ? <SignInCTA /> : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }: IApplicationState) => ({
  user: auth.user,
  currentPath: window.location.pathname
});

export default withRouter(connect(mapStateToProps)(Header));
