import * as React from "react";
import HighlightBanner from "../shared/HighlightBanner";
import localeService from "src/dataServices/LocaleService";
import { connect } from "react-redux";
import { IApplicationState } from "src/state";
import { BingAdsRegistrationState } from "src/state/offers/bing-ads/types";

interface IPropsFromState {
  bingAds: BingAdsRegistrationState;
}

interface IPropsFromParent {
  onClickActivate: () => any;
}

export type IBingAdsHighlightBannerProps = IPropsFromState & IPropsFromParent;

export const BingAdsHighlightBanner = (props: IBingAdsHighlightBannerProps) => (
  <>
    {
      <HighlightBanner
        titleText={localeService.getText("BingAds", "AdsForSocialImpact")}
        descriptionText={localeService.getText("BingAds", "AdsForSocialImpactCallToAction")}
        buttonText={
          !props.bingAds || !props.bingAds.adsRegistrationInfo
            ? localeService.getText("BingAds", "ActivateNow")
            : undefined
        }
        buttonOnClick={() => {
          props.onClickActivate();
        }}
        buttonAriaLabel={localeService.getText("BingAds", "ActivateNow")}
        resizableImageProps={{
          root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/BingAds`,
          imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/BingAds/AdsForSocialBanner.png`,
          imageClassName: "bing-ads-banner card-img",
          imageAlt: localeService.getText("BingAds", "AdsForSocialImpact"),
          fileSize1084: "AdsForSocialBanner.png"
        }}
        reverseTextAlign={false}
        reverseTextColor={false}
        isLoading={false}
        gradient={"right"}
      />
    }
  </>
);

const mapStateToProps = ({ offers }: IApplicationState) => ({
  bingAds: offers.bingAds
});

export default connect(mapStateToProps)(BingAdsHighlightBanner);
