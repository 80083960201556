export const enum WcpCookieConsentActionTypes {
  SET_COOKIE_CONSENT = "@@layout/SET_COOKIE_CONSENT"
}

export interface WcpCookieConsentResponse {
  isRequired: boolean;
  markup: string;
  cssUrl: string;
  jsUrl: string;
}

export type WcpCookieConsentState = WcpCookieConsentResponse & { hasConsent: boolean };
