import * as React from "react";
import { useState } from "react";
import FollowUsFooter from "src/components/followUsFooter/FollowUsFooter";
import WithProfile from "src/components/WithProfile";
import { ContactUsSection } from "../shared/ContactUsSection";
import StandardTrainingSection from "../shared/StandardTrainingSection";
import "./BingAds.scss";
import BingAdsAdditionalSupport from "./BingAdsAdditionalSupport";
import BingAdsClaimOfferModal from "./BingAdsClaimOfferModal";
import BingAdsDisclaimer from "./BingAdsDisclaimer";
import BingAdsFeatures from "./BingAdsFeatures";
import BingAdsHighlightBanner from "./BingAdsHighlightBanner";
import BingAdsOfferDetails from "./BingAdsOfferDetails";
import BingAdsStatusBanner from "./BingAdsStatusBanner";
import WithBingAdsRegistration from "./WithBingAdsRegistration";
import localeService from "src/dataServices/LocaleService";

const BingAds = () => {
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [triedOfferClaim, setTriedOfferCLaim] = useState(false);

  const openActivationModal = () => {
    setShowActivateModal(true);
  };

  const closeActivationModal = () => {
    setShowActivateModal(false);
  };

  const closeActivateModalAfterOfferClaim = () => {
    setShowActivateModal(false);
    setTriedOfferCLaim(true);
  };

  return (
    <WithProfile>
      <WithBingAdsRegistration>
      <div className="skip-to-main">
        <a href="#bing-ads-features" className="btn btn-link sr-only-focusable w-100 position-absolute skip-to-main-link">
          {localeService.getText("BingAds", "SkipToMainContentLink")}
          </a>
        </div>
        <div id="bing-ads-components">
          <BingAdsStatusBanner showErrorBanner={triedOfferClaim} tryAgainAction={openActivationModal} />
          <BingAdsHighlightBanner onClickActivate={openActivationModal} />
          <BingAdsFeatures />
          <BingAdsOfferDetails onClickActivate={openActivationModal} />
          <BingAdsAdditionalSupport />
          <StandardTrainingSection />
          <ContactUsSection />
          <BingAdsDisclaimer />
          <FollowUsFooter />
          <BingAdsClaimOfferModal
            isOpen={showActivateModal}
            onDismiss={closeActivationModal}
            onComplete={closeActivateModalAfterOfferClaim}
          />
        </div>
      </WithBingAdsRegistration>
    </WithProfile>
  );
};

export default BingAds;
