import "@ms-mwf/moray/dist/css/main.css";
import * as React from "react";
import localeService from "src/dataServices/LocaleService";

class ContactUsV2BillingSection extends React.Component {
  public render() {
    return (
      <div
        className="col mb-4 mb-md-0 material-surface"
        style={{
          padding: "20px 0"
        }}
      >
        <div className="card " data-mount="click-group">
          <div
            className="row "
            style={{
              margin: "0"
            }}
          >
            <div
              className="col-2"
              style={{
                padding: "0"
              }}
            >
              <div
                className="nonprofit-offers-icon"
                style={{
                  textAlign: "right"
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 2048 2048"
                  style={{
                    height: "24px",
                    width: "24px",
                    color: "#0067B8"
                  }}
                >
                  <path d="M2048 1280h-384v256H640l-384 384v-384H0V384h256V0h1792v1280zm-512-768H128v896h256v203q51-51 100-102t103-101h949V512zm384-384H384v256h1280v768h256V128z" />
                </svg>
              </div>
            </div>
            <div className="col">
              <div className="card-body ">
                <h2
                  className=" "
                  style={{
                    fontSize: "21px",
                    lineHeight: "26px"
                  }}
                >
                  {localeService.getText("ContactUsBillingSection", "Title")}
                </h2>
                <p
                  style={{
                    margin: "8px 0"
                  }}
                >
                  {localeService.getText("ContactUsBillingSection", "Detail")}
                </p>
                <div
                  className="link-group"
                  style={{
                    margin: "0"
                  }}
                >
                  <div>
                    <a
                      style={{
                        margin: "0"
                      }}
                      href="https://admin.microsoft.com"
                    >
                      {localeService.getText("ContactUsBillingSection", "Link1")}
                    </a>
                  </div>
                  <div>
                    <a
                      style={{
                        margin: "0"
                      }}
                      href="https://portal.azure.com"
                    >
                      {localeService.getText("ContactUsBillingSection", "Link2")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUsV2BillingSection;
