import * as msal from "@azure/msal-browser";

// Use const enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
export const enum AuthActionTypes {
  SET_AUTH_STATUS = "@@auth/SET_AUTH_STATUS",
  INITIATE_LOGIN = "@@auth/INITIATE_LOGIN",
  PROCESS_LOGIN_PROMISE = "@@auth/PROCESS_LOGIN_PROMISE",
  INITIATE_REGISTER = "@@auth/INITIATE_REGISTER",
  INITIATE_LOGOUT = "@@auth/INITIATE_LOGOUT"
}

export interface AuthStatus {
  readonly isAuthenticated: boolean;
  readonly isAgentOkay: boolean;
  readonly user: msal.AccountInfo | null | undefined;
}

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export type AuthState = AuthStatus & {
  readonly isSigningIn: boolean;
  readonly isRegistering: boolean;
  readonly isLoading: boolean;
};
