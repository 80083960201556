import { Icon } from "@fluentui/react";
import "@ms-mwf/moray/dist/css/main.css";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import localeService from "src/dataServices/LocaleService";
import { IApplicationState } from "src/state";
import { showFewerResults, showMoreResults } from "src/state/query/actions";
import MorayAccordion from "../moray/MorayAccordion";
import { MorayCollapseProps } from "../moray/MorayCollapse";

export interface IPropsFromState {
  insights: any;
  sentSuccessfully: boolean;
  numberOfSolutions: any;
  responseRecieved: any;
  ErrorResults: any;
}
export interface IPropsFromDispatch {
  showMoreResults: any;
  showFewerResults: any;
}

export type SelfHelpProps = IPropsFromState & IPropsFromDispatch;

const conditionalDisplay = (shouldDisplay: number | boolean, component: JSX.Element, ifFalse: JSX.Element) => {
  if (shouldDisplay) {
    return component;
  }
  return ifFalse;
};

const noResults = (text: string | undefined) => (
  <div
    style={{
      fontSize: "24px",
      lineHeight: "24px",
      color: "#757575",
      padding: "20px 40px"
    }}
  >
    <Icon
      iconName={"info"}
      style={{
        float: "left",
        padding: "0 20px"
      }}
    />
    <p
      role = "alert"
      style={{
        fontSize: "16px",
        lineHeight: "24px",
        color: "#757575"
      }}
    >
      {text}
    </p>
  </div>
);
const errorResults = (text: string | undefined) => (
  <div
    style={{
      fontSize: "24px",
      lineHeight: "24px",
      color: "#E81123",
      padding: "20px 40px"
    }}
  >
    <Icon
      iconName={"error"}
      style={{
        float: "left",
        padding: "0 20px"
      }}
    />
    <p
      style={{
        fontSize: "16px",
        lineHeight: "24px",
        color: "#E81123"
      }}
    >
      {text}
    </p>
  </div>
);

class ContactUsV2SelfHelp extends React.Component<SelfHelpProps> {
  public render() {
    const query = this.props.insights;
    const numberOfSolutions = this.props.numberOfSolutions;
    const errorText = localeService.getText("ContactUsSelfHelp", "ErrorResults");
    const noResultsText = localeService.getText("ContactUsSelfHelp", "NoSearchResults");

    let selfHelpSolutions: MorayCollapseProps[] = [];
    if (query.queryResponse && query.queryResponse.Insights) {
      selfHelpSolutions = query.queryResponse.Insights.map((insight: { Title: any; Description: any; }) => ({
        title: insight.Title,
        description: insight.Description
      }));
    }
    return conditionalDisplay(
      selfHelpSolutions.length && !this.props.sentSuccessfully,
      <div style={{ padding: "40px" }}>
        <MorayAccordion
          title={localeService.getText("ContactUsSelfHelp", "Title")}
          items={selfHelpSolutions.slice(0, numberOfSolutions)}
          totalItems={selfHelpSolutions.length}
          showMoreResults={this.props.showMoreResults}
          showFewerResults={this.props.showFewerResults}
        />
      </div>,
      conditionalDisplay(
        this.props.ErrorResults,
        errorResults(errorText),
        conditionalDisplay(
          !selfHelpSolutions.length && !this.props.sentSuccessfully && this.props.responseRecieved,
          noResults(noResultsText),
          <React.Fragment />
        )
      )
    );
  }
}

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  insights: state.query,
  sentSuccessfully: state.contactUs.sentSuccessfully ?? false,
  responseRecieved: state.query.queryResponse.CorrelationId,
  numberOfSolutions: state.query.numberOfSolutions,
  ErrorResults: state.query.error
});

const mapDispatchToProps: (dispatch: Dispatch) => IPropsFromDispatch = dispatch => ({
  showMoreResults: () => dispatch(showMoreResults()),
  showFewerResults: () => dispatch(showFewerResults())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactUsV2SelfHelp);
