import * as React from "react";
import Text from "src/components/text/Text";
import { getLocalizedPath } from "src/components/utilities/Localization/Utils";
import localeService from "src/dataServices/LocaleService";
import StatusBanner, { StatusIcon } from "../shared/StatusBanner";

export interface ISoftwareSendEligibilitySuccess {}

const SoftwareSendEligibilitySuccess = (props: ISoftwareSendEligibilitySuccess) => {
  var thisComponent: HTMLDivElement | null;

  React.useEffect(() => {
    if (thisComponent !== null) {
      thisComponent.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  }, []);

  return (
    <div
      ref={ref => {
        thisComponent = ref;
      }}
    >
      <StatusBanner iconType={StatusIcon.success} text={localeService.getText("SoftwareV2", "SendEligibilitySuccess")}>
        <button
          className="btn btn-primary"
          onClick={() => {
            window.open(getLocalizedPath("/"), "_self");
          }}
        >
          <Text category="SoftwareV2" id="BackToHomePageButton" />
        </button>
      </StatusBanner>
    </div>
  );
};

export default SoftwareSendEligibilitySuccess;
