import { ActionType, Behavior } from '@microsoft/1ds-analytics-web-js';
import { logService } from 'npo-common';
import { useState } from 'react';
import localeService from 'src/dataServices/LocaleService';
import { EligibilityPreCheckScn } from '../../common/Constants';
import MorayDropdown, { IMorayDropdownOption } from '../../components/moray/MorayDropdown';
import MorayRadio from '../../components/moray/MorayRadio';
import MoraySelect, { IMoraySelectOption } from '../../components/moray/MoraySelect';
import EligibilityPreCheckFailureScreen from './EligibilityPreCheckFailureScreen';
import './EligibilityPreCheckForm.scss';
import EligibilityPreCheckSuccessScreen from './EligibilityPreCheckSuccessScreen';

interface ICountryData {
  name: string;
  question: string;
}

interface IPrimaryActivity {
  value: string;
  displayName: string;
  children?: IPrimaryActivity[];
}

export enum EligibilityStatus {
  Eligible = 'Eligible',
  Ineligible = 'Ineligible',
  NotCompleted = 'NotCompleted'
}

enum EligiblePrimaryActivities {
  CultureArtsAndHumanities = 'CultureArtsAndHumanities',
  OtherEducationalOrResearchInstitute = 'OtherEducationalOrResearchInstitute',
  PublicPolicyAnalysis = 'PublicPolicyAnalysis',
  EnvironmentAndAnimalWelfare = 'EnvironmentAndAnimalWelfare',
  OtherHealthServices = 'OtherHealthServices',
  CivilRightsAndSocialAction = 'CivilRightsAndSocialAction',
  CrimePreventionOrLegalServices = 'CrimePreventionOrLegalServices',
  ReligiousAndSpiritual = 'ReligiousAndSpiritual',
  SocialServicesAndSupport = 'SocialServicesAndSupport',
  AmateurRecreationalSports = 'AmateurRecreationalSports'
}

enum EligiblePrimaryActivitiesIndependentOfCountry {
  LibraryOrMuseum = 'LibraryOrMuseum'
}

const EligibilityPreCheckForm = () => {
  const CountryData: ICountryData[] = localeService.getContent('GettingStartedV2', 'CountryData') as ICountryData[];
  const PrimaryActivities: IPrimaryActivity[] = localeService.getContent(
    'GettingStartedV2',
    'PrimaryActivities'
  ) as IPrimaryActivity[];

  const defaultPrimaryActivity = {
    value: '',
    displayName: localeService.getText('GettingStartedV2', 'EligibilityPreCheckSelectOne') ?? ''
  };
  const [country, setCountry] = useState<string>('');
  const [primaryActivity, setPrimaryActivity] = useState<IMorayDropdownOption>(defaultPrimaryActivity);
  const [countryAnswer, setCountryAnswer] = useState<string>('');
  const [eligibilityStatus, setEligibilityStatus] = useState<EligibilityStatus>(EligibilityStatus.NotCompleted);

  const moraySelectOptions: IMoraySelectOption[] = CountryData.map((countryData: ICountryData) => ({
    value: countryData.name,
    displayName: countryData.name
  }));

  const handleCountryChange = (value: string) => {
    setCountryAnswer('');
    setCountry(value);
  };

  const handlePrimaryActivityChange = (value: IMorayDropdownOption | undefined) => {
    setPrimaryActivity(value ?? defaultPrimaryActivity);
  };

  const handleCountryAnswerChange = (value: string) => {
    setCountryAnswer(value);
  };

  const getCountryQuestion = () => {
    return CountryData.find((countryData: ICountryData) => countryData.name === country)?.question ?? '';
  };

  const handleSubmission = () => {
    if (
      countryAnswer === localeService.getText('GettingStartedV2', 'EligibilityPreCheckCountryQuestionAnswerYes') &&
      primaryActivity.value in EligiblePrimaryActivities
    ) {
      window.analytics.capturePageAction(null, {
        behavior: Behavior.COMPLETEPROCESS,
        actionType: ActionType.CLICKLEFT,
        contentTags: {
          scn: EligibilityPreCheckScn,
          status: EligibilityStatus.Eligible
        }
      });
      logService.logInfo("EligibilityPreCheck: Completed", { status: EligibilityStatus.Eligible });

      setEligibilityStatus(EligibilityStatus.Eligible);
    } else if (primaryActivity.value in EligiblePrimaryActivitiesIndependentOfCountry) {
      window.analytics.capturePageAction(null, {
        behavior: Behavior.COMPLETEPROCESS,
        actionType: ActionType.CLICKLEFT,
        contentTags: {
          scn: EligibilityPreCheckScn,
          status: EligibilityStatus.Eligible
        }
      });
      logService.logInfo("EligibilityPreCheck: Completed", { status: EligibilityStatus.Eligible });

      setEligibilityStatus(EligibilityStatus.Eligible);
    } else {
      window.analytics.capturePageAction(null, {
        behavior: Behavior.COMPLETEPROCESS,
        actionType: ActionType.CLICKLEFT,
        contentTags: {
          scn: EligibilityPreCheckScn,
          status: EligibilityStatus.Ineligible
        }
      });
      logService.logInfo("EligibilityPreCheck: Completed", { status: EligibilityStatus.Ineligible });

      setEligibilityStatus(EligibilityStatus.Ineligible);
    }
  };

  const onStartOver = () => {
    window.analytics.capturePageAction(null, {
      behavior: Behavior.STARTPROCESS,
      actionType: ActionType.CLICKLEFT,
      contentTags: {
        scn: EligibilityPreCheckScn,
        status: EligibilityStatus.NotCompleted
      }
    });
    logService.logInfo('EligibilityPreCheck: Started', { status: EligibilityStatus.NotCompleted });

    setEligibilityStatus(EligibilityStatus.NotCompleted);
    setCountry('');
    setCountryAnswer('');
    setPrimaryActivity(defaultPrimaryActivity);
  };

  const isSubmissionEnabled = primaryActivity.value !== '' && countryAnswer !== '';

  switch (eligibilityStatus) {
    case EligibilityStatus.Eligible:
      return <EligibilityPreCheckSuccessScreen onStartOver={onStartOver} />;
    case EligibilityStatus.Ineligible:
      return <EligibilityPreCheckFailureScreen onStartOver={onStartOver} />;
    case EligibilityStatus.NotCompleted:
      return (
        <div className="p-2 pl-3 m-0 container">
          <div className="row col mb-2">
            <p className="required-field-alert">
              {localeService.getText('GettingStartedV2', 'EligibilityPreCheckRequiredField')}
            </p>
          </div>
          <div className="row row-cols-1 row-cols-md-2">
            <div className="col">
              <p className="mb-1">
                {localeService.getText('GettingStartedV2', 'EligibilityPreCheckOrganizationLocation')}
              </p>
              <MoraySelect
                defaultOption={{
                  value: '',
                  displayName: localeService.getText('GettingStartedV2', 'EligibilityPreCheckSelectALocation') ?? ''
                }}
                options={moraySelectOptions}
                onSelect={handleCountryChange}
              />
            </div>
            <div className="col pt-md-0 py-4">
              <p className="mb-1">{localeService.getText('GettingStartedV2', 'EligibilityPreCheckPrimaryActivity')}</p>
              <MorayDropdown
                optionName={primaryActivity.displayName}
                options={PrimaryActivities}
                onSelect={handlePrimaryActivityChange}
              />
            </div>
          </div>
          <div className="row col mt-md-4 mb-md-2">
            {country !== '' && (
              <MorayRadio
                title={getCountryQuestion()}
                options={[
                  localeService.getText('GettingStartedV2', 'EligibilityPreCheckCountryQuestionAnswerYes') ?? '',
                  localeService.getText('GettingStartedV2', 'EligibilityPreCheckCountryQuestionAnswerNo') ?? ''
                ]}
                selectedOption={countryAnswer}
                onChange={handleCountryAnswerChange}
              />
            )}
          </div>
          <div className={`row col mt-4`}>
            <button className={`btn btn-outline-primary`} disabled={!isSubmissionEnabled} onClick={handleSubmission}>
              {localeService.getText('GettingStartedV2', 'EligibilityPreCheckContinue')}
            </button>
          </div>
        </div>
      );
  }
};

export default EligibilityPreCheckForm;
