import localeService from "src/dataServices/LocaleService";
import { IProductCard } from "../../shared/ProductCard";
import ProductCardCarousel from "../../shared/ProductCardCarousel";

const ExploreAdoptionGuidesCard = () => {
  const images = [
    "microsoft-icon.svg",
    "document-collaboration-adoption-guides-icon.svg",
    "microsoft-teams-adoption-guides-icon.svg"
  ];
  const productCardCarouselProps = localeService.getCategoryContent("TrainingV2")
    .ExploreSection5ProductCards as IProductCard[];

  for (let i = 0; i < images.length; i++) {
    productCardCarouselProps[i].imageSrc = `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/Training/${images[i]}`;
  }

  const numCardsPerSlide = { xl: 4, lg: 4, md: 3, sm: 2, xs: 1 };

  return (
    <ProductCardCarousel
      title={localeService.getText("TrainingV2", "ExploreSection5Subtitle")}
      productCardCarouselProps={productCardCarouselProps}
      numCardsPerSlide={numCardsPerSlide}
    />
  );
};

export default ExploreAdoptionGuidesCard;
