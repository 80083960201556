import { all, apply, fork, put, select, takeLatest } from "redux-saga/effects";
import { getPermissionToContactInitialValues } from "src/components/contactpermission/Util";
import { IApiResponse } from "src/common/ApiResponse";
import ProfileService from "src/dataServices/ProfileService";
import { ActionType } from "typesafe-actions";
import { IApplicationState } from "../index";
import { initiateLogin } from "../auth/actions";
import { fetchNonprofitRequest, initiateContactPermissionSubmission, INonprofitMiniProfile } from "../nonprofit";
import {
  agentEmailSubmissionResult,
  fetchNewProfileSuccess,
  fetchProfileRequest,
  fetchProfileSuccess,
  initiateAgentEmailEdit,
  newProfileFormUpdate,
  newProfileUpdateComplete,
  profileFetchError,
  profileFormSubmissionValidationSuccess,
  profileFormUpdate,
  profileUpdateComplete,
  profileUpdateError,
  setEditMode
} from "./actions";
import { IProfile, IProfileState, ProfileActionTypes } from "./types";
import { logService } from "npo-common";

export function* handleFetchProfileCallback() {
  const isAuthenticated: boolean = yield select((state: IApplicationState) => state.auth.isAuthenticated);
  if (!isAuthenticated) {
    yield put(initiateLogin());
  } else {
    try {
      const profileResult: IApiResponse<any> = yield apply(ProfileService, ProfileService.getProfile, []);
      if (profileResult.status === 200) {
        yield all([put(fetchProfileSuccess(profileResult.value)), put(setEditMode(profileResult.value.isIncomplete))]);
        const profileValues = profileResult.value as IProfile;
        const profileState: IProfileState = yield select((state: IApplicationState) => state.profile);
        profileValues.permissionToContact = getPermissionToContactInitialValues(profileState);
      } else {
        yield put(profileFetchError(profileResult.error as string));
      }
    } catch (error: any) {
      yield put(profileFetchError(error));
    }
  }
}

export function* handleProfileAckAttestation() {
  try {
    const updatedProfile: IProfile = yield select((state: IApplicationState) => state.profile.profile);
    const profileUpdateResult: IApiResponse<boolean> = yield apply(ProfileService, ProfileService.saveProfile, [
      updatedProfile
    ]);

    logService.logInfo(
      "profileUpdateResult",
      {
        value: profileUpdateResult.value,
        status: profileUpdateResult.status
      }
    );
    if (profileUpdateResult.status === 200) {
      yield all([
        put(initiateContactPermissionSubmission(updatedProfile.permissionToContact)),
        put(profileUpdateComplete(profileUpdateResult.value)),
        put(fetchNonprofitRequest())
      ]);
    } else {
      yield put(profileUpdateError(profileUpdateResult.error as string));
    }
  } catch (error: any) {
    yield put(profileUpdateError(error));
  }
}

export function* handleProfileFormSubmissionValidation() {
  try {
    const profileState: IProfileState = yield select((state: IApplicationState) => state.profile);
    let profileValidationResult: IApiResponse<{}>;
    if (profileState.isNewProfile) {
      profileState.profile.lastAttestationDate = new Date();

      profileValidationResult = yield apply(ProfileService, ProfileService.profileCreateSubmitValidation, [
        profileState.profile
      ]);
    } else {
      profileValidationResult = yield apply(ProfileService, ProfileService.profileUpdateSubmitValidation, [
        profileState.profile
      ]);
    }

    if (profileValidationResult.status === 200) {
      yield all([
        put(profileFormSubmissionValidationSuccess()),
        profileState.isNewProfile
          ? put(newProfileFormUpdate(profileState.profile))
          : put(profileFormUpdate(profileState.profile))
      ]);

      // to restore the original state of this state value for error props
      yield put(profileUpdateError(undefined));
    } else {
      yield all([
        put(profileFormSubmissionValidationSuccess()),
        put(profileUpdateError(profileValidationResult.error as string))
      ]);
    }
  } catch (error: any) {
    yield all([put(profileFormSubmissionValidationSuccess()), put(profileUpdateError(error))]);
  }
}

export function* handleFetchProfileCommercial() {
  const isAuthenticated: boolean = yield select((state: IApplicationState) => state.auth.isAuthenticated);
  const nonprofit = (yield select(
    (state: IApplicationState) => state.nonprofit.miniProfile
  ) as unknown) as INonprofitMiniProfile;

  if (!isAuthenticated) {
    yield put(initiateLogin());
  } else {
    try {
      if (nonprofit) {
        const profileResult: IProfile = yield populateNonprofitData(nonprofit);

        yield put(fetchNewProfileSuccess(profileResult));
      }
    } catch (error: any) {
      yield put(profileFetchError(error));
    }
  }
}

function* populateNonprofitData(nonprofit: INonprofitMiniProfile) {
  const nameArray = nonprofit.primaryContact.firstName.split(" ");
  const firstName = nameArray[0];
  const middleName = nameArray.length > 2 ? nameArray[1] : undefined;
  const lastName = nameArray[nameArray.length - 1];

  const profileResult = {
    address: nonprofit.address,
    name: nonprofit.name || "",
    isIncomplete: nonprofit.isIncomplete,
    primaryContact: {
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      email: nonprofit.primaryContact.email,
      emailLanguagePreference: nonprofit.primaryContact.emailLanguagePreference
    },
    effectiveDateTime: nonprofit.effectiveDateTime,
    status: nonprofit.status,
    registrationIdentifiers: [],
    notificationEmails: [nonprofit.primaryContact.email],
    disableCountryEdit: nonprofit.disableCountryEdit,
    isEditable: true
  } as unknown as IProfile;

  const profileState: IProfileState = yield select((state: IApplicationState) => state.profile);
  profileResult.permissionToContact = getPermissionToContactInitialValues(profileState);

  return profileResult;
}

export function* handleNewProfileAckAttestation() {
  try {
    const newProfile: IProfile = yield select((state: IApplicationState) => state.profile.profile);
    const profileCreateResult: IApiResponse<string> = yield apply(ProfileService, ProfileService.createProfile, [
      newProfile
    ]);

    logService.logInfo(
      `${handleNewProfileAckAttestation.name} newProfileResult`,
      {
        value: profileCreateResult.value,
        status: profileCreateResult.status
      }
    );

    if (profileCreateResult.status === 200) {
      yield all([
        put(initiateContactPermissionSubmission(newProfile.permissionToContact)),
        put(newProfileUpdateComplete(profileCreateResult.value)),
        put(fetchNonprofitRequest())
      ]);
    } else {
      yield put(profileUpdateError(profileCreateResult.error as string));
    }
  } catch (error: any) {
    yield put(profileUpdateError(error));
  }
}

export function* handleAgentEmailSubmissionInProcess(action: ActionType<typeof initiateAgentEmailEdit>) {
  try {
    const updateAgentEmailResult: IApiResponse<string> = yield apply(ProfileService, ProfileService.updateAgentEmail, [
      action.payload
    ]);

    if (updateAgentEmailResult.status === 200) {
      yield put(agentEmailSubmissionResult(true));
    } else {
      yield put(agentEmailSubmissionResult(false));
    }
  } catch (error) {
    yield put(agentEmailSubmissionResult(false));
  }
}

export function* handleSaveProfile() {
  try {
    const updatedProfile: IProfile = yield select((state: IApplicationState) => state.profile.profile);
    const profileUpdateResult: IApiResponse<boolean> = yield apply(ProfileService, ProfileService.saveProfile, [
      updatedProfile
    ]);

    logService.logInfo(
      `${handleSaveProfile.name} newProfileResult`,
      {
        value: profileUpdateResult.value,
        status: profileUpdateResult.status
      }
    );

    if (profileUpdateResult.status === 200) {
      yield all([
        put(initiateContactPermissionSubmission(updatedProfile.permissionToContact)),
        put(profileUpdateComplete(profileUpdateResult.value)),
        put(fetchNonprofitRequest()),
        put(setEditMode(false))
      ]);
    } else {
      yield put(profileUpdateError(profileUpdateResult.error as string));
    }
  } catch (error: any) {
    yield put(profileUpdateError(error));
  }
}

export function* handleCreateProfile() {
  try {
    const newProfile: IProfile = yield select((state: IApplicationState) => state.profile.profile);
    const profileCreateResult: IApiResponse<string> = yield apply(ProfileService, ProfileService.createProfile, [
      newProfile
    ]);

    logService.logInfo(
      `${handleCreateProfile.name} newProfileResult`,
      {
        value: profileCreateResult.value,
        status: profileCreateResult.status
      }
    );

    if (profileCreateResult.status === 200) {
      yield all([
        put(initiateContactPermissionSubmission(newProfile.permissionToContact)),
        put(newProfileUpdateComplete(profileCreateResult.value)),
        put(fetchNonprofitRequest())
      ]);
    } else {
      yield put(profileUpdateError(profileCreateResult.error as string));
    }
  } catch (error: any) {
    yield put(profileUpdateError(error));
  }
}

function* watchFetchProfile() {
  yield takeLatest(ProfileActionTypes.FETCH_PROFILE_REQUEST, handleFetchProfileCallback);
}

function* watchFetchProfileCommercial() {
  yield takeLatest(ProfileActionTypes.FETCH_PROFILE_COMMERCIAL, handleFetchProfileCommercial);
}

function* watchProfileAckAttestation() {
  yield takeLatest(ProfileActionTypes.PROFILE_ACK_ATTESTATION, handleProfileAckAttestation);
}

function* watchNewProfileAckAttestation() {
  yield takeLatest(ProfileActionTypes.NEW_PROFILE_ACK_ATTESTATION, handleNewProfileAckAttestation);
}

function* watchProfileFormSubmissionValidation() {
  yield takeLatest(ProfileActionTypes.PROFILE_FORM_SUBMISSION_VALIDATION, handleProfileFormSubmissionValidation);
}

function* watchAgentEmailSubmissionInProcess() {
  yield takeLatest(ProfileActionTypes.INITIATE_AGENT_EMAIL_EDIT, handleAgentEmailSubmissionInProcess);
}

function* watchSaveProfile() {
  yield takeLatest(ProfileActionTypes.SAVE_PROFILE, handleSaveProfile);
}

function* watchCreateProfile() {
  yield takeLatest(ProfileActionTypes.CREATE_PROFILE, handleCreateProfile);
}

function* profileSaga() {
  yield all([
    fork(watchFetchProfile),
    fork(watchProfileAckAttestation),
    fork(watchFetchProfileCommercial),
    fork(watchNewProfileAckAttestation),
    fork(watchProfileFormSubmissionValidation),
    fork(watchAgentEmailSubmissionInProcess),
    fork(watchSaveProfile),
    fork(watchCreateProfile)
  ]);
}

export default profileSaga;
