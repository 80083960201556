import { IconType, Spinner, SpinnerSize } from "@fluentui/react";
import { BaseButton, Button, CompoundButton, IButtonProps, IconButton, PrimaryButton } from "@fluentui/react/lib/Button";
import * as React from "react";
import { logService, TelemetryEventType } from "npo-common";
import { IMobileIconProps, isNullOrUndefined } from "src/common/utilities/Utilities";
import "./LoadingButton.scss";

export type ILoadingButtonProps = IButtonProps & {
  loading: boolean;
  isExternal?: boolean;
  properties?: { [key: string]: any };
  mobileIconProps?: IMobileIconProps;
  secondaryText?: string;
};

const LoadingButton: React.FunctionComponent<ILoadingButtonProps> = props => {
  const { loading, isExternal, properties, mobileIconProps, secondaryText } = props;

  function handleOnClick(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement, MouseEvent>) {
    logService.logEvent({
      Name: 'ButtonClick',
      EventType: TelemetryEventType.SecondaryWorkflow,
      SloId: '',
      ...properties
    });
    if (!isNullOrUndefined(props) && !isNullOrUndefined(props.onClick) && typeof props.onClick === "function") {
      props.onClick(event);
    }
  }

  if (secondaryText) {
    return (
      <div className="loading-button-container">
        <CompoundButton
          primary
          {...props}
          disabled={props.disabled || loading}
          onClick={handleOnClick}
          secondaryText={secondaryText}
          styles={{
            label: { fontSize: "18px", fontWeight: "bold" },
            description: { fontSize: "15px" },
            root: { boxShadow: "rgb(0 0 0) 3px 3px 5px 1px" }
          }}
        />
        {loading && <Spinner size={SpinnerSize.small} ariaLive="assertive" />}
      </div>
    );
  }

  return (
    <div className="loading-button-container">
      {mobileIconProps ? (
        <IconButton
          iconProps={{
            className: "icon-button-props",
            iconType: mobileIconProps.isImage ? IconType.image : IconType.default,
            imageProps:
              mobileIconProps.isImage && mobileIconProps.imageProps
                ? {
                    src: mobileIconProps.name,
                    width: mobileIconProps.imageProps.width,
                    height: mobileIconProps.imageProps.height
                  }
                : undefined,
            iconName: mobileIconProps.isImage ? undefined : mobileIconProps.name
          }}
          {...props}
          disabled={props.disabled || loading}
          onClick={handleOnClick}
        />
      ) : isExternal ? (
        <PrimaryButton
          iconProps={{ iconName: "ChevronRightSmall" }}
          {...props}
          disabled={props.disabled || loading}
          onClick={handleOnClick}
        />
      ) : (
        <PrimaryButton {...props} disabled={props.disabled || loading} onClick={handleOnClick} />
      )}
      {loading && <Spinner size={SpinnerSize.small} ariaLive="assertive" />}
    </div>
  );
};

export default LoadingButton;
