import { Reducer } from "redux";
import { WcpCookieConsentActionTypes, WcpCookieConsentState } from "./types";

const initialState: WcpCookieConsentState = {
  isRequired: false,
  markup: "",
  jsUrl: "",
  cssUrl: "",
  hasConsent: false
};

const reducer: Reducer<WcpCookieConsentState> = (
  state: WcpCookieConsentState = initialState,
  action
): WcpCookieConsentState => {
  switch (action.type) {
    case WcpCookieConsentActionTypes.SET_COOKIE_CONSENT: {
      const hasConsent: boolean = action.payload;
      return { ...state, hasConsent };
    }
    default: {
      return state;
    }
  }
};

export { reducer as wcpCookieConsentReducer };

