import { IApiResponse } from "src/common/ApiResponse";
import { IContactPermissionSettings, INonprofitEmailUpdate, IProfile } from "src/state/profile/types";
import { callApi } from "./SharedServiceFunctions";

export class ProfileService {
  private API_URL = process.env.REACT_APP_INTERNAL_API_BASEURL;

  public async getProfile(): Promise<IApiResponse<IProfile>> {
    const response = await callApi<IProfile>(`${this.API_URL}/profile`);

    // Profile feature toggle - isIncomplete experience
    // response.value.isIncomplete = true;
    // response.value.registrationIdentifiers = [];

    // Profile feature toggle - readonly experience
    // response.value.isEditable = false;

    return response;
  }

  public async createProfile(profile: IProfile): Promise<IApiResponse<string>> {
    const response = await callApi<string>(`${this.API_URL}/profile`, JSON.stringify(profile), "POST");
    return response;
  }

  public async saveProfile(profile: IProfile): Promise<IApiResponse<boolean>> {
    const response = await callApi<boolean>(`${this.API_URL}/profile`, JSON.stringify(profile), "PUT");
    return response;
  }

  public async profileUpdateSubmitValidation(profile: IProfile): Promise<IApiResponse<{}>> {
    const response = await callApi<boolean>(`${this.API_URL}/profile/validateupdate`, JSON.stringify(profile), "POST");
    return response;
  }

  public async profileCreateSubmitValidation(profile: IProfile): Promise<IApiResponse<{}>> {
    const response = await callApi<boolean>(`${this.API_URL}/profile/validatecreate`, JSON.stringify(profile), "POST");
    return response;
  }

  public async getContactPermissions(): Promise<IApiResponse<IContactPermissionSettings>> {
    const response = await callApi<IContactPermissionSettings>(`${this.API_URL}/profile/contactpermissions`);
    return response;
  }

  public async patchContactPermissions(permissionToContact: boolean | undefined): Promise<IApiResponse<{}>> {
    const response = await callApi<IApiResponse<{}>>(
      `${this.API_URL}/profile/contactpermissions/${permissionToContact}`,
      null,
      "PATCH"
    );

    return { status: response.status };
  }

  public async updateAgentEmail(nonprofitEmailUpdate: INonprofitEmailUpdate): Promise<IApiResponse<{}>> {
    const response = await callApi<IApiResponse<{}>>(
      `${this.API_URL}/profile/update/email`,
      JSON.stringify(nonprofitEmailUpdate),
      "PUT"
    );

    return { status: response.status };
  }
}

const profileService = new ProfileService();
export default profileService;
