import Text from "src/components/text/Text";

export const requiredFieldValidator = (value: any) => (value ? undefined : "RequiredError");
export const emailAddressValidator = (value: any) =>
  value && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/i.test(value) ? "InvalidEmailError" : undefined;
export const websiteUrlValidator = (value: any) =>
  value &&
  !/(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i.test(value)
    ? "InvalidWebsiteError"
    : undefined;

export const inputTextFieldComponent = (field: any) => {
  const {
    input,
    type,
    id,
    category,
    name,
    label,
    required,
    readOnly,
    placeholder,
    maxLength,
    autoFocus,
    disabled,
    meta: { error, touched }
  } = field;
  return (
    <div className="form-field">
      <input
        {...input}
        type={type}
        id={id}
        category={category}
        name={name}
        label={label}
        required={required}
        placeholder={placeholder}
        readOnly={readOnly}
        autoFocus={autoFocus}
        maxLength={maxLength}
        disabled={disabled}
        className={touched && error ? "has-error" : null}
      />
      {touched && error && (
        <div role="alert" className="error">
          <Text category={`${category}`} id={`${id}${error}`} />
        </div>
      )}
    </div>
  );
};
export const inputTextAreaComponent = (field: any) => {
  const {
    input,
    id,
    name,
    category,
    label,
    rows,
    required,
    readOnly,
    placeholder,
    maxLength,
    autoFocus,
    disabled,
    meta: { error, touched }
  } = field;
  return (
    <div className="form-field">
      <textarea
        {...input}
        id={id}
        name={name}
        category={category}
        label={label}
        rows={rows}
        required={required}
        placeholder={placeholder}
        readOnly={readOnly}
        autoFocus={autoFocus}
        maxLength={maxLength}
        disabled={disabled}
        className={touched && error ? "has-error" : null}
      />
      {touched && error && (
        <div role="alert" className="error">
          <Text category={`${category}`} id={`${id}${error}`} />
        </div>
      )}
    </div>
  );
};
export const inputTextFieldValidators = (required: boolean, type: string) => (value: any) => {
  const validators = [];
  if (required) {
    validators.push(requiredFieldValidator);
  }
  if (type === "email") {
    validators.push(emailAddressValidator);
  }

  return validators.reduce((error: any, validator) => error || validator(value), undefined);
};
