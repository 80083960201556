import { all, apply, cancelled, fork, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { getRouteSetting, handleFallbackRoute, isRedirectionRoute } from "src/components/utilities/Localization/Utils";
import { IApiResponse } from "src/common/ApiResponse";
import localeService from "src/dataServices/LocaleService";
import { logService } from "npo-common";
import { ActionType } from "typesafe-actions";
import { IApplicationState } from "../index";
import { addContentToDictionary, addedToDictionarySuccess, localizationRequest } from "./actions";
import { ILocale, ILocalizationResolver, ILocalizationResolverReason, LocalizationActionTypes } from "./types";

export function* handleGetLocale(action: ActionType<typeof localizationRequest>) {
  const localeFromState: string = yield select((state: IApplicationState) => state.locale.currentLocale);
  const newLocaleForEvaluation = action.payload;

  let localeContent: ILocale | undefined = undefined;
  let localizationResolution: { resolvedReason: ILocalizationResolverReason; languageToFetch: string };

  try {
    const valueToFetch =
      newLocaleForEvaluation !== null &&
      newLocaleForEvaluation !== undefined &&
      newLocaleForEvaluation !== localeFromState
        ? newLocaleForEvaluation
        : localeFromState;

    // Decide what language to fetch and it's location - either CDN or local.
    localizationResolution = yield (apply(localeService, localeService.evaluateLocaleToFetch, [
      valueToFetch
    ]) as unknown) as ILocalizationResolver;

    localeContent = yield fetchData(localizationResolution);

    if (localeContent !== undefined) {
      yield put(addContentToDictionary(localeContent));
    }
  } catch (error: any) {
    logService.logError(error.message, error);
  } finally {
    const isCancelled: boolean = yield cancelled();
    if (isCancelled) {
      // do nothing if it was a cancellation.
    } else if (localeContent === undefined) {
      // get fallback data when no localeContent is retrieved.
      const fallBackContent = localeService.getFallbackData();
      yield put(addContentToDictionary(fallBackContent));

      handleFallbackRoute(window.location.pathname, newLocaleForEvaluation, fallBackContent.key.toLowerCase());
    }
  }
}

/**
 * Processes the localization resolver reason, and fetches the data from the required store - either CDN or local.
 * @param resolvedReason The resolved reason that determines data to be fetched.
 */
function* fetchData(resolvedReason: ILocalizationResolver) {
  switch (resolvedReason.resolvedReason) {
    case ILocalizationResolverReason.FetchFromLocalStorage:
      const dataFromLocal = localeService.fetchDataFromLocalStorage(resolvedReason.languageToFetch);
      return { key: resolvedReason.languageToFetch, content: dataFromLocal };
    case ILocalizationResolverReason.FetchFromCDN:
    case ILocalizationResolverReason.None:
      const dataFromCDN: IApiResponse<{ [key: string]: any }> = yield apply(localeService, localeService.fetchDataFromCDN, [resolvedReason.languageToFetch]);
      const resolvedDatafromCDN = dataFromCDN as IApiResponse<{ [key: string]: any }>;

      const dataFromSignupCDN: IApiResponse<{ [key: string]: any }> = yield apply(localeService, localeService.fetchDataFromSignupCDN, [
        resolvedReason.languageToFetch
      ]);
      const resolvedDatafromSignupCDN = dataFromSignupCDN as IApiResponse<{ [key: string]: any }>;

      if (
        resolvedDatafromCDN.status === 200 &&
        resolvedDatafromSignupCDN.status === 200 &&
        resolvedDatafromCDN.value !== undefined &&
        resolvedDatafromSignupCDN !== undefined &&
        Object.keys(resolvedDatafromCDN.value).length !== 0 &&
        Object.keys(resolvedDatafromSignupCDN.value ?? []).length !== 0
      ) {
        return {
          key: resolvedReason.languageToFetch,
          content: { ...resolvedDatafromCDN.value, ...resolvedDatafromSignupCDN.value }
        };
      } else {
        if (resolvedDatafromCDN.value === undefined || Object.keys(resolvedDatafromCDN.value).length === 0) {
          logService.logError("resolvedDatafromCDN.value undefined or empty.", resolvedDatafromCDN);
        }

        if (
          resolvedDatafromSignupCDN.value === undefined ||
          Object.keys(resolvedDatafromSignupCDN.value).length === 0
        ) {
          logService.logError("resolvedDatafromSignupCDN.value undefined or empty.", resolvedDatafromSignupCDN);
        }

        return undefined;
      }
    default:
      throw Error("Invalid Resolution, fallback activated.");
  }
}

export function* handleAddtoDictionaryRequest(action: ActionType<typeof addContentToDictionary>) {
  const addedToDictionary: boolean = yield apply(localeService, localeService.addContentToDictionary, [action.payload]);
  const value = action.payload as ILocale;

  if (addedToDictionary === true) {
    document.documentElement.lang = value.key;

    const currentPath = window.location.pathname;
    const routeSetting = getRouteSetting(currentPath);
    const isOldRoutePath = isRedirectionRoute(currentPath);
    const key = "locale";

    // dont update session storage for a disallowed route and old paths. This ensures that one returns to the original locale after going out of these route/redirections.
    if (!routeSetting.isDisallowed && !isOldRoutePath) {
      sessionStorage.setItem(key, value.key);
    }

    yield put(addedToDictionarySuccess(value.key));
  }
}

function* watchLocalizationRequest() {
  yield takeLatest(LocalizationActionTypes.LOCALIZATION_INIT_REQUEST, handleGetLocale);
}

function* watchaddToDictionaryRequest() {
  yield takeEvery(LocalizationActionTypes.SET_VALUE_IN_DICTIONARY, handleAddtoDictionaryRequest);
}

function* LocalizationSaga() {
  yield all([fork(watchLocalizationRequest), fork(watchaddToDictionaryRequest)]);
}

export default LocalizationSaga;
