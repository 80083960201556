import { Icon } from "@fluentui/react/lib/Icon";
import { Link } from "@fluentui/react/lib/Link";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import Text from "src/components/text/Text";
import * as agentVerificationActions from "src/state/agent-verification/actions";
import "./ResendConfirmation.scss";

interface IPropsFromDispatch {
  resendEmail?: typeof agentVerificationActions.resendVerificationEmail;
}

export interface IResendConfirmationProps {
  iconRequired: boolean;
}

export type ResendConfirmationProps = IPropsFromDispatch & IResendConfirmationProps;

export class ResendConfirmation extends React.Component<ResendConfirmationProps> {
  public render() {
    const { iconRequired, resendEmail } = this.props;

    return (
      <div className="ms-Grid-row" id="submit-code">
        {iconRequired ? (
          <p>
            {" "}
            <Icon iconName="Accept" className="icon-green" />
            <Text category="AgentVerification" id="New_Code_Text" />
          </p>
        ) : (
          <p>
            <Text category="AgentVerification" id="Content_1" />
            <Link type="submit" onClick={() => resendEmail?.()}>
              <Text category="AgentVerification" id="Content_2" />
            </Link>
            <Text category="AgentVerification" id="Content_3" />
          </p>
        )}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  resendEmail: () => dispatch(agentVerificationActions.resendVerificationEmail())
});

export default connect(
  null,
  mapDispatchToProps
)(ResendConfirmation);
