import { logService } from 'npo-common';
import MorayButton from 'src/components/moray/MorayButton';
import localeService from 'src/dataServices/LocaleService';

const GrantsSection = () => {
  const onClickApply = () => {
    if (process.env.REACT_APP_SIGNUP_URL) {
      window.location.href = process.env.REACT_APP_SIGNUP_URL;
    } else {
      logService.logError('missing process.env.REACT_APP_SIGNUP_URL in GrantsSection');
    }
  };

  return (
    <div className="container bg-white h-100">
      <div className="py-3 px-0 px-md-4 px-lg-0 h-100 d-flex flex-column">
        <h4 className="font-weight-semibold h4 py-2">
          {localeService.getText('GettingStartedV2', 'GrantsAndDiscountsTitle')}
        </h4>
        <p>{localeService.getText('GettingStartedV2', 'GrantsHeader')}</p>
        <div className="row row-cols-1 row-cols-sm-3">
          <div className="row sameline">
            <p
              className={`glyph-prepend glyph-prepend-large glyph-prepend-check-mark text-blue mb-list-element pr-xs-1 pr-sm-0`}
            />
            <p className="ml-1 mr-2">{localeService.getText('GettingStartedV2', 'GrantsCheck1')}</p>
          </div>
          <div className="row sameline">
            <p
              className={`glyph-prepend glyph-prepend-large glyph-prepend-check-mark text-blue mb-list-element pr-xs-1 pr-sm-0`}
            />
            <p className="ml-1 mr-2">{localeService.getText('GettingStartedV2', 'GrantsCheck2')}</p>
          </div>
          <div className="row sameline">
            <p
              className={`glyph-prepend glyph-prepend-large glyph-prepend-check-mark text-blue mb-list-element pr-xs-1 pr-sm-0`}
            />
            <p className="ml-1 mr-2">{localeService.getText('GettingStartedV2', 'GrantsCheck3')}</p>
          </div>
        </div>
        <div className="mt-auto">
          <p>{localeService.getText('GettingStartedV2', 'GrantsDescription')}</p>
          <MorayButton text={localeService.getText('GettingStartedV2', 'GrantsApplyButton')} onClick={onClickApply} />
        </div>
      </div>
    </div>
  );
};

export default GrantsSection;
