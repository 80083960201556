import * as React from "react";
import Text from "src/components/text/Text";

export function AzureOnboardingConcierge() {
  return (
    <>
      <h4>
        <Text category="AzureOnboardingConcierge" id="Title" />
      </h4>
      <section id="azure-onboarding-concierge">
        <p>
          <Text category="AzureOnboardingConcierge" id="Detail1" />
        </p>
        <div className="list-section">
          <ul>
            <li>
              <Text category="AzureOnboardingConcierge" id="ListItem1" />
            </li>
            <li>
              <Text category="AzureOnboardingConcierge" id="ListItem2" />
            </li>
            <li>
              <Text category="AzureOnboardingConcierge" id="ListItem3" />
            </li>
            <li>
              <Text category="AzureOnboardingConcierge" id="ListItem4" />
            </li>
            <li>
              <Text category="AzureOnboardingConcierge" id="ListItem5" />
            </li>
          </ul>
        </div>
      </section>
      <p style={{ fontStyle: "italic" }}>
        <Text category="AzureOnboardingConcierge" id="OnlyEnglish" />
      </p>
    </>
  );
}
