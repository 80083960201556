import MorayButton, { MorayButtonType } from 'src/components/moray/MorayButton';
import localeService from 'src/dataServices/LocaleService';
import { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { initiateLogin } from 'src/state/auth';

interface IPropsFromState {}

interface IPropsFromDispatch {
  initiateLogin: typeof initiateLogin;
}

const SignInSection = (props: IPropsFromState & IPropsFromDispatch) => {
  const [signinButtonDisabled, setSigninButtonDisabled] = useState(false);

  const initiateLogin = () => {
    setSigninButtonDisabled(true);
    props.initiateLogin();
  };

  return (
    <div className="container bg-white h-100">
      <div className="py-3 px-0 px-md-4 px-lg-0 h-100 d-flex flex-column">
        <h4 className="font-weight-semibold h4 py-2">
          {localeService.getText('GettingStartedV2', 'SignInSectionTitle')}
        </h4>
        <p>{localeService.getText('GettingStartedV2', 'SignInSectionDescription')}</p>
        <div className="mt-auto">
          <MorayButton
            text={localeService.getText('GettingStartedV2', 'SignInSectionButton')}
            onClick={initiateLogin}
            type={MorayButtonType.Outline}
            disabled={signinButtonDisabled}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (): IPropsFromState => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initiateLogin: () => dispatch(initiateLogin())
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInSection);
