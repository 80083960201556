import { logService } from "npo-common";
import { all, apply, delay, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { IApiResponse } from "src/common/ApiResponse";
import authenticationService from "src/dataServices/AuthenticationService";
import { default as NonprofitService } from "src/dataServices/NonprofitService";
import profileService from "src/dataServices/ProfileService";
import { ActionType } from "typesafe-actions";
import { loadedAgentVerification } from "../agent-verification/actions";
import { AgentVerificationActionTypes } from "../agent-verification/types";
import { initiateLogin } from "../auth/actions";
import { IApplicationState } from "../index";
import { setAppContext } from "../layout/actions";
import { fetchAzureRegistrationInfo } from "../offers/azure/actions";
import { fetchContactPermissionSettings, setCommercial, setEditMode } from "../profile/actions";
import { IContactPermissionSettings } from "../profile/types";
import {
  ContactPermissionError,
  ContactPermissionSuccess,
  fetchNonprofitFromGraphSuccess,
  fetchNonprofitRequest,
  fetchNonprofitSuccess,
  initiateContactPermissionSubmission,
  nonprofitError
} from "./actions";
import { INonprofitMiniProfile, NonprofitActionTypes, NonprofitError, NonprofitErrorOutcome, OfferId } from "./types";

export function* handleFetchNonprofitCallback() {
  const isAuthenticated: boolean = yield select((state: IApplicationState) => state.auth.isAuthenticated);
  logService.logInfo(
    "nonprofit sagas.ts: state isAuthenticated=" +
      isAuthenticated +
      ", authService isAuthenticated=" +
      authenticationService.isAuthenticated
  );

  if (!isAuthenticated) {
    yield put(initiateLogin());
  } else {
    try {
      let agentVerificationLoaded: boolean = yield select(
        (state: IApplicationState) => state.agentVerification.loadedSuccessfully
      );
      if (typeof agentVerificationLoaded === "undefined") {
        const loadedAction: ActionType<typeof loadedAgentVerification> = yield take(
          AgentVerificationActionTypes.LOADED_AGENT_VERIFICATION
        );
        agentVerificationLoaded = loadedAction.payload;
      }

      let nonprofit: INonprofitMiniProfile;
      const npResult: IApiResponse<INonprofitMiniProfile> = yield apply(
        NonprofitService,
        NonprofitService.getNonprofit,
        []
      );

      if (npResult && npResult.value && npResult.status === 200) {
        nonprofit = npResult.value;
        let otherResultsSucceeded = true;
        const otherResults: Array<IApiResponse<any>> = yield all([
          apply(NonprofitService, NonprofitService.getValidationStatus, []),
          apply(NonprofitService, NonprofitService.getOffers, [])
        ]);

        const validationRequestResult = otherResults[0];
        if (validationRequestResult && validationRequestResult.status === 200) {
          nonprofit.validationRequest = validationRequestResult.value;
        } else {
          otherResultsSucceeded = false;
          yield errorAndRedirect(NonprofitError.Unknown);
        }

        const offersResult = otherResults[1];
        if (offersResult && (offersResult.status === 200 || offersResult.status === 204)) {
          nonprofit.availableOffers = offersResult.value || [];
        } else {
          otherResultsSucceeded = false;
          yield errorAndRedirect(NonprofitError.Unknown);
        }

        if (otherResultsSucceeded) {
          const context = {
            NonprofitId: nonprofit.id,
            NonprofitRequestStatus: nonprofit.validationRequest
              ? nonprofit.validationRequest.requestStatus
              : "not-available",
            TransactionId: nonprofit.validationRequest
              ? nonprofit.validationRequest.providerTransactionId
              : "not-available"
          };

          const successActionList = [];
          successActionList.push(put(fetchNonprofitSuccess(nonprofit)));
          successActionList.push(put(setAppContext(context)));

          const isAnyAzureOfferEnabled = nonprofit.availableOffers.find(
            offer => offer.id === OfferId.covid19_azure_credits || offer.id === OfferId.azure
          );
          if (isAnyAzureOfferEnabled) {
            successActionList.push(put(fetchAzureRegistrationInfo()));
          }

          // load contact permission settings for a country.
          const contactPermissionSettingsResponse: IApiResponse<IContactPermissionSettings> = yield apply(
            profileService,
            profileService.getContactPermissions,
            []
          );
          if (contactPermissionSettingsResponse.status === 200) {
            yield successActionList.push(
              put(fetchContactPermissionSettings(contactPermissionSettingsResponse.value))
            );
          }

          yield all(successActionList);
        }
      } else {
        const error = npResult.error as { [key: string]: string };
        const errorOutcome = error && error.outcome ? (error.outcome.toString() as NonprofitErrorOutcome) : undefined;

        if (error && errorOutcome) {
          switch (errorOutcome) {
            case NonprofitErrorOutcome.RetryTenantNotReady:
              const retryAttempts: number = yield select((state: IApplicationState) => state.nonprofit.retryAttempts);

              if (retryAttempts < 5) {
                yield delay(5000);
                logService.logInfo("nonprofit sagas.ts: retry nonprofit request number " + retryAttempts);
                yield put(fetchNonprofitRequest(retryAttempts + 1));
              } else {
                yield errorAndRedirect(NonprofitError.TenantNotReady);
              }
              break;

            case NonprofitErrorOutcome.NotAdmin:
              yield errorAndRedirect(NonprofitError.NotAdmin);
              break;

            case NonprofitErrorOutcome.TenantNotFound:
              yield errorAndRedirect(NonprofitError.NotFound);
              break;

            case NonprofitErrorOutcome.AgentError:
              yield errorAndRedirect(NonprofitError.AgentError);
              break;

            case NonprofitErrorOutcome.TenantError:
              yield errorAndRedirect(NonprofitError.TenantError, error.id.toString());
              break;

            case NonprofitErrorOutcome.Commercial:
              yield getNonprofitFromGraph();
              break;

            default:
              logService.logError("Unhandled error when fetching nonprofit information.", errorOutcome);
              yield errorAndRedirect(NonprofitError.Unknown);
              break;
          }
        } else {
          yield errorAndRedirect(NonprofitError.Unknown);
        }
      }
    }
    catch (error: any) {
      logService.logError("Unhandled error when fetching nonprofit information.", error);
      yield errorAndRedirect(NonprofitError.Unknown);
    }
  }
}

function* getNonprofitFromGraph() {
  let nonprofit: INonprofitMiniProfile;
  const npResult: IApiResponse<INonprofitMiniProfile> = yield apply(
    NonprofitService,
    NonprofitService.getNonprofitGraphProfile,
    []
  );

  if (npResult && npResult.value && npResult.status === 200) {
    nonprofit = npResult.value;
    yield all([
      put(fetchNonprofitFromGraphSuccess(nonprofit)),
      put(setEditMode(true)),
      put(setCommercial(true))
    ]);
  }
}

export function* handleContactPermissionSubmissionRequest(
  action: ActionType<typeof initiateContactPermissionSubmission>
) {
  try {
    const response: IApiResponse<{}> = yield apply(profileService, profileService.patchContactPermissions, [
      action.payload
    ]);
    if (response.status === 200) {
      yield put(ContactPermissionSuccess());
    } else {
      yield put(ContactPermissionError());
    }
  } catch (e: any) {
    logService.logError("Error sending the contact permissions.", e);
    yield put(ContactPermissionError());
  }
}

function* errorAndRedirect(error: NonprofitError, nonprofitId?: string) {
  yield all([put(nonprofitError(error, nonprofitId))]);
}

function* watchFetchNonprofit() {
  yield takeLatest(NonprofitActionTypes.FETCH_NONPROFIT_REQUEST, handleFetchNonprofitCallback);
}

function* watchContactPermissionSubmissionRequest() {
  yield takeLatest(
    NonprofitActionTypes.INITIATE_CONTACT_PERMISSION_SUBMISSION,
    handleContactPermissionSubmissionRequest
  );
}
function* nonprofitSaga() {
  yield all([fork(watchFetchNonprofit), fork(watchContactPermissionSubmissionRequest)]);
}

export default nonprofitSaga;
