import "@ms-mwf/moray/dist/css/main.css";
import Collapse from "@ms-mwf/moray/src/components/collapse/collapse";
import HelpersUtil from "@ms-mwf/moray/src/js/util/helpers";
import * as React from "react";

export interface MorayCollapseProps {
  title?: string;
  titleButtonClassNames?: string;
  headerClassNames?: string;
  description: JSX.Element;
  hideOnMount?: boolean;
  onHide?: () => void;
  onShow?: () => void;
}

interface MwfCustomEvent {
  target: {
    id: string;
  };
}

class MorayCollapse extends React.Component<MorayCollapseProps> {
  private collapse: any;
  private el!: HTMLButtonElement | null;
  private ctrlId = `mwf${HelpersUtil.getUid()}`;

  componentDidMount() {
    this.collapse = new Collapse({
      el: this.el
    });

    this.collapse.el.addEventListener("onHide", (e: MwfCustomEvent) => {
      if (this.ctrlId === e?.target?.id) {
        this.props.onHide?.();
      }
    });

    this.collapse.el.addEventListener("onShow", (e: MwfCustomEvent) => {
      if (this.ctrlId === e?.target?.id) {
        this.props.onShow?.();
      }
    });

    if (this.props.hideOnMount) {
      this.collapse.hide();
    } else {
      this.collapse.toggle();
    }
  }

  componentWillUnmount() {
    this.collapse.remove();
  }

  public render() {
    return (
      <div>
        <h4 className={`accordion-header ${this.props.headerClassNames}`}>
          <button
            className="btn btn-collapse"
            data-mount="collapse"
            data-target={`#${this.ctrlId}`}
            aria-expanded="false"
            aria-controls={this.ctrlId}
            ref={el => (this.el = el)}
            type="button"
          >
            <span className="glyph" aria-hidden="true"></span>
            <span className={this.props.titleButtonClassNames}>{this.props.title}</span>
          </button>
        </h4>
        <div className="collapse" id={this.ctrlId}>
          <div className="accordion-body">{this.props.description}</div>
        </div>
      </div>
    );
  }
}

export default MorayCollapse;
