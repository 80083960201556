import { IApiResponse } from "src/common/ApiResponse";
import { INonprofitMiniProfile, IOffer, IValidationRequest } from "src/state/nonprofit/types";
import { callApi } from "./SharedServiceFunctions";

export class NonprofitService {
  private API_URL = process.env.REACT_APP_INTERNAL_API_BASEURL;

  public async getNonprofit(): Promise<IApiResponse<INonprofitMiniProfile>> {
    const response = await callApi<INonprofitMiniProfile>(`${this.API_URL}/nonprofit`);

    // Profile feature toggle - isIncomplete experience
    // response.value.isIncomplete = true;

    return response;
  }

  public async getNonprofitGraphProfile(): Promise<IApiResponse<INonprofitMiniProfile>> {
    const response = await callApi<INonprofitMiniProfile>(`${this.API_URL}/nonprofit/graphprofile`);
    if (response.value) {
      response.value.isIncomplete = true;
    }

    return response;
  }

  public async getValidationStatus(): Promise<IApiResponse<IValidationRequest>> {
    return await callApi<IValidationRequest>(`${this.API_URL}/validationrequest`);
  }

  public async getOffers(): Promise<IApiResponse<IOffer[]>> {
    return await callApi<IOffer[]>(`${this.API_URL}/nonprofitoffers`);
  }

  public async contactPermissionSubmission(permissionToContact: boolean): Promise<IApiResponse<{}>> {
    const response = await callApi<IApiResponse<{}>>(
      `${this.API_URL}/nonprofit/setpermissiontocontact/${permissionToContact}`,
      null,
      "PUT"
    );

    return { status: response.status };
  }
}

const nonprofitService = new NonprofitService();
export default nonprofitService;
