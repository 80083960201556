import '@ms-mwf/moray/dist/css/main.css';
import * as MorayComponents from '@ms-mwf/moray/dist/js/bundle';
import * as React from 'react';

interface IMorayShowMoreProps {
  ariaControls: string;
  visibleContent: React.ReactElement;
  hiddenContent: React.ReactElement;
  showMoreText?: string;
  showLessText?: string;
  hideEllipsis?: boolean;
  buttonAriaLabel?: string;
}

const MorayShowMore = (props: IMorayShowMoreProps) => {
  const element = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    new MorayComponents.ShowMoreShowLess({
      el: element.current
    });
  }, []);

  const { ariaControls, visibleContent, hiddenContent, showMoreText, showLessText, hideEllipsis, buttonAriaLabel } =
    props;

  return (
    <div
      ref={(el) => {
        element.current = el;
      }}
      className="show-more-show-less "
      data-mount="show-more-show-less"
    >
      <div>
        <div>{visibleContent}</div>
        {!hideEllipsis && <span className="show-more-show-less-ellipsis">...</span>}
        <div id={ariaControls} className="show-more-show-less-toggleable-content">
          {hiddenContent}
        </div>
      </div>
      <button
        className="action-trigger text-decoration-none mt-2 show-more-show-less-toggle"
        aria-expanded="false"
        data-show-less-text={showLessText}
        type="button"
        aria-controls={ariaControls}
        aria-label={buttonAriaLabel === undefined ? 'Show more content' : showMoreText}
        data-alternate-aria-label={buttonAriaLabel === undefined ? 'Show less content' : showLessText}
      >
        <span className="glyph glyph-circle-plus" aria-hidden="true"></span>
        {showMoreText}
      </button>
    </div>
  );
};

MorayShowMore.defaultProps = {
  hideEllipsis: true
};

export default MorayShowMore;
