import { Reducer } from "redux";
import { ClaimOffersRequestActionTypes, IClaimOfferRequestState } from "./types";

const initialState: IClaimOfferRequestState = {
  failedToSend: undefined,
  isSending: undefined,
  sentSuccessfully: undefined,
  alreadyClaim: undefined,
  claimStatus: false,
  accountGuardInfoError: false
};

const reducer: Reducer<IClaimOfferRequestState> = (state = initialState, action) => {
  switch (action.type) {
    case ClaimOffersRequestActionTypes.ACCOUNT_OFFER_REQUEST: {
      return { ...state, isSending: true, sentSuccessfully: false, failedToSend: false, alreadyClaim: false };
    }
    case ClaimOffersRequestActionTypes.ACCOUNT_OFFERS_REQUEST_SUCCESS: {
      return { ...state, isSending: false, sentSuccessfully: true, failedToSend: false, alreadyClaim: false };
    }
    case ClaimOffersRequestActionTypes.ACCOUNT_OFFERS_REQUEST_ERROR: {
      return { ...state, isSending: false, sentSuccessfully: false, failedToSend: true, alreadyClaim: false };
    }
    case ClaimOffersRequestActionTypes.ACCOUNT_OFFERS_REQUEST_RESET: {
      return {
        ...state,
        isSending: undefined,
        sentSuccessfully: undefined,
        failedToSend: undefined,
        alreadyClaim: undefined,
        claimStatus: false,
        accountGuardInfoError: false,
        languageSupportError: undefined
      };
    }
    case ClaimOffersRequestActionTypes.ACCOUNT_OFFERS_REQUEST_ALREADY_CLAIM: {
      return { ...state, isSending: false, sentSuccessfully: false, failedToSend: false, alreadyClaim: true };
    }
    case ClaimOffersRequestActionTypes.ACCOUNT_OFFERS_REQUEST_LANGUAGES_SUPPORT: {
      return {
        ...state,
        isSending: false,
        sentSuccessfully: false,
        failedToSend: false,
        alreadyClaim: false,
        languageSupportError: true
      };
    }
    case ClaimOffersRequestActionTypes.FETCH_ACCOUNTGUARD_INFO: {
      return {
        ...state,
        isSending: true
      };
    }
    case ClaimOffersRequestActionTypes.SET_ACCOUNTGUARD_INFO_SUCCESS: {
      const accountGuardInfo: boolean = action.payload;
      return {
        ...state,
        claimStatus: accountGuardInfo,
        isSending: false,
        accountGuardInfoError: false
      };
    }
    case ClaimOffersRequestActionTypes.SET_ACCOUNTGUARD_INFO_ERROR: {
      return { ...state, accountGuardInfoError: true, isSending: false };
    }
    default: {
      return state;
    }
  }
};

export { reducer as accountGuardOfferReducer };

