import '@ms-mwf/moray/dist/css/main.css';
import * as React from 'react';
import Text from 'src/components/text/Text';
import './PricingDisclaimer.scss';

export interface IPricingDisclaimerProps {
  pricingDisclaimer1: string;
  pricingDisclaimer2?: string;
  showAsterisk?: boolean;
}

const PricingDisclaimer: React.FunctionComponent<IPricingDisclaimerProps> = ({
  pricingDisclaimer1,
  pricingDisclaimer2 = null,
  showAsterisk = true
}) => {
  return (
    <div className="pricing-disclaimer">
      <p className="mb-4 pricing-disclarimer-1">
        {showAsterisk && <Text category="PricingDisclaimer" id={'DisclaimerSymbol'} />}
        <Text category="PricingDisclaimer" id={pricingDisclaimer1} />
      </p>
      {pricingDisclaimer2 && (
        <p className="pricing-disclarimer-2">
          <Text category="PricingDisclaimer" id={pricingDisclaimer2} />
        </p>
      )}
    </div>
  );
};

export default PricingDisclaimer;
