import * as React from "react";
import Text from "src/components/text/Text";
import TextLink from "src/components/text/TextLink";

const GitHub: React.FunctionComponent = () => {
  return (
    <section id="gitHub">
      <h1>
        <Text category="GitHub" id="Title" />
      </h1>
      <p>
        <TextLink category="GitHub" id="Details1" href="GitHub" target="_blank" />
      </p>
      <p>
        <Text category="GitHub" id="Details2" />
      </p>
      <p>
        <Text category="GitHub" id="Details3" />
      </p>
    </section>
  );
};

export default GitHub;
