import * as React from "react";
import ResizableImage, { IResizableImage } from "src/components/moray/ResizeableImage";
import localeService from "src/dataServices/LocaleService";

const SoftwareEligibilityTopBanner = () => {
  const resizableImageProps: IResizableImage = {
    root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/Partners`,
    imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/Partners/partners-on-premises-software-licensing.png`,
    imageClassName: "card-img",
    imageAlt: localeService.getText("SoftwareV2", "TopBannerImageTitle")
  };

  return (
    <section>
      <div className="card d-block mx-ng mx-md-0">
        <div className="row no-gutters material-surface">
          <div className="d-flex order-md-first col-md bg-gray">
            <div className="card-body align-self-center p-4 p-md-5">
              <h1 id="PartnersBannerTitle" className="h1">
                {localeService.getText("SoftwareV2", "EligibilityTopBannerTitle")}
              </h1>
              <div className="my-3">
                <p id="PartnersBannerDescription" className="partner-description">
                  {localeService.getText("SoftwareV2", "EligibilityTopBannerDescription")}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <ResizableImage {...resizableImageProps} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SoftwareEligibilityTopBanner;
