import { action } from "typesafe-actions";
import { AgentSettings, AgentVerificationActionTypes, ITokenVerificationSubmission } from "./types";

export const fetchAgentSettingsRequest = () => action(AgentVerificationActionTypes.FETCH_AGENT_SETTINGS_REQUEST);
export const fetchAgentSettingsSuccess = (data: AgentSettings | undefined) =>
  action(AgentVerificationActionTypes.FETCH_AGENT_SETTINGS_SUCCESS, data);
export const loadedAgentVerification = (success: boolean) =>
  action(AgentVerificationActionTypes.LOADED_AGENT_VERIFICATION, success);
export const resendVerificationEmail = () => action(AgentVerificationActionTypes.RESEND_CONFIRMATION_EMAIL);
export const resendVerificationSuccess = () => action(AgentVerificationActionTypes.RESEND_CONFIRMATION_EMAIL_SUCCESS);
export const resendVerificationError = () => action(AgentVerificationActionTypes.RESEND_CONFIRMATION_EMAIL_ERROR);

export const initiateTokenVerification = (token: ITokenVerificationSubmission) =>
  action(AgentVerificationActionTypes.INITIATE_TOKEN_VERIFICATION, token);
export const tokenVerificationSuccess = () => action(AgentVerificationActionTypes.TOKEN_VERIFICATION_SUCCESS);
export const tokenVerificationError = () => action(AgentVerificationActionTypes.TOKEN_VERIFICATION_ERROR);
