import { AgentVerificationState } from "src/state/agent-verification/types";
import { INonprofitState } from "src/state/nonprofit";
import { IProfileState } from "src/state/profile/types";
import { isEmailVerificationRequired } from "../verification/Utils";

export function shouldLoadContactPermission(
  nonprofit: INonprofitState,
  agentVerification: AgentVerificationState,
  profile: IProfileState
) {
  return (
    !isEmailVerificationRequired(nonprofit, agentVerification) &&
    profile.contactPermissionSettings &&
    profile.contactPermissionSettings.visible &&
    profile.contactPermissionSettings.shouldLoadBlockingPage === true &&
    !isContactPermissionSubmitted(nonprofit)
  );
}

export function getPermissionToContactInitialValues(profile: IProfileState) {
  const checkedByDefaultForCountry =
    profile.contactPermissionSettings && profile.contactPermissionSettings.checkedByDefault;

  return checkedByDefaultForCountry !== undefined ? checkedByDefaultForCountry : false;
}

function isContactPermissionSubmitted(nonprofit: INonprofitState) {
  return nonprofit.contactPermission && nonprofit.contactPermission.contactPermissionSuccess;
}
