import localeEnabledForV2, { LocaleConfigStrings } from '../../../common/localeEnabledForV2';

interface IUseCommunityTrainingOffer {
  isCommunityTrainingOfferAvailable: boolean;
}

const useCommunityTrainingOffer = (): IUseCommunityTrainingOffer => {
  return {
    isCommunityTrainingOfferAvailable: localeEnabledForV2(LocaleConfigStrings.CommunityTraining)
  };
};

export default useCommunityTrainingOffer;
