import * as React from "react";
import Text from "src/components/text/Text";
import localeService from "src/dataServices/LocaleService";
import "./BingAdsAdditionalSupport.scss";

const BingAdsAdditionalSupport = () => {
  return (
    <section>
      <div id="bingAdsAdditionalSupport" className="bingAdsAdditionalSupport">
        <div className="card d-block mx-ng mx-md-0 mt-5 mb-5">
          <div className="row no-gutters material-surface ml-5 mr-5">
            <div className="col-md-6">
              <img
                src={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/BingAds/AdditionalSupport.png`}
                className="card-img img-object-cover"
                alt={localeService.getText("BingAds", "AdditionalSupport")}
              />
            </div>
            <div className="d-flex col-md">
              <div className="card-body align-self-center p-4 p-md-5">
                <h2 id="additional-support-banner-title" className="additional-support-banner-title h2">
                  <Text category="BingAds" id="AdditionalSupport" />
                </h2>
                <div id="additional-support-banner-description" className="mb-3 mt-2">
                  <p className="description-text">
                    <Text category="BingAds" id="AdditionalSupportDetails" />
                  </p>
                </div>
                <div id="additional-support-banner-button" className="link-group">
                  <a
                    href={localeService.getText("BingAds", "CrashCourseLink")}
                    className="btn btn-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Text category="BingAds" id="WatchNow" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BingAdsAdditionalSupport;
