import { withAITracking } from '@microsoft/applicationinsights-react-js';
import arrayMutators from 'final-form-arrays';
import * as React from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import FollowUsFooter from 'src/components/followUsFooter/FollowUsFooter';
import { ContactUsSection } from 'src/components/offers/shared/ContactUsSection';
import { StatusIcon } from 'src/components/offers/shared/StatusBanner';
import BannerNotification from 'src/components/profilev2/BannerNotification';
import ConfirmationModal from 'src/components/profilev2/ConfirmationModal';
import DiscountAndDonationPolicies from 'src/components/profilev2/DiscountAndDonationPolicies';
import OrgInfoSection2Edit from 'src/components/profilev2/OrgInfoSection2Edit';
import OrgInfoSection2View from 'src/components/profilev2/OrgInfoSection2View';
import OrgRegistrationInfo from 'src/components/profilev2/OrgRegistrationInfo';
import PrimaryContactEdit from 'src/components/profilev2/PrimaryContactEdit';
import PrimaryContactView from 'src/components/profilev2/PrimaryContactView';
import { ProfileCollapse } from 'src/components/profilev2/ProfileCollapse';
import StatusHeader from 'src/components/profilev2/StatusHeader';
import Text from 'src/components/text/Text';
import EmailVerification from 'src/components/verification/EmailVerification';
import { isEmailVerificationRequired, isNonprofitValidationRequired } from 'src/components/verification/Utils';
import localeService from 'src/dataServices/LocaleService';
import { SloDefinitions, telemetryService } from 'npo-common';
import { IApplicationState } from 'src/state';
import { createProfile, saveProfile, setCommercial, setEditMode, setShowConfirmation } from 'src/state/profile/actions';
import { IProfile, IProfileState } from 'src/state/profile/types';
import './ProfilePageV2.scss';
import { FormApi } from 'final-form';
import meoLocaleService from 'src/dataServices/MeoLocaleService';
import { MeoLocaleState } from 'src/state/meo-localization/types';
import { TelemetryEventType, logService } from 'npo-common';

interface IPropsFromState {
  editMode: boolean;
  showConfirmation: boolean;
  isCommercial: boolean;
  profileState: IProfileState;
  profileError: string | undefined;
  emailVerificationRequired: boolean;
  meoLocale: MeoLocaleState;
}

interface IPropsFromDispatch {
  setShowConfirmation: Function;
  setEditMode: Function;
  setCommercial: Function;
  saveProfile: typeof saveProfile;
  createProfile: typeof createProfile;
}

type IProfilePropsV2 = IPropsFromState & IPropsFromDispatch;

export const ProfilePageV2 = ({
  editMode,
  setEditMode,
  showConfirmation,
  setShowConfirmation,
  isCommercial,
  profileState,
  saveProfile,
  createProfile,
  profileError,
  emailVerificationRequired,
  meoLocale
}: IProfilePropsV2) => {
  const initialEmailLocale = isCommercial
    ? meoLocaleService.resolveLocale(localeService.getlocale(), meoLocale.locales)
    : meoLocaleService.resolveLocale(profileState.profile.primaryContact.emailLanguagePreference, meoLocale.locales);

  const getProfileInitialValues = () => {
    if (profileState.profile.notificationEmails && profileState.profile.notificationEmails !== null) {
      profileState.profile.notificationEmails = profileState.profile.notificationEmails.filter(
        (email) => email !== profileState.profile.primaryContact.email
      );
    }

    return profileState.profile;
  };

  const [profileInitialValues, setProfileInitialValues] = React.useState(getProfileInitialValues());

  const onFieldChange = (field: string, value: string, change: any) => {
    if (field === 'address.countryCode') {
      change('address.addressLine1', '');
      change('address.addressOther', '');
      change('address.city', '');
      change('address.stateRegion', '');
      change('address.postalCode', '');
      change('address.phone', '');
      change('registrationIdentifiers', []);
    }
  };

  const onEditClicked = () => {
    setProfileInitialValues(profileState.profile);
    setEditMode(true);
  };

  const onSubmitClicked = () => {
    setShowConfirmation(true);
  };

  const onCancelClicked = (form: FormApi<IProfile>) => {
    form.reset();
    setEditMode(false);
  };

  const handleSubmit = (values: IProfile) => {
    updateProfilePayload(values);

    // set default values for legacy nonprofits to stay in sync with TechSoup payload
    values.missionStatement = 'nil';
    values.operatingBudget = 'nil';
    values.organizationSize = 'nil';

    if (isCommercial) {
      createProfile(values);
    } else {
      saveProfile(values);
    }

    trackEmailLanguagePreferenceUpdates(values);
  };

  const trackEmailLanguagePreferenceUpdates = (values: IProfile) => {
    // commerical signup
    if (isCommercial) {
      logService.logEvent({
        Name: 'InitialEmailLanguagePreference',
        EventType: TelemetryEventType.PrimaryWorkflow,
        SloId: SloDefinitions.ExistingTenantRegistration,
        Properties: {
          browserLocale: localeService.getlocale(),
          defaultLocale: initialEmailLocale.cultureCode,
          selectedLocale: values.primaryContact.emailLanguagePreference
        }
      });

      return; // return to avoid nesting
    }

    // profile update - only track if the email language preference has changed
    if (values.primaryContact.emailLanguagePreference !== initialEmailLocale.cultureCode) {
      logService.logEvent({
        Name: 'UpdateEmailLanguagePreference',
        EventType: TelemetryEventType.PrimaryWorkflow,
        SloId: SloDefinitions.NonprofitProfileUpdate,
        Properties: {
          browserLocale: localeService.getlocale(),
          existingLocale: initialEmailLocale.cultureCode,
          selectedLocale: values.primaryContact.emailLanguagePreference
        }
      });
    }
  }

  return (
    <section id="profile-page">
      {emailVerificationRequired && <EmailVerification />}
      {isCommercial ? (
        <BannerNotification type={StatusIcon.info} text={localeService.getText('ProfileV2', 'ConvertAccountMode')} />
      ) : !profileState.profile.isEditable ? (
        <BannerNotification type={StatusIcon.recent} text="">
          {new Text({ category: 'ProfileV2', id: 'NotEditableText' }).render()}
        </BannerNotification>
      ) : editMode ? (
        <BannerNotification type={StatusIcon.info} text={localeService.getText('ProfileV2', 'EditMode')} />
      ) : (
        <></>
      )}
      {profileError ? (
        <BannerNotification
          type={StatusIcon.error}
          text={localeService.getText('ProfileV2', 'ErrorDuringProcessing')}
        />
      ) : (
        <></>
      )}

      <Form
        initialValues={profileInitialValues}
        onSubmit={handleSubmit}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, form }) => (
          <form
            id="profile-v2"
            className="profile-form"
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && document.activeElement?.id !== 'profileFormSubmitBtnId') {
                e.preventDefault(); // prevent implicit form submission via enter key (unless submit button is focused)
              }
            }}
          >
            <StatusHeader showNonprofitStatus={!isCommercial} />
            <ProfileCollapse
              organizationRegistrationInfo={!isCommercial ? <OrgRegistrationInfo /> : undefined}
              organizationInfo={
                editMode ? (
                  <OrgInfoSection2Edit
                    profile={profileState.profile}
                    profileForm={form}
                    onFieldChange={(field, value) => onFieldChange(field, value, form.change)}
                  />
                ) : (
                  <OrgInfoSection2View profile={profileState.profile} />
                )
              }
              primaryContact={
                editMode ? (
                  <PrimaryContactEdit
                    profile={profileState.profile}
                    WrappedFieldArrayProps={undefined}
                    isCommercial={isCommercial}
                    initialEmailLocale={initialEmailLocale}/>
                ) : (
                  <PrimaryContactView profile={profileState.profile} />
                )
              }
            />
            <div className="profile-edit-policy">
              <DiscountAndDonationPolicies />
              <FormButtons
                editMode={editMode}
                isEditable={profileState.profile.isEditable}
                isCommercial={isCommercial}
                onEditClicked={onEditClicked}
                onSubmitClicked={onSubmitClicked}
                onCancelClicked={() => onCancelClicked(form)}
                form={form}
              />
            </div>
            <ConfirmationModal
              showConfirmation={showConfirmation}
              revalidationRequired={isNonprofitValidationRequired(
                form.getState().initialValues,
                form.getState().values
              )}
              onConfirmation={() => {
                setShowConfirmation(false);
                setCommercial(false);
              }}
              onCancelConfirmation={() => setShowConfirmation(false)}
              isCommercial={isCommercial}
            />
          </form>
        )}
      />
      <ContactUsSection />
      <FollowUsFooter />
    </section>
  );
};

const updateProfilePayload = (payload: IProfile) => {
  if (!payload) {
    return;
  }

  if (payload.notificationEmails) {
    payload.notificationEmails = payload.notificationEmails.filter((email) => email !== undefined && email !== null);
  }

  if (
    payload.notificationEmails === undefined ||
    payload.notificationEmails === null ||
    payload.notificationEmails.length === 0
  ) {
    payload.notificationEmails = [payload.primaryContact.email];
  }

  if (!payload.activityCode) {
    payload.activityCode = 'nil';
  }
};

interface IFormButtonsPros {
  editMode: boolean;
  isEditable: boolean;
  isCommercial: boolean;
  onEditClicked: React.MouseEventHandler<HTMLButtonElement>;
  onSubmitClicked: React.MouseEventHandler<HTMLButtonElement>;
  onCancelClicked: React.MouseEventHandler<HTMLButtonElement>;
  form: FormApi<IProfile>;
}

const FormButtons = (props: IFormButtonsPros): JSX.Element => {
  const { editMode, isEditable, isCommercial, onEditClicked, onSubmitClicked, onCancelClicked, form } = props;

  const dirtyFields = Object.keys(form.getState().dirtyFields);

  // registrationIdentifiers is a display-only field that is removed from the screen when a user clicks edit.
  // This causes it to be in the dirtyFields every time.
  // We want to ignore the change in that field
  // So, if that is the only change detected, we disable submit.
  const editsMade =
    form.getState().dirty && !(dirtyFields.length === 1 && dirtyFields[0] === 'registrationIdentifiers');

  if (!editMode) {
    return (
      <button
        className="profile-edit-button btn btn-primary"
        type="button"
        disabled={!isEditable}
        onClick={onEditClicked}
      >
        {localeService.getText('ProfileV2', 'Edit')}
      </button>
    );
  }

  return (
    <div className="profile-submit-edit-buttons">
      <button
        id="profileFormSubmitBtnId"
        className="profile-submit-button btn btn-primary"
        type="button"
        onClick={onSubmitClicked}
        disabled={!editsMade}
      >
        {localeService.getText('ProfileV2', 'Submit')}
      </button>
      {!isCommercial && (
        <button className="profile-cancel-button btn btn-outline-primary" type="button" onClick={onCancelClicked}>
          {localeService.getText('ProfileV2', 'Cancel')}
        </button>
      )}
    </div>
  );
};

const mapStateToProps = ({ profile, nonprofit, agentVerification, meoLocale }: IApplicationState): IPropsFromState => ({
  editMode: profile.editMode,
  showConfirmation: profile.showConfirmation,
  isCommercial: profile.isCommercial,
  profileState: profile,
  profileError: profile.error,
  emailVerificationRequired: isEmailVerificationRequired(nonprofit, agentVerification),
  meoLocale: meoLocale
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setEditMode: (editModeOn: boolean) => dispatch(setEditMode(editModeOn)),
  setShowConfirmation: (showConfirmation: boolean) => dispatch(setShowConfirmation(showConfirmation)),
  setCommercial: (setCommercial: (arg0: any) => any) => dispatch(setCommercial(setCommercial)),
  saveProfile: (profile: IProfile) => dispatch(saveProfile(profile)),
  createProfile: (profile: IProfile) => dispatch(createProfile(profile))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAITracking(telemetryService.aiReactPlugin, ProfilePageV2));
