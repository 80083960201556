export const enum OffersRequestActionTypes {
  OFFERS_REQUEST = "@@offers/OFFERS_REQUEST",
  OFFERS_REQUEST_SUCCESS = "@@offers/OFFERS_REQUEST_SUCCESS",
  OFFERS_REQUEST_ERROR = "@@offers/OFFERS_REQUEST_ERROR",
  OFFERS_REQUEST_RESET = "@@offers/OFFERS_REQUEST_RESET",
  CLAIM_OFFER_REQUEST = "@@offers/CLAIM_OFFER_REQUEST"
}

export interface IOfferRequestState {
  isSending?: boolean;
  sentSuccessfully?: boolean;
  failedToSend?: boolean;
  offerId: string | undefined;
}

export interface IEmailOfferRequestSubmissionForm {
  customerNotificationEmails: string;
  type: string;
}

export interface IOfferPayload {
  offerId: string;
  userConsent: boolean;
}
