import * as React from "react";
import Text from "src/components/text/Text";

export interface IFieldValidationErrorProps {
  localeErrorCategoryId?: string;
  localeErrorTextId: string;
}

const FieldValidationError = (props: IFieldValidationErrorProps): JSX.Element => {
  const { localeErrorCategoryId, localeErrorTextId } = props;

  return (
    <div role="alert" className="error glyph-prepend glyph-prepend-warning mt-2">
      <span className="ml-2">
        <Text category={localeErrorCategoryId || "ProfileV2"} id={localeErrorTextId} error={localeErrorTextId} />
      </span>
    </div>
  );
};

export default FieldValidationError;
