// Use const enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define however naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the convention
// of Redux's `@@INIT` action.
export const enum LayoutActionTypes {
  FETCH_ERROR = "@@layout/FETCH_ERROR",
  SET_APP_CONTEXT = "@@layout/SET_APP_CONTEXT"
}

export const enum SubmenuTypes {
  CARDS = "cards"
}

export interface HyperLink {
  title: string;
  url: string;
  target?: string;
  ariaLabel?: string;
}

export interface MenuItem {
  id?: string;
  title: string;
  url?: string;
  target?: string;
  submenu?: MenuItem[];
  submenuType?: string;
  expanded?: boolean;
  ariaLabel?: string;
  boldText?: string;
  boldTextNew?: string;
  text?: string;
}

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface LayoutState {
  readonly appContext: { [key: string]: any };
}
