import { action } from "typesafe-actions";
import { ClaimOffersRequestActionTypes } from "./types";

export const fetchAccountGuardInfo = () => action(ClaimOffersRequestActionTypes.FETCH_ACCOUNTGUARD_INFO);
export const setAccountGuardInfoSuccess = (result: boolean | undefined) =>
  action(ClaimOffersRequestActionTypes.SET_ACCOUNTGUARD_INFO_SUCCESS, result);
export const setAccountGuardInfoError = () => action(ClaimOffersRequestActionTypes.SET_ACCOUNTGUARD_INFO_ERROR);

export const accountOfferRequest = () => action(ClaimOffersRequestActionTypes.ACCOUNT_OFFER_REQUEST);
export const accountOfferRequestSuccess = () => action(ClaimOffersRequestActionTypes.ACCOUNT_OFFERS_REQUEST_SUCCESS);
export const accountOffersRequestError = () => action(ClaimOffersRequestActionTypes.ACCOUNT_OFFERS_REQUEST_ERROR);
export const accountOffersRequestAlreadyClaim = () =>
  action(ClaimOffersRequestActionTypes.ACCOUNT_OFFERS_REQUEST_ALREADY_CLAIM);
export const accountOffersRequestLanguageSupport = () =>
  action(ClaimOffersRequestActionTypes.ACCOUNT_OFFERS_REQUEST_LANGUAGES_SUPPORT);
export const accountOfferRequestReset = () => action(ClaimOffersRequestActionTypes.ACCOUNT_OFFERS_REQUEST_RESET);
