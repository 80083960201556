import * as React from "react";
import localeService from "src/dataServices/LocaleService";
import NavigationCardContent from "../../../navigation-cards/NavigationCardContent";

const GuidedDemosSolutionCenterCard = () => {
  return (
    <NavigationCardContent
      imgSrc={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/Training/solution_center.jpg`}
      imgAlt={localeService.getText("TrainingV2", "GuidedDemosSection3Title")}
      title={localeService.getText("TrainingV2", "GuidedDemosSection3Title")}
      ctaText={localeService.getText("TrainingV2", "GuidedDemosViewDemoButton")}
      ctaLink={localeService.getText("TrainingV2", "GuidedDemosSection3Link")}
      linkAriaLabel={localeService.getText("TrainingV2", "GuidedDemosSection3LinkAria")}
    >
      <p>{`${localeService.getText("TrainingV2", "GuidedDemosSection3Description")}`}</p>
    </NavigationCardContent>
  );
};

export default GuidedDemosSolutionCenterCard;
