import { action } from "typesafe-actions";
import {
  BingAdsRegistrationInfo,
  BingAdsActionTypes,
  BingAdsRegistrationState,
  ClaimAdsOfferRequest,
  SubmitClaimAdsOfferRequest
} from "./types";

export const fetchBingAdsRegistrationInfo = (nonprofitId: string) =>
  action(BingAdsActionTypes.FETCH_ADS_REGISTRATION_INFO, nonprofitId);

export const submitClaimAdsOffer = (request: SubmitClaimAdsOfferRequest) =>
  action(BingAdsActionTypes.SUBMIT_CLAIM_ADS_OFFER, request);

export const setBingAdsRegistrationInfo = (info: BingAdsRegistrationInfo) =>
  action(BingAdsActionTypes.SET_ADS_REGISTRATION_INFO, info);

export const setBingAdsRegistrationInfoError = (errorState: BingAdsRegistrationState) =>
  action(BingAdsActionTypes.SET_ADS_REGISTRATION_INFO_ERROR, errorState);
