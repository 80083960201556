import * as React from "react";
import ActionStepCard from "src/components/offers/shared/ActionStepCard";
import ActionSteps from "src/components/offers/shared/ActionSteps";
import localeService from "src/dataServices/LocaleService";

const PartnersSteps = () => {
  const step1ImagePath = "hub-redesign/ActionStep_1_3x.png";
  const step2ImagePath = "hub-redesign/ActionStep_2_3x.png";
  const step3ImagePath = "hub-redesign/ActionStep_3_3x.png";

  return (
    <div>
      <ActionSteps
        stepsHeader={localeService.getText("PartnersV2", "PartnersStepsHeading")}
        stepsFooterCTAText={localeService.getText("PartnersV2", "PartnersStepsCTAText")}
        stepsFooterCTALink={localeService.getText("PartnersV2", "PartnersStepsCTALink")}
        step1Card={
          <ActionStepCard
            stepIconImagePath={step1ImagePath}
            cardTextCategory="PartnersV2"
            cardTitle="PartnersStepsTitle1"
            cardDescription="PartnersStepsDescription1"
            ctaText="SelectIndustrySolutionStepButton"
          />
        }
        step2Card={
          <ActionStepCard
            stepIconImagePath={step2ImagePath}
            cardTextCategory="PartnersV2"
            cardTitle="PartnersStepsTitle2"
            cardDescription="PartnersStepsDescription2"
            ctaText="SelectPlansStepButton"
          />
        }
        step3Card={
          <ActionStepCard
            stepIconImagePath={step3ImagePath}
            cardTextCategory="PartnersV2"
            cardTitle="PartnersStepsTitle3"
            cardDescription="PartnersStepsDescription3"
            ctaText="ReadySolutionCentersStepButton"
          />
        }
      />
    </div>
  );
};

export default PartnersSteps;
