import htmlParser from "html-react-parser";
import * as React from "react";
import { Link } from "react-router-dom";
import localeService from "src/dataServices/LocaleService";
import doTextSubstitution from "src/common/doTextSubstitution";
import { getRouteSetting, isLocalizationEnabled } from "../utilities/Localization/Utils";

export interface ILink {
  href: string;
  target?: string;
}

export interface ITextLinkProps {
  category: string;
  id: string;
  href?: string;
  target?: string;
  links?: ILink[];
  substitutionData?: { [key: string]: any };
}

// Setting parameters to be optional as workaround for updated html-react-parser
interface IDomNode {
  type: string;
  children?: IDomNode[];
  data?: string;
  name?: string;
  attribs?: any;
}

/**
 * There are two ways to use TextLink. You can pass in props for href and target, or a single combined prop 'links'
 * - <TextLink href={space-delimited string} target={space-delimited string} />
 * - <TextLink links={{ href: string, target?: string }[]} />
 * If for whatever reason, both the href/target props and the links prop are used, the links prop will have its links processed last.
 */
export default class TextLink extends React.Component<ITextLinkProps> {
  public render() {
    const { category, id, substitutionData } = this.props;
    const links = this.propsToILinks()
      .concat(this.props.links ? this.props.links : [])
      .reverse(); // reverse to make processing simpler with links.pop()

    const text = doTextSubstitution(localeService.getText(category, id) ?? '', substitutionData);

    // text not found, return null
    if (text === undefined) {
      return null;
    }

    return htmlParser(text, {
      // parse and replace methods described in detail here: https://github.com/remarkablemark/html-react-parser#readme
      replace: (domNode: IDomNode) => {
        if (typeof domNode.name === "undefined" || typeof domNode.attribs === "undefined" || domNode.name !== "a") {
          return undefined;
        }

        const children = this.renderChildren(domNode);

        if (links.length === 0) {
          return <a href="src/components/text#">{children}</a>;
        }

        const link = links.pop();
        const url = this.deriveUrl(link?.href ?? '');

        // If the url is an external link, we use anchor tags.
        // Otherwise, we use Links for react-router.
        return url.indexOf("#") === 0 ||
          url.indexOf("http") === 0 ||
          url.indexOf("mailto") === 0 ||
          url.indexOf("tel") === 0 ? (
          <a {...domNode.attribs} href={url} target={link?.target}>
            {children}
          </a>
        ) : (
          this.renderInternalLink(url, domNode, children)
        );
      }
    });
  }

  private renderInternalLink(url: string, domNode: IDomNode, children: React.ReactNode) {
    const routeSetting = getRouteSetting(url);

    // Link to cannot handle switching between locales path.
    return isLocalizationEnabled() && routeSetting.isDisallowed ? (
      <a {...domNode.attribs} href={url}>
        {children}
      </a>
    ) : (
      <Link {...domNode.attribs} to={url}>
        {children}
      </Link>
    );
  }

  /**
   * Consolidates the href and target props into an array of ILink objects.
   *
   * This method assumes that href and target elements at corresponding array indexes are part of the same link.
   * When the target array is shorter than the href array, the last ILinks are given undefined targets.
   */
  private propsToILinks(): ILink[] {
    const { href, target } = this.props;
    if (href) {
      const targets = target ? target.split(" ") : [];
      return href.split(" ").map(
        (link, idx): ILink => ({
          href: link,
          target: idx < targets.length ? targets[idx] : undefined
        })
      );
    }

    return [];
  }

  /**
   * Takes a string and derives the proper URL if it is to be used as a substitution key.
   * @param url If the url starts with http or has a slash, we treat it as a link that does not need substitution.
   */
  private deriveUrl(url: string): string {
    if (url.indexOf("#") === -1 && url.indexOf("/") === -1 && url.indexOf("http") !== 0) {
      return doTextSubstitution(localeService.getText("Urls", url) ?? '', this.props.substitutionData);
    }

    return url;
  }

  /**
   * Takes a domNode as parsed by html-react-parser and returns its children as React Nodes.
   * @param domNode The DOM node whose children we wish to render as React nodes.
   */
  private renderChildren(domNode: IDomNode): React.ReactNode {
    if (typeof domNode.children === "undefined") {
      return null;
    }

    const deepRender = (node: IDomNode, key: string): React.ReactNode => {
      if (node.type !== "tag") {
        return node.data;
      }

      return React.createElement(node.name ?? '', { ...node.attribs, key }, this.renderChildren(node));
    };

    return domNode.children.map((child, idx) => deepRender(child, idx.toString()));
  }
}
