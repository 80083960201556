import Text from "src/components/text/Text";
import localeService from "src/dataServices/LocaleService";

export const requiredFieldValidator = (value: any) => (value && value.trim() ? undefined : "RequiredError");
export const emailAddressValidator = (value: any) =>
  value && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/i.test(value) ? "InvalidEmailError" : undefined;
export const websiteUrlValidator = (value: any) =>
  value &&
  !/(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i.test(value)
    ? "InvalidWebsiteError"
    : undefined;

export const inputTextFieldComponent = (field: any) => {
  const {
    input,
    type,
    id,
    name,
    label,
    ariaLabel,
    required,
    readOnly,
    placeholder,
    maxLength,
    autoFocus,
    disabled,
    meta: { error, touched, active },
    hasServerValidationError
  } = field;
  return (
    <div className="form-field">
      <input
        {...input}
        type={type}
        id={id}
        name={name}
        label={label}
        aria-label={ariaLabel}
        required={required}
        placeholder={placeholder}
        readOnly={readOnly}
        autoFocus={autoFocus}
        maxLength={maxLength}
        disabled={disabled}
        className={(touched && error) || hasServerValidationError ? "has-error" : null}
      />
      {(hasServerValidationError || (touched && error)) && (
        <div role="alert" className="error">
          <Text category="Profile" id={`${id}${error || "InvalidError"}`} error={error} />
        </div>
      )}
    </div>
  );
};

export const inputTextFieldValidators = (required: boolean, type: "text" | "email" | "phone" | "url") => {
  const validators = [];
  if (required) {
    validators.push(requiredFieldValidator);
  }
  if (type === "email") {
    validators.push(emailAddressValidator);
  }
  if (type === "text") {
    validators.push(websiteUrlValidator);
  }
  return validators;
};

export function validateLegalIdentifier(formValues: { registrationIdentifiers: { [x: string]: any; }; }, regulatoryBodies: string | any[], index: string | number, countryCode: any) {
  const registrationIdentifier = formValues.registrationIdentifiers[index];
  if (registrationIdentifier === undefined || regulatoryBodies.length === 0) {
    return undefined;
  }

  const regulatoryBody = registrationIdentifier.regulatoryBody;
  const legalIdentifier = registrationIdentifier.legalIdentifier;

  if (legalIdentifier === undefined) {
    return localeService.getText("Profile", "registrationIdentifiers[0].legalIdentifierRegexError");
  }

  if (regulatoryBody === undefined) {
    return undefined;
  }

  let regulatoryBodyInfo;
  for (let i = 0; i < regulatoryBodies.length; i++) {
    if (regulatoryBody === regulatoryBodies[i].acronym) {
      regulatoryBodyInfo = regulatoryBodies[i];
      break;
    }
  }

  const errorMessages = localeService.getCategoryContent("RegulatoryBodies").errorMessages.identifiers;
  if (!regulatoryBodyInfo.regexPattern.test(legalIdentifier)) {
    const messageKey = `${countryCode}_${regulatoryBodyInfo.acronym}_invalidFormat`;
    if (messageKey in errorMessages) {
      return errorMessages[messageKey];
    }
  }
  return undefined;
}

/**
 * Gets a list of regulatory bodies matched for the given country code. An empty list is returned when no match is found.
 * @param countryCode The country code for which the regulatory bodies have to be fetched.
 */
export const fetchRegulatoryBodiesForCountry = (countryCode: string) => {
  const allRegulatoryBodies = localeService.getCategoryContent("RegulatoryBodies").identifiers[countryCode];
  return fetch(
    `${process.env.REACT_APP_CDN_URL}/registration-identifiers-config/${countryCode.toLocaleLowerCase()}.json`
  )
    .then(registrationIdentifiersResponse =>
      registrationIdentifiersResponse.status === 200 ? registrationIdentifiersResponse.json() : Promise.reject()
    )
    .then(registrationIdentifiersJson =>
      registrationIdentifiersJson.map((identifier: { patterns: any[]; textKey: string | number; }) => ({
        ...identifier,
        regexPattern: new RegExp(identifier.patterns.join("|")),
        name: allRegulatoryBodies[identifier.textKey].regulatoryBody.name
      }))
    )
    .catch(() => []);
};
