import { IApiResponse } from "src/common/ApiResponse";
import { IContactUsSubmissionFields } from "src/state/contactus/types";
import { callApi } from "./SharedServiceFunctions";

export class ContactUsService {
  private API_URL = process.env.REACT_APP_INTERNAL_API_BASEURL_V2;

  public async CreateSupportTicket(formValues: IContactUsSubmissionFields): Promise<IApiResponse<{}>> {
    const response = await callApi<{}>(`${this.API_URL}/supportcases`, JSON.stringify(formValues), "POST");
    return response;
  }
}

const contactUsService = new ContactUsService();
export default contactUsService;
