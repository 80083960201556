export const enum SendToPartnerActionTypes {
  INITIATE_SEND_TO_PARTNER_REQUEST = "@@offers/software/INITIATE_SEND_TO_PARTNER_REQUEST",
  SEND_TO_PARTNER_SUCCESS = "@@offers/software/SEND_TO_PARTNER_SUCCESS",
  SEND_TO_PARTNER_ERROR = "@@offers/software/SEND_TO_PARTNER_ERROR",
  SEND_TO_PARTNER_RESET = "@@offers/software/SEND_TO_PARTNER_RESET"
}

export interface ISendToPartnerRequest {
  partnerCompanyName: string;
  partnerContactName: string;
  partnerContactEmail: string;
  userEmails: string;
}

export interface ISendToPartnerState {
  isSending?: boolean;
  sentSuccessfully?: boolean;
  failedToSend?: boolean;
}
