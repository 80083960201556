import * as React from "react";
import Text from "src/components/text/Text";
import "./InfoCard.scss";

const imagesUrl = process.env.REACT_APP_IMAGES_URL;

export interface IInfoCardProps {
  stepIconImagePath: string;
  cardTitle: string | undefined;
  cardDescription: string | undefined;
  category: string;
  cardLink?: string;
  cardButton?: string;
  buttonAriaLabel?: string;
}

const InfoCard: React.FunctionComponent<IInfoCardProps> = props => {
  const iconImageUrl = `${imagesUrl}/${props.stepIconImagePath}`;

  return (
    <div className="info-card card h-100" data-mount="click-group">
      <img src={iconImageUrl} className="card-img" alt="" />
      <div className="step-card-body-title card-body-title mt-0 mb-0 pb-0">
        <h4 className="h4 mb-0">
          <Text category={props.category} id={props.cardTitle ?? ''} />
        </h4>
      </div>
      <div className="step-card-body-detail card-body-detail mt-0 mb-0 pb-0">
        <p className="body base m-0">
          <Text category={props.category} id={props.cardDescription ?? ''} />
        </p>
      </div>
      {props.cardButton !== undefined && (
        <div className="step-card-button mt-4">
          <a
            id={props.cardLink + "step-card-button"}
            href={props.cardLink}
            className="cta"
            target="_blank"
            rel="noopener noreferrer"
            aria-label = { props.buttonAriaLabel === undefined ? props.cardButton : props.buttonAriaLabel }
          >
            <Text category={props.category} id={props.cardButton} />
            <span className="glyph" aria-hidden="true"></span>
          </a>
        </div>
      )}
    </div>
  );
};

export default InfoCard;
