import { withAITracking } from "@microsoft/applicationinsights-react-js";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import LoadingButton from "../../utilities/LoadingButton";
import "src/pages/home/GettingStartedPage.scss";
import localeService from "src/dataServices/LocaleService";
import { telemetryService } from "npo-common";
import { IApplicationState } from "../../../state";
import { initiateRegister } from "../../../state/auth";

interface IPropsFromState {
  isRegistering: boolean;
}
interface IPropsFromDispatch {
  initiateRegister: typeof initiateRegister;
}
interface IButtonProps {
  dontShowArrow?: boolean;
  placement: string;
  secondaryText?: string;
}
type RegisterProps = IPropsFromDispatch & IPropsFromState & IButtonProps;
class RegisterButton extends React.Component<RegisterProps> {
  public handleKeyDown = (evt: { key: string }) => {
    if (evt.key === "Tab") {
      this.setState({ fullMenu: true });
    } else if (evt.key === "Enter") {
      this.props.initiateRegister(true);
    }
  };
  public render() {
    const { initiateRegister, isRegistering, dontShowArrow, placement, secondaryText } = this.props;
    const registerButtonText = localeService.getText("GettingStarted", "RegisterButtonNew");
    return (
      <LoadingButton
        secondaryText={secondaryText}
        isExternal={dontShowArrow ? false : true}
        tabIndex={0}
        title={localeService.getText("GettingStarted", "RegisterTitle")}
        onKeyDown={this.handleKeyDown.bind(this)}
        onClick={() => initiateRegister(true)}
        loading={isRegistering}
        text={registerButtonText}
        role="link"
        className="register-button"
        properties={{ ButtonName: "GettingStarted-Register-" + placement }}
      />
    );
  }
}
const mapStateToProps = ({ auth }: IApplicationState): IPropsFromState => ({
  isRegistering: auth.isRegistering
});
const mapDispatchToProps = (dispatch: Dispatch): IPropsFromDispatch => ({
  initiateRegister: (value?: boolean) => dispatch(initiateRegister(value))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAITracking(telemetryService.aiReactPlugin, RegisterButton));
