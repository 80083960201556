import { Spinner, SpinnerSize } from "@fluentui/react";
import { Dialog, DialogType } from "@fluentui/react/lib/Dialog";
import { Link } from "@fluentui/react/lib/Link";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import Text from "src/components/text/Text";
import localeService from "src/dataServices/LocaleService";
import { IApplicationState } from "src/state";
import * as agentVerificationActions from "src/state/agent-verification/actions";
import { ITokenVerificationSubmission } from "src/state/agent-verification/types";
import * as profileActions from "src/state/profile/actions";
import { IEditEmailState, INonprofitEmailUpdate } from "src/state/profile/types";
import { TryAgain } from "../offers/SubmissionResult";
import EditEmailForm from "./edit-email/EditEmailForm";
import "./EmailVerification.scss";
import EmailVerificationForm from "./EmailVerificationForm";
import EmailVerificationHeader from "./EmailVerificationHeader";
import ResendConfirmationEmail from "./ResendConfirmationEmail";
import { mungeEmailAddress } from "./Utils";

interface IPropsFromDispatch {
  initiateTokenVerification?: typeof agentVerificationActions.initiateTokenVerification;
  initiateAgentEmailEdit?: typeof profileActions.initiateAgentEmailEdit;
}

export interface IEmailVerificationProps {
  hideDialog?: boolean;
  agentEmail: string;
  isTokenVerifyInProcess: boolean;
  isTokenVerificationFailure: boolean;
  resendEmailInProcess: boolean;
  editEmailState: IEditEmailState;
}

export type IEmailVerificationComponentProps = IPropsFromDispatch & IEmailVerificationProps;

class EmailVerification extends React.Component<
  IEmailVerificationComponentProps,
  {
    hideDialog: boolean;
    showEditAgentEmailView: boolean;
    showEditAgentEmailText: boolean;
    updatedAgentEmail: string;
  }
> {
  constructor(props: IEmailVerificationProps) {
    super(props);
    this.state = {
      hideDialog: false,
      showEditAgentEmailView: false,
      showEditAgentEmailText: true,
      updatedAgentEmail: ""
    };
  }

  public componentDidUpdate() {
    if (this.isEditAgentEmailSuccess() && this.state.showEditAgentEmailView === true) {
      this.setState({ showEditAgentEmailView: false, showEditAgentEmailText: false });
    }
  }

  public render() {
    return (
      <section id="email-verification">
        <Dialog
          hidden={this.state.hideDialog}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: <EmailVerificationHeader />,
            className: "email-verification-header",
          }}
          modalProps={{
            isBlocking: true,
            isDarkOverlay: true,
            className: "email-verification-dialog"
          }}
          maxWidth="40%"
        >
          {!this.shouldShowSpinner() && this.canShowEditAgentEmailView() && (
            <EditEmailForm onSubmit={(value: INonprofitEmailUpdate) => this.initiateAgentEmailUpdate(value)} />
          )}

          {this.canShowEditAgentEmailView() && this.isEditAgentEmailFailure() && (
            <div role="alert" id="error-message-email-update">
              <TryAgain textCategory="AgentVerification" textId="EmailUpdateSubmissionError">
                <Text category="AgentVerification" id="EmailUpdateSubmissionErrorContent" />
              </TryAgain>
            </div>
          )}

          {this.shouldShowSpinner() && (
            <Spinner
              size={SpinnerSize.medium}
              label={localeService.getText("Shared", "SpinnerPleaseWait")}
              labelPosition="right"
              aria-live="off"
            />
          )}

          {!this.canShowEditAgentEmailView() && !this.shouldShowSpinner() && (
            <section>
              <p className="subText">
                <Text
                  category="AgentVerification"
                  id="Email_Content_1"
                  substitutionData={{
                    EmailAddress: this.isEditAgentEmailSuccess()
                      ? mungeEmailAddress(this.state.updatedAgentEmail)
                      : mungeEmailAddress(this.props.agentEmail)
                  }}
                />
              </p>
              {this.props.isTokenVerifyInProcess ? (
                <Spinner
                  size={SpinnerSize.medium}
                  label={localeService.getText("AgentVerification", "SpinnerVerify")}
                  labelPosition="right"
                  aria-live="off"
                />
              ) : this.props.isTokenVerificationFailure ? (
                <section>
                  <section id="email-code-verification-error">
                    <Text category="AgentVerification" id="Email_Verification_Fail" />
                  </section>
                  <EmailVerificationForm
                    onSubmit={(value: ITokenVerificationSubmission) => this.props.initiateTokenVerification?.(value)}
                  />
                </section>
              ) : (
                <EmailVerificationForm
                  onSubmit={(value: ITokenVerificationSubmission) => this.props.initiateTokenVerification?.(value)}
                />
              )}
              <ResendConfirmationEmail />
              {this.state.showEditAgentEmailText && (
                <section id="change-email">
                  <Text category="AgentVerification" id="Email_Change_Text_1" />
                  <Link type="submit" onClick={() => this.setAgentEmailEditOptionVisible()}>
                    <Text category="AgentVerification" id="Email_Change_Text_2" />
                  </Link>
                  <Text category="AgentVerification" id="Email_Change_Text_3" />
                </section>
              )}
            </section>
          )}
        </Dialog>
      </section>
    );
  }

  private shouldShowSpinner() {
    return this.props.resendEmailInProcess || (this.props.editEmailState && this.props.editEmailState.inProgress);
  }

  private initiateAgentEmailUpdate(value: INonprofitEmailUpdate) {
    this.props.initiateAgentEmailEdit?.(value);
    this.setState({ updatedAgentEmail: value.agentEmail });
  }

  private canShowEditAgentEmailView() {
    return this.state.showEditAgentEmailView;
  }

  private isEditAgentEmailSuccess() {
    return this.props.editEmailState && this.props.editEmailState.result === true;
  }

  private isEditAgentEmailFailure() {
    return this.props.editEmailState && this.props.editEmailState.result === false;
  }

  private setAgentEmailEditOptionVisible() {
    this.setState({ showEditAgentEmailView: true });
  }
}

const mapStateToProps = ({ nonprofit, agentVerification, profile }: IApplicationState) => ({
  agentEmail: nonprofit.miniProfile.primaryContact.email,
  isTokenVerifyInProcess: agentVerification.tokenVerificationInProcess,
  isTokenVerificationFailure: agentVerification.isTokenVerificationFailure,
  resendEmailInProcess: agentVerification.resendEmailInProcess,
  editEmailState: profile.editEmailState
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initiateTokenVerification: (token: ITokenVerificationSubmission) =>
    dispatch(agentVerificationActions.initiateTokenVerification(token)),
  initiateAgentEmailEdit: (nonprofitEmailUpdate: INonprofitEmailUpdate) =>
    dispatch(profileActions.initiateAgentEmailEdit(nonprofitEmailUpdate))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailVerification);
