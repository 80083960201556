import { Spinner, SpinnerSize } from "@fluentui/react";
import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import localeService from "src/dataServices/LocaleService";
import { IApplicationState } from "src/state";
import { INonprofitMiniProfile } from "src/state/nonprofit";
import { fetchBingAdsRegistrationInfo } from "src/state/offers/bing-ads/actions";
import { BingAdsRegistrationState } from "src/state/offers/bing-ads/types";

interface IPropsFromState {
  nonprofit: INonprofitMiniProfile;
  bingAds: BingAdsRegistrationState;
  children: React.ReactNode
}

interface IPropsFromDispatch {
  fetchBingAdsRegistrationInfo: typeof fetchBingAdsRegistrationInfo;
}

export type IWithBingAdsRegistrationProps = IPropsFromState & IPropsFromDispatch;

export const WithBingAdsRegistration: React.FunctionComponent<IWithBingAdsRegistrationProps> = props => {
  const { nonprofit, bingAds, fetchBingAdsRegistrationInfo } = props;

  useEffect(() => {
    if (!bingAds || !bingAds.adsRegistrationInfo) {
      fetchBingAdsRegistrationInfo(nonprofit.id);
    }
  }, []);

  return (
    <>
      {bingAds.isLoading && (
        <Spinner
          size={SpinnerSize.large}
          label={localeService.getText("Shared", "SpinnerPleaseWait")}
          ariaLive="assertive"
        />
      )}

      {!bingAds.isLoading && props.children}
    </>
  );
};

const mapStateToProps = ({ nonprofit, offers }: IApplicationState) => ({
  nonprofit: nonprofit.miniProfile,
  bingAds: offers.bingAds
});

const mapDispatchToProps: (dispatch: Dispatch) => IPropsFromDispatch = dispatch => ({
  fetchBingAdsRegistrationInfo: nonprofitId => dispatch(fetchBingAdsRegistrationInfo(nonprofitId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithBingAdsRegistration);
