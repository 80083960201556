import { Icon, TextField } from "@fluentui/react";
import * as React from "react";
import { Field } from "react-final-form";
import localeService from "src/dataServices/LocaleService";
import { inputTextFieldValidators } from "../shared/TextInputComponent";
import Text from "../text/Text";

export interface IContactUsV2TextInputProps {
  id: string;
  multiline: boolean;
  required: boolean;
  label?: string;
  hasHelpText?: boolean;
  autoFocus?: boolean;
  type?: "text" | "email" | "phone" | "url";
  disabled?: boolean;
  category: string;
}

export const contactUsV2TextInputField = (field: any) => {
  const {
    input,
    type,
    id,
    category,
    name,
    label,
    required,
    readOnly,
    placeholder,
    maxLength,
    autoFocus,
    disabled,
    multiline,
    meta: { error, touched }
  } = field;
  return (
    <React.Fragment>
      <TextField
        {...input}
        type={type}
        style={touched && error ? { border: "0.125rem solid #E81123" } : { border: "0.125rem solid #505050" }}
        id={id}
        category={category}
        name={name}
        required={required}
        placeholder={placeholder}
        readOnly={readOnly}
        autoFocus={autoFocus}
        maxLength={maxLength}
        disabled={disabled}
        className={"" + (touched && error ? "has-error" : null)}
        multiline={multiline}
        ariaLabel={label}
      />
      {touched && error && (
        <div role="alert" className="error">
          <Icon
            iconName={"warning"}
            style={{
              fontSize: "16px",
              lineHeight: "24px",
              color: "#E81123"
            }}
          />
          <Text category={`${category}`} id={`${id}${error}`} />
        </div>
      )}
    </React.Fragment>
  );
};

export class ContactUsV2TextInput extends React.Component<IContactUsV2TextInputProps> {
  public render() {
    const { id, multiline, label, required, autoFocus, type, disabled, category } = this.props;
    return this.renderTextInput(id, label ?? '', required, multiline, autoFocus, type, disabled, category);
  }

  private renderTextInput = (
    id: string,
    label: string,
    required: boolean,
    isMultiLine?: boolean,
    autoFocus?: boolean,
    type?: "text" | "email" | "phone" | "url",
    disabled?: boolean,
    category?: string
  ): JSX.Element => (
    <React.Fragment>
      {isMultiLine ? (
        <Field
          id={id}
          name={id}
          component={contactUsV2TextInputField}
          placeholder={
            localeService.getText(`${category}`, `${label}Placeholder`) || localeService.getText(`${category}`, label)
          }
          multiline={true}
          maxLength={2250}
          validate={inputTextFieldValidators(required, type ?? '')}
          disabled={disabled}
          category={category}
          required={required}
          label={label}
        />
      ) : (
        <Field
          id={id}
          name={id}
          component={contactUsV2TextInputField}
          type={type || "text"}
          placeholder={
            localeService.getText(`${category}`, `${label}Placeholder`) || localeService.getText(`${category}`, label)
          }
          validate={inputTextFieldValidators(required, type ?? '')}
          maxLength={type === "phone" ? 30 : 100}
          disabled={disabled}
          category={category}
          multiline={false}
          required={required}
          label={label}
        />
      )}
    </React.Fragment>
  );
}
export default ContactUsV2TextInput;
