import * as React from "react";
import Text from "src/components/text/Text";

export function Windows10EnterpriseE3Offer() {
  return (
    <section id="windows-enterprise-offer">
      <p>
        <Text category="Windows10EnterpriseE3Offer" id="Detail1" />
      </p>
      <p>
        <Text category="Windows10EnterpriseE3Offer" id="Detail2" />
      </p>
    </section>
  );
}
