import * as React from "react";
import localeService from "src/dataServices/LocaleService";
import NavigationCardContent from "../../../navigation-cards/NavigationCardContent";
import { IFeatureFlagsState } from "src/state/feature-flags";
import { connect } from "react-redux";
import { IApplicationState } from "src/state";

interface IGuidedDemosAzureServicesCardPropsFromState {
  featureFlags: IFeatureFlagsState;
}

const GuidedDemosAzureServicesCard = ({featureFlags} : IGuidedDemosAzureServicesCardPropsFromState) => {
  return (
    <NavigationCardContent
      imgSrc={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/Training/azure_service_portal.jpg`}
      imgAlt={localeService.getText("TrainingV2", "GuidedDemosSection2Title")}
      title={localeService.getText("TrainingV2", "GuidedDemosSection2Title")}
      ctaText={localeService.getText("TrainingV2", "GuidedDemosViewDemoButton")}
      ctaLink={localeService.getText("TrainingV2", "GuidedDemosSection2Link")}
      linkAriaLabel={localeService.getText("TrainingV2", "GuidedDemosSection2LinkAria")}
    >
      <p>{`${localeService.getText("TrainingV2", "GuidedDemosSection2DescriptionNew")}`}</p>
    </NavigationCardContent>
  );
};

const mapStateToProps = ({ flags }: IApplicationState) => ({
  featureFlags: flags
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GuidedDemosAzureServicesCard);
