import * as React from "react";
import Text from "src/components/text/Text";
import TextLink from "src/components/text/TextLink";
import { getLocalizedPath } from "../utilities/Localization/Utils";

export interface ITenantErrorProps {
  nonprofitId: string | undefined;
}

const TenantError: React.FunctionComponent<ITenantErrorProps> = ({ nonprofitId }) => {
  return (
    <div className="tenant-error">
      <h2>
        <Text category="ErrorTenant" id="Header" />
      </h2>
      <p>
        <TextLink
          category="ErrorTenant"
          id="SubText"
          href={getLocalizedPath("/contactus")}
          substitutionData={{ NonprofitId: nonprofitId }}
        />
      </p>
    </div>
  );
};

export default TenantError;
