import * as React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "src/state";
import { INonprofitMiniProfile } from "src/state/nonprofit";
import "./NonprofitSummary.scss";

export interface INonprofitSummaryProps {
  nonprofit?: INonprofitMiniProfile;
}
export class NonprofitSummary extends React.Component<INonprofitSummaryProps> {
  public render() {
    const { nonprofit } = this.props;
    return nonprofit ? (
      <section id="nonprofit-summary">
        <div className="nonprofit-name">{nonprofit.name}</div>
      </section>
    ) : null;
  }
}

const mapStateToProps = ({ nonprofit }: IApplicationState) => ({
  nonprofit: nonprofit.miniProfile
});

export default connect(mapStateToProps)(NonprofitSummary);
