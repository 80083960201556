import InfoSection from "../shared/InfoSection";

interface IAccountGuardPlansProps {
  setRef?: (el: HTMLDivElement | null) => void;
}

const AccountGuardFeatures = (props: IAccountGuardPlansProps) => {
  return (
    <div
      id="features"
      className="current-features"
      ref={el => {
        if (props.setRef !== undefined) {
          props.setRef(el);
        }
      }}
    >
      <InfoSection
        category="AccountGuardV2"
        imagePath1="hub-redesign/AccountGuard/AcctGrd-Feature1-1920.jpg"
        title1="UnifiedThreatMonitoringTitle"
        description1="UnifiedThreatMonitoringDescription"
        imagePath2="hub-redesign/AccountGuard/AcctGrd-Feature2-1920.jpg"
        title2="SecureHybridTitle"
        description2="SecureHybridDescription"
        imagePath3="hub-redesign/AccountGuard/AcctGrd-Feature3-1920.jpg"
        title3="SecurityToolsTitle"
        description3="SecurityToolsDescription"
      />
    </div>
  );
};

export default AccountGuardFeatures;
