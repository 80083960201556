import { IApiResponse } from "src/common/ApiResponse";
import { IEmailOfferRequestSubmissionForm } from "src/state/offers/shared/types";
import { callApi } from "./SharedServiceFunctions";

export class LinkedInOfferService {
  private API_URL = process.env.REACT_APP_INTERNAL_API_BASEURL;

  public async initiateRequest(formValues: IEmailOfferRequestSubmissionForm): Promise<IApiResponse<{}>> {
    const response = await callApi<{}>(`${this.API_URL}/nonprofitoffers/linkedin`, JSON.stringify(formValues), "POST");
    return response;
  }
}

const linkedInOfferService = new LinkedInOfferService();
export default linkedInOfferService;
