import { IResizableImage } from 'src/components/moray/ResizeableImage';
import HighlightBanner from 'src/components/offers/shared/HighlightBanner';
import localeService from 'src/dataServices/LocaleService';
import './GettingStartedTopBanner.scss';

const GettingStartedTopBanner = () => {
  const resizableImageProps: IResizableImage = {
    root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/GettingStarted`,
    imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/GettingStarted/GettingStartedTopBanner1920.png`,
    imageClassName: 'getting-started-top-banner-image',
    imageAlt: localeService.getText('GettingStartedV2', 'HighlightBannerImageAlt'),
    fileSize1400: 'GettingStartedTopBanner1084.jpg',
    fileSize1920: 'GettingStartedTopBanner1084.jpg',
    fileSize1084: 'GettingStartedTopBanner1084.jpg',
    fileSize860: 'GettingStartedTopBanner1084.jpg',
    fileSize540: 'GettingStartedTopBanner1084.jpg',
    fileSize360: 'GettingStartedTopBanner1084.jpg'
  };

  return (
    <HighlightBanner
      reverseTextColor={true}
      titleText={localeService.getText('GettingStartedV2', 'HighlightBannerTitle')}
      descriptionText={localeService.getText('GettingStartedV2', 'HighlightBannerDescription')}
      leftTextAlign={true}
      textContainerWidth={'8'}
      resizableImageProps={resizableImageProps}
      backgroundClassName={'getting-started-top-banner-background'}
      containerClassName={'getting-started-top-banner-container'}
    />
  );
};

export default GettingStartedTopBanner;
