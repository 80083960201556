export const enum ClaimOffersRequestActionTypes {
  FETCH_ACCOUNTGUARD_INFO = "@@offers/FETCH_ACCOUNTGUARD_INFO",
  SET_ACCOUNTGUARD_INFO_SUCCESS = "@@offers/SET_ACCOUNTGUARD_INFO_SUCCESS",
  SET_ACCOUNTGUARD_INFO_ERROR = "@@offers/SET_ACCOUNTGUARD_INFO_ERROR",
  ACCOUNT_OFFER_REQUEST = "@@offers/ACCOUNT_OFFER_REQUEST",
  ACCOUNT_OFFERS_REQUEST_SUCCESS = "@@offers/ACCOUNT_REQUEST_SUCCESS",
  ACCOUNT_OFFERS_REQUEST_ERROR = "@@offers/ACCOUNT_REQUEST_ERROR",
  ACCOUNT_OFFERS_REQUEST_ALREADY_CLAIM = "@@offers/ACCOUNT_OFFERS_REQUEST_ALREADY_CLAIM",
  ACCOUNT_OFFERS_REQUEST_LANGUAGES_SUPPORT = "@@offers/ACCOUNT_OFFERS_REQUEST_LANGUAGES_SUPPORT",
  ACCOUNT_OFFERS_REQUEST_RESET = "@@offers/ACCOUNT_REQUEST_RESET"
}

export interface IClaimOfferRequestState {
  isSending?: boolean;
  sentSuccessfully?: boolean;
  failedToSend?: boolean;
  alreadyClaim?: boolean;
  claimStatus: boolean;
  accountGuardInfoError: boolean;
  languageSupportError?: boolean;
}
