import { connect } from "react-redux";
import { Dispatch } from "redux";
import ResizableImage from "src/components/moray/ResizeableImage";
import localeService from "src/dataServices/LocaleService";
import * as offerActions from "src/state/offers/shared/actions";
import { IOfferRequestState } from "src/state/offers/shared/types";
import OfferRequestForm from "./OfferRequestForm";

interface IPropsFromState {
  offers: IOfferRequestState;
  setRef: (el: HTMLElement | null) => void;
}

interface IPropsFromDispatch {
  offersRequestReset: typeof offerActions.offersRequestReset;
}

export type HardwareDiscountProps = IPropsFromState & IPropsFromDispatch;

export type SuccessAlertProps = {
  dismissAlert: typeof offerActions.offersRequestReset;
};

export const SuccessAlert = ({ dismissAlert }: SuccessAlertProps) => (
  <div className=".container">
    <div className="row">
      <div className="col-auto pl-0">
        <p className="glyph-prepend glyph-prepend-large glyph-prepend-completed text-green" />
      </div>
      <div className="col align-self-end">
        <p id="HardwareSuccessMessage" className="font-weight-semibold">
          {localeService.getText("Microsoft365V2", "HardwareSuccessMessage")}
        </p>
      </div>
    </div>
    <div className="row">
      <button id="HardwareSuccessGoBack" className="btn btn-primary" onClick={dismissAlert}>
        {localeService.getText("Microsoft365V2", "HardwareSuccessGoBack")}
      </button>
    </div>
  </div>
);

export const Disclaimer = () => (
  <section className="col-12 col-md-10 col-lg-12" role="region" aria-label="footnotes: disclaimers">
    <ul className="list-unstyled small mb-0">
      <li className="pt-3">
        <p id="HardwareDisclaimer" className="small">
          {localeService.getText("Microsoft365V2", "HardwareDisclaimer")}
        </p>
      </li>
    </ul>
  </section>
);

export const HardwareDiscount = ({ offers, setRef, offersRequestReset }: HardwareDiscountProps) => {
  return (
    <section className="content-card-container">
      <div ref={el => setRef(el)} className="card d-block mx-ng mx-md-0">
        <div className="row no-gutters material-surface">
          <div className="d-flex order-md-first col-md">
            <div className="card-body align-self-center p-4 pt-md-5 pl-md-5 pr-md-5 pb-md-3">
              <h2 className="h2 " id="HardwareTitle">
                {localeService.getText("Microsoft365V2", "HardwareTitle")}
              </h2>
              <div className="mb-3">
                <p id="HardwareInfo" className="base">
                  {localeService.getText("Microsoft365V2", "HardwareInfo")}
                </p>
              </div>
              {offers.sentSuccessfully ? (
                <SuccessAlert dismissAlert={offersRequestReset} />
              ) : (
                <OfferRequestForm offers={offers} />
              )}
            </div>
          </div>
          <div className="col-md">
            <ResizableImage
              id="HardwareDiscountImage"
              root={process.env.REACT_APP_HUB_IMAGES_CDN_URL ?? ''}
              imageSrc={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/hardware-discount-540.png`}
              imageClassName="content-card-image"
              imageAlt={localeService.getText("Microsoft365V2", "HardwareDiscountAlt")}
              fileSize1920={"hardware-discount-1920.png"}
              fileSize1400={"hardware-discount-1400.png"}
              fileSize1084={"hardware-discount-1084.png"}
              fileSize860={"hardware-discount-860.png"}
              fileSize540={"hardware-discount-540.png"}
              fileSize360={"hardware-discount-360.png"}
            />
          </div>
        </div>
        <Disclaimer />
      </div>
    </section>
  );
};

const mapDispatchToProps: (dispatch: Dispatch) => IPropsFromDispatch = dispatch => ({
  offersRequestReset: () => dispatch(offerActions.offersRequestReset())
});

export default connect(
  null,
  mapDispatchToProps
)(HardwareDiscount);
