import { IStackStyles, ITextStyles, Stack, StackItem, Text } from "@fluentui/react";
import { Link } from "@fluentui/react/lib/Link";
import localeService from "src/dataServices/LocaleService";
import "./SocialSection.scss";
const imagesUrl = process.env.REACT_APP_IMAGES_URL;
const stackStyles: IStackStyles = {
  root: {
    backgroundColor: "white",
    marginTop: 20,
    marginBottom: 20,
    padding: 15
  }
};
const titleTextStyles: ITextStyles = {
  root: {
    fontFamily: "Segoe UI light",
    fontSize: 15
  }
};
const SocialSection = () => {
  return (
    <section id="registration-social-section">
      <Stack styles={stackStyles} horizontal>
        <StackItem>
          <Text styles={titleTextStyles}>{localeService.getText("RegistrationComplete", "SocialSectionTitle")}</Text>
        </StackItem>
        <StackItem>
          <div id="social-media" className="social-icons">
            <Link
              className="facebook-icon"
              target="_blank"
              href={localeService.getText("RegistrationComplete", "FacebookUrl")}
              aria-label={localeService.getText("RegistrationComplete", "FacebookArialabel")}
            >
              <img
                src={`${imagesUrl}/registration-complete/Icon/facebook_icon.svg`}
                className="facebook-icon"
                alt={localeService.getText("RegistrationComplete", "FacebookArialabel")}
              />
            </Link>
            <Link
              className="twitter-icon"
              target="_blank"
              href={localeService.getText("RegistrationComplete", "TwitterUrl")}
              aria-label={localeService.getText("RegistrationComplete", "TwitterArialabel")}
            >
              <img
                src={`${imagesUrl}/registration-complete/Icon/twitter_icon.svg`}
                className="twitter-icon"
                alt={localeService.getText("RegistrationComplete", "TwitterArialabel")}
              />
            </Link>
          </div>
        </StackItem>
      </Stack>
    </section>
  );
};

export default SocialSection;
