import {
  OfferCard,
  OfferCardButton,
  OfferCardContainer,
  OfferCardDisclaimer,
  OfferCardFeatureHeading,
  OfferCardList,
  OfferCardPrice,
  OfferCardSection,
  OfferCardTitle
} from 'src/components/moray/MorayOfferCard';
import localeService from 'src/dataServices/LocaleService';

interface IMicrosoftCloudPlansV2Props {
  setRef?: (el: HTMLElement | null) => void;
}

const MicrosoftCloudPlansV2 = (props: IMicrosoftCloudPlansV2Props) => {
  return (
    <div
      className="microsoft-cloud-plans"
      ref={(el) => {
        if (props.setRef !== undefined) {
          props.setRef(el);
        }
      }}
    >
      <OfferCardFeatureHeading>
        <h4 id={'MicrosoftCloudPlanHeaderTitle'} className="font-weight-semibold h4">
          {localeService.getText('CloudV2', 'MicrosoftCloudPlansV2Header')}
        </h4>
      </OfferCardFeatureHeading>
      <OfferCardContainer>
        <OfferCard>
          <OfferCardSection id="offer-card-title">
            <OfferCardTitle id={'Card1Title'} title={localeService.getText('CloudV2', 'MicrosoftCloudPlansV2Title')} />
            <OfferCardPrice id={'Card1Price'} price={localeService.getText('CloudV2', 'MicrosoftCloudPlansV2Price')} />
            <OfferCardDisclaimer
              id={'Card1Disclaimer'}
              disclaimer={localeService.getText('CloudV2', 'MicrosoftCloudPlansV2Disclaimer')}
            />
          </OfferCardSection>
          <OfferCardSection id="offer-card-button">
            <OfferCardButton
              id="Card1Button"
              buttonText={localeService.getText('CloudV2', 'MicrosoftCloudPlansV2ButtonText')}
              buttonLink={localeService.getText('CloudV2', 'MicrosoftCloudPlansV2ButtonLink')}
            />
          </OfferCardSection>
          <OfferCardSection id="offer-card-list">
            <OfferCardList
              id={'Card1List1'}
              glyph={'check-mark'}
              list={localeService.getText('CloudV2', 'MicrosoftCloudPlansV2List1') as unknown as string[]}
            />
          </OfferCardSection>
          <OfferCardSection id="offer-card-list">
            <OfferCardList
              id={'Card1List2'}
              glyph={'trophy'}
              list={localeService.getText('CloudV2', 'MicrosoftCloudPlansV2List2') as unknown as string[]}
            />
          </OfferCardSection>
        </OfferCard>
      </OfferCardContainer>
    </div>
  );
};

export default MicrosoftCloudPlansV2;
