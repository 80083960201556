import { IApiResponse } from "src/common/ApiResponse";
import { FeatureFlags } from "src/common/FeatureFlags";
import { httpRequest } from "src/common/HttpRequest";
import { encodeGetParams, isNullOrUndefined } from "src/common/utilities/Utilities";
import { IFeatureFlagsState } from "src/state/feature-flags/types";
import { v4 as uuidv4, validate as uuidValidate } from "uuid";
import authenticationService from "./AuthenticationService";

class FeatureFlagsService {
  private DEFAULT_FLAGS = FeatureFlags;
  private API_URL = process.env.REACT_APP_INTERNAL_API_BASEURL;

  public async GetFeatureFlags(): Promise<IApiResponse<IFeatureFlagsState>> {
    let userId = "";
    const uId = localStorage.getItem("npUserId");

    // replace from local storage if it exists in local storage to maintain consistent userId.
    if (!isNullOrUndefined(uId) && uId && uuidValidate(uId)) {
      userId = uId;
    } else {
      userId = uuidv4();
      localStorage.setItem("npUserId", userId);
    }

    const token = await authenticationService.getToken();
    const response = await httpRequest(
      `${this.API_URL}/featureflags?${encodeGetParams({ names: Object.keys(this.DEFAULT_FLAGS), userId: userId })}`,
      token
    );
    if (response.ok) {
      return { status: response.status, value: await response.json() };
    } else {
      throw new Error(response.statusText);
    }
  }
}

const featureFlagsService = new FeatureFlagsService();
export default featureFlagsService;
