import * as React from "react";

const MoraySpinnerSmall = () => (
  <div id="spinner-1" className="spinner ml-1" tabIndex={-1} role="alert" aria-live="assertive">
    <span className="sr-only">Loading...</span>
    <span role="presentation" className="spinner-dot spinner-dot-1" />
    <span role="presentation" className="spinner-dot spinner-dot-2" />
    <span role="presentation" className="spinner-dot spinner-dot-3" />
    <span role="presentation" className="spinner-dot spinner-dot-4" />
    <span role="presentation" className="spinner-dot spinner-dot-5" />
  </div>
);

export default MoraySpinnerSmall;
