import { Reducer } from "redux";
import { ContactUsActionTypes, IContactUsState } from "src/state/contactus/types";

export const initialState: IContactUsState = {
  failedToSend: undefined,
  isSending: undefined,
  isLoadingResources: undefined,
  sentSuccessfully: undefined,
  supportTicketResponse: undefined,
  countryCodes: undefined
};

const reducer: Reducer<IContactUsState> = (state = initialState, action) => {
  switch (action.type) {
    case ContactUsActionTypes.CONTACT_RESOURCES_REQUEST: {
      return { ...state, isLoadingResources: true };
    }
    case ContactUsActionTypes.CONTACT_RESOURCES_SUCCESS: {
      return {
        ...state,
        countryCodes: action.payload.countryCodes,
        isLoadingResources: false
      };
    }
    case ContactUsActionTypes.CONTACT_RESOURCES_ERROR: {
      return {
        ...state,
        isLoadingResources: false
      };
    }

    case ContactUsActionTypes.CONTACTUS_CREATESUPPORTTICKET: {
      return {
        ...state,
        isSending: true,
        failedToSend: false
      };
    }
    case ContactUsActionTypes.CONTACTUS_CREATESUPPORTTICKET_ERROR: {
      return {
        ...state,
        isLoadingCountries: false,
        isLoadingCategories: false,
        failedToSend: true,
        isSending: false
      };
    }
    case ContactUsActionTypes.CONTACTUS_CREATESUPPORTTICKET_SUCCESS: {
      return {
        ...state,
        supportTicketResponse: action.payload.supportTicketResponse,
        isLoadingCountries: false,
        isLoadingCategories: false,
        failedToSend: false,
        isSending: false,
        sentSuccessfully: true
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as contactUsReducer };
