import * as React from "react";
import Text from "src/components/text/Text";
import TextLink from "src/components/text/TextLink";

const NotFound: React.FunctionComponent = () => {
  return (
    <div className="not-found-error">
      <h1>
        <Text category="ErrorNotFound" id="Header" />
      </h1>
      <p>
        <Text category="ErrorNotFound" id="SubText1" />
      </p>
      <ol>
        <li>
          <TextLink category="ErrorNotFound" id="Instruction1" href="Register" target="_blank" />
        </li>
        <li>
          <Text category="ErrorNotFound" id="Instruction2" />
          <ul>
            <li>
              <Text category="ErrorNotFound" id="Instruction2_1" />
            </li>
            <li>
              <Text category="ErrorNotFound" id="Instruction2_2" />
            </li>
          </ul>
        </li>
        <li>
          <Text category="ErrorNotFound" id="Instruction3" />
        </li>
      </ol>
      <h2>
        <Text category="ErrorNotFound" id="Header2" />
      </h2>
      <p>
        <TextLink category="ErrorNotFound" id="Instruction4" href="NonprofitSignin" target="_blank" />
      </p>
    </div>
  );
};

export default NotFound;
