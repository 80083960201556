import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { logService } from "npo-common";
import { telemetryService } from "npo-common";
import { IApplicationState } from "src/state";
import * as wcpCookieConsentActions from "src/state/cookie-consent/actions";
import localeService from "src/dataServices/LocaleService";
import { MarketingCookieManager } from "../marketingtags/MarketingCookieManager";
import "./WcpCookieConsent.scss";

interface IPropsFromDispatch {
  setWcpCookieConsent: typeof wcpCookieConsentActions.setWcpCookieConsent;
}

interface IPropsFromState {
  hasConsent: boolean;
}

type IWcpCookieConsentProps = IPropsFromState & IPropsFromDispatch;
let siteConsent: WcpConsent.SiteConsent;

class WcpCookieConsent extends React.Component<IWcpCookieConsentProps> {
  constructor(props: IWcpCookieConsentProps) {
    super(props);
    const { setWcpCookieConsent } = this.props;
    // Setting the state hasConsent property to true always so that api calls are not blocked.
    setWcpCookieConsent(true);

    // The telemetry cookie is mandatory required and doesn't need any consent. so we are enabling it by default.
    this.enableCookieUseInAI();

    try {
      WcpConsent.init(
        localeService.getlocale() ?? 'en-us',
        "cookie-banner",
        (err, _siteConsent) => {
          if (err) {
            logService.logError("WCPCookieConsent: Failed to load", { err });
          } else {
            siteConsent = _siteConsent!;
          }
        },
        (newConsent: Record<WcpConsent.consentCategories, boolean>) => {
          console.debug("siteConsent updated", this.getConsentState());
          MarketingCookieManager.cleanupMarketingCookies();
        }
      );

      console.debug("siteConsent", this.getConsentState());
    } catch (e: any) {
      logService.logError("WCPCookieConsent: Failed to load", { exception: e });
      console.debug("wcp error");
    }
  }

  public render() {
    return null;
  }

  private enableCookieUseInAI = () => {
    if (telemetryService.ai) {
      telemetryService.ai.config.disableCookiesUsage = false;
    }
  };

  private getConsentState() {
    return {
      ...siteConsent,
      analytics: siteConsent.getConsentFor(WcpConsent.consentCategories.Analytics),
      advertising: siteConsent.getConsentFor(WcpConsent.consentCategories.Advertising),
      required: siteConsent.getConsentFor(WcpConsent.consentCategories.Required),
      socialMedia: siteConsent.getConsentFor(WcpConsent.consentCategories.SocialMedia)
    };
  }
}

const mapStateToProps = ({ wcpCookieConsent }: IApplicationState): IPropsFromState => wcpCookieConsent;
const mapDispatchToProps = (dispatch: Dispatch): IPropsFromDispatch => ({
  setWcpCookieConsent: (hasConsent: boolean) => dispatch(wcpCookieConsentActions.setWcpCookieConsent(hasConsent))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WcpCookieConsent);

export function isConsentProvided(category: WcpConsent.consentCategories): boolean {
  try {
    return siteConsent.getConsentFor(category);
  } catch {
    return false;
  }
}

export function isWcpCookieConsentRequired(): boolean {
  try {
    return siteConsent.isConsentRequired;
  } catch {
    return false;
  }
}

export function manageConsent() {
  siteConsent.manageConsent();
}
