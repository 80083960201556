import localeService from '../../../dataServices/LocaleService';
import FeatureCard from '../shared/FeatureCard';

const CommunityTrainingPlanInfoComponent = () => (
  <div>
    <div className={`card-body align-self-center p-4 p-md-5 }`}>
      <h4 id="PartnersBannerTitle" className={`font-weight-semibold h3`}>
        {localeService.getText('CommunityTrainingV2', 'CommunityTrainingPlanFeatureCardTitle')}
      </h4>
      <div className="my-3">
        <p className="base">
          {localeService.getText('CommunityTrainingV2', 'CommunityTrainingPlanFeatureCardDescription')}
        </p>
      </div>
      <div className="row row-cols-2 d-flex align-items-center">
        <p className="mb-0">
          {localeService.getText('CommunityTrainingV2', 'CommunityTrainingPlanFeatureCardPricing')}
        </p>
        <div>
          <button
            className="btn btn-primary"
            onClick={() =>
              window.open(
                localeService.getText('CommunityTrainingV2', 'CommunityTrainingPlanFeatureCardCtaLink'),
                '_blank'
              )
            }
          >
            {localeService.getText('CommunityTrainingV2', 'CommunityTrainingPlanFeatureCardCtaText')}
          </button>
        </div>
      </div>
    </div>
    <div className="card-footer px-4 px-md-5 ">
      <p className="mb-1" style={{ fontSize: '0.75rem' }}>
        {localeService.getText('CommunityTrainingV2', 'CommunityTrainingPlanFeatureCardDisclaimer')}
      </p>
    </div>
  </div>
);

const CommunityTrainingPlanFeatureCard = () => {
  const index = 0;
  return (
    <FeatureCard
      containerClassNames="px-md-5 pb-5"
      customFeatureCardInfoComponent={<CommunityTrainingPlanInfoComponent />}
      key={index}
      reverseTextAlign={false}
      offset={false}
      imageProps={{
        id: 'ct_plan_img',
        root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/CommunityTraining`,
        imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/CommunityTraining/community_training_plan_feature_card.jpg`,
        imageClassName: 'card-img mx-0 pl-0 h-100',
        imageAlt: localeService.getText('CommunityTrainingV2', 'CommunityTrainingPlanFeatureCardImgAlt')
      }}
    />
  );
};

export default CommunityTrainingPlanFeatureCard;
