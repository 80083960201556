import { logService } from "npo-common";
import { IApiResponse } from "src/common/ApiResponse";
import {
  defaultAddressResponse,
  defaultAddressResponseUS,
  IAddressResources,
  IContactPermissionSettings
} from "src/state/profile/types";
import { ICountryCode } from "src/state/shared";
import localeService from "./LocaleService";
import { callApi } from "./SharedServiceFunctions";

export class ResourceService {
  private API_URL = process.env.REACT_APP_INTERNAL_API_BASEURL;
  private NONPROFIT_SIGNUP_API_URL = process.env.REACT_APP_NONPROFIT_SIGNUP_API;

  public async getCountryCodes(): Promise<IApiResponse<ICountryCode[]>> {
    const response = await callApi<ICountryCode[]>(`${this.API_URL}/resource/countrycodes/list`);
    return response;
  }

  public async getContactPermissionSettings(countryCode: string): Promise<IApiResponse<IContactPermissionSettings>> {
    const response = await callApi<IContactPermissionSettings>(
      `${this.API_URL}/resource/contactpreferences/default/${countryCode}`
    );

    return response;
  }

  public async getAddressResourcesForCountry(countryCode: string): Promise<IApiResponse<IAddressResources>> {
    const locale = localeService.getlocale();
    const url = `${this.NONPROFIT_SIGNUP_API_URL}/addressvalidationfields/${countryCode}/${locale}`;

    try {
      const response = await callApi<IAddressResources>(url);
      if (response.value) {
        response.value.countryCode = countryCode;
      }
      return response;
    } catch (error) {
      logService.logError("getAddressResourcesForCountry Error", { error });
      return this.getDefaultAddressResources(countryCode);
    }
  }

  private getDefaultAddressResources(countryCode: string): IApiResponse<IAddressResources> {
    const fallBackResources: IAddressResources =
      countryCode === "US" ? defaultAddressResponseUS : defaultAddressResponse;
    const fallbackResponse: IApiResponse<IAddressResources> = {
      status: 500,
      value: fallBackResources
    };
    return fallbackResponse;
  }
}

const resourceService = new ResourceService();
export default resourceService;
