import localeService from "src/dataServices/LocaleService";
import { IClaimOfferRequestState } from "src/state/offers/accountguard/types";
import ActionStepCard from "../shared/ActionStepCard";
import ActionSteps from "../shared/ActionSteps";

export interface IAccountGuardActionStepsProps {
  setRef: (ref: HTMLDivElement | null) => void;
  accountGuard: IClaimOfferRequestState;
  accountOfferRequest: () => void;
}

export const AccountGuardActionSteps = (props: IAccountGuardActionStepsProps) => {
  const { setRef, accountGuard, accountOfferRequest } = props;

  return (
    <div ref={ref => setRef(ref)}>
      <ActionSteps
        step1Card={
          <ActionStepCard
            stepIconImagePath="hub-redesign/ActionStep_1_3x.png"
            cardTextCategory="AccountGuardV2"
            cardTitle="LearnMoreTitle"
            cardDescription="LearnMoreDescription"
            ctaText="LearnMoreText"
            onClickLink={localeService.getText("Urls", "AccountGuard")}
          />
        }
        step2Card={
          <ActionStepCard
            stepIconImagePath="hub-redesign/ActionStep_2_3x.png"
            cardTextCategory="AccountGuardV2"
            cardTitle="ClaimAccountGuardTitle"
            cardDescription="ClaimAccountGuardDescription"
            ctaText="ClaimNowText"
            ctaDisabled={accountGuard.claimStatus || accountGuard.sentSuccessfully}
            onClickAction={() => accountOfferRequest()}
            maxReloadsAfterSpinner={1}
          />
        }
        step3Card={
          <ActionStepCard
            stepIconImagePath="hub-redesign/ActionStep_3_3x.png"
            cardTextCategory="AccountGuardV2"
            cardTitle="SignupFreeSecurityAssessmentTitle"
            cardDescription="SignupFreeSecurityAssessmentDescription"
            ctaText="SignUpText"
            onClickLink={localeService.getText("Urls", "SecurityAssessment")}
          />
        }
      />
    </div>
  );
};

export default AccountGuardActionSteps;
