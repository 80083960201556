import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { connect } from "react-redux";
import Text from "src/components/text/Text";
import localeService from "src/dataServices/LocaleService";
import { telemetryService } from "npo-common";
import { IApplicationState } from "src/state";
import { IProfile } from "src/state/profile/types";
import "./StatusHeader.scss";

interface IStatusHeaderProps {
  profile: IProfile;
  showNonprofitStatus: boolean;
}

export const StatusHeader = ({ showNonprofitStatus, profile }: IStatusHeaderProps) => {
  const getIcon = ({ profile }: { profile: any }): JSX.Element | null => {
    switch (profile.status) {
      case "Approved":
        return <p id="icon" className="glyph-prepend glyph-prepend-completed w-auto h-auto text-green mb-1" />;
      case "Declined":
        return <p id="icon" className="glyph-prepend glyph-prepend-button-x w-auto h-auto text-red mb-1" />;
      case "Closed":
        return <p id="icon" className="glyph-prepend glyph-prepend-button-x w-auto h-auto text-red mb-1" />;
      case "InProgress":
        return <p id="icon" className="glyph-prepend glyph-prepend-recent w-auto h-auto text-light-orange mb-1" />;
      default:
        return null;
    }
  };

  if (profile.name != null && profile.status != null) {
    return (
      <section className="profile-status-header">
        <h2 id="profile-status-header-title" className="text-style h3 mt-4 mb-3">
          <Text category="ProfileV2" id="HeaderTitle" />
        </h2>
        {showNonprofitStatus && (
          <div className="status-header">
            <div className="icon">{getIcon({ profile })}</div>
            <p className="status-text ml-3">
              <b>
                {localeService
                  .getText("ProfileV2", "HeaderDescription")
                  ?.replace("{0}", profile.name)
                  .replace("{1}", profile.status.toLowerCase())}
              </b>
            </p>
          </div>
        )}
      </section>
    );
  } else {
    return null;
  }
};

const mapStateToProps = ({ profile }: IApplicationState) => ({
  profile: profile.profile
});

export default connect(mapStateToProps)(withAITracking(telemetryService.aiReactPlugin, StatusHeader));
