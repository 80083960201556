export const profileFormName = 'profileV2';
export const STRONG_CONSISTENCY_HEADER_NAME = 'nonprofit-scenario-id';
export const STRONG_CONSISTENCY_HEADER_VALUE = 'NonprofitSignup';
export const EligibilityPreCheckScn = 'EligibilityPreCheck';
export const PageRenderingErrorMessage = 'There was an error while rendering the page.';
export const CustomPageView = 'CustomPageView';
export const PageNames = {
  GettingStarted: 'Getting Started'
};
export const InProgressIconGlyph = "glyph-prepend-recent";
export const InProgressIconGlyphColor = "#FF9349";
