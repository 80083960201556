import * as React from "react";
import Text from "src/components/text/Text";
import "./AccountGuardPolicyDisclaimer.scss";

const AccountGuardPolicyDisclaimer = () => {
  return (
    <div className="banner card">
      <div className="material-surface account-guard-policy-disclaimer">
        <blockquote className="">
          <p className="account-guard-policy-disclaimer-text">
            <Text category="AccountGuardV2" id={"Disclaimer1"} />
          </p>
          <p className="account-guard-policy-disclaimer-text">
            <Text category="AccountGuardV2" id={"Disclaimer2"} />
          </p>
          <p className="account-guard-policy-disclaimer-text">
            <Text category="AccountGuardV2" id={"Disclaimer3"} />
          </p>
        </blockquote>
      </div>
    </div>
  );
};
export default AccountGuardPolicyDisclaimer;
