import { Action, AnyAction, Dispatch } from "redux";
import { all, fork } from "redux-saga/effects";
import agentVerificationSaga from "./agent-verification/sagas";
import { AgentVerificationState } from "./agent-verification/types";
import authSaga from "./auth/sagas";
import { AuthState } from "./auth/types";
import ContactUsSaga from "./contactus/sagas";
import { IContactUsState } from "./contactus/types";
import { WcpCookieConsentState } from "./cookie-consent/types";
import { IFeatureFlagsState } from "./feature-flags";
import featureFlagsSaga from "./feature-flags/sagas";
import { LayoutState } from "./layout/types";
import LocalizationSaga from "./localization/sagas";
import { ILocalizationState } from "./localization/types";
import { INonprofitState } from "./nonprofit";
import nonprofitSaga from "./nonprofit/sagas";
import offersSaga from "./offers/sagas";
import { IOffersState } from "./offers/types";
import profileSaga from "./profile/sagas";
import { IProfileState } from "./profile/types";
import querySaga from "./query/sagas";
import { IQueryState } from "./query/types";
import { MeoLocaleState } from "./meo-localization/types";
import meoLocalizationSaga from "./meo-localization/sagas";

// The top-level application state object
export interface IApplicationState {
  locale: ILocalizationState;
  layout: LayoutState;
  auth: AuthState;
  nonprofit: INonprofitState;
  profile: IProfileState;
  offers: IOffersState;
  wcpCookieConsent: WcpCookieConsentState;
  agentVerification: AgentVerificationState;
  form: any;
  flags: IFeatureFlagsState;
  contactUs: IContactUsState;
  query: IQueryState;
  meoLocale: MeoLocaleState;
}

// Additional props for connected React components. This prop is passed by default with `connect()`
export interface IConnectedReduxProps<A extends Action = AnyAction> {
  dispatch: Dispatch<A>;
}

// Here we use `redux-saga` to trigger actions asynchronously. `redux-saga` uses something called a
// "generator function", which you can read about here:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/function*
export function* rootSaga() {
  yield all([
    fork(LocalizationSaga),
    fork(agentVerificationSaga),
    fork(authSaga),
    fork(nonprofitSaga),
    fork(offersSaga),
    fork(profileSaga),
    fork(featureFlagsSaga),
    fork(ContactUsSaga),
    fork(querySaga),
    fork(meoLocalizationSaga)
  ]);
}
