import { connect } from 'react-redux';
import { getHomePageStatusBanner, HomePageStatus } from 'src/components/homev2/HomePageStatusBannerProvider';
import OurProductsFeatureCards from 'src/components/homev2/OurProductsFeatureCards';
import TechnologySolutionsBanner from 'src/components/homev2/TechnologySolutionsBanner';
import { ContactUsSection } from 'src/components/offers/shared/ContactUsSection';
import StandardTrainingSection from 'src/components/offers/shared/StandardTrainingSection';
import localeService from 'src/dataServices/LocaleService';
import { IApplicationState } from 'src/state';
import { INonprofitMiniProfile, NonprofitRequestStatus } from 'src/state/nonprofit';
import EmailVerification from '../verification/EmailVerification';
import { isEmailVerificationRequired } from '../verification/Utils';
import { NewForYouCarousel } from './NewForYouCarousel';

interface IPropsFromState {
  nonprofit: INonprofitMiniProfile;
  emailVerificationRequired: boolean;
  tradeScreenEnabled: boolean;
}

export type IStatusV2Props = IPropsFromState;

export const StatusV2 = (props: IStatusV2Props) => {
  const { nonprofit, emailVerificationRequired, tradeScreenEnabled } = props;
  let homeContent;

  if (!nonprofit || !nonprofit.validationRequest || !nonprofit.validationRequest.requestStatus) {
    homeContent = null;
  } else if (
    nonprofit.validationRequest.requestStatus == NonprofitRequestStatus.Approved &&
    nonprofit.pendingDeclineDate
  ) {
    const ineligibilityDate = localeService.getDateString(nonprofit.pendingDeclineDate.toString());
    homeContent = getHomePageStatusBanner(HomePageStatus.RequestNotEligible, { ineligibilityDate: ineligibilityDate });
  } else {
    homeContent = {
      [NonprofitRequestStatus.Approved]: (
        <div>
          <TechnologySolutionsBanner />
          <OurProductsFeatureCards />
          <NewForYouCarousel />
          <StandardTrainingSection />
          <ContactUsSection />
        </div>
      ),
      [NonprofitRequestStatus.Declined]: getHomePageStatusBanner(HomePageStatus.RequestDeclined),
      [NonprofitRequestStatus.InProgress]: getHomePageStatusBanner(HomePageStatus.RequestInProgress, {
        tradeScreenEnabled: tradeScreenEnabled ? 'true' : 'false',
        nonprofit: JSON.stringify(nonprofit)
      }),
      [NonprofitRequestStatus.ExpiredActionRequired]: getHomePageStatusBanner(HomePageStatus.RequestClosed),
      [NonprofitRequestStatus.InProgressActionRequired]: getHomePageStatusBanner(HomePageStatus.RequestActionNeeded),
      [NonprofitRequestStatus.NotAvailable]: <></>
    }[nonprofit.validationRequest.requestStatus];
  }
  return (
    <div id="home-page-v2-status">
      {homeContent}
      {emailVerificationRequired && <EmailVerification />}
    </div>
  );
};

const mapStateToProps = ({ nonprofit, agentVerification, flags }: IApplicationState) => ({
  nonprofit: nonprofit.miniProfile,
  emailVerificationRequired: isEmailVerificationRequired(nonprofit, agentVerification),
  tradeScreenEnabled: flags.TSEnabled
});

export default connect(mapStateToProps)(StatusV2);
