import * as React from "react";
import Text from "src/components/text/Text";

const BingAdsDisclaimer = () => (
  <div id="bing-ads-disclaimer" className="pricing-disclaimer">
    <p className="mb-4 pricing-disclarimer-1">
      <Text category="Disclaimer" id={"Disclaimer1"} />
    </p>
  </div>
);

export default BingAdsDisclaimer;
