import * as React from "react";
import { connect } from "react-redux";
import ResizableImage from "src/components/moray/ResizeableImage";
import localeService from "src/dataServices/LocaleService";
import { IApplicationState } from "src/state";
import "./PartnersBanner.scss";

export const PartnersBanner = () => (
  <section className="p-md-5">
    <div className="card d-block mx-ng mx-md-0">
      <div className="row no-gutters material-surface">
        <div className="d-flex order-md-first col-md text-white bg-dark-blue">
          <div className="card-body align-self-center p-4 p-md-5">
            <h2 id="PartnersBannerTitle" className="h2">
              {localeService.getText('PartnersBanner', 'Title')}
            </h2>
            <div className="my-3">
              <p id="PartnersBannerDescription" className="partner-description">
                {localeService.getText('PartnersBanner', 'Description')}
              </p>
            </div>
            <button
              id="PartnersBannerButton"
              className="btn btn-primary"
              onClick={() => {
                const v2Link = '/offers/partners';
                const link = v2Link;
                window.open(link, '_blank');
              }}
            >
              {localeService.getText('PartnersBanner', 'Button')}
            </button>
          </div>
        </div>
        <div className="col-md-6">
          <ResizableImage
            id="PartnersBannerImage"
            root={process.env.REACT_APP_HUB_IMAGES_CDN_URL}
            imageSrc={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/partners-540.png`}
            imageClassName="img-object-cover"
            imageAlt={localeService.getText('PartnersBanner', 'Alt')}
            fileSize1920={'partners-1920.png'}
            fileSize1400={'partners-1400.png'}
            fileSize1084={'partners-1084.png'}
            fileSize860={'partners-860.png'}
            fileSize540={'partners-540.png'}
            fileSize360={'partners-360.png'}
          />
        </div>
      </div>
    </div>
  </section>
);

const mapStateToProps = ({ flags }: IApplicationState) => ({
  featureFlags: flags
});
export default connect(mapStateToProps)(PartnersBanner);
