import { getLocalizedPath } from "src/components/utilities/Localization/Utils";
import localeService from "src/dataServices/LocaleService";
import StepCard from "./StepCard";
import TrainingSection from "./TrainingSection";

interface IStandardTrainingSectionProps {
  setRef?: (el: HTMLDivElement | null) => void;
}

export default function StandardTrainingSection(props: IStandardTrainingSectionProps) {
  return (
    <div
      id="trainingSection"
      className="trainingSection"
      ref={el => {
        if (props.setRef !== undefined) {
          props.setRef(el);
        }
      }}
    >
      <TrainingSection
        sectionHeader={localeService.getText("TrainingSection", "SectionHeader")}
        sectionContent={localeService.getText("TrainingSection", "SectionContent")}
        ctaLink={getLocalizedPath("/offers/training")}
        sectionBackgroundColor={"#f2f2f2"}
        stepCard1={
          <StepCard
            stepIconImagePath="hub-redesign/training-guidedTours@3x.png"
            cardTitle="GuidedToursStepTitle"
            cardDescription="GuidedToursStepDescription"
            category="TrainingSection"
            iconBackgroundColor="#243a5e"
          />
        }
        stepCard2={
          <StepCard
            stepIconImagePath="hub-redesign/training-nonprofitTraining@3x.png"
            cardTitle="NonprofitTrainingStepTitle"
            cardDescription="NonprofitTrainingStepDescription"
            category="TrainingSection"
            iconBackgroundColor="#243a5e"
          />
        }
        stepCard3={
          <StepCard
            stepIconImagePath="hub-redesign/training-moreResources@3x.png"
            cardTitle="MoreResourcesStepTitle"
            cardDescription="MoreResourcesStepDescription"
            category="TrainingSection"
            iconBackgroundColor="#243a5e"
          />
        }
      />
    </div>
  );
}
