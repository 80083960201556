import { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import TextInput from "src/components/shared/TextInput";
import localeService from "src/dataServices/LocaleService";
import * as offerActions from "src/state/offers/shared/actions";
import { IEmailOfferRequestSubmissionForm, IOfferRequestState } from "src/state/offers/shared/types";
import MoraySpinnerSmall from "../shared/MoraySpinnerSmall";

interface IPropsFromState {
  offers: IOfferRequestState;
}

interface IPropsFromDispatch {
  offersRequest: typeof offerActions.offersRequest;
}

export type OfferRequestProps = IPropsFromState & IPropsFromDispatch;

interface ErrorMessageProps {
  message: string;
}

export const ErrorMessage = ({ message }: ErrorMessageProps) => (
  <div className="text-red" id="emailAddress-feedback">
    <p id="HardwareErrorMessage" className="glyph-prepend glyph-prepend-warning red">
      {message}
    </p>
  </div>
);

export const OfferRequestForm = ({ offers, offersRequest }: OfferRequestProps) => {
  const isMultiEmailValid = (value: string) =>
    value && /^(\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+(;\s*)?)*$/.test(value);

  const validateEmailAddress = (event: any) => {
    event.preventDefault();
    const customerNotificationEmails = event.target.emailAddress.value;

    if (isMultiEmailValid(customerNotificationEmails)) {
      offersRequest({
        customerNotificationEmails,
        type: "Hardware"
      });
      return;
    } else {
      return;
    }
  };

  useEffect(() => {
    offers.failedToSend = false;
  }, []);

  return (
    <form className="hardware-request-form" id="OfferRequestForm" onSubmit={validateEmailAddress}>
      <div className="form-row align-middle">
        <div className="col-md mb-2">
          <TextInput
            id="emailAddress"
            label={localeService.getText("Microsoft365V2", "HardwareEmailLabel")}
            placeholder={localeService.getText("Microsoft365V2", "HardwareEnterEmailPlaceholder")}
            description={localeService.getText("Microsoft365V2", "HardwareDescription")}
            validationRules={[
              {
                validationRule: (text: string) => {
                  return isMultiEmailValid(text) ? !offers.failedToSend : false;
                },
                validationText: localeService.getText("Microsoft365V2", "HardwareInvalidEmailError")
              },
              {
                validationRule: (text: string) => {
                  return !offers.failedToSend;
                },
                validationText: localeService.getText("Microsoft365V2", "HardwareTryAgainError")
              }
            ]}
          />
        </div>
        <div className="mb-2 ml-1 hardware-submit-container">
          {offers.isSending ? (
            <MoraySpinnerSmall />
          ) : (
            <button id="HardwareSubmitRequest" className="hardware-submit-button btn btn-primary">
              {localeService.getText("Microsoft365V2", "HardwareSubmitRequest")}
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

const mapDispatchToProps: (dispatch: Dispatch) => IPropsFromDispatch = dispatch => ({
  offersRequest: (formValues: IEmailOfferRequestSubmissionForm) => dispatch(offerActions.offersRequest(formValues))
});

export default connect(
  null,
  mapDispatchToProps
)(OfferRequestForm);
