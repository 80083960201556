import { IApiResponse } from "src/common/ApiResponse";
import { IEmailOfferRequestSubmissionForm } from "src/state/offers/shared/types";
import { callApi } from "./SharedServiceFunctions";

export class HardwareOfferService {
  private API_URL = process.env.REACT_APP_INTERNAL_API_BASEURL;

  public async initiateRequest(formValues: IEmailOfferRequestSubmissionForm): Promise<IApiResponse<{}>> {
    const response = await callApi<{}>(`${this.API_URL}/nonprofitoffers/hardware`, JSON.stringify(formValues), "POST");
    return response;
  }
}

const hardwareOfferService = new HardwareOfferService();
export default hardwareOfferService;
