import { Spinner } from "@fluentui/react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IApplicationState } from "src/state";
import { initiateLogin } from "src/state/auth";

interface IPropsFromState {
    isSigningIn: boolean;
}

interface IPropsFromDispatch {
    initiateLogin: typeof initiateLogin;
}

type SignUpCompleteProps = IPropsFromDispatch & IPropsFromState;

const SignupCompletePage = ({ isSigningIn, initiateLogin }: SignUpCompleteProps) => {
    sessionStorage.setItem("IsSignupRedirection", "true");
    initiateLogin();

    return <Spinner />
}

const mapStateToProps = ({ auth }: IApplicationState): IPropsFromState => ({
    isSigningIn: auth.isSigningIn
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    initiateLogin: () => dispatch(initiateLogin())
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupCompletePage);