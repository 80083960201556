import { AzureExpirationStatus } from "src/state/offers/azure/types";

/**
 * Gets the expiration status based on the end date provided. An Azure registration is considered expiring if the end date is less than 31 days from today.
 * An Expired azure registration will have end date in the past. Any end date not meeting the Expired or Expiring criteria is Not Expiring.
 * @param endDate The end date of the azure registration.
 * @returns The {AzureExpirationStatus} of the azure registration.
 */
export function getAzureExpirationStatus(endDate: string): AzureExpirationStatus {
  const today = new Date();
  const todayPlus31 = new Date();
  todayPlus31.setDate(today.getDate() + 31);

  const isExpiring = todayPlus31 > new Date(endDate);
  const isExpired = today > new Date(endDate);

  if (isExpired) {
    return AzureExpirationStatus.Expired;
  } else if (isExpiring) {
    return AzureExpirationStatus.Expiring;
  }

  return AzureExpirationStatus.NotExpiring;
}

export function getRemainingDaysForAzureExpiration(endDate: string): number {
  const oneDay = 1000 * 60 * 60 * 24;
  const dateDiffInTime = new Date(endDate).getTime() - new Date().getTime();
  return Math.round(dateDiffInTime / oneDay);
}
