import * as React from "react";
import localeService from "src/dataServices/LocaleService";
import FeatureCard from "../shared/FeatureCard";

const WhyMicrosoftFeatureCards = () => {
  return (
    <section className="mx-5 my-5">
      <div className="text-center">
        <h2 className="h2">{localeService.getText("PartnersV2", "WhyMicrosoftHeading")}</h2>
      </div>
      <FeatureCard
        title={localeService.getText("PartnersV2", "WhyMicrosoftTitle1")}
        description={localeService.getText("PartnersV2", "WhyMicrosoftDescription1")}
        reverseTextAlign={true}
        imageProps={{
          id: "PartnersBannerImage",
          root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/Partners`,
          imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/Partners/why-microsoft-partner-1.png`,
          imageClassName: "card-img",
          imageAlt: localeService.getText("PartnersV2", "WhyMicrosoftImageAlt1")
        }}
      />
      <FeatureCard
        title={localeService.getText("PartnersV2", "WhyMicrosoftTitle2")}
        description={localeService.getText("PartnersV2", "WhyMicrosoftDescription2")}
        imageProps={{
          id: "PartnersBannerImage",
          root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/Partners`,
          imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/Partners/why-microsoft-partner-2.png`,
          imageClassName: "card-img",
          imageAlt: localeService.getText("PartnersV2", "WhyMicrosoftImageAlt2")
        }}
      />
      <FeatureCard
        title={localeService.getText("PartnersV2", "WhyMicrosoftTitle3")}
        description={localeService.getText("PartnersV2", "WhyMicrosoftDescription3")}
        reverseTextAlign={true}
        imageProps={{
          id: "PartnersBannerImage",
          root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/Partners`,
          imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/Partners/why-microsoft-partner-3.png`,
          imageClassName: "card-img",
          imageAlt: localeService.getText("PartnersV2", "WhyMicrosoftImageAlt3")
        }}
      />
    </section>
  );
};

export default WhyMicrosoftFeatureCards;
