import "@ms-mwf/moray/dist/css/main.css";
import * as React from "react";
import localeService from "src/dataServices/LocaleService";
import "./Over300Employees.scss";

export function Over300Employees() {
  return (
    <div id="over-300-employees" className="over-300-employees material-surface" data-mount="click-group">
      <h2 className="over-300-employees-title mb-3 h2">
        {localeService.getText("Microsoft365V2", "Over300EmployeesTitle")}
      </h2>
      <p id="over-300-employees-info" className="over-300-employees-content-style">
        {localeService.getText("Microsoft365V2", "Over300EmployeesInfo")}
      </p>
      <a
        id="over-300-employees-button"
        href={localeService.getText("Microsoft365V2", "Over300EmployeesUrl")}
        className="cta"
        target="_blank"
        rel="noopener noreferrer"
      >
        {localeService.getText("Microsoft365V2", "Over300EmployeesButton")}
        <span className="glyph" aria-hidden="true"></span>
      </a>
    </div>
  );
}
