import * as React from "react";
import localeService from "src/dataServices/LocaleService";
import "./Banner.scss";

const Banner = () => {
  return (
    <section
      id="registration-complete-banner"
      style={{
        background: `linear-gradient(90deg, rgba(35, 57, 95, 1) 35%, rgba(255, 255, 255, 0) 90%),url(${
          process.env.REACT_APP_IMAGES_URL
        }/registration-complete/registration-complete.jpg)`,
        backgroundSize: "cover"
      }}
      aria-label={localeService.getText("RegistrationComplete", "Title")}
      className="ms-Grid-row ms-sm12 ms-lg12"
    >
      <section id="banner-region">
        <h1 id="banner-title" className="ms-Grid-row ms-sm6 ms-lg5 ms-md6">
          {localeService.getText("RegistrationComplete", "Title")}
        </h1>
        <div id="banner-text" className="ms-Grid-row ms-Grid-col ms-sm6 ms-lg4 ms-md6">
          <p>{localeService.getText("RegistrationComplete", "Text1")}</p>
          <p>{localeService.getText("RegistrationComplete", "Text2")}</p>
        </div>
      </section>
    </section>
  );
};

export default Banner;
