import * as React from "react";
import Text from "src/components/text/Text";
import TextLink from "src/components/text/TextLink";

const TenantNotReady: React.FunctionComponent = () => {
  return (
    <div className="tenant-not-ready-error">
      <h2>
        <Text category="ErrorTenantNotReady" id="Header" />
      </h2>
      <TextLink category="ErrorTenantNotReady" id="SubText" href="/signin /" />
    </div>
  );
};

export default TenantNotReady;
