import * as React from 'react';
import { connect } from 'react-redux';
import Text from 'src/components/text/Text';
import TextLink from 'src/components/text/TextLink';
import localeService from 'src/dataServices/LocaleService';
import { IApplicationState } from 'src/state';

export interface IContextProps {
  values: { [key: string]: string };
}

const NotAdmin: React.FunctionComponent<IContextProps> = (props) => {
  const values = props.values;

  return (
    <div className="not-admin-error">
      <h1>
        <Text category="ErrorNotAdminV2" id="Header" />
      </h1>
      <br />
      <p>
        <Text category="ErrorNotAdminV2" id="Body" />
      </p>
      <p>
        <Text category="ErrorNotAdminV2" id="SubHeader" />
      </p>
      <ol>
        <li>
          <Text category="ErrorNotAdminV2" id="SubText1" />
        </li>
        <li>
          <TextLink category="ErrorNotAdminV2" id="SubText2" href="AssignAdminRoles" target="_blank" />
        </li>
        <li>
          <TextLink category="ErrorNotAdminV2" id="SubText3" href="FindAdmin" target="_blank" />
        </li>
      </ol>
      <p>
        <TextLink category="ErrorNotAdminV2" id="SupportText" href="ContactUsNoLocale" target="_blank" />
      </p>
      {/* note: these are object values and thus don't need to be localized */}
      <ul>
        <li>Puid: {values.Puid}</li>
        <li>TenantId: {values.TenantId}</li>
        <li>ObjectId: {values.ObjectId}</li>
        <li>SessionId: {values.SessionId}</li>
        <li>Locale: {values.Locale}</li>
      </ul>
      <p>
        <a
          href={localeService.getText('Urls', 'AboutAdminRoles')}
          className="cta"
          aria-label={localeService.getText('ErrorNotAdminV2', 'MoreInfo')}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Text category="ErrorNotAdminV2" id="MoreInfo" />
          <span className="glyph" aria-hidden="true"></span>
        </a>
      </p>
    </div>
  );
};

const mapStateToProps = ({ layout }: IApplicationState) => ({
  values: layout.appContext
});

export default connect(mapStateToProps)(NotAdmin);
