import { Reducer } from "redux";
import { telemetryService } from "npo-common";
import { ILocalizationState, LocalizationActionTypes } from "./types";

export const initialState: ILocalizationState = {
  isLoading: false,
  isSuccessfulRetrival: undefined,
  currentLocale: '',
  defaultAppLanguage: "en-US"
};

const reducer: Reducer<ILocalizationState> = (state = initialState, action) => {
  switch (action.type) {
    case LocalizationActionTypes.LOCALIZATION_INIT_REQUEST: {
      return { ...state, isLoading: true, isSuccessfulRetrival: undefined };
    }
    case LocalizationActionTypes.ADD_TO_DICTIONARY_SUCCESS: {
      const locale = action.payload as string;
      telemetryService.setContext({ Locale: locale });
      return {
        ...state,
        currentLocale: locale,
        isSuccessfulRetrival: true,
        isLoading: false
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as localizationReducer };

