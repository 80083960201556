import { isConsentProvided } from "../cookie-consent/WcpCookieConsent";

const WcpConsent = window.WcpConsent;

export abstract class MarketingCookieManager {
  /**
   * Cleans up any marketing cookies which should not be present based on consent state.
   */
  public static cleanupMarketingCookies(): void {
    const hasAnalyticsConsent = isConsentProvided(WcpConsent.consentCategories.Analytics);
    const hasAdvertisingConsent = isConsentProvided(WcpConsent.consentCategories.Advertising);

    if (!hasAnalyticsConsent || !hasAdvertisingConsent) {
      this.cleanupCookie("_gcl_au", "/", ".microsoft.com"); // google tag manager
      this.cleanupCookie("_clck", "/", ".microsoft.com"); // clarity
      this.cleanupCookie("_clsk", "/", ".microsoft.com"); // clarity
    }

    if (!hasAdvertisingConsent) {
      this.cleanupCookie("IDE", "/", ".doubleclick.net"); // google tag manager
    }
  }

  private static cleanupCookie(name: string, path = "", domain = "") {
    if (this.hasCookie(name)) {
      console.debug(`Removing cookie '${name}' due to lack of consent.`);
      this.deleteCookie(name, path, domain);
    }
  }

  private static hasCookie = (name: string) => {
    console.debug(document.cookie);
    return document.cookie.split(";").some(c => {
      return c.trim().startsWith(name + "=");
    });
  };

  private static deleteCookie = (name: string, path = "", domain = "") => {
    document.cookie =
      name +
      "=" +
      (path ? ";path=" + path : "") +
      (domain ? ";domain=" + domain : "") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  };
}
