import * as React from "react";
import localeService from "src/dataServices/LocaleService";
import NavigationCardContent from "../../../navigation-cards/NavigationCardContent";

const ProductDemosD365Card = () => {
  return (
    <NavigationCardContent
      imgSrc={`${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/Training/MSC17_asa_006.jpg`}
      imgAlt={localeService.getText("TrainingV2", "ProductDemosD365Title")}
      title={localeService.getText("TrainingV2", "ProductDemosD365Title")}
      ctaText={localeService.getText("TrainingV2", "ProductDemosD365CtaText")}
      ctaLink={localeService.getText("TrainingV2", "ProductDemosD365CtaLink")}
    >
      <p>{`${localeService.getText("TrainingV2", "ProductDemosD365Description")}`}</p>
    </NavigationCardContent>
  );
};

export default ProductDemosD365Card;
