/* eslint-disable jsx-a11y/anchor-is-valid */
import "@ms-mwf/moray/dist/css/main.css";
import localeService from "src/dataServices/LocaleService";
import { scrollOptions } from "src/common/utilities/Utilities";
import "./NavigationBar.scss";

interface NavigationBarProps {
  offersTitle?: string;
  offersElement?: HTMLElement | null;
  trainingTitle?: string;
  trainingElement?: HTMLElement | null;
  stepsElement?: HTMLElement | null;
  isShowButton?: boolean; // DEPRECATED
}
const NavigationBar = (props: NavigationBarProps) => {
  const offersLink = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    props.offersElement !== null && props.offersElement?.scrollIntoView(scrollOptions);
  };
  const stepsLink = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    props.stepsElement !== null && props.stepsElement?.scrollIntoView(scrollOptions);
  };
  const trainingLink = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    props.trainingElement !== null && props.trainingElement?.scrollIntoView(scrollOptions);
  };

  return (
    <div id="navigation-bar" className="material-surface p-3 text-center navigation-bar">
      <ul className="nav">
        <li id="navigation-bar-features" className="nav-item">
          <button className="nav-link navigation-bar-content" onClick={offersLink}>
            {props.offersTitle ? props.offersTitle : localeService.getText("Shared", "CurrentGrants")}
          </button>
        </li>
        <li id="navigation-bar-training" className="nav-item">
          <button className="nav-link navigation-bar-content" onClick={trainingLink}>
            {props.trainingTitle ? props.trainingTitle : localeService.getText("Shared", "DigitalTraining")}
          </button>
        </li>
        {props.stepsElement !== null && props.stepsElement !== undefined && (
          <li className="ml-auto ">
            <button className="btn btn-primary " onClick={stepsLink}>
              {localeService.getText("Shared", "ViewPlans")}
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default NavigationBar;
