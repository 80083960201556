import * as React from "react";
import FollowUsFooter from "src/components/followUsFooter/FollowUsFooter";
import OnPremiseSoftwareLicenses from "./OnPremiseSoftwareLicenses";
import PartnersSteps from "./PartnersSteps";
import "./PartnersV2.scss";
import PartnersV2TopBanner from "./PartnersV2TopBanner";
import WhyMicrosoftFeatureCards from "./WhyMicrosoftFeatureCards";

export const PartnersV2 = () => (
  <section>
    <PartnersV2TopBanner />
    <WhyMicrosoftFeatureCards />
    <PartnersSteps />
    <OnPremiseSoftwareLicenses />
    <FollowUsFooter />
  </section>
);

export default PartnersV2;
