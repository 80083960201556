import { Icon, ITextFieldStyleProps, ITextFieldStyles, Stack, Text, TextField } from "@fluentui/react";
import htmlParser from "html-react-parser";
import "./TextInput.scss";

export interface ITextInputValidation {
  validationRule: (text: string) => boolean;
  validationText?: string;
}

export interface ITextInputProps {
  id: string;
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  description?: string;
  required?: boolean;
  validationRules?: ITextInputValidation[];
}

const TextInput = (props: ITextInputProps) => {
  const { id, label, placeholder, defaultValue, description, required, validationRules } = props;

  const onRenderLabel = () => {
    if (label === undefined) {
      return null;
    } else {
      return (
        <label>
          {required ? (
            <>
              <span aria-hidden="true">*</span> {label}
            </>
          ) : (
            <>{label}</>
          )}
        </label>
      );
    }
  };

  const onRenderDescription = () => {
    if (description === undefined) {
      return null;
    } else {
      return <p className="small text-gray-600 mb-0">{htmlParser(description)}</p>;
    }
  };

  const getRichErrorMessage = (value: string) => {
    if (!validationRules) {
      return ""
    }

    var validationMesages = [];

    for (let i = 0; i < validationRules.length; i++) {
      const errorMessage = validationRules[i].validationText;

      if (!validationRules[i].validationRule(value)) {
        validationMesages.push(
          <Stack verticalAlign="center" horizontal>
            <Icon className="validation-icon" iconName="Warning" />
            <Text className="validation-text">{errorMessage}</Text>
          </Stack>
        );
      }
    }

    if (validationMesages.length > 0) return <>{validationMesages}</>;
    else return "";
  };

  return (
    <>
      <TextField
        className="text-input"
        aria-required={required}
        styles={(props: ITextFieldStyleProps) => getStyles(props, description)}
        id={id}
        label={label}
        placeholder={placeholder}
        defaultValue={defaultValue}
        description={description}
        onRenderLabel={onRenderLabel}
        onRenderDescription={onRenderDescription}
        onGetErrorMessage={getRichErrorMessage}
        validateOnLoad={false}
        validateOnFocusOut
      />
      <div className="invalid-feedback" id="input-firstname-feedback" />
    </>
  );
};

function getStyles(props: ITextFieldStyleProps, description: string | undefined): Partial<ITextFieldStyles> {
  const { hasErrorMessage } = props;

  return {
    fieldGroup: [
      { height: 40 },
      hasErrorMessage
        ? {
            border: "2px solid #E81123 !important",
            marginBottom: "0px"
          }
        : {
            marginBottom: "29px"
          },
      description !== undefined && { marginBottom: "0px" }
    ]
  };
}

TextInput.defaultProps = {
  placeholder: "",
  defaultValue: "",
  validationRules: [],
  required: false
};

export default TextInput;
