import "@ms-mwf/moray/dist/css/main.css";
import Text from "src/components/text/Text";
import "./ActionHeadingBanner.scss";

export interface IActionHeadingBanner {
  headingCatalog: string;
  headingText: string;
  setRef?: (el: HTMLDivElement | null) => void;
}

export function ActionHeadingBanner(props: IActionHeadingBanner) {
  return (
    <div
      className="cloud-steps"
      ref={el => {
        if (props.setRef !== undefined) {
          props.setRef(el);
        }
      }}
    >
      <div className="container-style card align-items-center justify-content-center">
        <div className="text-box-style d-flex align-items-center justify-content-center">
          <h2 id="title" className="text-style h3 mb-0 text-center">
            <Text category={props.headingCatalog} id={props.headingText} />
          </h2>
        </div>
      </div>
    </div>
  );
}
