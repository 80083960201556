import { apply, fork, put, takeEvery } from "redux-saga/effects";
import { IApiResponse } from "src/common/ApiResponse";
import queryService from "src/dataServices/QueryService";
import { ActionType } from "typesafe-actions";
import { submitAlchemyQuery, submitQueryError, submitQuerySuccess } from "./actions";
import { IQueryResponse, QueryActionTypes } from "./types";

export function* handleSubmitQuery(action: ActionType<typeof submitAlchemyQuery>) {
  try {
    const response: IApiResponse<IQueryResponse> = yield apply(queryService, queryService.SubmitQueryRequest, [
      action.payload
    ]);
    if (response.status === 200) {
      yield put(submitQuerySuccess(response.value));
    } else {
      console.error("Error occured while submitting query");
      yield put(submitQueryError(response.error));
    }
  } catch (error) {
    console.error("Error occured while submitting query");
    yield put(submitQueryError(error));
  }
}
function* watchSubmitQuery() {
  yield takeEvery(QueryActionTypes.QUERY_SUBMITREQUEST, handleSubmitQuery);
}

function* QuerySaga() {
  yield fork(watchSubmitQuery);
}

export default QuerySaga;
