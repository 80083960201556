import * as React from "react";
import Text from "src/components/text/Text";
import TextLink from "src/components/text/TextLink";

export function AzureSpecialOffer() {
  return (
    <section id="azure-special-offer">
      <p>
        <Text category="AzureSpecialOffer" id="Detail1" />
      </p>
      <p>
        <Text category="AzureSpecialOffer" id="Detail2" />
      </p>
      <p>
        <TextLink category="AzureSpecialOffer" id="Detail3" href="Instructions" target="_blank" />
      </p>
    </section>
  );
}
