import { withAITracking } from "@microsoft/applicationinsights-react-js";
import * as React from "react";
import FollowUsFooter from "src/components/followUsFooter/FollowUsFooter";
import HomePageTopBanner from "src/components/homev2/HomePageTopBanner";
import StatusV2 from "src/components/homev2/StatusV2";
import { telemetryService } from "npo-common";

export const HomePageV2 = () => {
  return (
    <section id="home-page">
      <HomePageTopBanner />
      <StatusV2 />
      <FollowUsFooter />
    </section>
  );
};

export default withAITracking(telemetryService.aiReactPlugin, HomePageV2);
