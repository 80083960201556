import "./CenteredTextSection.scss";

interface ICenteredTextProps {
  title?: string;
  content?: string;
}

const CenteredTextSection = (props: ICenteredTextProps) => (
  <section className="centered-text-section ms-Grid-row ms-sm12 ms-lg12">
    <div className="centered-heading">{props.title}</div>
    <div className="centered-content">{props.content}</div>
  </section>
);

export default CenteredTextSection;
