import { Stack } from "@fluentui/react";
import * as React from "react";
import CenteredTextSection from "src/components/getting-started/new/CenteredTextSection";
import Banner from "src/components/registration-complete/Banner";
import Disclaimer from "src/components/registration-complete/Disclaimer";
import SocialSection from "src/components/registration-complete/SocialSection";
import StatusCardSection from "src/components/registration-complete/StatusCardSection";
import LoadingButton from "src/components/utilities/LoadingButton";
import localeService from "src/dataServices/LocaleService";
import StepCard from "../../components/step-card/StepCard";
import "./RegistrationCompletePage.scss";
const imagesUrl = process.env.REACT_APP_IMAGES_URL;

export class RegistrationCompletePage extends React.Component {
  public render() {
    const StepCard1Content = (
      <div className="step-card-content">
        <p>{localeService.getText("RegistrationComplete", "Text1")}</p>
      </div>
    );

    const StepCard2Content = (
      <div className="step-card-content">
        <p>{localeService.getText("StepCards", "Step2CardText1")}</p>
        <p>
          {localeService.getText("StepCards", "Step2CardText3Part1")}
          <a
            href={localeService.getText("Urls", "TrainingCourses")}
            title={localeService.getText("StepCards", "ResourcesTitle")}
            target="_blank"
            rel="noopener noreferrer"
          >
            {localeService.getText("StepCards", "Step2CardText3Link")}
          </a>
          {localeService.getText("StepCards", "Step2CardText3Part2")}
        </p>
        <p>{localeService.getText("RegistrationComplete", "StepTwoCardText3")}</p>
      </div>
    );

    const StepCard3Content = (
      <div className="step-card-content">
        <p>{localeService.getText("StepCards", "Step3CardText")}</p>
      </div>
    );
    return (
      <section id="registration-complete-page">
        <Stack>
          <Stack.Item>
            <Banner />
          </Stack.Item>
          <Stack.Item>
            <StatusCardSection />
          </Stack.Item>
          <Stack.Item>
            <section className="ms-Grid-row step-card-section">
              <StepCard
                image={`${imagesUrl}/step-tile-image1.jpg`}
                altText={localeService.getText("StepCards", "Step1CardAltText")}
                title={localeService.getText("StepCards", "Step1Header")}
                selected={false}
                content={StepCard1Content}
              />
              <StepCard
                image={`${imagesUrl}/step-tile-image2.jpg`}
                altText={localeService.getText("StepCards", "Step2CardAltText")}
                title={localeService.getText("StepCards", "Step2Header")}
                selected={true}
                content={StepCard2Content}
              />
              <StepCard
                image={`${imagesUrl}/step-tile-image3.jpg`}
                altText={localeService.getText("StepCards", "Step3CardAltText")}
                title={localeService.getText("StepCards", "Step3Header")}
                selected={false}
                content={StepCard3Content}
              />
            </section>
          </Stack.Item>
          <Stack.Item>
            <div className="elevated-section">
              <CenteredTextSection
                title={localeService.getText("RegistrationComplete", "ContactUsTitle")}
                content={localeService.getText("RegistrationComplete", "ContactUsText1")}
              />
              <div className="button-wrapper">
                <LoadingButton
                  href={localeService.getText("RegistrationComplete", "ContactUsUrl")}
                  target="_blank"
                  isExternal={true}
                  title={localeService.getText("GettingStarted", "ContactUsTitle")}
                  text={localeService.getText("RegistrationComplete", "ContactUsLink")}
                  loading={false}
                  role="link"
                  properties={{ ButtonName: "GettingStarted-ContactUsButton" }}
                  className="inverted-large-button"
                />
              </div>
            </div>
          </Stack.Item>
          <Stack.Item>
            <Disclaimer />
          </Stack.Item>
          <Stack.Item>
            <SocialSection />
          </Stack.Item>
        </Stack>
      </section>
    );
  }
}
