import * as React from "react";
import localeService from "src/dataServices/LocaleService";
import "./Disclaimer.scss";

const Disclaimer = () => (
  <section id="getting-started-disclaimer" className="ms-Grid-col ms-sm-12 ms-lg12">
    <p>{localeService.getText("Disclaimer", "Disclaimer1")}</p>
    <p>
      {localeService.getText("Disclaimer", "Disclaimer2Part1")}
      <a
        title={localeService.getText("GettingStarted", "EligibilityTitle")}
        href={localeService.getText("Urls", "Eligibility")}
        target="_blank"
        rel="noopener noreferrer"
      >
        {localeService.getText("Disclaimer", "Disclaimer2Link")}
      </a>
      {localeService.getText("Disclaimer", "Disclaimer2Part2")}
    </p>
  </section>
);

export default Disclaimer;
