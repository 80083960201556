import { connect } from "react-redux";
import MorayContentCardsSneakPeek, {
  IContentCard,
  MorayCardImageClassName
} from "src/components/moray/MorayContentCardsSneakPeek";
import { IResizableImage } from "src/components/moray/ResizeableImage";
import CarouselOffer from "src/components/offers/shared/CarouselOffer";
import localeService from "src/dataServices/LocaleService";
import { IApplicationState } from "src/state";
import { IFeatureFlagsState } from "src/state/feature-flags";
import useD365CopilotOfferUpdate from './useD365CopilotOfferUpdate';

interface ID365OffersProps {
  setRef?: (el: HTMLDivElement | null) => void;
  featureFlags: IFeatureFlagsState;
}

const D365Offers = (props: ID365OffersProps) => {
  const { isD365CopilotOfferAvailable } = useD365CopilotOfferUpdate();

  const offerOneContents = (
    <CarouselOffer category="Dynamic365V2" descriptions={['OfferOneDescription1', 'OfferOneDescription2']} />
  );

  const copilotOfferContents = (
    <>
      <p className="base mb-4">{localeService.getText('Dynamic365V2', 'CopilotOfferDescription1')}</p>
      <div className="link-group">
        <a
          tabIndex={0}
          href={`${localeService.getText('Dynamic365V2', 'CopilotOfferCtaLink1')}`}
          target="_blank"
          rel="noreferrer"
          className="cta m-0"
        >
          {localeService.getText('Dynamic365V2', 'CopilotOfferCtaText1')}
          <span className="glyph" aria-hidden="true"></span>
        </a>
      </div>
      <br />
      <h4 className="h4 pt-3 mb-3">{localeService.getText('Dynamic365V2', 'CopilotOfferTitle2')}</h4>
      <p className="base mb-4">{localeService.getText('Dynamic365V2', 'CopilotOfferDescription2')}</p>
      <div className="link-group">
        <a
          tabIndex={0}
          href={`${localeService.getText('Dynamic365V2', 'CopilotOfferCtaLink2')}`}
          target="_blank"
          rel="noreferrer"
          className="cta m-0"
        >
          {localeService.getText('Dynamic365V2', 'CopilotOfferCtaText2')}
          <span className="glyph" aria-hidden="true"></span>
        </a>
      </div>
    </>
  );

  const offerOneImageSizes: IResizableImage = {
    root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/D365`,
    imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/D365/D365 - Offer 1 - VP2 540.jpg`,
    imageClassName: MorayCardImageClassName,
    imageAlt: localeService.getText('Dynamic365V2', 'OfferOneImageAlt'),
    fileSize1920: 'D365 - Offer 1 - VP5 1920.jpg',
    fileSize1400: 'D365 - Offer 1 - VP5 1400.jpg',
    fileSize1084: 'D365 - Offer 1 - VP4 1084.jpg',
    fileSize860: 'D365 - Offer 1 - VP3 860.jpg',
    fileSize540: 'D365 - Offer 1 - VP3 540.jpg',
    fileSize360: 'D365 - Offer 1 - VP3 360.jpg'
  };

  const copilotOfferImageSizes: IResizableImage = {
    root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/D365`,
    imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/D365/D365CopilotOffer.jpg`,
    imageClassName: MorayCardImageClassName,
    imageAlt: localeService.getText('Dynamic365V2', 'CopilotOfferImgAlt')
  };

  const updatedOffers: IContentCard[] = [
    {
      title: localeService.getText('Dynamic365V2', 'CopilotOfferTitle1'),
      contents: copilotOfferContents,
      sectionAria: localeService.getText('Dynamic365V2', 'CopilotOfferSectionAria'),
      imgSizes: copilotOfferImageSizes
    },
    {
      title: localeService.getText('Dynamic365V2', 'OfferTitle1'),
      contents: offerOneContents,
      sectionAria: localeService.getText('Dynamic365V2', 'OfferOneSectionAria'),
      imgSizes: offerOneImageSizes,
      actionText: localeService.getText('Dynamic365V2', 'OfferOneActionText'),
      onClickAria: localeService.getText('Dynamic365V2', 'OfferOneActionText'),
      onClickAction: () => {
        window.open(localeService.getText('Urls', 'PowerAppsGrantLink'));
      }
    }
  ];

  if (!isD365CopilotOfferAvailable) {
    updatedOffers.shift(); // Remove the first child if offer is not available
  }

  return (
    <div
      id="offers"
      className="current-offers"
      ref={(el) => {
        if (props.setRef !== undefined) {
          props.setRef(el);
        }
      }}
    >
      <h2 className="title h2">{localeService.getText('Dynamic365V2', 'CurrentOffersTitle')}</h2>
      <MorayContentCardsSneakPeek
        contentCards={updatedOffers}
        ariaLabel={localeService.getText('Dynamic365V2', 'CurrentOffersTitle')}
      />
    </div>
  );
};

const mapStateToProps = ({ flags }: IApplicationState) => ({
  featureFlags: flags
});

export default connect(mapStateToProps)(D365Offers);
