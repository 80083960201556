import * as React from "react";
import StatusBanner, { StatusIcon } from "src/components/offers/shared/StatusBanner";
import "src/components/profilev2/BannerNotification.scss";

interface IBannerNotification {
  type: StatusIcon;
  text?: string;
  children?: React.ReactNode;
}

export default ({ text, type, children }: IBannerNotification) => (
  <div className="banner-notification">
    <StatusBanner text={""} iconType={type}>
      <p className="banner-text">{text}</p>
      {children}
    </StatusBanner>
  </div>
);
