import { Label } from "@fluentui/react";
import Text from "src/components/text/Text";
import { IRegistrationIdentifier } from "src/state/nonprofit/types";

export interface IProfileLegalIdentifierProps {
  registrationIdentifiers: IRegistrationIdentifier[];
}

const RegistrationIdentifiersView = ({ registrationIdentifiers }: IProfileLegalIdentifierProps): JSX.Element => {
  return (
    <>
      {registrationIdentifiers && registrationIdentifiers.length > 0 ? (
        registrationIdentifiers.map((ri, index) => (
          <div className="ms-Grid-row" key={index}>
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 ms-xl6">
              <div className="field">
                <Label tabIndex={0} id="RegulatoryBody">
                  <Text category="ProfileV2" id="RegistrationType" />
                </Label>
              </div>
              <div className="value">
                <span id={`regulatoryBody${index}`} aria-labelledby="RegulatoryBody">
                  <b>{ri.regulatoryBody}</b>
                </span>
              </div>
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl4">
              <div className="field">
                <Label tabIndex={0} id={"field" + `legalIdentifier${index}`}>
                  <Text category="Profile" id="LegalIdentifier" />
                </Label>
              </div>
              <div className="value">
                <span id={`legalIdentifier${index}`} aria-labelledby="LegalIdentifier">
                  <b>{ri.legalIdentifier}</b>
                </span>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12">
            <Text category="Profile" id="NoRegistrationIdentifiers" />
          </div>
        </div>
      )}
    </>
  );
};

export default RegistrationIdentifiersView;
