import { IQueryResponse, IQuerySubmissionFields, QueryActionTypes } from "src/state/query/types";
import { action } from "typesafe-actions";

export const submitAlchemyQuery = (queryPayload: IQuerySubmissionFields) => {
  return action(QueryActionTypes.QUERY_SUBMITREQUEST, queryPayload);
};

export const submitQueryError = (error: any) => {
  return action(QueryActionTypes.QUERY_ERROR, error);
};

export const submitQuerySuccess = (queryResponse: IQueryResponse | undefined) => {
  return action(QueryActionTypes.QUERY_SUCCESS, queryResponse);
};

export const showMoreResults = () => {
  return action(QueryActionTypes.QUERY_SHOW_MORE_RESULTS);
};

export const showFewerResults = () => {
  return action(QueryActionTypes.QUERY_SHOW_FEWER_RESULTS);
};
