import * as React from "react";
import Text from "src/components/text/Text";
import TextLink from "src/components/text/TextLink";

export class ItInfrastructure extends React.Component {
  public render() {
    return (
      <section id="it-infrastructure">
        <p>
          <Text category="EligibilityChanges" id="ItInfrastructure" />
        </p>
        <p>
          <TextLink
            category="EligibilityChanges"
            id="ItInfrastructure_Line1"
            href="PayAsYouGo SupportRequest AzureBilling"
          />
        </p>
        <p>
          <TextLink category="EligibilityChanges" id="ItInfrastructure_Line2" href="NonprofitPortal" />
        </p>
      </section>
    );
  }
}

export default ItInfrastructure;
