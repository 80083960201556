import * as React from "react";
import { connect } from "react-redux";
import navigationService from "src/dataServices/NavigationService";
import { IApplicationState } from "src/state";
import { INonprofitMiniProfile } from "src/state/nonprofit";
import NavigationMenuV2 from "./NavigationMenuV2";

interface IPropsFromState {
  nonprofit: INonprofitMiniProfile;
  currentPath: string;
}

export type INavigationV2Props = IPropsFromState;

export class NavigationV2 extends React.PureComponent<INavigationV2Props> {
  public render() {
    const { nonprofit, currentPath } = this.props;
    const navigationV2Links = navigationService.getNavigationLinksV2(nonprofit);
    return navigationV2Links && navigationV2Links.length > 0 ? (
      <NavigationMenuV2 key={navigationV2Links.length} initialItems={navigationV2Links} currentPath={currentPath} />
    ) : null;
  }
}

const mapStateToProps = ({ nonprofit }: IApplicationState) => ({
  nonprofit: nonprofit.miniProfile,
  currentPath: window.location.pathname
});

export default connect(mapStateToProps)(NavigationV2);
