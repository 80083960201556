import * as React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { IApplicationState } from "src/state";

export interface IBoundaryProps {
  isCheckSatisfied: boolean;
  redirect?: string;
  children: React.ReactNode;
}

export class Boundary extends React.Component<IBoundaryProps> {
  props!: {
    children: any; isCheckSatisfied: any; redirect: any;
  };
  public render() {
    const { isCheckSatisfied, redirect } = this.props;

    if (!isCheckSatisfied) {
      return redirect ? <Redirect to={redirect} push={true} /> : null;
    }

    return this.props.children;
  }
}

interface IBoundaryOwnProps {
  redirect?: string;
}

const authRequiredMapStateToProps = ({ auth }: IApplicationState, ownProps: IBoundaryOwnProps) => ({
  isCheckSatisfied: auth.isAuthenticated,
  redirect: ownProps.redirect
});

const unauthRequiredMapStateToProps = ({ auth }: IApplicationState, ownProps: IBoundaryOwnProps) => ({
  isCheckSatisfied: !auth.isAuthenticated,
  redirect: ownProps.redirect
});

const agentMapStateToProps = ({ auth }: IApplicationState, ownProps: IBoundaryOwnProps) => ({
  isCheckSatisfied: auth.isAgentOkay,
  redirect: ownProps.redirect
});

const hasErrorMapStateToProps = ({ nonprofit }: IApplicationState, ownProps: IBoundaryOwnProps) => ({
  isCheckSatisfied: nonprofit.error ? nonprofit.error.length > 0 : false,
  redirect: ownProps.redirect
});

const hasIncompleteProfile = ({ nonprofit }: IApplicationState, ownProps: IBoundaryOwnProps) => ({
  isCheckSatisfied: nonprofit.miniProfile && !nonprofit.miniProfile.isIncomplete,
  redirect: ownProps.redirect
});

const hasEditableProfile = ({ profile }: IApplicationState, ownProps: IBoundaryOwnProps) => ({
  isCheckSatisfied: profile.profile && profile.profile.isEditable,
  redirect: ownProps.redirect
});

export const AuthRequiredBoundary = connect(authRequiredMapStateToProps)(Boundary);
export const UnauthRequiredBoundary = connect(unauthRequiredMapStateToProps)(Boundary);
export const AgentBoundary = connect(agentMapStateToProps)(Boundary);
export const HasErrorBoundary = connect(hasErrorMapStateToProps)(Boundary);
export const HasIncompleteProfile = connect(hasIncompleteProfile)(Boundary);
export const HasEditableProfile = connect(hasEditableProfile)(Boundary);
