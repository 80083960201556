import { History } from "history";
import * as React from "react";
import { connect } from "react-redux";
import { BrowserRouter, HashRouter, Route } from 'react-router-dom';
import { Dispatch } from "redux";
import ScrollToTop from "src/components/ScrollToTop";
import LocalizedRouter from "src/common/routing/LocalizedRouter";
import { IApplicationState } from "src/state";
import { fetchAgentSettingsRequest } from "src/state/agent-verification/actions";
import { fetchFlags } from "src/state/feature-flags";
import { ILocalizationState } from "src/state/localization/types";
import Routes from "src/common/routing/Routes";
import { initiateLogin, processLoginPromise } from "./state/auth/actions";
import OneDS from "./components/Analytics/OneDS";
import "./App.scss";
import { isLocalizationEnabled, isRedirectionRoute } from "./components/utilities/Localization/Utils";
import { setAppContext } from "./state/layout";
import MarketingTags from "./components/marketingtags/MarketingTags";
import { fetchMeoLocales } from "./state/meo-localization/actions";

interface IStateProps {
  localeState: ILocalizationState;
}

interface IPropsFromDispatch {
  initiateLogin: typeof initiateLogin;
  processLoginPromise: typeof processLoginPromise;
  fetchAgentSettingsRequest: typeof fetchAgentSettingsRequest;
  fetchFlags: typeof fetchFlags;
  fetchMeoLocales: typeof fetchMeoLocales;
  setAppContext: typeof setAppContext;
}

// Any additional component props go here.
interface IOwnProps {
  history: History;
}

// Create an intersection type of the component props and our Redux props.
type AllProps = IStateProps & IPropsFromDispatch & IOwnProps;

class App extends React.Component<AllProps> {
  private localizationEnabled = isLocalizationEnabled();

  constructor(props: any) {
    super(props);
  }

  public componentWillMount() {
    this.props.processLoginPromise();
  }

  public componentDidMount() {
    this.props.fetchAgentSettingsRequest();
    this.props.fetchFlags();
    this.props.fetchMeoLocales();

    const pathname = this.props.history.location.pathname;

    if (isRedirectionRoute(pathname, false)) {
      this.props.initiateLogin();
    }
  }

  public componentWillUpdate(nextProps: { localeState: { currentLocale: string } }) {
    this.props.processLoginPromise();
  }

  public render() {
    const { history } = this.props;
    const { hash } = window.location;
    if (hash.length > 0 && hash.indexOf("#/") === 0) {
      return (
        <HashRouter>
          <Route
            exact
            path="/:path"
            render={props => {
              // legacy url redirect
              document.location.replace(`/${props.match.params.path}`);
              return null;
            }}
          />
        </HashRouter>
      );
    } else {
      return (
        <BrowserRouter>
          <OneDS /> 
          <MarketingTags />
          <ScrollToTop />
          {this.localizationEnabled ? <LocalizedRouter history={history} /> : <Routes history={history} />}
        </BrowserRouter>
      );
    }
  }
}

const mapStateToProps = ({ locale }: IApplicationState) => ({
  localeState: locale
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAgentSettingsRequest: () => dispatch(fetchAgentSettingsRequest()),
  fetchFlags: () => dispatch(fetchFlags()),
  fetchMeoLocales: () => dispatch(fetchMeoLocales()),
  initiateLogin: () => dispatch(initiateLogin()),
  processLoginPromise: () => dispatch(processLoginPromise()),
  setAppContext: (context: { [key: string]: any }) => dispatch(setAppContext(context))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
