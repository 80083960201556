import * as React from "react";
import InfoSection from "../shared/InfoSection";
import localeService from "src/dataServices/LocaleService";

const BingAdsFeatures = () => (
  <>
    {
      <div id="bing-ads-features">
      <InfoSection
        sectionHeaderString={localeService.getText("BingAds", "Features")}
        category="BingAds"
        imagePath1="hub-redesign/BingAds/Features_1.png"
        title1="GrowAwareness"
        description1="GrowAwarenessDetails"
        imagePath2="hub-redesign/BingAds/Features_2.png"
        title2="AttractDonors"
        description2="AttractDonorsDetails"
        imagePath3="hub-redesign/BingAds/Features_3.png"
        title3="AccelerateMission"
        description3="AccelerateMissionDetails"
      />
      </div>
    }
  </>
);

export default BingAdsFeatures;
