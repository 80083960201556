import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as agentVerificationActions from "src/state/agent-verification/actions";
import ResendConfirmation from "./ResendConfirmation";
import "./ResendConfirmationEmail.scss";

interface IPropsFromDispatch {
  resendEmail?: typeof agentVerificationActions.resendVerificationEmail;
}

class ResendConfirmationEmail extends React.Component<IPropsFromDispatch> {
  public render() {
    return (
      <div id="agent-verification-message">
        <ResendConfirmation iconRequired={false} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resendEmail: () => dispatch(agentVerificationActions.resendVerificationEmail())
});

export default connect(
  null,
  mapDispatchToProps
)(ResendConfirmationEmail);
