import { logService } from 'npo-common';
import {
  OfferCard,
  OfferCardButton,
  OfferCardContainer,
  OfferCardFauxColumn,
  OfferCardFeatureHeading,
  OfferCardLargeTitle,
  OfferCardSection,
  OfferCardSubHeading,
  OfferCardText
} from 'src/components/moray/MorayOfferCard';
import localeService from 'src/dataServices/LocaleService';
import IIndustrySolutionContents from './IndustrySolutionContents';
import { OfferId } from '../../../state/nonprofit';
import useCommunityTrainingOffer from '../communitytraining/useCommunityTrainingOffer';
import useMC4NPOfferUpdate from './useMC4NPOfferUpdate';

interface IIndustrySolutionPlansProps {
  setRef?: (el: HTMLElement | null) => void;
}

const IndustrySolutionPlansV2 = (props: IIndustrySolutionPlansProps) => {
  const { isCommunityTrainingOfferAvailable } = useCommunityTrainingOffer();
  const { isMC4NPOfferUpdateAvailable } = useMC4NPOfferUpdate();

  var industrySolutionContents = localeService.getCategoryContent('CloudV2')
    ?.IndustrySolutionContents as IIndustrySolutionContents[];

  if (industrySolutionContents === undefined) {
    logService.logError('Missing CloudV2 localization IndustrySolutionContents.', undefined, { console: true });
    return null;
  }

  if (isMC4NPOfferUpdateAvailable) {
    industrySolutionContents = industrySolutionContents.filter(
      (industrySolution) =>
        industrySolution.id !== 'ReachAndEngageAudiencesV1' && industrySolution.id !== 'AttractRetainGrowV1'
    );
  } else {
    industrySolutionContents = industrySolutionContents.filter(
      (industrySolution) =>
        industrySolution.id !== 'ReachAndEngageAudiencesV2' && industrySolution.id !== 'AttractRetainGrowV2'
    );
  }

  return (
    <div
      className="industry-solution-plans"
      ref={(el) => {
        if (props.setRef !== undefined) {
          props.setRef(el);
        }
      }}
    >
      <OfferCardFeatureHeading>
        <h4 id={'IndustrySolutionHeaderTitle'} className="font-weight-semibold h4">
          {localeService.getText('CloudV2', 'IndustrySolutionHeaderTitle')}
        </h4>
      </OfferCardFeatureHeading>
      <OfferCardContainer paddingBottom={false}>
        {industrySolutionContents?.map((industrySolution, solutionIndex) => (
          <OfferCard key={`offer-card-${solutionIndex}`}>
            <OfferCardSection id={`offer-card-title-${solutionIndex}`}>
              <OfferCardLargeTitle id={`Card1Title-${solutionIndex}`} title={industrySolution.Title} />
            </OfferCardSection>
            <OfferCardFauxColumn />
            {industrySolution.Sections?.map((section, sectionIndex) => {
              if (section.id === OfferId.community_training && !isCommunityTrainingOfferAvailable) {
                return null;
              }

              return (
                <OfferCardSection
                  key={`offer-card-section-${solutionIndex}-${sectionIndex}`}
                  id={`offer-card-section-${solutionIndex}-${sectionIndex}`}
                >
                  <OfferCardSubHeading
                    id={`Card-${solutionIndex}-SubHeading-${solutionIndex}-${sectionIndex}`}
                    subHeading={section.Title}
                  />
                  {section.Actions?.map((action, actionIndex) => (
                    <div key={`actions-${solutionIndex}-${sectionIndex}-${actionIndex}`}>
                      <OfferCardText id={`Card-${solutionIndex}-Text-${sectionIndex}`} text={action.Title} />
                      <OfferCardButton
                        id={`Card-${solutionIndex}-Button-${sectionIndex}`}
                        buttonText={action.Button}
                        buttonLink={action.Link}
                        buttonAriaLabel={action.Title + action.Button}
                      />
                      <OfferCardFauxColumn />
                    </div>
                  ))}
                </OfferCardSection>
              );
            })}
          </OfferCard>
        ))}
      </OfferCardContainer>
    </div>
  );
};

export default IndustrySolutionPlansV2;
