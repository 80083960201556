import * as React from "react";
import localeService from "src/dataServices/LocaleService";
import { IProductCard } from "../../shared/ProductCard";
import ProductCardCarousel from "../../shared/ProductCardCarousel";

const ProductDemosM365Card = () => {
  let productCardCarouselProps: IProductCard[] = localeService.getCategoryContent("TrainingV2")
    .ProductDemosSection2ProductCards;

  for (let i = 0; i < productCardCarouselProps.length; i++) {
    productCardCarouselProps[i].imageSrc = `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/Training/${
      productCardCarouselProps[i].image
    }`;
  }
  productCardCarouselProps = productCardCarouselProps.filter(productCard => productCard.title !== undefined);

  const numCardsPerSlide = { xl: 4, lg: 4, md: 3, sm: 2, xs: 1 };

  return (
    <ProductCardCarousel productCardCarouselProps={productCardCarouselProps} numCardsPerSlide={numCardsPerSlide} />
  );
};

export default ProductDemosM365Card;
