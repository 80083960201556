import { MenuItem } from "src/state/layout";
import { INonprofitMiniProfile, NonprofitRequestStatus, NonprofitStatus, OfferId } from "src/state/nonprofit";
import localeService from "./LocaleService";

export class NavigationService {
  public getNavigationLinks(nonprofit: INonprofitMiniProfile): MenuItem[] {
    let sourceLinks: MenuItem[] = [];

    const localeContent = localeService.getLocaleContent();
    if (localeContent) {
      sourceLinks = localeContent.NavigationLinks || [];
    }

    if (sourceLinks.length === 0) {
      return sourceLinks;
    }

    let menuItems: MenuItem[] = Object.assign([], sourceLinks);
    const offersNode: MenuItem | undefined = menuItems.find(item => item.url === "#offers");
    if (
      !nonprofit ||
      !nonprofit.availableOffers ||
      nonprofit.availableOffers.length === 0 ||
      !offersNode?.submenu ||
      offersNode.submenu.length === 0
    ) {
      // Don't display offers if not available
      menuItems = menuItems.filter(item => !(item.url === "#offers"));
    } else {
      // Only display offers that are available
      const availableOfferIds = nonprofit.availableOffers.map(x => x.id);
      offersNode.submenu = offersNode.submenu.filter(item => item.id && availableOfferIds.indexOf(item.id) > -1);
      offersNode.submenu.forEach(item => (item.url = `/offers/${item.id}`));
      if (offersNode.submenu.length === 0) {
        menuItems = menuItems.filter(item => !(item.url === "#offers"));
      }
    }

    // Don't display profile when not logged in
    if (!nonprofit) {
      menuItems = menuItems.filter(item => !(item.url === "/profile"));
    }

    return menuItems;
  }

  public getNavigationLinksV2(nonprofit: INonprofitMiniProfile): MenuItem[] {
    let sourceLinks: MenuItem[] = [];
    let defaultMenuItemIds = ["resourcesandtraining", "resourcesandtrainingexternal", "contactus", "contactexternal"];

    const localeContent = localeService.getLocaleContent();
    if (localeContent) {
      sourceLinks = localeContent.NavigationLinksV2 || [];
    }

    if (sourceLinks.length === 0) {
      return sourceLinks;
    }

    // filter out hardware link where not available
    if (
      nonprofit
      && nonprofit.availableOffers
      && !nonprofit.availableOffers.some(offer => offer.id === OfferId.hardware)
      && sourceLinks.length > 0
    ) {
      let grants = sourceLinks.filter(obj => obj.id === "grants");

      if (grants.length > 0) {
        const indexOfHardware = grants[0].submenu?.findIndex(obj => obj.id === "hardware");
        if (indexOfHardware && indexOfHardware >= 0) {
          grants[0].submenu?.splice(indexOfHardware, 1);
        }
      }
    }

    let menuItems: MenuItem[] = Object.assign([], sourceLinks);
    const offersNode: MenuItem | { submenu: [] } = menuItems.find(item => item.url === "#offers") || { submenu: [] };

    if (
      nonprofit &&
      nonprofit.validationRequest &&
      (nonprofit.validationRequest.requestStatus !== NonprofitRequestStatus.Approved || nonprofit.pendingDeclineDate)
    ) {
      menuItems = menuItems.filter(item => defaultMenuItemIds.includes(item.id ?? ''));
    }

    if (!nonprofit) {
      menuItems = menuItems.filter(item => !(item.id === "lookingfor") && !(item.id === "grants") && !(item.id === "partners"));
    }

    if (
      !nonprofit ||
      !nonprofit.availableOffers ||
      nonprofit.availableOffers.length === 0 ||
      !offersNode.submenu ||
      offersNode.submenu.length === 0
    ) {
      // Don't display offers if not available
      menuItems = menuItems.filter(item => !(item.url === "#offers"));
    } else {
      // Only display offers that are available
      const availableOfferIds = nonprofit.availableOffers.map(x => x.id);
      offersNode.submenu = offersNode.submenu.filter(item => item.id && availableOfferIds.indexOf(item.id) > -1);
      offersNode.submenu.forEach(item => (item.url = `/offers/${item.id}`));
      if (offersNode.submenu.length === 0) {
        menuItems = menuItems.filter(item => !(item.url === "#offers"));
      }
    }

    if (!nonprofit || (nonprofit && nonprofit.status !== NonprofitStatus.Approved)) {
      menuItems = menuItems.filter(item => !(item.id === "resourcesandtraining"));
    } else {
      menuItems = menuItems.filter(item => !(item.id === "resourcesandtrainingexternal"));
    }

    if (!nonprofit || (nonprofit && (nonprofit.status === NonprofitStatus.InProgress || nonprofit.status === NonprofitStatus.Closed))) {
      menuItems = menuItems.filter(item => !(item.id === "contactus"));
    } else {
      menuItems = menuItems.filter(item => !(item.id === "contactexternal"));
    }

    // Don't display profile when not logged in
    if (!nonprofit) {
      menuItems = menuItems.filter(item => !(item.url === "/profile"));
    }

    return menuItems;
  }
}

const navigationService = new NavigationService();
export default navigationService;
