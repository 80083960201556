import { IResizableImage } from 'src/components/moray/ResizeableImage';
import localeService from 'src/dataServices/LocaleService';
import HighlightBanner from '../shared/HighlightBanner';
import './CommunityTrainingTopBanner.scss';

const CommunityTrainingTopBanner = () => {
  const resizableImageProps: IResizableImage = {
    root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/CommunityTraining`,
    imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/CommunityTraining/community_training_banner.jpg`,
    imageClassName: 'card-img w-100',
    imageAlt: localeService.getText('CommunityTrainingV2', 'TopBannerImgAlt')
  };

  return (
    <HighlightBanner
      titleText={localeService.getText('CommunityTrainingV2', 'TopBannerTitle')}
      descriptionText={localeService.getText('CommunityTrainingV2', 'TopBannerDescription')}
      buttonText={localeService.getText('CommunityTrainingV2', 'TopBannerButtonText')}
      buttonOnClick={() => window.open(localeService.getText('CommunityTrainingV2', 'TopBannerButtonLink'), '_blank')}
      buttonAriaLabel={localeService.getText('CommunityTrainingV2', 'TopButton1AriaText')}
      resizableImageProps={resizableImageProps}
      cardContainerClassNames="top-banner-card-container px-lg-3 py-lg-4"
    />
  );
};

export default CommunityTrainingTopBanner;
