import { all, apply, fork, put, takeEvery } from "redux-saga/effects";
import { IApiResponse } from "src/common/ApiResponse";
import contactUsService from "src/dataServices/ContactUsService";
import ResourceService from "src/dataServices/ResourceService";
import { ActionType } from "typesafe-actions";
import { NonprofitError } from "../nonprofit";
import { ICountryCode } from "../shared";
import {
  contactusResourcesError,
  contactusResourcesSuccess,
  createSupportTicket,
  createSupportTicketError,
  createSupportTicketSuccess
} from "./actions";
import { ContactUsActionTypes, ISupportTicketResponse } from "./types";

export function* handleGetContactusResourcesRequest() {
  try {
    const countriesResponse: IApiResponse<ICountryCode[]> = yield apply(
      ResourceService,
      ResourceService.getCountryCodes,
      []
    );

    if (countriesResponse.status === 200) {
      yield put(contactusResourcesSuccess(countriesResponse.value));
    } else {
      const error = "Error in fetching resources for Contact Us from API.";
      console.error(error);
      yield errorAndRedirect(NonprofitError.Unknown);
    }
  } catch (error) {
    console.error("Error in fetching resources for Contact Us from API.", error);
    yield errorAndRedirect(NonprofitError.Unknown);
  }
}

export function* handleCreateSupportTicket(action: ActionType<typeof createSupportTicket>) {
  try {
    const response: IApiResponse<ISupportTicketResponse> = yield apply(
      contactUsService,
      contactUsService.CreateSupportTicket,
      [action.payload]
    );
    if (response.status === 200) {
      yield put(createSupportTicketSuccess(response.value));
    } else {
      console.error("Error occured while creating support ticket");
      yield put(createSupportTicketError());
    }
  } catch (error) {
    console.error("Error occured while creating support ticket");
    yield put(createSupportTicketError());
  }
}

function* errorAndRedirect(error: NonprofitError) {
  yield all([put(contactusResourcesError(error))]);
}
function* watchCreateSupportTicket() {
  yield takeEvery(ContactUsActionTypes.CONTACTUS_CREATESUPPORTTICKET, handleCreateSupportTicket);
}
function* watchContactUsResourcesRequest() {
  yield takeEvery(ContactUsActionTypes.CONTACT_RESOURCES_REQUEST, handleGetContactusResourcesRequest);
}
function* ContactUsSaga() {
  yield all([fork(watchCreateSupportTicket), fork(watchContactUsResourcesRequest)]);
}

export default ContactUsSaga;
