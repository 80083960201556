import * as React from "react";
import { IResizableImage } from "src/components/moray/ResizeableImage";
import localeService from "src/dataServices/LocaleService";
import HighlightBanner from "../shared/HighlightBanner";

const TrainingTopBanner = () => {
  const resizableImageProps: IResizableImage = {
    root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/Training`,
    imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/Training-Highlight-540.jpg`,
    imageClassName: "card-img",
    imageAlt: localeService.getText("LinkedInV2", "HighlightBannerTitle"),
    fileSize1920: "Training-Highlight-1400.jpg",
    fileSize1400: "Training-Highlight-1400.jpg",
    fileSize1084: "Training-Highlight-1084.jpg",
    fileSize860: "Training-Highlight-860.jpg",
    fileSize540: "Training-Highlight-540.jpg",
    fileSize360: "Training-Highlight-360.jpg"
  };

  return (
    <HighlightBanner
      reverseTextColor={false}
      titleText={localeService.getText("TrainingV2", "HighlightBannerTitle")}
      descriptionText={localeService.getText("TrainingV2", "HighlightBannerDescription")}
      buttonAriaLabel={localeService.getText("TrainingV2", "HighlightBannerButton")}
      resizableImageProps={resizableImageProps}
      gradient="right"
    />
  );
};

export default TrainingTopBanner;
