import "@ms-mwf/moray/dist/css/main.css";
import * as React from "react";
import image from "src/assets/images/contactustopbanner.jpg";
import localeService from "src/dataServices/LocaleService";

class ContactUsV2TopBanner extends React.Component {
  public render() {
    return (
      <div className="card mx-md-0">
        <div className="row no-gutters material-surface">
          <div
            className="col-sm"
            style={{
              backgroundPosition: "top center",
              maxHeight: "300px",
              height: "45vw",
              width: "100%",
              backgroundImage: `url(${image})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat"
            }}
          >
            <div className="card-body p-4 p-md-5 w-lg-col-11 w-xl-col-8 pl-md-g">
              <div id="ContactUsTopBannerText" style={{ paddingLeft: "30px" }}>
                <h2 className="h3 ">{localeService.getText("ContactUsTopBanner", "Title")}</h2>
                <div className="mb-4" style={{ width: "50%", margin: 0 }}>
                  <p>{localeService.getText("ContactUsTopBanner", "Detail")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUsV2TopBanner;
