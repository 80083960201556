import ActionStepCard from "src/components/offers/shared/ActionStepCard";
import ActionSteps from "src/components/offers/shared/ActionSteps";
import localeService from "src/dataServices/LocaleService";
import { scrollOptions } from "src/common/utilities/Utilities";

export interface ID365Steps {
  solutionsElement: HTMLElement | null;
}

export default function D365Steps(props: ID365Steps) {
  const step1ImagePath = "hub-redesign/ActionStep_1_3x.png";
  const step2ImagePath = "hub-redesign/ActionStep_2_3x.png";
  const step3ImagePath = "hub-redesign/ActionStep_3_3x.png";
  const { solutionsElement } = props;

  return (
    <div>
      <ActionSteps
        stepsHeader={localeService.getText("Dynamic365V2", "StepsHeader")}
        step1Card={
          <ActionStepCard
            stepIconImagePath={step1ImagePath}
            cardTextCategory="Dynamic365V2"
            cardTitle="StepOneTitle"
            cardDescription="StepOneDescription"
            ctaText="StepOneAction"
            onClickAction={() => {
              if (solutionsElement !== null) {
                solutionsElement.scrollIntoView(scrollOptions);
              }
            }}
          />
        }
        step2Card={
          <ActionStepCard
            stepIconImagePath={step2ImagePath}
            cardTextCategory="Dynamic365V2"
            cardTitle="StepTwoTitle"
            cardDescription="StepTwoDescription"
            ctaText="StepTwoAction"
            onClickAction={() => {
              window.open(localeService.getText("Urls", "ActivateD365License"));
            }}
          />
        }
        step3Card={
          <ActionStepCard
            stepIconImagePath={step3ImagePath}
            cardTextCategory="Dynamic365V2"
            cardTitle="StepThreeTitle"
            cardDescription="StepThreeDescription"
            ctaText="StepThreeAction"
            onClickAction={() => {
              window.open(localeService.getText("Urls", "D365CustomerStories"));
            }}
          />
        }
      />
    </div>
  );
}
