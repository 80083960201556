import { Icon } from "@fluentui/react";
import "@ms-mwf/moray/dist/css/main.css";
import * as React from "react";
import localeService from "src/dataServices/LocaleService";

export interface IContactUsV2FeaturesCardProps {
  iconName: string;
  titleId: string;
  detailId: string;
  buttonId: string;
  href: string | undefined;
}
class ContactUsV2FeaturesCard extends React.Component<IContactUsV2FeaturesCardProps> {
  constructor(props: IContactUsV2FeaturesCardProps) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <div
        className="col mb-4 mb-md-0"
        style={{
          padding: "20px 0"
        }}
      >
        <div className="card " data-mount="click-group">
          <div
            className="row "
            style={{
              margin: "0"
            }}
          >
            <div
              className="col-2"
              style={{
                padding: "0"
              }}
            >
              <div
                className="nonprofit-offers-icon"
                style={{
                  textAlign: "center"
                }}
              >
                <Icon
                  iconName={this.props.iconName}
                  style={{
                    fontSize: "48px",
                    lineHeight: "56px",
                    color: "#0067B8"
                  }}
                />
              </div>
            </div>
            <div className="col">
              <div className="card-body ">
                <h2
                  style={{
                    fontSize: "21px",
                    lineHeight: "26px"
                  }}
                >
                  {localeService.getText("ContactUsFeatures", this.props.titleId)}
                </h2>
                <p
                  style={{
                    margin: "8px 0"
                  }}
                >
                  {localeService.getText("ContactUsFeatures", this.props.detailId)}
                </p>
                <div
                  className="link-group"
                  style={{
                    margin: "0"
                  }}
                >
                  <a
                    href={this.props.href}
                    className="cta"
                    style={{
                      margin: "0"
                    }}
                  >
                    {localeService.getText("ContactUsFeatures", this.props.buttonId)}
                    <span className="glyph" aria-hidden="true"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUsV2FeaturesCard;
