import * as React from "react";
import ActionSteps from "../shared/ActionSteps";
import ActionStepCard from "../shared/ActionStepCard";
import localeService from "src/dataServices/LocaleService";
import { connect } from "react-redux";
import { IApplicationState } from "src/state";
import { BingAdsRegistrationState } from "src/state/offers/bing-ads/types";
import "./BingAds.scss";

interface IPropsFromState {
  bingAds: BingAdsRegistrationState;
}

interface IPropsFromParent {
  onClickActivate: () => any;
}

export type IBingAdsOfferDetailsProps = IPropsFromState & IPropsFromParent;

export const BingAdsOfferDetails: React.FunctionComponent<IBingAdsOfferDetailsProps> = props => {
  const { bingAds } = props;
  return (
    <>
      {
        <ActionSteps
          stepsHeader={localeService.getText("BingAds", "OfferDetails")}
          step1Card={
            <ActionStepCard
              stepIconImagePath="hub-redesign/ActionStep_1_3x.png"
              cardTextCategory="BingAds"
              cardTitle="ActivateYourGrant"
              cardDescription="ActivateYourGrantDetails"
              ctaText="ActivateNow"
              onClickAction={() => props.onClickActivate()}
              ctaDisabled={bingAds && bingAds.adsRegistrationInfo !== null && bingAds.adsRegistrationInfo !== undefined}
            />
          }
          step2Card={
            <ActionStepCard
              stepIconImagePath="hub-redesign/ActionStep_2_3x.png"
              cardTextCategory="BingAds"
              cardTitle="SetUpGrant"
              cardDescription="SetUpGrantDetails"
              ctaText="WatchDemo"
              onClickLink={"https://aka.adsforsocialimpact.com"}
            />
          }
          step3Card={
            <ActionStepCard
              stepIconImagePath="hub-redesign/ActionStep_3_3x.png"
              cardTextCategory="BingAds"
              cardTitle="LearnMore"
              cardDescription="LearnMoreDetails"
              ctaText="LearnMore"
              onClickLink={"https://aka.createyourfirstad.com"}
            />
          }
        />
      }
    </>
  );
};

const mapStateToProps = ({ offers }: IApplicationState) => ({
  bingAds: offers.bingAds
});

export default connect(mapStateToProps)(BingAdsOfferDetails);
